<template>
  <v-row>
    <template v-if="item.question.isImage">
      <v-col cols="12">
        <p class="caption">
          {{ item.question.label }}
        </p>
        <v-radio-group v-model="selectedValue" :disabled="readOnly">
          <v-radio
            v-for="option in options"
            :key="option.id"
            :value="option.value"
            :readonly="readOnly"
          >
            <template v-slot:label>
              <img :src="option.value" />
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </template>
    <template v-else>
      <template v-if="item.question.showAllOptions">
        <v-col cols="12">
          <p class="caption">
            {{ item.question.label }}
          </p>
          <v-radio-group
            v-model="selectedValue"
            :disabled="readOnly"
            @input="onInputChange"
            @change="onInputChange"
          >
            <v-radio
              v-for="option in options"
              :key="option.id"
              :value="option.value"
              :label="option.label"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" class="mb-2">
          <validation-provider
            slim
            v-slot="{ errors, valid }"
            :name="item.question.label"
            :rules="{
              required: isRequired,
            }"
            ref="field"
          >
            <v-select
              :items="options"
              item-text="label"
              item-value="value"
              :label="item.question.label"
              v-model="selectedValue"
              @input="onInputChange"
              @change="onInputChange"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
              hide-details
            >
            </v-select>
          </validation-provider>
        </v-col>
      </template>
    </template>

    <v-col cols="12" v-if="showTextInput">
      <validation-provider
        v-slot="{ errors, valid }"
        :name="item.question.label"
        rules="required"
      >
        <v-text-field
          v-model="otherValue"
          hide-details
          :error-messages="errors"
          :success="valid"
          color="#3F51B5"
          :name="item.id"
          @input="onInputChange"
          @change="onInputChange"
          :disabled="readOnly"
          :label="item.question.label"
        >
        </v-text-field>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GroupSingleSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    selected: Boolean,
    readOnly: Boolean,
  },
  computed: {
    options() {
      if (this.item.question.isImage) {
        return [...this.item.question.options];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...this.item.question.options,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...this.item.question.options];
        }
      }
    },
    showTextInput() {
      return this.selectedValue === "other";
    },
    isRequired() {
      return this.item.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValue: "",
    };
  },
  mounted() {
    this.validateField();
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const result = await this.$refs?.field?.validateSilent();
      const { valid = true } = result ?? {};
      const { item } = this;
      const { id, question } = item;
      this.$emit("validated", {
        valid,
        label: question.label,
        id,
      });
    },
    async onInputChange() {
      if (this.selectedValue === "other") {
        this.$emit("input", this.otherValue);
      } else {
        this.$emit("input", this.selectedValue);
      }
      await this.validateField();
    },
  },
  beforeMount() {
    const { value } = this;
    const values = this.options.map((o) => o.value);
    if (value && !values.includes(value)) {
      this.otherValue = value;
      this.selectedValue = "other";
    } else {
      this.selectedValue = value;
    }
  },
  watch: {
    value: {
      handler(val) {
        const values = this.options.map((o) => o.value);
        if (val && !values.includes(val)) {
          this.otherValue = val;
          this.selectedValue = "other";
        } else {
          this.selectedValue = val;
        }
      },
    },
    isRequired() {
      this.validateField();
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff8e1;
}
</style>
