<template>
  <div
    v-if="itemInfo && itemInfo.symbol"
    @click="addSymbol(itemInfo)"
    class="px-4 py-2"
  >
    <div v-if="itemInfo.symbol.type === 'picture-marker'">
      <div class="d-flex align-center">
        <div
          class="icon d-flex align-center justify-center"
          v-show="viewSymbols"
        >
          <img
            class="mr-2"
            :style="{
              width: `${itemInfo.symbol.width}px`,
              height: `${itemInfo.symbol.height}px`,
            }"
            :src="itemInfo.symbol.url"
          />
        </div>
        <div>{{ itemInfo.label }}</div>
      </div>
    </div>
    <div v-else-if="itemInfo.symbol.type === 'simple-marker'" class="mx-0 px-0">
      <div class="d-flex align-center">
        <div
          class="icon d-flex align-center justify-center"
          v-show="viewSymbols"
        >
          <RendererCircle
            v-if="itemInfo.symbol.style === 'circle'"
            :itemInfo="itemInfo"
          />
          <Square
            v-else-if="itemInfo.symbol.style === 'square'"
            :itemInfo="itemInfo"
          />
          <Diamond
            v-else-if="itemInfo.symbol.style === 'diamond'"
            :itemInfo="itemInfo"
          />
          <Cross
            v-else-if="itemInfo.symbol.style === 'cross'"
            :itemInfo="itemInfo"
          />
          <XCross
            v-else-if="itemInfo.symbol.style === 'x'"
            :itemInfo="itemInfo"
          />
          <template v-else>
            <div
              v-if="
                itemInfo.symbol.outline &&
                itemInfo.symbol.color &&
                itemInfo.symbol.outline.color
              "
              :style="{
                'border-radius': '50%',
                width: `${itemInfo.symbol.size}pt`,
                height: `${itemInfo.symbol.size}pt`,
                border: `${itemInfo.symbol.outline.style} ${itemInfo.symbol.outline.width}px rgba(${itemInfo.symbol.outline.color[0]},${itemInfo.symbol.outline.color[1]},${itemInfo.symbol.outline.color[2]},${itemInfo.symbol.color[3]})`,
                background: Array.isArray(itemInfo.symbol.color)
                  ? `rgba(${itemInfo.symbol.color[0]},${itemInfo.symbol.color[1]},${itemInfo.symbol.color[2]},${itemInfo.symbol.color[3]})`
                  : `rgba(${itemInfo.symbol.color.r},${itemInfo.symbol.color.g},${itemInfo.symbol.color.b},${itemInfo.symbol.color.a})`,
              }"
            ></div>
          </template>
        </div>
        <div>{{ itemInfo.label }}</div>
      </div>
    </div>
    <div v-else-if="itemInfo.symbol.type === 'simple-fill'">
      <div class="d-flex align-center">
        <div class="icon" v-show="viewSymbols">
          <svg overflow="hidden" width="30" height="30">
            <defs></defs>
            <path
              :fill="
                Array.isArray(itemInfo.symbol.color)
                  ? `rgb(${itemInfo.symbol.color[0]},${itemInfo.symbol.color[1]},${itemInfo.symbol.color[2]})`
                  : `rgba(${itemInfo.symbol.color.r},${itemInfo.symbol.color.g},${itemInfo.symbol.color.b},${itemInfo.symbol.color.a})`
              "
              fill-opacity="0.5"
              :stroke="`rgb(${itemInfo.symbol.outline.color[0]},${itemInfo.symbol.outline.color[1]},${itemInfo.symbol.outline.color[2]})`"
              stroke-opacity="0.5"
              :stroke-width="itemInfo.symbol.outline.width"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              :stroke-miterlimit="itemInfo.symbol.outline.width"
              path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
              d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
              fill-rule="evenodd"
              stroke-dasharray="16,12"
              transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
            ></path>
          </svg>
        </div>
        <div>{{ itemInfo.label }}</div>
      </div>
    </div>
    <div v-else-if="itemInfo.symbol.type === 'simple-line'">
      <div class="d-flex align-center">
        <div class="icon" v-show="viewSymbols">
          <RendererLine :itemInfo="itemInfo" />
        </div>
        <div>{{ itemInfo.label }}</div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-center">
        <div class="icon" v-show="viewSymbols">X</div>
        <div>{{ itemInfo.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import RendererCircle from "@/components/shared/renderer-item/RendererCircle";
import Square from "@/components/shared/renderer-item/Square";
import Diamond from "@/components/shared/renderer-item/Diamond";
import Cross from "@/components/shared/renderer-item/Cross";
import XCross from "@/components/shared/renderer-item/XCross";
import RendererLine from "@/components/shared/renderer-item/RendererLine";

export default {
  name: "RendererItem",
  props: {
    itemInfo: Object,
    viewSymbols: Boolean,
  },
  components: {
    RendererCircle,
    Square,
    Diamond,
    Cross,
    XCross,
    RendererLine,
  },
  methods: {
    addSymbol(itemInfo) {
      this.$emit("add-symbol", itemInfo);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 40px;
}
</style>
