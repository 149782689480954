var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.values, function(v, index) {
      return _c(
        "v-col",
        {
          key: index,
          staticClass: "mt-0 mb-0 pt-0 pb-0",
          attrs: { cols: "12" }
        },
        [
          ["TEXT", "EMAIL"].includes(_vm.item.question.type)
            ? [
                _vm.item.question.isLong
                  ? [
                      _c("validation-provider", {
                        ref: "field",
                        refInFor: true,
                        attrs: {
                          slim: "",
                          name: _vm.item.question.label,
                          rules: {
                            required: _vm.isRequired,
                            email: _vm.item.question.type === "EMAIL",
                            max: Boolean(_vm.item.question.max_length)
                              ? _vm.item.question.max_length
                              : Infinity
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var valid = ref.valid
                                var validated = ref.validated
                                return [
                                  _c("QuillEditor", {
                                    attrs: {
                                      label:
                                        _vm.item.number +
                                        " " +
                                        _vm.item.question.label,
                                      placeholder:
                                        _vm.item.question.placeholder,
                                      validated: validated,
                                      valid: valid,
                                      errors: errors,
                                      multipleItems: _vm.values.length > 1,
                                      allowMultiple: _vm.item.allowMultiple,
                                      disabled: _vm.isDisabled,
                                      maxChars: +_vm.item.question.max_length
                                    },
                                    on: {
                                      "remove-entry": _vm.removeEntry,
                                      "add-entry": _vm.addEntry,
                                      input: _vm.onInput
                                    },
                                    model: {
                                      value: v.text,
                                      callback: function($$v) {
                                        _vm.$set(v, "text", $$v)
                                      },
                                      expression: "v.text"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  : [
                      _vm.item.question.forInformationOnly
                        ? [
                            _c("label", { staticClass: "caption" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.item.number +
                                      " " +
                                      _vm.item.question.label
                                  ) +
                                  " "
                              )
                            ]),
                            _c("p", [_vm._v(_vm._s(v.text))])
                          ]
                        : [
                            _c("validation-provider", {
                              ref: "field",
                              refInFor: true,
                              attrs: {
                                slim: "",
                                name: _vm.item.question.label,
                                rules: {
                                  required: _vm.isRequired,
                                  email: _vm.item.question.type === "EMAIL",
                                  max: Boolean(_vm.item.question.max_length)
                                    ? _vm.item.question.max_length
                                    : Infinity
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      return [
                                        _c(
                                          "v-text-field",
                                          {
                                            key: index,
                                            attrs: {
                                              label:
                                                _vm.item.number +
                                                " " +
                                                _vm.item.question.label,
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "#3F51B5",
                                              name: _vm.item.id,
                                              hint:
                                                _vm.item.question.placeholder,
                                              "persistent-hint": "",
                                              disabled: _vm.isDisabled,
                                              maxlength:
                                                _vm.item.question.max_length
                                            },
                                            on: { input: _vm.onInput },
                                            model: {
                                              value: v.text,
                                              callback: function($$v) {
                                                _vm.$set(v, "text", $$v)
                                              },
                                              expression: "v.text"
                                            }
                                          },
                                          [
                                            _vm.item.allowMultiple
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      slot: "append",
                                                      color: "red"
                                                    },
                                                    on: { click: _vm.addEntry },
                                                    slot: "append"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPlus) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.values.length > 1
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      slot: "append",
                                                      color: "green"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeEntry(
                                                          index
                                                        )
                                                      }
                                                    },
                                                    slot: "append"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiMinus) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "caption",
                                            style: {
                                              color: _vm.getCharLimitTextColor(
                                                _vm.item.question,
                                                v.text
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getCharLimitHint(
                                                    _vm.item.question,
                                                    v.text
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                    ]
              ]
            : _vm._e(),
          ["NUMBER"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  ref: "field",
                  refInFor: true,
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                      regex: /^\-?\d+(\.\d+)?$/
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c(
                              "v-text-field",
                              {
                                key: index,
                                attrs: {
                                  label:
                                    _vm.item.number +
                                    " " +
                                    _vm.item.question.label,
                                  "hide-details": "auto",
                                  "error-messages": errors,
                                  success: valid,
                                  color: "#3F51B5",
                                  name: _vm.item.id,
                                  hint: _vm.item.question.placeholder,
                                  "persistent-hint": "",
                                  disabled: _vm.isDisabled,
                                  maxlength: _vm.item.question.max_length
                                },
                                on: {
                                  keyup: function($event) {
                                    return _vm.onNumberInput(errors)
                                  },
                                  keydown: _vm.onNumberKeyDown
                                },
                                model: {
                                  value: v.text,
                                  callback: function($$v) {
                                    _vm.$set(v, "text", $$v)
                                  },
                                  expression: "v.text"
                                }
                              },
                              [
                                _vm.item.allowMultiple
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: { slot: "append", color: "red" },
                                        on: { click: _vm.addEntry },
                                        slot: "append"
                                      },
                                      [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]
                                    )
                                  : _vm._e(),
                                _vm.values.length > 1
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          slot: "append",
                                          color: "green"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeEntry(index)
                                          }
                                        },
                                        slot: "append"
                                      },
                                      [_vm._v(" " + _vm._s(_vm.mdiMinus) + " ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "caption",
                                style: {
                                  color: _vm.getCharLimitTextColor(
                                    _vm.item.question,
                                    v.text
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getCharLimitHint(
                                        _vm.item.question,
                                        v.text
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            : _vm._e()
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }