<template>
  <v-dialog :value="showUnsavedMarkupDialog" width="500px" persistent>
    <v-card>
      <v-card-text class="py-4">
        <h1 class="font-weight-regular">Unsaved Markups</h1>
        <p class="py-2">
          You have unsaved markups. If you proceed, all your unsaved markups
          will be lost. Would you like to proceed?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end gap flex-wrap">
        <v-btn
          text
          color="#3F51B5"
          @click="$emit('cancel-markup-image-dialog-close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="#3F51B5"
          dark
          @click="$emit('confirm-markup-image-dialog-close')"
        >
          Discharge Changes and Proceed
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnsavedMarkupDialog",
  props: {
    showUnsavedMarkupDialog: Boolean,
  },
};
</script>
