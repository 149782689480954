<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{ required: isRequired }"
        ref="field"
      >
        <div>
          <v-menu
            max-width="100%"
            :close-on-content-click="false"
            v-model="showDatePicker"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :label="item.question.label"
                color="#3F51B5"
                v-model="dateValue"
                @input="onInput"
                :error-messages="errors"
                :success="valid"
                :disabled="readOnly"
                readonly
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateValue" @input="onInput"></v-date-picker>
          </v-menu>
        </div>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "GroupDateInput",
  props: {
    item: Object,
    readOnly: Boolean,
    value: String,
  },
  computed: {
    isRequired() {
      return this.item.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      dateValue: undefined,
      showDatePicker: false,
    };
  },
  beforeMount() {
    this.dateValue = cloneDeep(this.value);
  },
  mounted() {
    this.validateField();
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const { valid } = await this.$refs.field.validateSilent();
      const { item } = this;
      const { id, number, question } = item;
      this.$emit("validated", {
        valid,
        label: `${number} ${question.label}`,
        id,
      });
    },
    async onInput() {
      await this.validateField();
      this.$emit("input", this.dateValue);
      this.showDatePicker = false;
    },
  },
};
</script>
