var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      ref: "toolbar",
      attrs: { dark: "", width: "100%", color: "#3F51B5", height: "56px" }
    },
    [
      _c(
        "div",
        {
          staticClass: "mr-3 text-truncate",
          staticStyle: { width: "25%", "max-width": "25%" }
        },
        [_c("div", [_vm._v("Crop")])]
      ),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "ml-n3",
          attrs: { text: "", color: "white" },
          on: {
            click: function($event) {
              return _vm.$emit("reset")
            }
          }
        },
        [_vm._v(" Reset ")]
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close-button-click")
            }
          }
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }