var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "m-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { justify: "space-around" } },
            [
              _c("v-color-picker", {
                attrs: {
                  value: _vm.color,
                  "hide-canvas": "",
                  "hide-inputs": "",
                  "show-swatches": "",
                  "mode.sync": "rgba"
                },
                on: { "update:color": _vm.onUpdate }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "2",
                        type: "button",
                        color: "#3F51B5"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("color-picker-cancelled")
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }