var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showFeatureServiceUnreachableDialog,
        "max-width": "550px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c(
              "section",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                      _vm._v(_vm._s(_vm.mdiClose))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("section", [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: { click: _vm.copyToClipboard }
                },
                [
                  _c("span", { staticClass: "font-weight-regular" }, [
                    _vm._v("Copy Message to Clipboard")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }