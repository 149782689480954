import {
  getGisValue,
  getOrgValue,
  getGisDataValue,
  getCurrentValue,
} from "@/mixins/getDefaultValues";

export const FIELD_MATCH_CHOICE_VALUES = {
  EQUAL: "EQUAL",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
  NOT_EQUAL: "NOT_EQUAL",
};

export const APPLY_IF = {
  ANY: "ANY",
  ALL: "ALL",
};

export const CONDITION_CHOICES = {
  ALWAYS: "ALWAYS",
  CONDITIONAL: "CONDITIONAL",
};

export const DEPENDANT_OPTIONS = {
  USER: "USER",
  GIS_DATA: "GIS_DATA",
  ORGANIZATION: "ORGANIZATION",
  FORM_ITEM: "FORM_ITEM",
  GIS: "GIS",
  CURRENT: "CURRENT",
};

export const checkCurrentValue = ({ condition, value, item }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getCurrentValue(item, value) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getCurrentValue(item, value) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getCurrentValue(item, value) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getCurrentValue(item, value) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getCurrentValue(item, value) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getCurrentValue(item, value) !== value;
  }
};

export const checkGis = ({ condition, field, value, featureAttributes }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisValue(value, featureAttributes) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisValue(value, featureAttributes) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisValue(field, featureAttributes) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisValue(field, featureAttributes) !== value;
  }
};

export const checkGisData = ({ condition, field, value, gisDataValues }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisDataValue(field, gisDataValues) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisDataValue(field, gisDataValues) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisDataValue(field, gisDataValues) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisDataValue(field, gisDataValues) !== value;
  }
};

export const checkOrgValue = ({ condition, value }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getOrgValue() === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getOrgValue() > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getOrgValue() >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getOrgValue() < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getOrgValue() <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getOrgValue() !== value;
  }
};

export const checkGisValue = ({
  condition,
  value,
  field,
  featureAttributes,
}) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisValue(field, featureAttributes) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisValue(field, featureAttributes) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisValue(field, featureAttributes) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisValue(field, featureAttributes) !== value;
  }
};

export const checkFormItemValue = ({
  condition,
  value,
  field,
  dependantItems,
}) => {
  const dependantItem = dependantItems.find((it) => {
    return +it.id === +field;
  });

  let dependantItemValue;
  if (Array.isArray(dependantItem?.value) && dependantItem?.value.length > 0) {
    [dependantItemValue] = dependantItem?.value;
  } else {
    dependantItemValue = dependantItem?.value;
  }
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return (
      (dependantItemValue === value || +dependantItemValue === +value) &&
      +dependantItem?.id === +field
    );
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +dependantItemValue > +value && +dependantItem?.id === +field;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +dependantItemValue >= +value && +dependantItem?.id === +field;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +dependantItemValue < +value && +dependantItem?.id === +field;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +dependantItemValue <= +value && +dependantItem?.id === +field;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return dependantItemValue !== value && +dependantItem?.id === +field;
  }
};

export const checkVisibleValue = ({
  type,
  field,
  condition,
  value,
  featureAttributes,
  dependantItems,
  gisDataValues,
  item,
}) => {
  if (type === DEPENDANT_OPTIONS.GIS) {
    return checkGisValue({ condition, value, field, featureAttributes });
  } else if (type === DEPENDANT_OPTIONS.FORM_ITEM) {
    return checkFormItemValue({ condition, value, field, dependantItems });
  } else if (type === DEPENDANT_OPTIONS.ORGANIZATION) {
    return checkOrgValue({ condition, value });
  } else if (type === DEPENDANT_OPTIONS.GIS_DATA) {
    return checkGisData({
      condition,
      value,
      field,
      gisDataValues,
    });
  } else if (type === DEPENDANT_OPTIONS.GIS) {
    return checkGis({ condition, value, field, featureAttributes });
  } else if (type === DEPENDANT_OPTIONS.CURRENT) {
    return checkCurrentValue({ condition, value, item });
  }
};

export const checkItemVisibilityWithItem = ({
  item,
  featureAttributes,
  dependantItems,
  gisDataValues,
}) => {
  if (
    !item?.question?.visible?.applyVisible ||
    item?.question?.visible?.applyVisible === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  if (item?.question?.visible?.applyVisible === CONDITION_CHOICES.NEVER) {
    return false;
  }

  const { applyIf, conditions } =
    item?.question?.visible?.applyVisibleConditions ?? {};
  if (applyIf === APPLY_IF.ANY) {
    return conditions.some((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        dependantItems,
        item,
        gisDataValues,
      };
      return checkVisibleValue(args);
    });
  } else if (applyIf === APPLY_IF.ALL) {
    return conditions.every((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        dependantItems,
        item,
        gisDataValues,
      };
      return checkVisibleValue(args);
    });
  }
};

export const checkItemVisibilityWithInfoItem = ({
  item,
  featureAttributes,
  dependantItems,
  gisDataValues,
}) => {
  if (
    !item?.information?.visible?.applyVisible ||
    item?.information?.visible?.applyVisible === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  if (item?.information?.visible?.applyVisible === CONDITION_CHOICES.NEVER) {
    return false;
  }

  const { applyIf, conditions } =
    item?.information?.visible?.applyVisibleConditions ?? {};
  if (applyIf === APPLY_IF.ANY) {
    return conditions.some((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        dependantItems,
        item,
        gisDataValues,
      };
      return checkVisibleValue(args);
    });
  } else if (applyIf === APPLY_IF.ALL) {
    return conditions.every((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        dependantItems,
        item,
        gisDataValues,
      };
      return checkVisibleValue(args);
    });
  }
};
