<template>
  <div
    class="d-flex justify-space-between px-3 py-2"
    style="background-color: #38383d; color: white"
  >
    <div class="d-flex gap justify-end flex-wrap align-center flex-grow-1">
      <div class="d-flex gap flex-wrap">
        <div class="d-flex flex-no-wrap align-center gap px-2">
          <div>
            Tap to add a <b>{{ customSymbolName }}</b>
          </div>
          <div>
            <v-btn icon @click="$emit('toolbar-close')">
              <v-icon color="white">{{ mdiClose }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "CustomSymbolBottomBar",
  data() {
    return {
      mdiClose,
    };
  },
  props: {
    selectedCustomSymbol: Object,
  },
  computed: {
    customSymbolName() {
      return this.selectedCustomSymbol?.label;
    },
  },
};
</script>
