<template>
  <v-dialog :value="showMakeSymbolInactiveDialog" width="500px">
    <v-card>
      <v-card-title class="font-weight-regular">
        Make Symbol Inactive
      </v-card-title>
      <v-card-text>
        If you make this symbol inactive, it will no longer show in the list of
        symbols, but it will still show in markups where it was already added.
        Do you want to make the symbol inactive?
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          color="#3F51B5"
          @click="$emit('cancel-symbol-inactive-dialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="#3F51B5"
          dark
          @click="$emit('confirm-symbol-inactive-dialog')"
        >
          Make Symbol Inactive
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MakeSymbolInactiveDialog",
  props: {
    showMakeSymbolInactiveDialog: Boolean,
  },
};
</script>
