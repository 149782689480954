var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", [
        _c("span", { staticClass: "caption" }, [
          _vm._v(
            " " +
              _vm._s(_vm.item.question.number) +
              " " +
              _vm._s(_vm.item.question.label) +
              " "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.result) + " ")]),
          _c(
            "v-btn",
            {
              staticClass: "mt-n2",
              attrs: { text: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.showCalculationDialog = true
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiInformationOutline))])],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.showCalculationDialog,
            callback: function($$v) {
              _vm.showCalculationDialog = $$v
            },
            expression: "showCalculationDialog"
          }
        },
        [
          _c("CalculationList", {
            attrs: { formDefinition: _vm.formDefinition, item: _vm.item },
            on: {
              "calculation-list-close": function($event) {
                _vm.showCalculationDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }