var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.size,
        height: _vm.size,
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", [
        _c("line", {
          attrs: {
            "stroke-width": _vm.strokeWidth,
            fill: _vm.fillColor,
            stroke: _vm.strokeColor,
            x1: 0,
            y1: _vm.size * 0.5,
            x2: _vm.size,
            y2: _vm.size * 0.5,
            id: "svg_2"
          }
        }),
        _c("line", {
          attrs: {
            "stroke-width": _vm.strokeWidth,
            id: "svg_1",
            y2: _vm.size,
            x2: _vm.size * 0.5,
            y1: 0,
            x1: _vm.size * 0.5,
            stroke: _vm.strokeColor,
            fill: _vm.fillColor
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }