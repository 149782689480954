<template>
  <v-toolbar dark width="100%" color="#3F51B5" ref="toolbar" height="56px">
    <div class="mr-3 text-truncate" style="width: 25%; max-width: 25%">
      <div>Crop</div>
    </div>

    <v-spacer />

    <v-btn text color="white" class="ml-n3" @click="$emit('reset')">
      Reset
    </v-btn>

    <v-btn icon @click="$emit('close-button-click')">
      <v-icon>{{ mdiClose }}</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "CropToolbar",
  data() {
    return {
      mdiClose,
    };
  },
};
</script>
