var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { overflow: "hidden", width: "30", height: "30" } },
    [
      _c("defs"),
      _c("path", {
        attrs: {
          fill: "none",
          "fill-opacity": "0",
          stroke: _vm.strokeColor,
          "stroke-opacity": "1",
          "stroke-width": _vm.strokeWidth,
          "stroke-linecap": "butt",
          "stroke-linejoin": "miter",
          "stroke-miterlimit": _vm.strokeWidth,
          path: "M -15,0 L 15,0 E",
          d: "M-15 0L 15 0",
          "stroke-dasharray": _vm.strokeDashArray,
          transform:
            "matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }