var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [{ name: "resize", rawName: "v-resize" }],
      staticClass: "px-3",
      attrs: { flat: "", id: "layers-card", height: "350px" }
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            { staticClass: "ma-0 pa-0" },
            [
              _c("v-list-item-content", { staticClass: "pa-0 ma-0" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c("div", { staticClass: "mt-2" }, [_vm._v("Layers")]),
                    _c(
                      "v-menu",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiDotsVertical))
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.viewSymbols = !_vm.viewSymbols
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.viewSymbols ? "Hide" : "Show"
                                      ) +
                                      " Symbols "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("Visibility", {
        attrs: {
          layers: _vm.layers,
          viewSymbols: _vm.viewSymbols,
          level: 0,
          allLayers: _vm.allLayers,
          multiLayerImages: _vm.multiLayerImages
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }