<template>
  <div class="mx-4 my-2" v-if="item.details">
    <validation-observer ref="graphicDetailsForm">
      <form>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors, valid }" name="Symbol">
              <v-text-field
                v-model="item.details.label"
                :error-messages="errors"
                :success="valid"
                label="Label"
                color="#3F51B5"
                @change="onChange"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{ errors, valid }" name="Symbol">
              <v-textarea
                outlined
                v-model="item.details.description"
                :error-messages="errors"
                :success="valid"
                label="Description"
                color="#3F51B5"
                @change="onChange"
              >
              </v-textarea>
            </validation-provider>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "GraphicDetailsForm",
  props: {
    selectedItem: Object,
  },
  data() {
    return {
      item: {},
    };
  },
  methods: {
    loadItem() {
      if (this.selectedItem) {
        this.item = cloneDeep(this.selectedItem);
        if (!this.item.details) {
          this.item.details = {};
        }
      }
    },
    async onChange() {
      const success = await this.$refs.graphicDetailsForm.validate();
      if (!success) {
        return;
      }
      this.$emit("graphic-details-changed", this.item);
    },
  },
  beforeMount() {
    this.loadItem();
  },
  watch: {
    selectedItem: {
      deep: true,
      handler() {
        this.loadItem();
      },
    },
  },
};
</script>
