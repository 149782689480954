<template>
  <section>
    <v-card v-if="['URL'].includes(item.information.type)" class="elevation-0">
      <a :href="item.information.url" target="_blank">
        {{ item.information.label }}
      </a>
    </v-card>

    <v-card
      v-if="['HEADER'].includes(item.information.type)"
      class="elevation-0"
    >
      <h2>
        {{ item.information.label }}
      </h2>
    </v-card>

    <v-card v-if="['TEXT'].includes(item.information.type)" class="elevation-0">
      <v-card-text class="pa-0 ma-0">
        <div v-html="item.information.label" class="raw-html"></div>
      </v-card-text>
    </v-card>

    <v-card
      v-if="['IMAGE'].includes(item.information.type)"
      max-height="fit-content"
      max-width="fit-content"
      class="pa-2 my-6"
      v-show="item.information.url"
    >
      <v-card-text>
        <p>{{ item.information.label }}</p>
        <a :href="item.information.openUrl" target="_blank" class="pa-0 ma-0">
          <img
            class="pa-0 ma-0"
            :src="item.information.url"
            :style="{
              'max-height': '500px',
              'max-width': '500px',
            }"
          />
        </a>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "RepeatingGroupInfo",
  props: {
    item: Object,
  },
};
</script>

<style scoped>
.raw-html >>> * {
  margin-bottom: 0;
}
</style>
