<template>
  <v-dialog :value="showSelectPdfPageDialog" max-width="200px" persistent>
    <v-card color="#4F4F4F">
      <v-toolbar dark color="black" class="elevation-0">
        <v-toolbar-title>Select Page</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('select-pdf-page-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <div class="d-flex align-center justify-center" v-if="isLoading">
          <v-progress-circular indeterminate color="white">
          </v-progress-circular>
        </div>
        <div v-else>
          <div class="d-flex justify-center align-center">
            <validation-observer ref="form" slim>
              <form @submit.prevent>
                <validation-provider name="Page" rules="numeric">
                  <v-text-field
                    v-model.number="page"
                    style="width: 60px"
                    class="page-input py-0 my-0"
                    dark
                    hide-details
                  >
                    <template #append-outer>
                      <div style="margin-top: 6px; width: 30px">
                        of {{ pdfPageImages.length }}
                      </div>
                    </template>
                  </v-text-field>
                </validation-provider>
              </form>
            </validation-observer>
          </div>

          <div class="d-flex flex-wrap justify-center">
            <img
              :style="{
                outline: index + 1 === page ? '6px solid #8AB4F8' : 'none',
                opacity: index + 1 !== page ? '0.2' : 1,
              }"
              v-for="(p, index) of pdfPageImages"
              :key="p.file_id"
              :src="p.thumbnail"
              style="width: 80px"
              class="my-5 cursor-pointer elevation-5"
              @click="onPageClick(index)"
            />
          </div>
        </div>

        <AddPageToFormDialog
          v-if="showAddPageToFormDialog"
          :showAddPageToFormDialog="showAddPageToFormDialog"
          :selectedPage="selectedPage"
          :maxWidthHeight="maxWidthHeight"
          :formResultId="formResultId"
          @add-page-to-form-dialog-close="showAddPageToFormDialog = false"
          @confirm-add-page="
            $emit('confirm-add-page', $event);
            showAddPageToFormDialog = false;
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiChevronDown } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import AddPageToFormDialog from "@/components/tickets/shared/photo-input/add-image-dialog/select-doc-dialog/select-pdf-page-dialog/AddPageToFormDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SelectPdfPageDialog",
  props: {
    showSelectPdfPageDialog: Boolean,
    selectedFile: Object,
    formResultId: String,
    maxWidthHeight: Number,
  },
  components: {
    AddPageToFormDialog,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      pdfPageImages: [],
      page: 1,
      isLoading: false,
      showAddPageToFormDialog: false,
      selectedPage: {},
    };
  },
  watch: {
    selectedFile: {
      deep: true,
      handler() {
        this.getPdfPageImages();
      },
    },
  },
  async beforeMount() {
    await this.getPdfPageImages();
  },
  methods: {
    async onPageClick(index) {
      this.page = index + 1;
      if (this.pdfPageImages[index]) {
        this.selectedPage = this.pdfPageImages[index];
        this.showAddPageToFormDialog = true;
      }
    },
    async getPdfPageImages() {
      if (!this.selectedFile?.file_id) {
        return;
      }
      this.isLoading = true;
      const { file_id: fileId } = this.selectedFile;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/files/${fileId}/images`);
      this.pdfPageImages = await Promise.all(
        results.map(async (r) => {
          const { data } = await axiosWithRegularAuth.get(
            `${APIURL}/files/${r.file_id}/resize`,
            {
              params: {
                max_size: 128,
              },
            }
          );
          const thumbnail = `data:image/jpeg+png+svg+xml;base64,${data}`;
          return {
            ...r,
            thumbnail,
          };
        })
      );
      this.isLoading = false;
    },
  },
};
</script>
