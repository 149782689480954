<template>
  <div>
    <v-menu offset-y max-height="90vh" class="cursor-pointer">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex justify-space-between align-center"
          v-bind="attrs"
          v-on="on"
        >
          <div class="pa-0 d-flex" style="color: white; line-height: 75%">
            <template v-if="markupVersionChoices.length > 0">
              {{
                selectedVersionDateTime | formatRevisionDate("MM/DD/YY hh:mm a")
              }}
            </template>
            <div v-else class="text-truncate">No saved edits</div>
          </div>
          <v-icon class="my-0 py-0" color="white">
            {{ mdiChevronDown }}
          </v-icon>
        </div>
      </template>
      <v-list v-if="markupVersionChoices.length > 0">
        <v-list-item
          v-for="(m, i) of markupVersionChoices"
          :key="`${i}-${m.value}`"
          @click="
            selectedVersionDateTime = m.value;
            $emit('revision-selected', selectedVersionDateTime);
          "
        >
          <v-list-item-content>
            <div class="d-flex align-center justify-space-between">
              <div>
                {{ m.label | formatRevisionDate("MM/DD/YY hh:mm a") }}
              </div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" x-small>
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="px-0 mx-0">
                  <v-list-item class="px-0 mx-0">
                    <v-btn text @click="deleteFileMarkup(m.label, i)">
                      <div
                        class="
                          font-weight-regular
                          capitalize
                          d-flex
                          align-center
                          gap
                        "
                      >
                        <v-icon>
                          {{ mdiDelete }}
                        </v-icon>
                        <div>Delete Markup Version</div>
                      </div>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="d-flex justify-center" v-if="hasMore">
          <v-list-item-title class="d-flex justify-center">
            <v-btn text @click.stop="$emit('show-more')"> Show More </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item class="d-flex justify-center">
          No saved edits
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mdiClose, mdiChevronDown, mdiDotsVertical, mdiDelete } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "RevisionDropdown",
  props: {
    selectedPdfPage: Object,
    selectedFile: Object,
    fileMarkupRevisions: Array,
    pdfPageFileMarkupCounts: Array,
    imageFileMarkupCount: Object,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiDotsVertical,
      mdiDelete,
      markupVersions: [],
      selectedVersionDateTime: undefined,
      maxRevisionCount: 10,
    };
  },
  computed: {
    hasMore() {
      if (this.selectedFile.s3_file_path_original_image?.endsWith(".pdf")) {
        return (
          this.pdfPageFileMarkupCounts.find(
            (p) => p.fileId === this.selectedPdfPage.file_id
          )?.count > this.markupVersionChoices.length
        );
      } else {
        return (
          this.imageFileMarkupCount.count > this.markupVersionChoices.length
        );
      }
    },
    markupVersionChoices() {
      return this.markupVersions.map((m) => {
        const { saved } = m;
        return {
          label: saved,
          value: saved,
        };
      });
    },
  },
  methods: {
    async getMarkups() {
      this.markupVersions = [...this.fileMarkupRevisions];
      this.selectedVersionDateTime = this.markupVersions?.[0]?.saved;
    },
    async deleteFileMarkup(savedVersion, index) {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        await axiosWithRegularAuth.delete(
          `${APIURL}/file_markup/${this.selectedPdfPage.file_id}/${savedVersion}`
        );
      } else {
        await axiosWithRegularAuth.delete(
          `${APIURL}/file_markup/${this.selectedFile.file_id}/${savedVersion}`
        );
      }
      this.markupVersions.splice(index, 1);
      this.$emit("revision-deleted");
    },
  },
  watch: {
    fileMarkupRevisions: {
      deep: true,
      handler() {
        this.getMarkups();
      },
    },
  },
  beforeMount() {
    this.getMarkups();
  },
};
</script>
