var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { "max-width": "500px", value: _vm.showInvalidFieldsDialog } },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "#3F51B5", "max-width": "500px" } },
        [
          _c("v-toolbar-title", [_c("div", [_vm._v("Invalid Fields")])]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.$emit("invalid-fields-dialog-close")
                }
              }
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "rounded-0" },
        [
          _c(
            "v-card-text",
            { staticClass: "overflow-y-auto", staticStyle: { height: "60vh" } },
            [
              _c("div", { staticClass: "text-center pt-3" }, [
                _vm._v(
                  " The following form fields contain invalid data or are required to be completed before the form can be submitted as final. "
                )
              ]),
              _c(
                "v-list",
                _vm._l(_vm.invalidFieldSections, function(section, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-list-item-content", [
                                _c("b", [_vm._v(_vm._s(section.name))])
                              ])
                            ],
                            1
                          ),
                          _vm._l(section.items, function(item, j) {
                            return _c(
                              "v-list-item",
                              { key: j },
                              [
                                _c("v-list-item-content", [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.number) +
                                      " " +
                                      _vm._s(item.question.label) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("invalid-fields-dialog-close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }