var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "max-width": "290px",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("validation-provider", {
                ref: "field",
                attrs: {
                  slim: "",
                  name: _vm.item.question.label,
                  rules: {
                    required: _vm.isRequired
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        var valid = ref.valid
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                staticClass: "time-field",
                                attrs: {
                                  readonly: "",
                                  value: _vm.time,
                                  "error-messages": errors,
                                  success: valid,
                                  label:
                                    _vm.item.number +
                                    " " +
                                    _vm.item.question.label,
                                  color: "#3F51B5",
                                  clearable: "",
                                  disabled: _vm.isDisabled
                                },
                                on: {
                                  "click:clear": function($event) {
                                    return _vm.$emit("input", "")
                                  }
                                }
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.showTimePicker,
        callback: function($$v) {
          _vm.showTimePicker = $$v
        },
        expression: "showTimePicker"
      }
    },
    [
      _c("v-time-picker", {
        on: { change: _vm.onInput },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }