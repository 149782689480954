var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-0 ma-0" },
    _vm._l(_vm.sortedInfos, function(info) {
      return _c(
        "div",
        { key: info.id, staticClass: "pa-0 ma-0" },
        [
          info.symbol
            ? _c("RendererItem", {
                staticClass: "feature_hover cursor-pointer",
                attrs: { itemInfo: info, viewSymbols: _vm.viewSymbols },
                on: {
                  "add-symbol": function($event) {
                    return _vm.$emit("add-symbol", $event)
                  }
                }
              })
            : _c("UtilisyncRendererItem", {
                staticClass: "feature_hover cursor-pointer",
                attrs: { itemInfo: info, viewSymbols: _vm.viewSymbols },
                on: {
                  "add-symbol": function($event) {
                    return _vm.$emit("add-symbol", $event)
                  }
                }
              })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }