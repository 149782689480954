var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.showFormIdDialog, "max-width": "400px" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("form-id-dialog-close")
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-5" }, [
            _c("div", { staticClass: "caption" }, [
              _vm._v("Form Submission ID")
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-space-between" },
              [
                _c("div", [_vm._v(_vm._s(_vm.formResultId))]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-n2",
                    attrs: { icon: "" },
                    on: { click: _vm.copyToClipboard }
                  },
                  [
                    _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                      _vm._v(" " + _vm._s(_vm.mdiContentCopy))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }