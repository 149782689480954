var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "p",
        {
          staticClass: "caption",
          style: { color: _vm.validated && !_vm.valid ? "#ff5252" : undefined }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c("div", { attrs: { id: "quill-toolbar-" + _vm.ID } }, [
        _vm.showExtraButtons
          ? _c("select", { staticClass: "ql-size" })
          : _vm._e(),
        _c("button", { staticClass: "ql-bold" }),
        _c("button", { staticClass: "ql-italic" }),
        _c("button", { staticClass: "ql-underline" }),
        _vm.showExtraButtons
          ? _c("button", { staticClass: "ql-strike" })
          : _vm._e(),
        _vm.showExtraButtons
          ? _c("select", { staticClass: "ql-align" })
          : _vm._e(),
        _c("button", { staticClass: "ql-list", attrs: { value: "ordered" } }),
        _c("button", { staticClass: "ql-list", attrs: { value: "bullet" } }),
        _vm.showExtraButtons
          ? _c("select", { staticClass: "ql-color" })
          : _vm._e(),
        _c("button", { staticClass: "ql-link" })
      ]),
      _c(
        "div",
        {
          style: { height: _vm.height },
          attrs: { id: "quill-editor-" + _vm.ID }
        },
        [_vm._v(" " + _vm._s(_vm.inputValue) + " ")]
      ),
      _c("p", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.placeholder))]),
      Array.isArray(_vm.errors)
        ? _c("p", { staticClass: "caption red--text" }, [
            _vm._v(" " + _vm._s(_vm.errors[0]) + " ")
          ])
        : _vm._e(),
      _vm.allowMultiple
        ? _c(
            "v-icon",
            {
              staticClass: "cursor-pointer",
              attrs: { slot: "append", color: "red" },
              on: {
                click: function($event) {
                  return _vm.$emit("add-entry")
                }
              },
              slot: "append"
            },
            [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]
          )
        : _vm._e(),
      _vm.multipleItems
        ? _c(
            "v-icon",
            {
              attrs: { slot: "append", color: "green" },
              on: {
                click: function($event) {
                  return _vm.$emit("remove-entry", _vm.index)
                }
              },
              slot: "append"
            },
            [_vm._v(" " + _vm._s(_vm.mdiMinus) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }