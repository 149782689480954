var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "my-0 pt-0", attrs: { cols: "12" } },
        [
          ["TEXT", "EMAIL"].includes(_vm.item.question.type)
            ? [
                _vm.item.question.isLong
                  ? [
                      _c("validation-provider", {
                        ref: "field",
                        attrs: {
                          slim: "",
                          name: _vm.item.question.label,
                          rules: {
                            required: _vm.isRequired,
                            email: _vm.item.question.type === "EMAIL",
                            max: Boolean(_vm.item.question.max_length)
                              ? _vm.item.question.max_length
                              : Infinity
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var valid = ref.valid
                                var validated = ref.validated
                                return [
                                  _c("QuillEditor", {
                                    attrs: {
                                      label: "" + _vm.item.question.label,
                                      placeholder:
                                        _vm.item.question.placeholder,
                                      validated: validated,
                                      valid: valid,
                                      errors: errors,
                                      allowMultiple: _vm.item.allowMultiple,
                                      disabled: _vm.readOnly,
                                      maxChars: +_vm.item.question.max_length
                                    },
                                    on: { input: _vm.onInput },
                                    model: {
                                      value: _vm.textValue,
                                      callback: function($$v) {
                                        _vm.textValue = $$v
                                      },
                                      expression: "textValue"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          661857319
                        )
                      })
                    ]
                  : [
                      _vm.item.question.forInformationOnly
                        ? [
                            _c("label", { staticClass: "caption" }, [
                              _vm._v(_vm._s(_vm.item.question.label))
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.item.value))])
                          ]
                        : [
                            _c("validation-provider", {
                              ref: "field",
                              attrs: {
                                slim: "",
                                name: _vm.item.question.label,
                                rules: {
                                  required: _vm.isRequired,
                                  max: Boolean(_vm.item.question.max_length)
                                    ? _vm.item.question.max_length
                                    : Infinity
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.item.question.label,
                                            name: _vm.item.id,
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "#3F51B5",
                                            hint: _vm.item.question.placeholder,
                                            "persistent-hint": "",
                                            disabled: _vm.readOnly,
                                            maxlength:
                                              _vm.item.question.max_length
                                          },
                                          on: { input: _vm.onInput },
                                          model: {
                                            value: _vm.textValue,
                                            callback: function($$v) {
                                              _vm.textValue = $$v
                                            },
                                            expression: "textValue"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1927564920
                              )
                            })
                          ]
                    ]
              ]
            : _vm._e(),
          ["NUMBER"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                      regex: /^\d+(\.\d+)?$/
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.item.question.label,
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "#3F51B5",
                                name: _vm.item.id,
                                hint: _vm.item.question.placeholder,
                                "persistent-hint": "",
                                disabled: _vm.readOnly,
                                type: "number"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onNumberInput(valid)
                                },
                                keydown: _vm.onNumberKeyDown
                              },
                              model: {
                                value: _vm.textValue,
                                callback: function($$v) {
                                  _vm.textValue = _vm._n($$v)
                                },
                                expression: "textValue"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    998609712
                  )
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }