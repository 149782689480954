<template>
  <div
    class="pr-3 d-flex justify-center align-center"
    style="width: 40px"
    v-if="symbol && symbol.symbol"
  >
    <template v-if="symbol.symbol.symbolType === SYMBOL_TYPES.SHAPE">
      <img
        v-if="symbol.symbol.shape === SHAPE_TYPES.MAP_SYMBOL"
        :src="require(`@/assets${symbol.symbol.shapeUrl}`)"
        :style="{
          width: `${symbol.symbol.size}px`,
          height: `${symbol.symbol.size}px`,
        }"
      />
      <div v-else-if="symbol.symbol.shape === SHAPE_TYPES.ELLIPSE">
        <div
          :style="{
            width: '30px',
            height: '25px',
            'border-radius': '50%',
            'background-color': symbol.symbol.shapeFill,
            border: `1px solid ${symbol.symbol.shapeOutlineColor}`,
          }"
        ></div>
      </div>
      <div v-else-if="symbol.symbol.shape === SHAPE_TYPES.RECTANGLE">
        <div
          :style="{
            width: '30px',
            height: '25px',
            'background-color': symbol.symbol.shapeFill,
            border: `1px solid ${symbol.symbol.shapeOutlineColor}`,
          }"
        ></div>
      </div>
      <div v-else-if="symbol.symbol.shape === SHAPE_TYPES.POLYGON">
        <v-icon>
          {{ mdiVectorPolygon }}
        </v-icon>
      </div>
    </template>
    <img
      v-else-if="symbol.symbol.symbolType === SYMBOL_TYPES.IMAGE"
      :src="symbol.symbol.imageUrl"
      :style="{
        width: `${symbol.symbol.size}px`,
        height: `${symbol.symbol.size}px`,
      }"
    />
    <div
      v-else-if="symbol.symbol.symbolType === SYMBOL_TYPES.LINE"
      :style="{
        'background-color': symbol.symbol.lineStroke,
        height: `${symbol.symbol.lineStrokeWidth}px`,
        width: '25px',
      }"
    ></div>
    <div
      v-else-if="symbol.symbol.symbolType === SYMBOL_TYPES.POINT"
      :style="{
        'background-color': symbol.symbol.pointFill,
        width: `${symbol.symbol.size}px`,
        height: `${symbol.symbol.size}px`,
        'border-radius': '50%',
        border: `1px solid ${symbol.symbol.pointOutlineColor}`,
      }"
    ></div>
  </div>
</template>

<script>
import SYMBOL_TYPES from "@/constants/symbolTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";

export default {
  name: "SymbolIconDisplay",
  props: {
    symbol: Object,
  },
  data() {
    return {
      SYMBOL_TYPES,
      SHAPE_TYPES,
    };
  },
};
</script>
