<template>
  <div class="pa-0 ma-0">
    <div class="pa-0 ma-0" v-for="info of sortedInfos" :key="info.id">
      <RendererItem
        :itemInfo="info"
        v-if="info.symbol"
        :viewSymbols="viewSymbols"
        @add-symbol="$emit('add-symbol', $event)"
        class="feature_hover cursor-pointer"
      />
      <UtilisyncRendererItem
        v-else
        :itemInfo="info"
        :viewSymbols="viewSymbols"
        @add-symbol="$emit('add-symbol', $event)"
        class="feature_hover cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
import RendererItem from "@/components/shared/RendererItem";
import UtilisyncRendererItem from "@/components/shared/UtilisyncRendererItem";

export default {
  name: "RendererItemList",
  components: {
    RendererItem,
    UtilisyncRendererItem,
  },
  props: {
    infos: Array,
    viewSymbols: Boolean,
  },
  computed: {
    sortedInfos() {
      const { infos } = this;
      return [...infos].sort((a, b) => a.min_value - b.min_value);
    },
  },
  methods: {
    addSymbol(info) {
      this.$emit("add-symbol", info);
    },
  },
};
</script>

<style scoped>
.feature_hover:hover {
  background-color: #ccc;
}
</style>
