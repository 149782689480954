<template>
  <div class="d-flex justify-space-between flex-grow-1 gap">
    <div class="mt-2">
      <p class="pb-1 my-0 caption settings-heading">Color</p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                'background-color': pointOptions.pointFill,
                width: '25px',
                height: '25px',
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <ColorPicker
              v-model="pointOptions.pointFill"
              @color-picker-cancelled="showFillDialog = false"
              @input="
                showFillDialog = false;
                $emit('point-options-changed', pointOptions);
              "
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="mt-2" style="width: 120px">
      <p class="pb-1 my-0 caption settings-heading">Outline Color</p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                width: '25px',
                height: '25px',
                'background-color': pointOptions.pointOutlineColor,
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <ColorPicker
              v-model="pointOptions.pointOutlineColor"
              @color-picker-cancelled="showFillDialog = false"
              @input="
                showFillDialog = false;
                $emit('point-options-changed', pointOptions);
              "
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="mt-2">
      <p class="pb-1 my-0 caption settings-heading">Outline Width</p>
      <v-slider
        :max="16"
        :min="0"
        hide-details
        v-model="pointOptions.pointOutlineWidth"
        @input="$emit('point-options-changed', pointOptions)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="pointOptions.pointOutlineWidth"
            readonly
            class="number-input my-0 py-0 mt-n2"
            hide-details
            style="width: 20px"
          />
        </template>
      </v-slider>
    </div>

    <div class="mt-2 flex-grow-1">
      <p class="pb-1 px-1 my-0 caption">Symbol Size</p>
      <v-slider
        :max="40"
        :min="0"
        hide-details
        v-model="pointOptions.size"
        @change="$emit('point-options-changed', pointOptions)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="pointOptions.size"
            readonly
            class="number-input my-0 py-0 mt-n2"
            hide-details
            style="width: 20px"
          />
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
} from "@mdi/js";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ColorPicker from "@/components/shared/ColorPicker";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "PointForm",
  props: {
    pointOptionsObj: Object,
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      SHAPE_TYPES,
      pointOptions: {
        pointFill: "#2196F3",
        pointOutlineColor: "#2196F3",
        size: 30,
      },
      colorChoices,
      symbols,
      showFillDialog: false,
    };
  },
  beforeMount() {
    this.pointOptions = { ...this.pointOptionsObj };
  },
};
</script>
