<template>
  <v-dialog
    :value="showTestArcGisLayerConnectionDialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Test ArcGIS Layer Connection</div>
        <v-spacer />
        <v-btn icon @click="$emit('test-connection-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5">
        <form>
          <validation-observer ref="form">
            <validation-provider
              name="arcGisServiceUrl"
              v-slot="{ errors, valid }"
              rules="required"
            >
              <section class="d-flex align-center gap">
                <v-textarea
                  v-model="arcGisServiceUrl"
                  :error-messages="errors"
                  :success="valid"
                  label="ArcGIS Service URL *"
                  color="#3F51B5"
                  class="ma-0 pa-0"
                  hide-details
                  disabled
                  auto-grow
                  rows="1"
                >
                </v-textarea>

                <v-btn icon dark color="primary" @click="openUrl">
                  <v-icon>{{ mdiOpenInNew }}</v-icon>
                </v-btn>
              </section>
              <template v-if="submitted">
                <p class="caption my-0 py-0" style="color: red" v-if="error">
                  {{ error }}
                </p>
                <p class="caption my-0 py-0" style="color: green" v-else>
                  Success! Connected to ArcGIS Feature Service.
                </p>
              </template>
            </validation-provider>

            <validation-provider>
              <v-switch
                v-model="securedService"
                label="Secured Service"
                disabled
              >
              </v-switch>
            </validation-provider>
          </validation-observer>
        </form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn dark color="#3F51B5" @click="testUrl"> Test Connection </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiOpenInNew } from "@mdi/js";

export default {
  name: "TestArcGisLayerConnectionDialog",
  data() {
    return {
      mdiClose,
      mdiOpenInNew,
      arcGisServiceUrl: "",
      securedService: false,
      error: "",
      submitted: false,
    };
  },
  props: {
    showTestArcGisLayerConnectionDialog: Boolean,
    allLayers: Array,
    mapServiceId: String,
  },
  methods: {
    getEsriToken() {
      return localStorage.getItem("esri_token");
    },
    openUrl() {
      const { arcGisServiceUrl, securedService } = this;
      const params = new URLSearchParams();
      if (securedService) {
        params.append("token", localStorage.getItem("esri_token"));
      }
      window.open(`${arcGisServiceUrl}?${params.toString()}`);
    },
    async testUrl() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.submitted = false;
      this.error = "";
      const { arcGisServiceUrl, securedService } = this;
      try {
        const url = new URL(arcGisServiceUrl);
        const searchParams = new URLSearchParams();
        const token = securedService
          ? localStorage.getItem("esri_token")
          : undefined;
        if (token) {
          searchParams.append("token", token);
          url.search = searchParams;
        }
        const res = await fetch(url.toString(), {
          redirect: "manual",
        });
        const { type, status } = res;
        if (type === "opaqueredirect") {
          this.error =
            "Unable to connect to layer. Your user does not have permission to access that layer";
        } else {
          const data = await res.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/html");
          const [error] = doc.getElementsByClassName("restErrors");
          if (error) {
            this.error = error?.textContent;
          } else if (status >= 300) {
            this.error = `${status}: Unable to connect to layer, reason unknown.`;
          }
        }
      } catch (error) {
        this.error = "Unable to connect to layer, reason unknown.";
      } finally {
        this.submitted = true;
      }
    },
    loadData() {
      if (Array.isArray(this.allLayers)) {
        const { service_url: serviceUrl, token_type: tokenType } =
          this.allLayers.find((v) => v.map_service_id === this.mapServiceId) ??
          {};
        this.arcGisServiceUrl = serviceUrl;
        this.securedService = tokenType !== "NONE";
      }
    },
  },
  watch: {
    mapServiceId() {
      this.loadData();
    },
  },
  beforeMount() {
    this.loadData();
  },
};
</script>
