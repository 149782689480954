<template>
  <v-card>
    <v-toolbar dark color="#3F51B5">
      <v-toolbar-title>Calculation</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('calculation-list-close')">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0 mb-0">
          <v-list class="py-0 my-0">
            <v-list-item>
              <v-list-item-content class="heading">
                <b>Formula</b>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0 my-0">
              <v-list-item-content>
                <section class="d-flex flex-wrap align-center gap">
                  <template v-for="(s, index) of splitExpression">
                    <v-chip v-if="/^{(\d+?)}$/.test(s)" small :key="index">
                      {{ getQuestionLabelById(s) }}
                    </v-chip>
                    <div v-else :key="index">
                      {{ s }}
                    </div>
                  </template>
                </section>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" class="px-0 mx-0">
          <v-list class="px-0 mx-0">
            <v-list-item class="py-0 my-0">
              <v-list-item-content class="py-0 my-0">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="px-3"><b>Question</b></th>
                      <th>
                        <div class="d-flex justify-end"><b>Value</b></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="v of variables" :key="v.id">
                      <td class="px-3 caption">{{ v.label }}</td>
                      <td class="caption">
                        <div class="d-flex justify-end">{{ v.value }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" class="pt-0 mt-0">
          <v-list class="pt-0 mt-0">
            <v-list-item class="pt-0 mt-0">
              <v-list-item-content class="heading">
                <b>Calculation</b>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                {{ calculationExpression }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mdiClose } from "@mdi/js";

export default {
  name: "CalculationList",
  props: {
    formDefinition: Object,
    item: Object,
  },
  data() {
    return {
      mdiClose,
    };
  },
  computed: {
    variables() {
      const { sections } = this.formDefinition.form;
      const nestedItems = sections.map(({ items }) => items);
      const items = nestedItems.flat();
      return this.item.question.watchIds.map((id) => {
        const item = items.find((item) => item.id === id);
        if (!item) {
          return {};
        }
        const { value, question } = item;
        return {
          id: uuidv4(),
          label: question?.label,
          value,
        };
      });
    },
    calculationExpression() {
      const { sections } = this.formDefinition.form;
      const nestedItems = sections.map(({ items }) => items);
      const items = nestedItems.flat().filter(({ question }) => {
        return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
      });

      let calculationExpression = this.item.question.calculation;
      const params = [...this.item.question.calculation.matchAll(/{{(.*?)}}/g)];
      if (!Array.isArray(params) || params.length === 0) {
        return "";
      }
      for (const p of params) {
        const [fullParam, param] = p.toString().split(",");
        const [, questionId] = param.split(".");
        const { value } = items.find((i) => +i.id === +questionId);
        calculationExpression = calculationExpression.replace(fullParam, value);
      }
      return calculationExpression;
    },
    splitExpression() {
      const expression = this.item.question.calculation;
      let modifiedExpression = expression;
      const params = [...expression.matchAll(/{{(.*?)}}/g)];
      for (const p of params) {
        const [fullParam, param] = p.toString().split(",");
        const [, questionId] = param.split(".");
        modifiedExpression = modifiedExpression.replace(
          fullParam,
          `{${questionId}}`
        );
      }
      const matches = modifiedExpression.split(/(\{\d+?\})/);
      return matches;
    },
    numberQuestions() {
      const { sections } = this.formDefinition.form;
      const nestedItems = sections.map(({ items }) => items);
      const items = nestedItems.flat().filter((item) => {
        return ["NUMBER", "SINGLE_SELECT"].includes(item?.question?.type);
      });
      return items;
    },
  },
  methods: {
    getQuestionLabelById(questionId) {
      const item = this.numberQuestions.find(
        (i) => i.id === +questionId.replace("{", "").replace("}", "")
      );
      return item?.question?.label;
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 3px;
}

v-simple-table >>> th,
.heading {
  font-size: 18px;
}
</style>
