var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-0 ma-0" }, [
    _c(
      "div",
      {
        staticClass:
          "\n      d-flex\n      flex-sm-nowrap flex-wrap\n      justify-space-between\n      align-center\n      py-0\n      mt-n3\n    ",
        staticStyle: { height: "80px" }
      },
      [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "truncate mt-n1",
                class: { "item-title": _vm.level > 0 }
              },
              [_vm._v(" " + _vm._s(_vm.layer.utiliSyncLayerName) + " ")]
            ),
            _c("div", { staticClass: "error--text" }, [
              _vm._v("Failed to Load")
            ])
          ])
        ]),
        _c("VisibilityMenu", {
          attrs: {
            mapServiceId: _vm.layer.mapServiceId,
            allLayers: _vm.allLayers
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }