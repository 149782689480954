var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showErrorConnectingToGisFeatureDialog,
        "max-width": "500px"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _vm._v(
              " There was an error connecting to the GIS feature: " +
                _vm._s(_vm.errorMsg) +
                ". Verify you are signed in with an ArcGIS user and that you have access to the layer " +
                _vm._s(_vm.layerName) +
                ". Would you like to proceed with creating a new form? If you proceed, any form field that has a GIS field default will be blank. "
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "error-connecting-to-gis-feature-dialog-close"
                      )
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("create-form-result")
                    }
                  }
                },
                [_vm._v(" Create Form Result ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }