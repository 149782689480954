import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=7b6cfefb&scoped=true&"
import script from "./Legend.vue?vue&type=script&lang=js&"
export * from "./Legend.vue?vue&type=script&lang=js&"
import style0 from "./Legend.vue?vue&type=style&index=0&id=7b6cfefb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6cfefb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b6cfefb')) {
      api.createRecord('7b6cfefb', component.options)
    } else {
      api.reload('7b6cfefb', component.options)
    }
    module.hot.accept("./Legend.vue?vue&type=template&id=7b6cfefb&scoped=true&", function () {
      api.rerender('7b6cfefb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mapView/docs-tab/markup-image-dialog/Legend.vue"
export default component.exports