var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          staticClass: "cursor-pointer",
          attrs: { "offset-y": "", "max-height": "90vh" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass:
                            "d-flex justify-space-between align-center"
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-0 d-flex",
                          staticStyle: { color: "white", "line-height": "75%" }
                        },
                        [
                          _vm.markupVersionChoices.length > 0
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatRevisionDate")(
                                        _vm.selectedVersionDateTime,
                                        "MM/DD/YY hh:mm a"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            : _c("div", { staticClass: "text-truncate" }, [
                                _vm._v("No saved edits")
                              ])
                        ],
                        2
                      ),
                      _c(
                        "v-icon",
                        { staticClass: "my-0 py-0", attrs: { color: "white" } },
                        [_vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm.markupVersionChoices.length > 0
            ? _c(
                "v-list",
                [
                  _vm._l(_vm.markupVersionChoices, function(m, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: i + "-" + m.value,
                        on: {
                          click: function($event) {
                            _vm.selectedVersionDateTime = m.value
                            _vm.$emit(
                              "revision-selected",
                              _vm.selectedVersionDateTime
                            )
                          }
                        }
                      },
                      [
                        _c("v-list-item-content", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between"
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatRevisionDate")(
                                        m.label,
                                        "MM/DD/YY hh:mm a"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      "x-small": ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiDotsVertical)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "px-0 mx-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "px-0 mx-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteFileMarkup(
                                                    m.label,
                                                    i
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "\n                        font-weight-regular\n                        capitalize\n                        d-flex\n                        align-center\n                        gap\n                      "
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiDelete) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Delete Markup Version"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  }),
                  _vm.hasMore
                    ? _c(
                        "v-list-item",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("show-more")
                                    }
                                  }
                                },
                                [_vm._v(" Show More ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "v-list",
                [
                  _c("v-list-item", { staticClass: "d-flex justify-center" }, [
                    _vm._v(" No saved edits ")
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }