<template>
  <div
    class="d-flex justify-space-between px-3 py-2 rounded-0"
    style="background-color: #38383d; color: white"
  >
    <div
      class="
        d-flex
        gap
        justify-space-between
        flex-wrap
        align-center
        flex-grow-1
      "
    >
      <div class="d-flex align-center gap flex-wrap">
        <div
          class="d-flex gap button cursor-pointer square"
          :class="{
            'selected-symbol-type': itemType === ITEM_TYPES.TEXT,
          }"
          @click="onToolbarButtonClick(ITEM_TYPES.TEXT)"
        >
          <v-icon color="white">
            {{ mdiFormatText }}
          </v-icon>
        </div>
        <div
          class="d-flex gap button cursor-pointer square"
          :class="{
            'selected-symbol-type': itemType === ITEM_TYPES.LINE,
          }"
          @click="onToolbarButtonClick(ITEM_TYPES.LINE)"
        >
          <v-icon color="white">
            {{ mdiVectorPolyline }}
          </v-icon>
        </div>
        <div
          class="d-flex gap button cursor-pointer square"
          :class="{
            'selected-symbol-type': itemType === ITEM_TYPES.SHAPE,
          }"
          @click="onToolbarButtonClick(ITEM_TYPES.SHAPE)"
        >
          <v-icon color="white">
            {{ mdiShape }}
          </v-icon>
        </div>
        <div
          class="d-flex gap button cursor-pointer square"
          :class="{
            'selected-symbol-type': itemType === ITEM_TYPES.SKETCH,
          }"
          @click="onToolbarButtonClick(ITEM_TYPES.SKETCH)"
        >
          <v-icon color="white">
            {{ mdiFountainPenTip }}
          </v-icon>
        </div>
        <div
          class="d-flex gap button cursor-pointer square"
          :class="{
            'selected-symbol-type': itemType === ITEM_TYPES.ARROW,
          }"
          @click="onToolbarButtonClick(ITEM_TYPES.ARROW)"
        >
          <v-icon color="white">
            {{ mdiArrowTopRight }}
          </v-icon>
        </div>
        <div @click="firstLoad = false">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-if="selectedCustomSymbol" class="white--text">
                <div
                  class="px-2 py-0 d-flex cursor-pointer align-center"
                  :class="{
                    'selected-symbol-type': addingCustomSymbol && !firstLoad,
                  }"
                >
                  <div
                    class="d-flex align-center"
                    @click="
                      $emit('selected-custom-symbol', selectedCustomSymbol)
                    "
                  >
                    <SymbolIconDisplay :symbol="selectedCustomSymbol" />
                    <div class="white--text" v-if="$vuetify.breakpoint.smAndUp">
                      {{ selectedCustomSymbol.label }}
                    </div>
                  </div>
                  <v-btn v-bind="attrs" v-on="on" icon class="py-0 my-0">
                    <v-icon color="white">{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-else class="white--text">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  class="d-flex cursor-pointer align-center text-capitalize"
                  dark
                >
                  <p class="py-0 my-0">Symbol</p>
                  <v-icon color="white">{{ mdiChevronDown }}</v-icon>
                </v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item class="d-flex justify-space-between">
                <form>
                  <validation-provider v-slot="{ errors, valid }" name="Symbol">
                    <v-text-field
                      v-model="symbolKeyword"
                      :error-messages="errors"
                      :success="valid"
                      label="Symbol"
                      color="#3F51B5"
                    >
                      <template #append>
                        <v-icon> {{ mdiMagnify }}</v-icon>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </form>
                <div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text>
                        <v-icon>
                          {{ mdiDotsVertical }}
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        class="d-flex justify-space-between gap"
                        @click="showManageSymbolsDialog = true"
                      >
                        <v-icon>
                          {{ mdiTune }}
                        </v-icon>
                        <div>Manage Symbols</div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-list-item>

              <v-list-item
                v-for="s of activeCustomSymbols"
                :key="s.markup_symbol_id"
                @click="
                  selectedCustomSymbol = s;
                  firstLoad = false;
                  saveSelectedCustomSymbol();
                  $emit('selected-custom-symbol', selectedCustomSymbol);
                "
                :class="{
                  'selected-symbol-type':
                    selectedItem &&
                    selectedItem.markupSymbolId === s.markup_symbol_id,
                }"
              >
                <v-list-item-title class="d-flex align-center cursor-pointer">
                  <SymbolIconDisplay :symbol="s" />
                  <div
                    :class="{
                      'white--text':
                        selectedItem &&
                        selectedItem.markupSymbolId === s.markup_symbol_id,
                    }"
                  >
                    {{ s.label }}
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div class="d-flex gap">
        <v-btn icon @click="$emit('undo')" color="white">
          <v-icon>{{ mdiUndo }}</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('redo')" color="white">
          <v-icon>{{ mdiRedo }}</v-icon>
        </v-btn>
      </div>
    </div>

    <ManageSymbolsDialog
      :showManageSymbolsDialog="showManageSymbolsDialog"
      :customSymbols="customSymbols"
      @close-button-click="showManageSymbolsDialog = false"
      @custom-symbol-added="$emit('custom-symbol-added')"
      @custom-symbol-edited="$emit('custom-symbol-edited')"
      @get-custom-symbols="$emit('get-custom-symbols', $event)"
    />
  </div>
</template>

<script>
import {
  mdiUndo,
  mdiRedo,
  mdiChevronDown,
  mdiFormatText,
  mdiVectorPolyline,
  mdiShape,
  mdiFountainPenTip,
  mdiArrowTopRight,
  mdiMagnify,
  mdiDotsVertical,
  mdiTune,
} from "@mdi/js";
import ITEM_TYPES from "@/constants/markupItemTypes";
import SymbolIconDisplay from "@/components/mapView/docs-tab/markup-image-dialog/markup-items-toolbar/SymbolIconDisplay";
import ManageSymbolsDialog from "@/components/mapView/docs-tab/markup-image-dialog/markup-items-toolbar/ManageSymbolsDialog";

export default {
  name: "MarkupItemsToolbar",
  components: {
    SymbolIconDisplay,
    ManageSymbolsDialog,
  },
  props: {
    itemType: String,
    displayCustomSymbolToolbar: Boolean,
    customSymbols: Array,
    selectedItem: Object,
  },
  data() {
    return {
      ITEM_TYPES,
      mdiUndo,
      mdiRedo,
      mdiChevronDown,
      mdiFormatText,
      mdiVectorPolyline,
      mdiShape,
      mdiFountainPenTip,
      mdiArrowTopRight,
      mdiMagnify,
      mdiDotsVertical,
      mdiTune,
      selectedCustomSymbol: undefined,
      symbolKeyword: "",
      showManageSymbolsDialog: false,
      addingCustomSymbol: false,
      firstLoad: true,
    };
  },
  computed: {
    activeCustomSymbols() {
      return this.customSymbols.filter((s) => !s.deactivated);
    },
  },
  async beforeMount() {
    try {
      this.selectedCustomSymbol = JSON.parse(
        localStorage.getItem("selected-custom-symbol")
      );
    } catch (error) {
      this.selectedCustomSymbol = undefined;
    }
  },
  methods: {
    saveSelectedCustomSymbol() {
      localStorage.setItem(
        "selected-custom-symbol",
        JSON.stringify(this.selectedCustomSymbol)
      );
    },
    onToolbarButtonClick(selectedItemType) {
      this.addingCustomSymbol = false;
      this.firstLoad = false;
      if (this.itemType) {
        this.$emit("hide-toolbar");
      } else {
        this.$emit("show-toolbar", selectedItemType);
      }
    },
  },
  watch: {
    activeCustomSymbols: {
      deep: true,
      handler(val) {
        try {
          const { markup_symbol_id: markupSymbolId } =
            JSON.parse(localStorage.getItem("selected-custom-symbol")) ?? {};
          this.selectedCustomSymbol = {
            ...(val.find((v) => v.markup_symbol_id === markupSymbolId) ??
              val[0]),
          };
          localStorage.setItem(
            "selected-custom-symbol",
            JSON.stringify(this.selectedCustomSymbol)
          );
        } catch (error) {
          console.error(error);
        }
      },
    },
    selectedCustomSymbol: {
      deep: true,
      handler(val) {
        if (val) {
          this.addingCustomSymbol = true;
        }
      },
    },
    displayCustomSymbolToolbar(val) {
      this.addingCustomSymbol = Boolean(val);
    },
  },
};
</script>

<style scoped>
.selected-symbol-type {
  background-color: #8ab4f8;
}

.square {
  width: 24px;
  height: 24px;
}
</style>
