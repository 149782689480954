var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.size,
        height: _vm.size,
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: _vm.size / 2,
          cy: _vm.size / 2,
          r: _vm.size / 2.5,
          stroke: _vm.strokeColor,
          "stroke-width": _vm.strokeWidth,
          fill: _vm.fillColor
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }