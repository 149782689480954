<template>
  <v-dialog max-width="500px" :value="showFormInfoDialog">
    <v-card>
      <v-card-title>
        <h4 class="font-weight-regular">Form Details</h4>
      </v-card-title>
      <v-card-text> ID: {{ formResultId }} </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="$emit('form-info-dialog-close')">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormInfoDialog",
  props: {
    showFormInfoDialog: Boolean,
    formResultId: String,
  },
};
</script>
