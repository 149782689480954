<template>
  <v-row>
    <v-col cols="12" class="my-0 pt-0">
      <template v-if="['TEXT', 'EMAIL'].includes(item.question.type)">
        <template v-if="item.question.isLong">
          <validation-provider
            slim
            v-slot="{ errors, valid, validated }"
            :name="item.question.label"
            :rules="{
              required: isRequired,
              email: item.question.type === 'EMAIL',
              max: Boolean(item.question.max_length)
                ? item.question.max_length
                : Infinity,
            }"
            ref="field"
          >
            <QuillEditor
              v-model="textValue"
              :label="`${item.question.label}`"
              :placeholder="item.question.placeholder"
              :validated="validated"
              :valid="valid"
              :errors="errors"
              :allowMultiple="item.allowMultiple"
              :disabled="readOnly"
              :maxChars="+item.question.max_length"
              @input="onInput"
            />
          </validation-provider>
        </template>
        <template v-else>
          <template v-if="item.question.forInformationOnly">
            <label class="caption">{{ item.question.label }}</label>
            <p>{{ item.value }}</p>
          </template>
          <template v-else>
            <validation-provider
              slim
              v-slot="{ errors, valid }"
              :name="item.question.label"
              :rules="{
                required: isRequired,
                max: Boolean(item.question.max_length)
                  ? item.question.max_length
                  : Infinity,
              }"
              ref="field"
            >
              <v-text-field
                v-model="textValue"
                :label="item.question.label"
                :name="item.id"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="#3F51B5"
                :hint="item.question.placeholder"
                persistent-hint
                @input="onInput"
                :disabled="readOnly"
                :maxlength="item.question.max_length"
              >
              </v-text-field>
            </validation-provider>
          </template>
        </template>
      </template>

      <template v-if="['NUMBER'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
            regex: /^\d+(\.\d+)?$/,
          }"
        >
          <v-text-field
            v-model.number="textValue"
            :label="item.question.label"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="#3F51B5"
            :name="item.id"
            :hint="item.question.placeholder"
            persistent-hint
            @input="onNumberInput(valid)"
            @keydown="onNumberKeyDown"
            :disabled="readOnly"
            type="number"
          >
          </v-text-field>
        </validation-provider>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import QuillEditor from "@/components/shared/QuillEditor";

export default {
  name: "GroupTextInput",
  components: {
    QuillEditor,
  },
  props: {
    item: Object,
    value: [String, Number],
    readOnly: Boolean,
    formDefinition: Object,
    isRequired: Boolean,
  },
  mixins: [dependantValueMixin],
  data() {
    return {
      textValue: "",
      showEditor: false,
    };
  },
  beforeMount() {
    this.textValue = cloneDeep(this.value);
  },
  methods: {
    onInput() {
      this.$emit("input", this.textValue);
    },
    onNumberKeyDown(e) {
      if (["e", "+", "*", "/"].includes(e.key?.toLowerCase())) {
        e.preventDefault();
      }
    },
    onNumberInput(valid) {
      if (!valid) {
        return;
      }
      this.$emit("input", this.textValue);
    },
  },
};
</script>
