<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="caption">{{ item.question.label }}</div>
      <v-btn
        text
        color="#3F51B5"
        @click="showAddImageDialog = true"
        :disabled="isDisabled"
      >
        <v-icon small>
          {{ mdiPlus }}
        </v-icon>
        Add
      </v-btn>
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th>Image</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="base64Images.length > 0">
        <tr v-for="(image, index) of base64Images" :key="image.id">
          <td>
            <img
              :src="getPreviewUrl(image)"
              class="thumbnail cursor-pointer"
              @click.stop="!isDisabled ? onClick(image) : onViewClick(image)"
            />
          </td>
          <td>
            <v-textarea
              label="Description"
              color="#3F51B5"
              v-model="image.description"
              @change="$emit('input', base64Images)"
              auto-grow
              rows="1"
              ref="textAreaRef"
              :disabled="alreadySubmittedFinalOnline && !isEditingFinalForm"
            >
            </v-textarea>
          </td>
          <td>
            <div class="d-flex justify-end align-center">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onClick(image)"
                    v-if="!alreadySubmittedFinalOnline || isEditingFinalForm"
                  >
                    <v-list-item-title
                      :style="{
                        'pointer-events': isBase64Image(image)
                          ? 'none'
                          : undefined,
                        opacity: isBase64Image(image) ? 0.5 : 1,
                      }"
                    >
                      <v-icon>{{ mdiFileEdit }}</v-icon>
                      Markup
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onViewClick(image)" v-else>
                    <v-list-item-title>
                      <v-icon>
                        {{ mdiFile }}
                      </v-icon>
                      View Image
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="removePhoto(index, image.id)"
                    v-if="!alreadySubmittedFinalOnline || isEditingFinalForm"
                  >
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <MarkupImageDialog
              v-if="showMarkupImageDialog[image.id]"
              @markup-image-dialog-close="
                showMarkupImageDialog = {};
                onFileMarkupPreviewSaved();
              "
              @file-markup-preview-saved="onFileMarkupPreviewSaved"
              :showMarkupImageDialog="showMarkupImageDialog[image.id]"
              :selectedFile="selectedFile"
            />
            <MarkupViewerDialog
              v-if="showMarkupViewerDialog[image.id]"
              @markup-viewer-dialog-close="showMarkupViewerDialog = {}"
              :showMarkupViewerDialog="showMarkupViewerDialog[image.id]"
              :selectedFile="selectedFile"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <div class="d-flex justify-center">No Images</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <AddImageDialog
      @add-photo-dialog-close="showAddImageDialog = false"
      :showAddImageDialog="showAddImageDialog"
      :maxWidthHeight="+maxWidthHeight"
      :readOnly="isDisabled"
      :allowMultiple="allowMultiple"
      :formResultId="formResultId"
      :formDefinition="formDefinition"
      :selectedMapServiceId="selectedMapServiceId"
      :item="item"
      :globalId="globalId"
      :objectId="objectId"
      @input="onAddImageDialogInput"
      v-if="showAddImageDialog"
    />
  </div>
</template>

<script>
import AddImageDialog from "@/components/tickets/shared/photo-input/AddImageDialog";
import MarkupImageDialog from "@/components/mapView/docs-tab/MarkupImageDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import {
  mdiDotsVertical,
  mdiDelete,
  mdiPlus,
  mdiFileEdit,
  mdiFile,
} from "@mdi/js";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import { cloneDeep } from "lodash";
import MarkupViewerDialog from "@/components/mapView/docs-tab/MarkupViewerDialog";

const APIURL = process.env.VUE_APP_API_URL;

const base64Regex =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export default {
  name: "GroupPhotoInput",
  mixins: [dependantValueMixin],
  computed: {
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (
            +dependantItem.id === +this.item?.question?.required?.dependantId
          ) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item?.question?.required?.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm || !this.objectId;
      }
      return (
        !this.canEdit ||
        !this.objectId ||
        (!this.allowMultiple && this.base64Images.length >= 1)
      );
    },
  },
  props: {
    id: Number,
    allowMultiple: Boolean,
    value: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    formResultId: String,
    maxWidthHeight: Number,
    showDescription: Boolean,
    formDefinition: Object,
    item: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    selectedGisInfo: Object,
  },
  components: {
    AddImageDialog,
    MarkupImageDialog,
    MarkupViewerDialog,
  },
  data() {
    return {
      uploadFiles: undefined,
      base64Images: [],
      showMarkupImageDialog: {},
      showMarkupViewerDialog: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      windowWidth: window.innerWidth,
      mdiDotsVertical,
      mdiDelete,
      mdiPlus,
      mdiFileEdit,
      mdiFile,
      selectedFile: {},
      showAddImageDialog: false,
      formResultFiles: [],
    };
  },
  methods: {
    async onFileMarkupPreviewSaved() {
      await this.getInsertedFiles();
    },
    getPreviewUrl(file) {
      const imageFile = this.formResultFiles.find((f) => f.file_id === file.id);
      return imageFile?.s3_file_path ?? imageFile?.s3_file_path_original_image;
    },
    async getInsertedFiles() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${this.formResultId}/files`
      );
      this.formResultFiles = cloneDeep(results);
    },
    onAddImageDialogInput(newImages) {
      const newImageIds = this.base64Images.map((im) => im.id);
      for (const [index, im] of this.base64Images.entries()) {
        if (newImageIds.includes(im.id)) {
          const newImage = newImages.find((nim) => nim.id === im.id);
          this.base64Images[index] = {
            ...im,
            ...newImage,
          };
        }
      }
      const newImagesNotAdded = newImages.filter(
        (nim) => !newImageIds.includes(nim.id)
      );
      this.base64Images = [...this.base64Images, ...newImagesNotAdded];
      this.$emit("input", this.base64Images);
    },
    async removePhoto(index, imageId) {
      this.base64Images.splice(index, 1);
      this.$emit("input", this.base64Images);
      if (navigator.onLine) {
        await axiosWithRegularAuth.delete(`${APIURL}/files/${imageId}`);
      }
    },
    async validateInput() {
      await this.$nextTick();
      const { label, id, base64Images, item } = this;
      const isRequired = item?.question?.required;
      const valid = isRequired ? base64Images?.length > 0 : true;
      this.$emit("validated", {
        valid,
        label,
        id,
      });
    },
    isBase64Image(image) {
      const { url } = image;
      const [, base64] = url.split(",");
      return base64Regex.test(base64);
    },
    async onClick(image) {
      const { id, url, fileName, description } = image;
      this.selectedFile = {
        file_id: id,
        s3_file_path_original_image: url,
        name: fileName ?? description,
      };
      this.showMarkupImageDialog = {
        ...this.showMarkupImageDialog,
        [id]: true,
      };
    },
    async onViewClick(image) {
      const { id, fileName, description, url } = image;
      if (this.isBase64Image(image)) {
        this.selectedFile = {
          file_id: id,
          s3_file_path_original_image: url,
          name: fileName ?? description,
        };
      } else {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(`${APIURL}/files/${id}/metadata`);
        this.selectedFile = {
          file_id: id,
          s3_file_path_original_image: results?.s3_file_path_original_image,
          name: fileName ?? description,
        };
      }
      this.showMarkupViewerDialog = {
        ...this.showMarkupViewerDialog,
        [id]: true,
      };
    },
    onClose(imageId) {
      this.showPhotoEditor = { ...this.showPhotoEditor, [imageId]: false };
    },
  },
  watch: {
    base64Images: {
      deep: true,
      async handler() {
        await this.validateInput();
        await this.getInsertedFiles();
      },
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$refs.textAreaRef;
    });
    await this.validateInput();
    await this.getInsertedFiles();
  },
  beforeMount() {
    if (Array.isArray(this.value)) {
      this.base64Images = [...this.value].map((v) => ({
        ...v,
        dataUrl: v.dataUrl ? v.dataUrl : v.url,
      }));
    }
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}
</style>
