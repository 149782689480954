<template>
  <v-menu
    :close-on-content-click="false"
    v-model="showDatePicker"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
        ref="field"
      >
        <v-text-field
          readonly
          class="date-field"
          :value="formattedDate"
          v-on="on"
          :error-messages="errors"
          :success="valid"
          :label="`${item.number} ${item.question.label}`"
          color="#3F51B5"
          clearable
          :disabled="isDisabled"
          @click:clear="$emit('input', '')"
        ></v-text-field>
      </validation-provider>
    </template>
    <v-date-picker v-model="date" @change="onInput"></v-date-picker>
  </v-menu>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";
import moment from "moment";

export default {
  name: "DateInput",
  props: {
    item: Object,
    value: [String, Number],
    formDefinition: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  mixins: [dependantValueMixin],
  data() {
    return {
      date: "",
      showDatePicker: false,
    };
  },
  computed: {
    formattedDate() {
      if (
        !moment(this.date, ["MM/DD/YYYY", "YYYY-MM-DD"]).utc().isValid(true)
      ) {
        return "";
      }
      return moment(this.date).utc().format("MM/DD/YYYY");
    },
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const { valid } = (await this.$refs.field?.validateSilent()) ?? {};
      const { item } = this;
      const { id, number, question } = item;
      this.$emit("validated", {
        valid,
        label: `${number} ${question.label}`,
        id,
      });
    },
    async onInput() {
      await this.validateField();
      this.$emit("input", this.date);
      await this.$nextTick();
      this.showDatePicker = false;
    },
  },
  mounted() {
    this.validateField();
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.date = val;
      },
    },
    isRequired() {
      this.validateField();
    },
  },
};
</script>
