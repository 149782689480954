var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lastSavedTime
    ? _c(
        "span",
        [
          !_vm.alreadySubmittedFinalOnline
            ? [
                !_vm.isSaving && !_vm.submittingFinalForm
                  ? [
                      _vm._v(
                        " Draft Saved " +
                          _vm._s(_vm._f("formatTime")(_vm.lastSavedTime)) +
                          " "
                      )
                    ]
                  : [_vm._v(" Saving to UtiliSync Online... ")]
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }