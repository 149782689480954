<template>
  <span v-if="lastSavedTime">
    <template v-if="!alreadySubmittedFinalOnline">
      <template v-if="!isSaving && !submittingFinalForm">
        Draft Saved
        {{ lastSavedTime | formatTime }}
      </template>
      <template v-else> Saving to UtiliSync Online... </template>
    </template>
  </span>
</template>

<script>
export default {
  name: "LastSaved",
  props: {
    lastSavedTime: [String, Date],
    alreadySubmittedFinalOnline: Boolean,
    isSaving: Boolean,
    submittingFinalForm: Boolean,
  },
};
</script>
