var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-0 pdf-list d-flex flex-column",
      attrs: { flat: "", height: "100vh", width: "150px" }
    },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "d-block rounded-0 flex-grow-0",
          attrs: { dark: "", height: "56px" }
        },
        [
          _c("v-toolbar-title", { staticClass: "d-flex flex-grow-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-end align-center flex-grow-1" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("markup-pdf-list-close")
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pdf-list flex-grow-1 overflow-y-auto" },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "d-flex align-center justify-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [
                    _c(
                      "validation-observer",
                      { ref: "form", attrs: { slim: "" } },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c(
                              "validation-provider",
                              { attrs: { name: "Page", rules: "numeric" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "page-input py-0 my-0",
                                  staticStyle: { width: "60px" },
                                  attrs: { dark: "" },
                                  on: { input: _vm.onPageChange },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-outer",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "6px",
                                                width: "30px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " of " +
                                                  _vm._s(
                                                    _vm.pdfPageImages.length
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value: _vm.page,
                                    callback: function($$v) {
                                      _vm.page = _vm._n($$v)
                                    },
                                    expression: "page"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap justify-center" },
                  _vm._l(_vm.pdfPageImages, function(p, index) {
                    return _c("img", {
                      key: p.file_id,
                      staticClass: "my-5 cursor-pointer elevation-5",
                      staticStyle: { width: "80px" },
                      style: {
                        outline:
                          index + 1 === _vm.page ? "6px solid #8AB4F8" : "none",
                        opacity: index + 1 !== _vm.page ? "0.2" : 1
                      },
                      attrs: { src: p.thumbnail },
                      on: {
                        click: function($event) {
                          _vm.$emit("page-selected", p)
                          _vm.page = index + 1
                        }
                      }
                    })
                  }),
                  0
                )
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }