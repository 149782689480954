var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemInfo && _vm.itemInfo.symbol
    ? _c(
        "div",
        {
          staticClass: "px-4 py-2",
          on: {
            click: function($event) {
              return _vm.addSymbol(_vm.itemInfo)
            }
          }
        },
        [
          _vm.itemInfo.symbol.type === "picture-marker"
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon d-flex align-center justify-center"
                    },
                    [
                      _c("img", {
                        staticClass: "mr-2",
                        style: {
                          width: _vm.itemInfo.symbol.width + "px",
                          height: _vm.itemInfo.symbol.height + "px"
                        },
                        attrs: { src: _vm.itemInfo.symbol.url }
                      })
                    ]
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.itemInfo.label))])
                ])
              ])
            : _vm.itemInfo.symbol.type === "simple-marker"
            ? _c("div", { staticClass: "mx-0 px-0" }, [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon d-flex align-center justify-center"
                    },
                    [
                      _vm.itemInfo.symbol.style === "circle"
                        ? _c("RendererCircle", {
                            attrs: { itemInfo: _vm.itemInfo }
                          })
                        : _vm.itemInfo.symbol.style === "square"
                        ? _c("Square", { attrs: { itemInfo: _vm.itemInfo } })
                        : _vm.itemInfo.symbol.style === "diamond"
                        ? _c("Diamond", { attrs: { itemInfo: _vm.itemInfo } })
                        : _vm.itemInfo.symbol.style === "cross"
                        ? _c("Cross", { attrs: { itemInfo: _vm.itemInfo } })
                        : _vm.itemInfo.symbol.style === "x"
                        ? _c("XCross", { attrs: { itemInfo: _vm.itemInfo } })
                        : [
                            _vm.itemInfo.symbol.outline &&
                            _vm.itemInfo.symbol.color &&
                            _vm.itemInfo.symbol.outline.color
                              ? _c("div", {
                                  style: {
                                    "border-radius": "50%",
                                    width: _vm.itemInfo.symbol.size + "pt",
                                    height: _vm.itemInfo.symbol.size + "pt",
                                    border:
                                      _vm.itemInfo.symbol.outline.style +
                                      " " +
                                      _vm.itemInfo.symbol.outline.width +
                                      "px rgba(" +
                                      _vm.itemInfo.symbol.outline.color[0] +
                                      "," +
                                      _vm.itemInfo.symbol.outline.color[1] +
                                      "," +
                                      _vm.itemInfo.symbol.outline.color[2] +
                                      "," +
                                      _vm.itemInfo.symbol.color[3] +
                                      ")",
                                    background: Array.isArray(
                                      _vm.itemInfo.symbol.color
                                    )
                                      ? "rgba(" +
                                        _vm.itemInfo.symbol.color[0] +
                                        "," +
                                        _vm.itemInfo.symbol.color[1] +
                                        "," +
                                        _vm.itemInfo.symbol.color[2] +
                                        "," +
                                        _vm.itemInfo.symbol.color[3] +
                                        ")"
                                      : "rgba(" +
                                        _vm.itemInfo.symbol.color.r +
                                        "," +
                                        _vm.itemInfo.symbol.color.g +
                                        "," +
                                        _vm.itemInfo.symbol.color.b +
                                        "," +
                                        _vm.itemInfo.symbol.color.a +
                                        ")"
                                  }
                                })
                              : _vm._e()
                          ]
                    ],
                    2
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.itemInfo.label))])
                ])
              ])
            : _vm.itemInfo.symbol.type === "simple-fill"
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            overflow: "hidden",
                            width: "30",
                            height: "30"
                          }
                        },
                        [
                          _c("defs"),
                          _c("path", {
                            attrs: {
                              fill: Array.isArray(_vm.itemInfo.symbol.color)
                                ? "rgb(" +
                                  _vm.itemInfo.symbol.color[0] +
                                  "," +
                                  _vm.itemInfo.symbol.color[1] +
                                  "," +
                                  _vm.itemInfo.symbol.color[2] +
                                  ")"
                                : "rgba(" +
                                  _vm.itemInfo.symbol.color.r +
                                  "," +
                                  _vm.itemInfo.symbol.color.g +
                                  "," +
                                  _vm.itemInfo.symbol.color.b +
                                  "," +
                                  _vm.itemInfo.symbol.color.a +
                                  ")",
                              "fill-opacity": "0.5",
                              stroke:
                                "rgb(" +
                                _vm.itemInfo.symbol.outline.color[0] +
                                "," +
                                _vm.itemInfo.symbol.outline.color[1] +
                                "," +
                                _vm.itemInfo.symbol.outline.color[2] +
                                ")",
                              "stroke-opacity": "0.5",
                              "stroke-width": _vm.itemInfo.symbol.outline.width,
                              "stroke-linecap": "butt",
                              "stroke-linejoin": "miter",
                              "stroke-miterlimit":
                                _vm.itemInfo.symbol.outline.width,
                              path:
                                "M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z",
                              d: "M-10-10L 10 0L 10 10L-10 10L-10-10Z",
                              "fill-rule": "evenodd",
                              "stroke-dasharray": "16,12",
                              transform:
                                "matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.itemInfo.label))])
                ])
              ])
            : _vm.itemInfo.symbol.type === "simple-line"
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon"
                    },
                    [_c("RendererLine", { attrs: { itemInfo: _vm.itemInfo } })],
                    1
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.itemInfo.label))])
                ])
              ])
            : _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon"
                    },
                    [_vm._v("X")]
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.itemInfo.label))])
                ])
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }