var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showDeleteFormDialog, "max-width": "320px" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-5" }, [
            _c("p", [_vm._v("Delete form?")]),
            _c("p", [_vm._v("This is permanent and cannot be undone.")])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel-delete-form")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-weight-regular" }, [
                    _vm._v("Cancel")
                  ])
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete-form")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-weight-regular" }, [
                    _vm._v("Delete Form")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }