var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-between px-3 py-2 rounded-0",
      staticStyle: { "background-color": "#38383d", color: "white" }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex gap justify-space-between flex-wrap flex-grow-1"
        },
        [
          _c("div", { staticClass: "d-flex gap flex-wrap" }, [
            _vm.itemType === _vm.ITEM_TYPES.TEXT
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex align-content-space-between flex-wrap",
                    staticStyle: { height: "45px", width: "45px" }
                  },
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Color")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            "background-color":
                                              _vm.options.fill,
                                            width: "25px",
                                            height: "25px",
                                            border: "1px solid black"
                                          }
                                        },
                                        [_vm._v("   ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          603758932
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("ColorPicker", {
                                  on: {
                                    "color-picker-cancelled": function($event) {
                                      _vm.showFillDialog = false
                                    },
                                    input: function($event) {
                                      _vm.showFillDialog = false
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  },
                                  model: {
                                    value: _vm.options.fill,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "fill", $$v)
                                    },
                                    expression: "options.fill"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.TEXT
              ? _c(
                  "div",
                  { staticClass: "number-input-slider" },
                  [
                    _c("p", { staticClass: "py-0 my-0 caption px-2" }, [
                      _vm._v("Size")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 64,
                        min: 0,
                        "hide-details": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.size,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "size", $$v)
                                    },
                                    expression: "options.size"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2062656978
                      ),
                      model: {
                        value: _vm.options.size,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "size", $$v)
                        },
                        expression: "options.size"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SHAPE
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Shape")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.options.shape ===
                                          _vm.SHAPE_TYPES.RECTANGLE
                                            ? _c("div", {
                                                style: {
                                                  width: "20px",
                                                  height: "15px",
                                                  "background-color":
                                                    _vm.options.shapeFill,
                                                  border:
                                                    _vm.options
                                                      .shapeOutlineWidth +
                                                    "px solid " +
                                                    _vm.options.shapeOutline
                                                }
                                              })
                                            : _vm.options.shape ===
                                              _vm.SHAPE_TYPES.POLYGON
                                            ? _c("Pentagon", {
                                                attrs: {
                                                  stroke:
                                                    _vm.options.shapeOutline,
                                                  fill: _vm.options.shapeFill,
                                                  strokeWidth: Math.min(
                                                    _vm.options
                                                      .shapeOutlineWidth,
                                                    2
                                                  )
                                                }
                                              })
                                            : _vm.options.shape ===
                                              _vm.SHAPE_TYPES.ELLIPSE
                                            ? _c("div", {
                                                style: {
                                                  width:
                                                    _vm.options.size + "px",
                                                  height:
                                                    _vm.options.size + "px",
                                                  border:
                                                    Math.min(
                                                      _vm.options
                                                        .shapeOutlineWidth,
                                                      2
                                                    ) +
                                                    "px solid " +
                                                    _vm.options.shapeOutline,
                                                  "border-radius": "50%",
                                                  "background-color":
                                                    _vm.options.shapeFill
                                                }
                                              })
                                            : _vm.options.shape ===
                                              _vm.SHAPE_TYPES.MAP_SYMBOL
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "25px",
                                                  height: "25px"
                                                },
                                                attrs: {
                                                  src: require("@/assets/img/mapSymbols/" +
                                                    _vm.getShapeFillColor(
                                                      _vm.options.shapeFill
                                                    ) +
                                                    _vm.options.shapeSymbol +
                                                    ".png")
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          741371073
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.options.shape =
                                      _vm.SHAPE_TYPES.RECTANGLE
                                    _vm.$emit("options-changed", _vm.options)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center"
                                  },
                                  [
                                    _c("div", {
                                      style: {
                                        width: "25px",
                                        height: "20px",
                                        "background-color":
                                          _vm.options.shapeFill,
                                        border:
                                          Math.min(
                                            _vm.options.shapeOutlineWidth,
                                            2
                                          ) +
                                          "px solid " +
                                          _vm.options.shapeOutline
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.options.shape = _vm.SHAPE_TYPES.POLYGON
                                    _vm.$emit("options-changed", _vm.options)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center"
                                  },
                                  [
                                    _c("Pentagon", {
                                      attrs: {
                                        stroke: _vm.options.shapeOutline,
                                        fill: _vm.options.shapeFill,
                                        strokeWidth: Math.min(
                                          _vm.options.shapeOutlineWidth,
                                          2
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.options.shape = _vm.SHAPE_TYPES.ELLIPSE
                                    _vm.$emit("options-changed", _vm.options)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center"
                                  },
                                  [
                                    _c("div", {
                                      style: {
                                        width: "20px",
                                        height: "20px",
                                        "border-radius": "50%",
                                        "background-color":
                                          _vm.options.shapeFill,
                                        border:
                                          Math.min(
                                            _vm.options.shapeOutlineWidth,
                                            2
                                          ) +
                                          "px solid " +
                                          _vm.options.shapeOutline
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.symbols, function(s) {
                              return _c(
                                "v-list-item",
                                {
                                  key: s,
                                  on: {
                                    click: function($event) {
                                      _vm.options.shape =
                                        _vm.SHAPE_TYPES.MAP_SYMBOL
                                      _vm.options.shapeSymbol = s
                                      _vm.options.shapeUrl =
                                        "/img/mapSymbols/" +
                                        _vm.getShapeFillColor(
                                          _vm.options.shapeFill
                                        ) +
                                        _vm.options.shapeSymbol +
                                        ".png"
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center"
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "25px",
                                          height: "25px"
                                        },
                                        attrs: {
                                          src: require("@/assets/img/mapSymbols/" +
                                            _vm.getShapeFillColor(
                                              _vm.options.shapeFill
                                            ) +
                                            s +
                                            ".png")
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SHAPE
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.options.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
                              ? "Symbol Color"
                              : "Fill"
                          ) +
                          " "
                      )
                    ]),
                    _vm.options.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
                      ? _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "pa-0 d-flex",
                                              attrs: { text: "", "x-small": "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "div",
                                            {
                                              style: {
                                                "background-color":
                                                  _vm.options.shapeFill,
                                                width: "25px",
                                                height: "25px",
                                                border: "1px solid black"
                                              }
                                            },
                                            [_vm._v("   ")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { color: "white" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiChevronDown) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1333231739
                            )
                          },
                          [
                            _c(
                              "v-list",
                              _vm._l(_vm.colorChoices, function(choice) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: choice.value,
                                    on: {
                                      click: function($event) {
                                        _vm.options.shapeFill = choice.value
                                        _vm.options.shapeImageFillColor = _vm.getShapeFillColor(
                                          choice.value
                                        )
                                        _vm.options.shapeUrl =
                                          "/img/mapSymbols/" +
                                          _vm.getShapeFillColor(choice.value) +
                                          _vm.options.shapeSymbol +
                                          ".png"
                                        _vm.$emit(
                                          "options-changed",
                                          _vm.options
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mr-2",
                                            style: {
                                              "background-color": choice.value,
                                              width: "25px",
                                              height: "25px"
                                            }
                                          },
                                          [_vm._v("   ")]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(choice.label))
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "pa-0 d-flex",
                                              attrs: { text: "", "x-small": "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "div",
                                            {
                                              style: {
                                                "background-color":
                                                  _vm.options.shapeFill,
                                                width: "25px",
                                                height: "25px",
                                                border: "1px solid black"
                                              }
                                            },
                                            [_vm._v("   ")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "my-0 py-0",
                                              attrs: { color: "white" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiChevronDown) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1333231739
                            )
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("ColorPicker", {
                                      on: {
                                        "color-picker-cancelled": function(
                                          $event
                                        ) {
                                          _vm.showFillDialog = false
                                        },
                                        input: function($event) {
                                          _vm.showFillDialog = false
                                          _vm.$emit(
                                            "options-changed",
                                            _vm.options
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.options.shapeFill,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.options,
                                            "shapeFill",
                                            $$v
                                          )
                                        },
                                        expression: "options.shapeFill"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SHAPE &&
            _vm.options.shape !== _vm.SHAPE_TYPES.MAP_SYMBOL
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Outline")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            "background-color":
                                              _vm.options.shapeOutline,
                                            width: "25px",
                                            height: "25px",
                                            border: "1px solid black"
                                          }
                                        },
                                        [_vm._v("   ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1928348852
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("ColorPicker", {
                                  on: {
                                    "color-picker-cancelled": function($event) {
                                      _vm.showFillDialog = false
                                    },
                                    input: function($event) {
                                      _vm.showFillDialog = false
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  },
                                  model: {
                                    value: _vm.options.shapeOutline,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "shapeOutline", $$v)
                                    },
                                    expression: "options.shapeOutline"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SHAPE &&
            _vm.options.shape !== _vm.SHAPE_TYPES.MAP_SYMBOL
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Outline Width")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 16,
                        min: 0,
                        "hide-details": ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.shapeOutlineWidth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        "shapeOutlineWidth",
                                        $$v
                                      )
                                    },
                                    expression: "options.shapeOutlineWidth"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1455204734
                      ),
                      model: {
                        value: _vm.options.shapeOutlineWidth,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "shapeOutlineWidth", $$v)
                        },
                        expression: "options.shapeOutlineWidth"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.LINE
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Stroke")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            "background-color":
                                              _vm.options.lineStroke,
                                            width: "25px",
                                            height: "25px",
                                            border: "1px solid black"
                                          }
                                        },
                                        [_vm._v("   ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2360557921
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("ColorPicker", {
                                  on: {
                                    "color-picker-cancelled": function($event) {
                                      _vm.showFillDialog = false
                                    },
                                    input: function($event) {
                                      _vm.showFillDialog = false
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  },
                                  model: {
                                    value: _vm.options.lineStroke,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "lineStroke", $$v)
                                    },
                                    expression: "options.lineStroke"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.LINE
              ? _c(
                  "div",
                  { staticClass: "number-input-slider" },
                  [
                    _c("p", { staticClass: "py-0 my-0 caption px-2" }, [
                      _vm._v("Width")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 16,
                        min: 1,
                        "hide-details": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.lineStrokeWidth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        "lineStrokeWidth",
                                        $$v
                                      )
                                    },
                                    expression: "options.lineStrokeWidth"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3839078795
                      ),
                      model: {
                        value: _vm.options.lineStrokeWidth,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "lineStrokeWidth", $$v)
                        },
                        expression: "options.lineStrokeWidth"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SKETCH
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex align-content-space-between flex-wrap",
                    staticStyle: { height: "45px", width: "45px" }
                  },
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Stroke")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            "background-color":
                                              _vm.options.sketchStroke,
                                            width: "25px",
                                            height: "25px",
                                            border: "1px solid black"
                                          }
                                        },
                                        [_vm._v("   ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1276574605
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("ColorPicker", {
                                  on: {
                                    "color-picker-cancelled": function($event) {
                                      _vm.showFillDialog = false
                                    },
                                    input: function($event) {
                                      _vm.showFillDialog = false
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  },
                                  model: {
                                    value: _vm.options.sketchStroke,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "sketchStroke", $$v)
                                    },
                                    expression: "options.sketchStroke"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.SKETCH
              ? _c(
                  "div",
                  { staticClass: "number-input-slider" },
                  [
                    _c("p", { staticClass: "py-0 my-0 caption px-2" }, [
                      _vm._v("Width")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 16,
                        min: 1,
                        "hide-details": ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.sketchStrokeWidth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        "sketchStrokeWidth",
                                        $$v
                                      )
                                    },
                                    expression: "options.sketchStrokeWidth"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2574631143
                      ),
                      model: {
                        value: _vm.options.sketchStrokeWidth,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "sketchStrokeWidth", $$v)
                        },
                        expression: "options.sketchStrokeWidth"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.ARROW
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption" }, [
                      _vm._v("Color")
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pa-0 d-flex",
                                          attrs: { text: "", "x-small": "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            "background-color":
                                              _vm.options.arrowStroke,
                                            width: "25px",
                                            height: "25px",
                                            border: "1px solid black"
                                          }
                                        },
                                        [_vm._v("   ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiChevronDown) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          114078198
                        )
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("ColorPicker", {
                                  on: {
                                    "color-picker-cancelled": function($event) {
                                      _vm.showFillDialog = false
                                    },
                                    input: function($event) {
                                      _vm.showFillDialog = false
                                      _vm.$emit("options-changed", _vm.options)
                                    }
                                  },
                                  model: {
                                    value: _vm.options.arrowStroke,
                                    callback: function($$v) {
                                      _vm.$set(_vm.options, "arrowStroke", $$v)
                                    },
                                    expression: "options.arrowStroke"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.ARROW
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption px-2" }, [
                      _vm._v("Line Width")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 16,
                        min: 1,
                        "hide-details": ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.arrowStrokeWidth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        "arrowStrokeWidth",
                                        $$v
                                      )
                                    },
                                    expression: "options.arrowStrokeWidth"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3344202140
                      ),
                      model: {
                        value: _vm.options.arrowStrokeWidth,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "arrowStrokeWidth", $$v)
                        },
                        expression: "options.arrowStrokeWidth"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.itemType === _vm.ITEM_TYPES.ARROW
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "py-0 my-0 caption px-2" }, [
                      _vm._v("Pointer Size")
                    ]),
                    _c("v-slider", {
                      attrs: {
                        color: "white",
                        max: 16,
                        min: 1,
                        "hide-details": ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("options-changed", _vm.options)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "number-input my-0 py-0 mt-n2",
                                  attrs: { readonly: "", "hide-details": "" },
                                  model: {
                                    value: _vm.options.arrowPointerSize,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        "arrowPointerSize",
                                        $$v
                                      )
                                    },
                                    expression: "options.arrowPointerSize"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2267133200
                      ),
                      model: {
                        value: _vm.options.arrowPointerSize,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "arrowPointerSize", $$v)
                        },
                        expression: "options.arrowPointerSize"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _c("div", { staticClass: "d-flex flex-no-wrap align-center gap px-2" }, [
        _vm.$vuetify.breakpoint.smAndUp
          ? _c(
              "div",
              [
                _vm.drawingLine
                  ? [_vm._v(" Tap to add line ")]
                  : [
                      _vm.itemType === _vm.ITEM_TYPES.LINE
                        ? [_vm._v(" Tap to add line ")]
                        : [
                            _vm._v(" Tap on the image to add "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$options.filters.itemTypeFilter(
                                    _vm.itemType
                                  )
                                )
                              }
                            })
                          ]
                    ]
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.drawingLine
              ? _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("finish-line")
                      }
                    }
                  },
                  [_vm._v(" Finish Line ")]
                )
              : _vm.isPaintPolygon
              ? _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("finish-polygon")
                      }
                    }
                  },
                  [_vm._v(" Finish Polygon ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("toolbar-close")
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(_vm._s(_vm.mdiClose))
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }