<template>
  <div>
    <v-dialog
      :value="showMarkupImageDialog"
      fullscreen
      :retain-focus="false"
      persistent
      class="rounded-0"
      no-click-animation
    >
      <v-card
        class="d-flex rounded-0"
        style="overflow: hidden"
        ref="card"
        height="calc(100vh - (100vh - 100%) - 56px)"
      >
        <v-slide-x-reverse-transition>
          <div
            :style="{ width: '200px' }"
            v-show="showPdfPagesPanel"
            ref="pdfList"
          >
            <MarkupPdfList
              @markup-pdf-list-close="showPdfPagesPanel = false"
              @page-selected="
                selectedPdfPage = $event;
                loadPdfPageOrMarkup();
              "
              :selectedFile="selectedFile"
            />
          </div>
        </v-slide-x-reverse-transition>
        <div class="flex-grow-1 d-flex flex-column">
          <div class="flex-grow-1 stage">
            <CropToolbar
              v-if="isCropping"
              @close-button-click="
                isCropping = false;
                $set(configKonva, 'draggable', true);
                clearCropRect();
              "
              @reset="resetCrop"
              ref="cropToolbar"
            />
            <MarkupToolbar
              v-else
              :selectedFile="selectedFile"
              :selectedPdfPage="selectedPdfPage"
              :hasLegend="hasLegend"
              :fileMarkupRevisions="fileMarkupRevisions"
              :pdfPageFileMarkupCounts="pdfPageFileMarkupCounts"
              :imageFileMarkupCount="imageFileMarkupCount"
              :disableFitToScreen="rotateAngleDegrees % 360 !== 0"
              :disableCrop="rotateAngleDegrees % 360 !== 0"
              @revision-selected="onRevisionSelected"
              @show-pdf-pages-panel="showPdfPagesPanel = !showPdfPagesPanel"
              @rotate="
                rotate($event);
                updateRotation($event);
              "
              @fit-to-screen="fitToScreen"
              @crop="crop"
              @download="download"
              @undo="undo"
              @redo="redo"
              @add-legend="showToolbar(ITEM_TYPES.LEGEND)"
              @dialog-close-button-click="onDialogCloseButtonClick"
              @show-more="getFileMarkupRevisions(++markupRevisionPage)"
              @revision-deleted="onRevisionDeleted()"
              ref="markupToolbar"
            />

            <MarkupItemsToolbar
              :itemType="itemType"
              :displayCustomSymbolToolbar="displayCustomSymbolToolbar"
              :customSymbols="customSymbols"
              :selectedItem="selectedItem"
              @show-toolbar="showToolbar"
              @hide-toolbar="hideToolbar"
              @undo="undo"
              @redo="redo"
              @selected-custom-symbol="onSelectCustomSymbol"
              @custom-symbol-added="getCustomSymbols()"
              @custom-symbol-edited="getCustomSymbols()"
              @get-custom-symbols="
                symbolInactiveStatus = $event;
                getCustomSymbols();
              "
              ref="markupItemToolbar"
            />

            <div style="overflow: hidden" class="d-flex pa-0 ma-0">
              <UnsavedMarkupDialog
                :showUnsavedMarkupDialog="showUnsavedMarkupDialog"
                @cancel-markup-image-dialog-close="
                  showUnsavedMarkupDialog = false
                "
                @confirm-markup-image-dialog-close="
                  $emit('markup-image-dialog-close');
                  undoItems = {};
                  redoItems = {};
                "
              />

              <Legend ref="legend" :addedCustomSymbols="addedCustomSymbols" />

              <div class="flex-grow-1 stage">
                <v-stage
                  :config="configKonva"
                  ref="stage"
                  @mousedown="handleStageMouseDown"
                  @touchstart="handleStageMouseDown"
                  @click="addItem"
                  @tap="addItem"
                  @dblclick="handleStageDoubleClick"
                  @dbltap="handleStageDoubleClick"
                  class="d-flex justify-center align-center"
                >
                  <v-layer ref="layer" :config="layerConfig">
                    <template v-for="(item, index) of items">
                      <v-image
                        v-if="
                          item.type === ITEM_TYPES.IMAGE ||
                          item.type === ITEM_TYPES.LEGEND
                        "
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                      />
                      <v-text
                        v-else-if="item.type === ITEM_TYPES.TEXT"
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                      />
                      <v-line
                        v-else-if="item.type === ITEM_TYPES.LINE"
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragmove="onLineDrag(index)"
                        @dragend="onLineDrag(index)"
                      />
                      <v-line
                        v-else-if="
                          item.type === ITEM_TYPES.SHAPE &&
                          item.shape === SHAPE_TYPES.POLYGON
                        "
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragmove="onPolygonDrag(index)"
                        @dragend="onPolygonDrag(index)"
                      />
                      <v-line
                        v-else-if="item.type === ITEM_TYPES.SKETCH"
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                      />
                      <v-rect
                        v-else-if="
                          item.type === ITEM_TYPES.SHAPE &&
                          item.shape === SHAPE_TYPES.RECTANGLE
                        "
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                        @transformend="onTransformEnd(index)"
                      />
                      <v-ellipse
                        v-else-if="
                          item.type === ITEM_TYPES.SHAPE &&
                          item.shape === SHAPE_TYPES.ELLIPSE
                        "
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                        @transformend="onTransformEnd(index)"
                      />
                      <v-image
                        v-else-if="
                          item.type === ITEM_TYPES.SHAPE &&
                          item.shape === SHAPE_TYPES.MAP_SYMBOL
                        "
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                      />
                      <v-circle
                        v-else-if="item.type === ITEM_TYPES.POINT"
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragend="onDragEnd(index, $event)"
                        @transformend="onTransformEnd(index)"
                      />
                      <v-arrow
                        v-else-if="item.type === ITEM_TYPES.ARROW"
                        :config="item.config"
                        :key="item.id"
                        ref="items"
                        @dragmove="onArrowDrag(index)"
                        @dragend="onArrowDrag(index)"
                      />
                    </template>
                    <v-transformer ref="transformer" />
                  </v-layer>
                  <v-layer ref="cropperLayer">
                    <v-transformer
                      ref="cropperRectTransformer"
                      v-if="isCropping"
                      @transformend="onCropperRectDragTransform"
                      @transformstart="onCropperRectDragTransform"
                      @transform="onCropperRectDragTransform"
                      @dragend="onCropperRectDragTransform"
                      @dragstart="onCropperRectDragTransform"
                      @drag="onCropperRectDragTransform"
                    />
                    <v-rect
                      ref="cropperRect"
                      v-if="isCropping"
                      :config="cropperConfig"
                    />
                  </v-layer>
                </v-stage>

                <v-btn
                  class="bottom-right-button"
                  color="#3F51B5"
                  dark
                  @click="confirmCrop()"
                  v-if="isCropping"
                >
                  Crop
                </v-btn>
                <div v-else class="bottom-right-button">
                  <v-btn
                    dark
                    tile
                    color="utilisync"
                    @click="isSaveAndClose ? saveAndClose() : saveFileMarkup()"
                    :disabled="!isSaveAndClose && !changesMade"
                  >
                    {{ isSaveAndClose ? "Save and Close" : "Save Draft" }}
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        tile
                        dark
                        v-bind="attrs"
                        v-on="on"
                        width="30px"
                        min-width="30px"
                        color="utilisync"
                      >
                        <v-icon color="white">
                          {{ mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="isSaveAndClose = false">
                        Save Draft
                      </v-list-item>
                      <v-list-item @click="isSaveAndClose = true">
                        Save and Close
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <BottomToolbar
                  class="bottom-bar"
                  v-if="displayToolbar"
                  @options-changed="itemOptions = $event"
                  @toolbar-close="
                    displayToolbar = false;
                    itemType = '';
                  "
                  @finish-line="finishLine"
                  @finish-polygon="finishPolygon"
                  :itemType="itemType"
                  :drawingLine="drawingLine"
                  :isPaintPolygon="isPaintPolygon"
                  :selectedFile="selectedFile"
                />

                <EditItemBottomToolbar
                  class="bottom-bar"
                  v-if="displayEditItemToolbar"
                  :selectedItem="selectedItem"
                  @options-changed="onSelectedItemOptionsChanged"
                  @toolbar-close="
                    displayEditItemToolbar = false;
                    clearSelection();
                  "
                  @graphic-details-changed="onSelectedItemDetailsChanged"
                  @delete-item="deleteItem"
                />

                <CustomSymbolBottomBar
                  class="bottom-bar"
                  v-if="displayCustomSymbolToolbar"
                  :selectedCustomSymbol="selectedCustomSymbol"
                  @toolbar-close="
                    displayCustomSymbolToolbar = false;
                    selectedCustomSymbol = undefined;
                  "
                />
              </div>
            </div>

            <v-navigation-drawer
              app
              v-model="showGraphicDetailsPanel"
              right
              :permanent="showGraphicDetailsPanel"
              style="z-index: 100"
              hide-overlay
              width="300px"
              stateless
              class="elevation-1"
            >
              <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
                <div>Details</div>
                <v-spacer />
                <v-btn icon @click="showGraphicDetailsPanel = false">
                  <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
              </v-toolbar>

              <GraphicDetailsForm
                :selectedItem="selectedItem"
                @graphic-details-changed="onGraphicDetailsChanged"
              />
            </v-navigation-drawer>

            <v-navigation-drawer
              app
              v-model="editingText"
              right
              :permanent="editingText"
              style="z-index: 100"
              hide-overlay
              width="300px"
              stateless
              class="elevation-1"
            >
              <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
                <div>Edit Text</div>
                <v-spacer />
                <v-btn icon @click="editingText = false">
                  <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
              </v-toolbar>

              <EditTextForm
                :selectedItem="selectedItem"
                @text-changed="onTextChanged"
              />
            </v-navigation-drawer>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog persistent :value="savingAndClosing" fullscreen>
      <v-card
        style="background-color: black"
        class="d-flex justify-center align-center"
      >
        <v-card-text class="d-flex justify-center align-center">
          <v-progress-circular
            color="white"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiFormatText,
  mdiVectorPolyline,
  mdiShape,
  mdiFountainPenTip,
  mdiUndo,
  mdiRedo,
  mdiDotsVertical,
  mdiRotateLeft,
  mdiRotateRight,
  mdiFitToPageOutline,
  mdiMenu,
  mdiVectorPolygon,
  mdiPencil,
  mdiMagnify,
  mdiSyncCircle,
  mdiArrowTopRight,
  mdiDownload,
  mdiChevronDown,
  mdiChevronUp,
  mdiCrop,
} from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import BottomToolbar from "@/components/mapView/docs-tab/markup-image-dialog/BottomToolbar";
import EditItemBottomToolbar from "@/components/mapView/docs-tab/markup-image-dialog/EditItemBottomToolbar";
import UnsavedMarkupDialog from "@/components/mapView/docs-tab/markup-image-dialog/UnsavedMarkupDialog";
import GraphicDetailsForm from "@/components/mapView/docs-tab/markup-image-dialog/GraphicDetailsForm";
import EditTextForm from "@/components/mapView/docs-tab/markup-image-dialog/EditTextForm";
import MarkupPdfList from "@/components/mapView/docs-tab/markup-image-dialog/MarkupPdfList";
import MarkupToolbar from "@/components/mapView/docs-tab/markup-image-dialog/MarkupToolbar";
import CropToolbar from "@/components/mapView/docs-tab/markup-image-dialog/CropToolbar";
import MarkupItemsToolbar from "@/components/mapView/docs-tab/markup-image-dialog/MarkupItemsToolbar";
import CustomSymbolBottomBar from "@/components/mapView/docs-tab/markup-image-dialog/CustomSymbolBottomBar";
import Legend from "@/components/mapView/docs-tab/markup-image-dialog/Legend";
import ITEM_TYPES from "@/constants/markupItemTypes";
import SYMBOL_TYPES from "@/constants/symbolTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { cloneDeep, chunk } from "lodash";
import Konva from "konva";
import html2canvas from "html2canvas";

const APIURL = process.env.VUE_APP_API_URL;
const UNDO_ITEM_TYPES = {
  ITEM_ADDED: "item-added",
  ITEM_REMOVED: "item-removed",
  ITEM_CHANGE: "item-change",
  ITEM_CROPPED: "item-cropped",
};

const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

const anchorOptions = {
  width: 10,
  height: 10,
  fill: "white",
  stroke: "#80d0ff",
  draggable: true,
};

export default {
  name: "MarkupImageDialog",
  components: {
    BottomToolbar,
    UnsavedMarkupDialog,
    MarkupPdfList,
    GraphicDetailsForm,
    EditTextForm,
    EditItemBottomToolbar,
    MarkupToolbar,
    CropToolbar,
    CustomSymbolBottomBar,
    MarkupItemsToolbar,
    Legend,
  },
  data() {
    return {
      mdiClose,
      mdiPlus,
      mdiFormatText,
      mdiVectorPolyline,
      mdiShape,
      mdiFountainPenTip,
      mdiUndo,
      mdiRedo,
      mdiDotsVertical,
      mdiRotateLeft,
      mdiRotateRight,
      mdiFitToPageOutline,
      mdiMenu,
      mdiVectorPolygon,
      mdiPencil,
      mdiMagnify,
      mdiSyncCircle,
      mdiArrowTopRight,
      mdiDownload,
      mdiChevronDown,
      mdiChevronUp,
      mdiCrop,
      configKonva: {
        width: window.innerWidth - 200,
        height: window.innerHeight - 65,
        draggable: true,
      },
      items: [],
      ITEM_TYPES,
      SHAPE_TYPES,
      showSymbolPanel: false,
      openPanels: [true, true],
      selectedShapeName: undefined,
      isPaint: false,
      isPaintPolygon: false,
      selectedNode: undefined,
      undoItems: {},
      redoItems: {},
      itemType: "",
      displayToolbar: false,
      itemOptions: {},
      showUnsavedMarkupDialog: false,
      showAddSymbolDialog: false,
      customSymbols: [],
      SYMBOL_TYPES,
      showPdfPagesPanel: false,
      selectedPdfPage: {},
      showEditSymbolDialog: false,
      selectedSymbol: {},
      symbolInactiveStatus: false,
      symbolKeyword: "",
      isLoadingMarkup: true,
      pdfPageItems: [],
      showGraphicDetailsPanel: false,

      firstLoad: false,
      selectedCustomSymbol: undefined,
      displayEditItemToolbar: false,
      pdfPageImages: [],
      displayCustomSymbolToolbar: false,
      arrowAnchors: [],
      lineAnchors: [],
      polygonAnchors: [],
      cropperRectAnchors: [],
      isCropping: false,
      cropperConfig: {
        draggable: true,
      },
      changesMade: false,
      anchorScale: 1,
      downloading: false,
      cropperShape: undefined,
      drawingLine: false,
      editingText: false,
      pdfPageFileMarkupCounts: [],
      imageFileMarkupCount: {},
      fileMarkupRevisions: [],
      markupRevisionPage: 1,
      isSaveAndClose: true,
      savingAndClosing: false,
    };
  },
  props: {
    showMarkupImageDialog: Boolean,
    selectedFile: Object,
  },
  async beforeMount() {
    this.onResize();
    this.watchResize();
    await this.getCustomSymbols();
  },
  computed: {
    rotateAngleDegrees() {
      const { index } = this.findMarkupImageItemAndNodeIndexes();
      const { rotateAngleDegrees = 0 } = this.items[index] ?? {};
      return rotateAngleDegrees;
    },
    layerConfig() {
      if (!Array.isArray(this.items)) {
        return {
          clip: {},
          draggable: false,
        };
      }
      let index = -1;
      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        index = this.items.findIndex(
          (it) => it.src === this.selectedPdfPage?.s3_file_path_original_image
        );
      } else {
        index = this.items.findIndex(
          (it) => it.src === this.selectedFile?.s3_file_path_original_image
        );
      }
      return (
        this.items[index]?.layerConfig ?? {
          clip: {},
          draggable: false,
        }
      );
    },
    hasLegend() {
      const legend = this.items?.find((it) => it.type === ITEM_TYPES.LEGEND);
      return Boolean(legend);
    },
    addedCustomSymbols() {
      if (!Array.isArray(this.items)) {
        return [];
      }
      const itemCustomSymbolIds = this.items
        .map((it) => it.markupSymbolId)
        .filter(Boolean);
      return this.customSymbols
        .filter((it) => itemCustomSymbolIds.includes(it.markup_symbol_id))
        .map((it) => {
          const { label, markup_symbol_id: markupSymbolId, symbol } = it;
          return {
            label,
            markupSymbolId,
            symbol,
            count: itemCustomSymbolIds.filter((id) => id === markupSymbolId)
              .length,
          };
        });
    },
    selectedItem() {
      return this.items?.find((item) => item.id === this.selectedShapeName);
    },
    filteredCustomSymbols() {
      if (this.symbolKeyword) {
        return cloneDeep(this.customSymbols).filter((c) =>
          c.label
            .trim()
            .toLowerCase()
            .includes(this.symbolKeyword.trim().toLowerCase())
        );
      }
      return cloneDeep(this.customSymbols);
    },
  },
  async mounted() {
    const transformerNode = this.$refs.transformer.getNode();
    transformerNode.ignoreStroke(false);
    this.addZoom();
    this.addPinchZoomAndPan();
    this.isLoadingMarkup = true;

    if (this.selectedFile?.s3_file_path?.endsWith("pdf")) {
      await this.countPdfPageMarkup();
      await this.getPdfPageImages();
      this.selectedPdfPage = this.pdfPageImages[0];
      await this.getFileMarkupRevisions();
      await this.loadPdfPageOrMarkup();
    } else {
      await this.countImagePageMarkup();
      const { count } = this.imageFileMarkupCount;
      if (count === 0) {
        await this.loadImage();
      } else {
        await this.getFileMarkupRevisions();
        await this.getMarkup();
        this.fitToScreen();
      }

      const { index } = this.findMarkupImageItemAndNodeIndexes();
      const { rotateAngleDegrees } = this.items[index] ?? {};
      if (typeof rotateAngleDegrees === "number") {
        this.rotate(rotateAngleDegrees);
      }
    }
    this.isLoadingMarkup = false;

    const stage = this.$refs.stage?.getNode();
    if (!stage || !this.$refs.card.$el) {
      return;
    }
    stage.width(this.$refs.card.$el.offsetWidth);
    stage.height(this.$refs.card.$el.offsetHeight);
    this.configKonva = {
      ...this.configKonva,
      width: this.$refs.card.$el.offsetWidth,
      height: this.$refs.card.$el.offsetHeight,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("keydown", this.onKeyDown);
    localStorage.removeItem("markup-item-settings");
  },
  methods: {
    async onRevisionDeleted() {
      await this.reloadRevisionCounts();
      await this.saveFileMarkupPreview();
    },
    async reloadRevisionCounts() {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith(".pdf")) {
        await this.countPdfPageMarkup();
      } else {
        await this.countImagePageMarkup();
      }
    },
    async getFileMarkupRevisions(page = 1) {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith(".pdf")) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedPdfPage.file_id}`,
          { params: { page } }
        );
        if (page === 1) {
          this.fileMarkupRevisions = results;
        } else {
          this.fileMarkupRevisions = [...this.fileMarkupRevisions, ...results];
        }
      } else {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedFile.file_id}`,
          { params: { page } }
        );
        if (page === 1) {
          this.fileMarkupRevisions = results;
        } else {
          this.fileMarkupRevisions = [...this.fileMarkupRevisions, ...results];
        }
      }
    },
    onTransformEnd(index) {
      const nodeIndex = this.$refs.items.findIndex(
        (it) => it.getNode().name() === this.items[index].id
      );
      const node = this.$refs.items[nodeIndex].getNode();
      this.items[index].config = {
        ...this.items[index].config,
        scale: {
          x: node.scaleX(),
          y: node.scaleY(),
        },
      };
      this.changesMade = true;
    },
    async loadPdfPageOrMarkup() {
      const savedMarkup =
        this.pdfPageFileMarkupCounts.find(
          (p) => p.fileId === this.selectedPdfPage.file_id
        )?.count > 0;
      if (savedMarkup) {
        this.items = await this.loadPdfPageMarkup();
        await this.$nextTick();
        const layer = this.$refs.layer?.getNode();
        if (!layer) {
          return;
        }
        const { nodeIndex, index } = this.findMarkupImageItemAndNodeIndexes();
        const imageNode = this.$refs.items?.[nodeIndex]?.getNode();
        if (!imageNode) {
          return;
        }
        const { x, y, width, height } =
          this.items[index]?.layerConfig?.clip ?? {};
        if (!isNaN(width) && !isNaN(height) && !isNaN(x) && !isNaN(y)) {
          layer.clipX(x);
          layer.clipY(y);
          layer.clipWidth(width);
          layer.clipHeight(height);
        } else {
          layer.clipX(imageNode.x());
          layer.clipY(imageNode.y());
          layer.clipWidth(imageNode.width());
          layer.clipHeight(imageNode.height());
        }
        const { rotateAngleDegrees } = this.items[index] ?? {};
        this.fitToScreen();
        if (typeof rotateAngleDegrees === "number") {
          this.rotate(rotateAngleDegrees);
        }
      } else {
        this.firstLoad = true;
        const image = await this.loadPdfPage();
        this.zoomToFit(image);
      }
    },
    onSelectCustomSymbol(selectedCustomSymbol) {
      this.selectedCustomSymbol = selectedCustomSymbol;
      this.clearSelection();
      this.resetStageListeners();
      this.itemType = "";
      this.displayToolbar = false;
      this.displayCustomSymbolToolbar = true;
      this.showSymbolPanel = false;
    },
    async confirmCrop() {
      const stage = this.$refs.stage.getNode();
      const oldPos = stage.position();
      const oldScale = stage.scale();
      const oldRotation = stage.rotation();
      stage.scale({ x: 1, y: 1 });
      stage.position({ x: 0, y: 0 });
      stage.rotation(0);
      this.addUndoItem(
        UNDO_ITEM_TYPES.ITEM_CROPPED,
        cloneDeep(this.configKonva)
      );
      const transformerNode = this.$refs.cropperRectTransformer.getNode();
      const { index } = this.findMarkupImageItemAndNodeIndexes();
      this.$set(this.items[index], "layerConfig", {
        clip: {
          x: transformerNode.x(),
          y: transformerNode.y(),
          width: transformerNode.width(),
          height: transformerNode.height(),
        },
        draggable: false,
      });
      this.isCropping = false;
      stage.scale(oldScale);
      stage.position(oldPos);
      stage.rotation(oldRotation);
      this.changesMade = true;
    },
    findMarkupImageItemAndNodeIndexes() {
      let index = -1;
      if (
        this.selectedFile?.s3_file_path_original_image?.endsWith("pdf") ||
        this.selectedFile?.s3_file_path?.endsWith("pdf")
      ) {
        index = this.items.findIndex(
          (it) => it.src === this.selectedPdfPage?.s3_file_path_original_image
        );
      } else {
        index = this.items.findIndex(
          (it) => it.src === this.selectedFile?.s3_file_path_original_image
        );
      }
      const nodeIndex = this.$refs.items?.findIndex(
        (it) => it.getNode().name() === this.items[index]?.id
      );
      return { index, nodeIndex };
    },
    async resetCrop() {
      this.$set(this.configKonva, "draggable", false);
      this.isCropping = false;
      await this.$nextTick();
      this.isCropping = true;
      await this.$nextTick();

      this.$set(this.configKonva, "draggable", false);
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      const imageNode = this.$refs.items[nodeIndex].getNode();
      const cropperRect = this.$refs.cropperRect.getNode();
      const transformerNode = this.$refs.cropperRectTransformer.getNode();
      const layer = this.$refs.layer.getNode();
      layer.clipX(imageNode.x());
      layer.clipY(imageNode.y());
      layer.clipWidth(imageNode.width());
      layer.clipHeight(imageNode.height());
      cropperRect.x(imageNode.x());
      cropperRect.y(imageNode.y());
      cropperRect.width(imageNode.width());
      cropperRect.height(imageNode.height());
      transformerNode.nodes([cropperRect]);
      transformerNode.rotateEnabled(false);
      transformerNode.draggable(false);
      this.zoomToFit();
    },
    async crop() {
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      this.isCropping = true;
      this.$set(this.configKonva, "draggable", false);
      await this.$nextTick();
      const imageNode = this.$refs.items[nodeIndex].getNode();
      const cropperRect = this.$refs.cropperRect.getNode();
      const layer = this.$refs.layer.getNode();
      const transformerNode = this.$refs.cropperRectTransformer.getNode();
      let oldClipX = imageNode.x();
      let oldClipY = imageNode.y();
      let oldClipWidth = imageNode.width();
      let oldClipHeight = imageNode.height();

      if (!isNaN(layer.clipX()) && !isNaN(layer.clipY())) {
        oldClipX = layer.clipX();
        oldClipY = layer.clipY();
        oldClipWidth = layer.clipWidth();
        oldClipHeight = layer.clipHeight();
      }

      layer.clipX(oldClipX);
      layer.clipY(oldClipY);
      layer.clipWidth(oldClipWidth);
      layer.clipHeight(oldClipHeight);

      cropperRect.x(oldClipX);
      cropperRect.y(oldClipY);
      cropperRect.width(oldClipWidth);
      cropperRect.height(oldClipHeight);

      transformerNode.nodes([cropperRect]);
      transformerNode.rotateEnabled(false);
      transformerNode.draggable(false);
    },
    clearCropRect() {
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      const layer = this.$refs.layer.getNode();
      const imageNode = this.$refs.items[nodeIndex].getNode();
      layer.clipX(imageNode.x());
      layer.clipY(imageNode.y());
      layer.clipWidth(imageNode.width());
      layer.clipHeight(imageNode.height());
    },
    async onCropperRectDragTransform() {
      const layer = this.$refs.layer.getNode();
      const stage = this.$refs.stage.getNode();
      const oldPos = stage.position();
      const oldScale = stage.scale();
      const oldRotation = stage.rotation();
      stage.scale({ x: 1, y: 1 });
      stage.position({ x: 0, y: 0 });
      stage.rotation(0);
      const transformerNode = this.$refs.cropperRectTransformer.getNode();
      layer.clipX(transformerNode.x());
      layer.clipY(transformerNode.y());
      layer.clipWidth(transformerNode.width());
      layer.clipHeight(transformerNode.height());
      stage.scale(oldScale);
      stage.position(oldPos);
      stage.rotation(oldRotation);
    },
    async download() {
      this.downloading = true;
      const transformerNode = this.$refs.transformer.getNode();
      const oldNodes = transformerNode.nodes();
      transformerNode.nodes([]);
      const layer = this.$refs.layer.getNode();
      for (const a of this.arrowAnchors) {
        a?.remove();
      }

      for (const a of this.lineAnchors) {
        a?.remove();
      }

      for (const a of this.polygonAnchors) {
        a?.remove();
      }
      layer.batchDraw();
      await this.$nextTick();
      const dataURL = layer.toDataURL();
      const link = document.createElement("a");
      link.download = "markup.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      transformerNode.nodes(oldNodes);
      for (const a of this.arrowAnchors) {
        layer.add(a);
      }

      for (const a of this.lineAnchors) {
        layer.add(a);
      }

      for (const a of this.polygonAnchors) {
        layer.add(a);
      }
      this.downloading = false;
    },
    async onRevisionSelected(selectedVersionDateTime) {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith(".pdf")) {
        if (!this.selectedPdfPage.file_id) {
          return;
        }
        const markupObj = this.fileMarkupRevisions.find(
          (r) => r.saved === selectedVersionDateTime
        );
        await this.loadItemsFromMarkup(markupObj.markup);
      } else {
        if (!this.selectedFile.file_id) {
          return;
        }
        const markupObj = this.fileMarkupRevisions.find(
          (r) => r.saved === selectedVersionDateTime
        );
        await this.loadItemsFromMarkup(markupObj.markup);
      }
    },
    async onSelectedItemDetailsChanged(selectedItemId, selectedItemDetails) {
      const itemIndex = this.items.findIndex((it) => it.id === selectedItemId);
      this.items[itemIndex] = {
        ...this.items[itemIndex],
        details: selectedItemDetails,
      };
      this.changesMade = true;
    },
    async onSelectedItemOptionsChanged(id, item) {
      const itemIndex = this.items.findIndex((it) => it.id === id);
      this.addUndoItem(UNDO_ITEM_TYPES.ITEM_CHANGE, this.items[itemIndex]);
      const node = this.$refs.items[itemIndex]?.getNode();
      if (this.items[itemIndex].type === ITEM_TYPES.TEXT) {
        const { fill, size } = item;
        this.items[itemIndex] = {
          ...this.items[itemIndex],
          fill,
          size,
        };
        this.items[itemIndex].config = {
          ...this.items[itemIndex].config,
          fontSize: size,
          fill,
        };
        if (node) {
          node.fill(fill);
          node.fontSize(size);
        }
      } else if (this.items[itemIndex].type === ITEM_TYPES.LINE) {
        const { lineStroke, lineStrokeWidth } = item;
        this.items[itemIndex] = {
          ...this.items[itemIndex],
          stroke: lineStroke,
          strokeWidth: lineStrokeWidth,
        };
        if (node) {
          node.stroke(lineStroke);
          node.strokeWidth(lineStrokeWidth);
        }
      } else if (this.items[itemIndex].type === ITEM_TYPES.SKETCH) {
        const { sketchStroke, sketchStrokeWidth } = item;
        this.items[itemIndex] = {
          ...this.items[itemIndex],
          stroke: sketchStroke,
          strokeWidth: sketchStrokeWidth,
        };
        if (node) {
          node.stroke(sketchStroke);
          node.strokeWidth(sketchStrokeWidth);
        }
      } else if (this.items[itemIndex].type === ITEM_TYPES.SHAPE) {
        if (this.items[itemIndex].shape === SHAPE_TYPES.MAP_SYMBOL) {
          const {
            shapeUrl,
            shapeFill: fill,
            shapeOutline: stroke,
            shapeOutlineWidth: strokeWidth,
          } = item;
          this.items[itemIndex] = {
            ...this.items[itemIndex],
            src: shapeUrl,
            shapeFill: fill,
            shapeOutline: stroke,
            shapeOutlineWidth: strokeWidth,
          };
          const img = new window.Image();
          img.src = require(`@/assets${shapeUrl}`);
          img.crossOrigin = "Anonymous";
          img.onload = () => {
            const image = img;
            this.items[itemIndex].config = {
              ...this.items[itemIndex].config,
              image,
            };
            if (node) {
              node.image(image);
            }
          };
        } else {
          const {
            shapeFill: fill,
            shapeOutline: stroke,
            shapeOutlineWidth: strokeWidth,
          } = item;
          this.items[itemIndex] = {
            ...this.items[itemIndex],
            shapeFill: fill,
            shapeOutline: stroke,
            shapeOutlineWidth: strokeWidth,
          };
          this.items[itemIndex].config = {
            ...this.items[itemIndex].config,
            fill,
            stroke,
            strokeWidth,
          };
          if (node) {
            node.stroke(stroke);
            node.strokeWidth(strokeWidth);
            node.fill(fill);
          }
        }
      } else if (this.items[itemIndex].type === ITEM_TYPES.POINT) {
        const {
          pointFill: fill,
          pointOutlineColor,
          pointOutlineWidth,
          size,
        } = item;
        this.items[itemIndex] = {
          ...this.items[itemIndex],
          pointFill: fill,
          pointOutlineColor,
          pointOutlineWidth,
          size,
        };
        this.items[itemIndex].config = {
          ...this.items[itemIndex].config,
          stroke: pointOutlineColor,
          strokeWidth: pointOutlineWidth,
          fill,
        };
        if (node) {
          node.stroke(pointOutlineColor);
          node.strokeWidth(pointOutlineWidth);
          node.fill(fill);
          node.width(size);
          node.height(size);
        }
      } else if (this.items[itemIndex].type === ITEM_TYPES.ARROW) {
        const { arrowStroke, arrowStrokeWidth, arrowPointerSize } = item;
        this.items[itemIndex] = {
          ...this.items[itemIndex],
          arrowStroke,
          arrowPointerSize,
        };
        this.items[itemIndex].config = {
          ...this.items[itemIndex].config,
          stroke: arrowStroke,
          strokeWidth: arrowStrokeWidth,
          pointerLength: arrowPointerSize,
          pointerWidth: arrowPointerSize,
        };
        if (node) {
          node.stroke(arrowStroke);
          node.strokeWidth(arrowStrokeWidth);
          node.pointerLength(arrowPointerSize);
          node.pointerWidth(arrowPointerSize);
        }
      }
    },
    onDialogCloseButtonClick() {
      if (this.changesMade) {
        this.showUnsavedMarkupDialog = true;
      } else {
        this.undoItems = {};
        this.redoItems = {};
        this.$emit("markup-image-dialog-close");
      }
    },
    onTextChanged(item) {
      const { id, config } = item;
      const index = this.items.findIndex((it) => it.id === id);
      const node = this.$refs.items
        .find((it) => it.getNode().name() === id)
        .getNode();
      this.items[index] = {
        ...this.items[index],
        config,
      };
      const { fontFamily, fontSize } = config;
      const layer = this.$refs.layer?.getNode();
      const ctx = layer.getContext();
      ctx.font = `${fontSize}px ${fontFamily}`;
      node.width(ctx.measureText(config.text).width);
      node.text(config.text);
      this.changesMade = true;
    },
    onGraphicDetailsChanged(item) {
      const { id, details } = item;
      const index = this.items.findIndex((it) => it.id === id);
      this.items[index] = {
        ...this.items[index],
        details,
      };
      this.changesMade = true;
    },
    loadImageFromMarkup(markupItem, isMapSymbol) {
      return new Promise((resolve, reject) => {
        const {
          src,
          id,
          config,
          shapeUrl,
          shapeFill,
          shapeImageFillColor,
          shapeSymbol,
          shapeOutlineWidth,
          layerConfig,
          type,
          markupSymbolId,
          rotateAngleDegrees = 0,
        } = markupItem;
        const img = new window.Image();
        img.src = src;
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const image = img;
          const item = {
            id,
            src,
            shapeUrl,
            shapeFill,
            shapeImageFillColor,
            shapeSymbol,
            shapeOutlineWidth,
            markupSymbolId,
            config: {
              ...config,
              image,
              draggable: false,
              name: id,
            },
            layerConfig,
            type: isMapSymbol ? ITEM_TYPES.SHAPE : type,
            shape: isMapSymbol ? SHAPE_TYPES.MAP_SYMBOL : undefined,
            rotateAngleDegrees,
          };
          resolve(item);
        };
        img.onerror = () => {
          reject();
        };
      });
    },
    async returnItemsFromMarkup(markup) {
      const items = [];
      try {
        for (const m of markup) {
          if (m.type === ITEM_TYPES.IMAGE || m.type === ITEM_TYPES.LEGEND) {
            const item = await this.loadImageFromMarkup(m, false);
            items.push(item);
          } else if (
            m.type === ITEM_TYPES.LINE ||
            m.type === ITEM_TYPES.SKETCH ||
            m.type === ITEM_TYPES.TEXT ||
            m.type === ITEM_TYPES.POINT ||
            m.type === ITEM_TYPES.ARROW
          ) {
            items.push(m);
          } else if (m.type === ITEM_TYPES.SHAPE) {
            const { shape } = m;
            if (shape === SHAPE_TYPES.MAP_SYMBOL) {
              const item = await this.loadImageFromMarkup(m, true);
              items.push(item);
            } else {
              items.push(m);
            }
          }
        }
        return items;
      } catch (error) {
        console.log(error);
      }
    },
    async loadItemsFromMarkup(markup) {
      this.items = [];

      for (const m of markup) {
        try {
          if (m.type === ITEM_TYPES.IMAGE || m.type === ITEM_TYPES.LEGEND) {
            const item = await this.loadImageFromMarkup(m);
            this.items.push(item);
          } else if (
            m.type === ITEM_TYPES.LINE ||
            m.type === ITEM_TYPES.SKETCH ||
            m.type === ITEM_TYPES.TEXT ||
            m.type === ITEM_TYPES.POINT ||
            m.type === ITEM_TYPES.ARROW
          ) {
            this.items.push(m);
          } else if (m.type === ITEM_TYPES.SHAPE) {
            const { shape } = m;
            if (shape === SHAPE_TYPES.MAP_SYMBOL) {
              const item = await this.loadImageFromMarkup(m, true);
              this.items.push(item);
            } else {
              this.items.push(m);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getMarkup(reloadMarkup = true) {
      if (!this.selectedFile.file_id) {
        return;
      }
      const [latestMarkup = {}] = this.fileMarkupRevisions;
      if (
        Array.isArray(latestMarkup.markup) &&
        latestMarkup.markup.length > 0
      ) {
        if (reloadMarkup) {
          await this.loadItemsFromMarkup(latestMarkup.markup);
        }
      }
    },
    async countPdfPageMarkup() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/pdf_file_markup_counts/${this.selectedFile.file_id}`
      );
      this.pdfPageFileMarkupCounts = results.map((r) => {
        const { count, file_id: fileId } = r;
        return { fileId, count };
      });
    },
    async countImagePageMarkup() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/image_file_markup_counts/${this.selectedFile.file_id}`
      );
      this.imageFileMarkupCount = results;
    },
    async loadPdfPageMarkup() {
      const [latestMarkup = {}] = this.fileMarkupRevisions;
      if (
        Array.isArray(latestMarkup.markup) &&
        latestMarkup.markup.length > 0
      ) {
        const items = await this.returnItemsFromMarkup(latestMarkup.markup);
        return items;
      }
      return [];
    },
    async saveAndClose() {
      this.savingAndClosing = true;

      await this.saveFileMarkup();
      await this.saveFileMarkupPreview();
      this.$emit("markup-image-dialog-close");
      this.savingAndClosing = false;
    },
    async saveFileMarkup() {
      if (this.selectedFile.s3_file_path_original_image?.endsWith(".pdf")) {
        await this.savePdfFileMarkup();
        await this.countPdfPageMarkup();
      } else {
        await this.saveImageFileMarkup();
        await this.countImagePageMarkup();
      }
      await this.getFileMarkupRevisions();
      this.changesMade = false;
    },
    async saveFileMarkupPreview() {
      const transformerNode = this.$refs.transformer.getNode();
      const oldNodes = transformerNode.nodes();
      transformerNode.nodes([]);
      const layer = this.$refs.layer.getNode();
      for (const a of this.arrowAnchors) {
        a?.remove();
      }

      for (const a of this.lineAnchors) {
        a?.remove();
      }

      for (const a of this.polygonAnchors) {
        a?.remove();
      }
      layer.batchDraw();

      // get image attributes
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      let { x, y, width, height } = this.$refs?.items[nodeIndex]
        ?.getNode()
        ?.image();

      // Check if image is clipped
      if (!isNaN(layer.clipWidth()) && !isNaN(layer.clipHeight())) {
        width = layer.clipWidth();
        height = layer.clipHeight();
        x = layer.clipX();
        y = layer.clipY();
      }

      // reset the stage to properly export image
      const stage = this.$refs.stage.getNode();
      const rotation = Math.round(stage.getAbsoluteRotation());

      //position the image based on the stage rotation
      switch (rotation) {
        case 90: {
          const widthOrig = width;
          width = height;
          height = widthOrig;
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: width, y: 0 });
          break;
        }
        case -270: {
          const widthOrig = width;
          width = height;
          height = widthOrig;
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: width, y: 0 });
          break;
        }
        case -180: {
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: width, y: height });
          break;
        }
        case 180: {
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: width, y: height });
          break;
        }
        case -90: {
          const widthOrig = width;
          width = height;
          height = widthOrig;
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: 0, y: height });
          break;
        }
        case 270: {
          const widthOrig = width;
          width = height;
          height = widthOrig;
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: 0, y: height });
          break;
        }
        default:
          stage.scale({ x: 1, y: 1 });
          stage.position({ x: 0, y: 0 });
      }

      // export image
      const dataURL = layer.toDataURL({
        x,
        y,
        width,
        height,
      });

      await this.$nextTick();
      let fileId;
      if (this.selectedFile?.s3_file_path_original_image?.endsWith(".pdf")) {
        fileId = this.selectedPdfPage.file_id;
      } else {
        fileId = this.selectedFile.file_id;
      }
      const res = await fetch(dataURL);
      const buf = await res.arrayBuffer();
      const previewImgFile = new File([buf], "preview.png", {
        type: "image/png",
      });
      const formData = new FormData();
      formData.append("file", previewImgFile);
      const {
        data: { results: newFileResults },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/file_markup/${fileId}/file_markup_preview`,
        formData
      );
      this.$emit("file-markup-preview-saved", newFileResults);
      transformerNode.nodes(oldNodes);
      for (const a of this.arrowAnchors) {
        layer.add(a);
      }

      for (const a of this.lineAnchors) {
        layer.add(a);
      }

      for (const a of this.polygonAnchors) {
        layer.add(a);
      }
    },
    async savePdfFileMarkup() {
      const { selectedPdfPage, items } = this;
      if (!Array.isArray(items) || items.length === 0) {
        return;
      }
      await axiosWithRegularAuth.post(
        `${APIURL}/file_markup/${selectedPdfPage.file_id}`,
        {
          markup: items,
        }
      );
    },
    async saveImageFileMarkup() {
      const { items } = this;
      if (!Array.isArray(items) || items.length === 0) {
        return;
      }
      await axiosWithRegularAuth.post(
        `${APIURL}/file_markup/${this.selectedFile.file_id}`,
        {
          markup: items,
        }
      );
    },
    async updateLegend() {
      await this.$nextTick();
      if (!Array.isArray(this.items)) {
        return;
      }
      const legendIndex = this.items?.findIndex(
        (it) => it.type === ITEM_TYPES.LEGEND
      );

      const node = this.$refs.items?.[legendIndex]?.getNode();
      if (!node) {
        return;
      }
      const canvas = await html2canvas(this.$refs.legend.$el, {
        backgroundColor: null,
      });
      const dataUrl = canvas.toDataURL();
      const img = new window.Image();
      img.src = dataUrl;
      img.onload = () => {
        const image = img;
        this.items[legendIndex].config = {
          ...this.items[legendIndex].config,
          image,
          width: image.width,
          height: image.height,
        };
        node.image(image);
      };
    },
    async addLegend(itemOptions = {}) {
      await this.$nextTick();
      const canvas = await html2canvas(this.$refs.legend.$el, {
        backgroundColor: null,
      });
      const dataUrl = canvas.toDataURL();
      const img = new window.Image();
      const id = uuidv4();
      const { legendFontSize } = itemOptions;
      img.src = dataUrl;
      img.onload = () => {
        const image = img;
        const imageItem = {
          id,
          src: dataUrl,
          legendFontSize,
          config: {
            image,
            name: id,
            draggable: true,
            width: image.width,
            height: image.height,
            ...itemOptions,
          },
          type: ITEM_TYPES.LEGEND,
        };
        this.displayToolbar = false;
        this.items.push(imageItem);
        const [undoItem] = this.items.slice(-1);
        this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
        this.itemType = "";
        this.changesMade = true;
      };
    },
    async addCustomSymbol(customSymbol) {
      const { symbol, label, markup_symbol_id: markupSymbolId } = customSymbol;
      const transformerNode = this.$refs.transformer.getNode();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const { x, y } = transform.point(stage.getPointerPosition());
      if (symbol.symbolType === SYMBOL_TYPES.IMAGE) {
        const { imageUrl, size, imageDataURL } = symbol;
        const itemOptions = {
          url: imageUrl,
          size,
          x,
          y,
          imageDataURL,
        };
        this.addExternalImage(itemOptions, label, markupSymbolId);
      } else if (symbol.symbolType === SYMBOL_TYPES.SHAPE) {
        if (
          symbol.shape === SHAPE_TYPES.RECTANGLE ||
          symbol.shape === SHAPE_TYPES.ELLIPSE
        ) {
          const { shape, size, shapeFill, shapeOutlineColor } = symbol;
          const itemOptions = {
            shape,
            size,
            shapeFill,
            shapeOutlineColor,
            x,
            y,
          };
          this.addCustomShape(itemOptions, label, markupSymbolId);
        } else if (symbol.shape === SHAPE_TYPES.POLYGON) {
          const {
            shapeFill: fill,
            shapeOutlineColor: shapeOutline,
            size,
            shape,
          } = symbol;
          this.itemOptions = {
            shapeFill: fill,
            strokeWidth: size,
            shapeOutline,
            shape,
            x,
            y,
          };
          this.itemType = ITEM_TYPES.SHAPE;
        } else if (symbol.shape === SHAPE_TYPES.MAP_SYMBOL) {
          const { shapeUrl, size, shape } = symbol;
          const itemOptions = {
            shapeUrl,
            size,
            shape,
            x,
            y,
          };
          this.addCustomShape(itemOptions, label, markupSymbolId);
        }
      } else if (symbol.symbolType === SYMBOL_TYPES.POINT) {
        const { pointFill, pointOutlineColor, pointOutlineWidth, size, shape } =
          symbol;
        const itemOptions = {
          pointFill,
          pointOutlineColor,
          pointOutlineWidth,
          size,
          shape,
          x,
          y,
        };
        this.addPoint(itemOptions, label, markupSymbolId);
      } else if (symbol.symbolType === SYMBOL_TYPES.LINE) {
        const { lineStroke: stroke, lineStrokeWidth: strokeWidth } = symbol;
        const itemOptions = { stroke, strokeWidth };
        this.addLine(itemOptions, {}, label, markupSymbolId);
      }
      this.selectedCustomSymbol = undefined;
      this.displayCustomSymbolToolbar = false;
      this.changesMade = true;
    },
    async getCustomSymbols() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/markup_symbols`);
      this.customSymbols = results.filter(
        (r) => Boolean(r.deactivated) === this.symbolInactiveStatus
      );
    },
    fitToScreen() {
      if (!Array.isArray(this.items) || !Array.isArray(this.$refs.items)) {
        return;
      }
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      const image = this.$refs?.items[nodeIndex]?.getNode()?.image();
      this.zoomToFit(image);
    },
    updateRotation(angleDegrees) {
      const { index } = this.findMarkupImageItemAndNodeIndexes();
      let { rotateAngleDegrees = 0 } = this.items[index];
      rotateAngleDegrees += +angleDegrees;
      this.$set(this.items[index], "rotateAngleDegrees", rotateAngleDegrees);
    },
    rotate(angleDegrees) {
      const stage = this.$refs.stage.getNode();
      const point = { x: stage.getWidth() / 2, y: stage.getHeight() / 2 };
      const angleRadians = (angleDegrees * Math.PI) / 180;
      const x =
        point.x +
        (stage.x() - point.x) * Math.cos(angleRadians) -
        (stage.y() - point.y) * Math.sin(angleRadians);
      const y =
        point.y +
        (stage.x() - point.x) * Math.sin(angleRadians) +
        (stage.y() - point.y) * Math.cos(angleRadians);
      stage.position({ x, y });
      stage.rotation(stage.rotation() + angleDegrees);
    },
    hideToolbar() {
      this.resetStageListeners();
      this.itemType = "";
      this.displayCustomSymbolToolbar = false;
      this.displayToolbar = false;
      this.selectedCustomSymbol = undefined;
    },
    showToolbar(itemType) {
      this.$set(this.configKonva, "draggable", false);
      this.resetStageListeners();
      this.clearSelection();
      this.itemType = itemType;
      this.displayCustomSymbolToolbar = false;
      this.displayToolbar = true;
      this.selectedCustomSymbol = undefined;
    },
    async addItem() {
      const stage = this.$refs.stage.getNode();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const { x, y } = transform.point(stage.getPointerPosition());
      if (this.selectedCustomSymbol) {
        this.addCustomSymbol(this.selectedCustomSymbol);
        return;
      }

      if (this.itemType === ITEM_TYPES.TEXT) {
        const { fill, size, label, description } = this.itemOptions;
        const itemOptions = {
          fill,
          fontSize: size,
          x,
          y,
          label,
          description,
        };
        this.addText(itemOptions);
        this.displayToolbar = false;
      } else if (this.itemType === ITEM_TYPES.SHAPE) {
        const {
          shapeFill: fill,
          shapeOutline: stroke,
          shape,
          label,
          description,
        } = this.itemOptions;
        if (shape === SHAPE_TYPES.POLYGON) {
          const itemOptions = { fill, stroke };
          const details = { label, description };
          this.addPolygon(itemOptions, details, label);
        } else if (
          shape === SHAPE_TYPES.RECTANGLE ||
          shape === SHAPE_TYPES.ELLIPSE
        ) {
          const details = { label, description };
          this.addShape(this.itemOptions, details);
          this.displayToolbar = false;
        } else {
          const {
            shapeFill,
            shapeImageFillColor,
            shapeSymbol,
            shapeOutlineWidth,
            shape,
            shapeUrl,
          } = this.itemOptions;
          const itemOptions = {
            x,
            y,
            shapeFill,
            shapeImageFillColor,
            shapeSymbol,
            shapeOutlineWidth,
            shape,
            shapeUrl,
          };
          const details = { label, description };
          this.addShape(itemOptions, details);
          this.displayToolbar = false;
        }
      } else if (this.itemType === ITEM_TYPES.LINE) {
        const {
          lineStroke: stroke,
          lineStrokeWidth: strokeWidth,
          label,
          description,
        } = this.itemOptions;
        const itemOptions = { stroke, strokeWidth };
        const details = { label, description };
        this.addLine(itemOptions, details);
      } else if (this.itemType === ITEM_TYPES.ARROW) {
        const {
          label,
          description,
          arrowStroke,
          arrowStrokeWidth,
          arrowPointerSize,
        } = this.itemOptions;
        const itemOptions = {
          stroke: arrowStroke,
          strokeWidth: arrowStrokeWidth,
          pointerLength: arrowPointerSize,
        };
        const details = { label, description };
        this.addArrow(itemOptions, details);
        this.displayToolbar = false;
      } else if (this.itemType === ITEM_TYPES.LEGEND) {
        const { legendFontSize } = this.itemOptions;
        const itemOptions = {
          x,
          y,
          legendFontSize,
        };
        this.addLegend(itemOptions);
      }
      this.itemType = "";
      this.showSymbolPanel = false;
    },
    onArrowDrag(index) {
      const layer = this.$refs.layer.getNode();
      const [anchor1, anchor2] = this.arrowAnchors;
      const arrowItem = this.$refs.items[index].getNode();
      anchor1.x(arrowItem.points()[0] + arrowItem.x());
      anchor1.y(arrowItem.points()[1] + arrowItem.y());
      anchor2.x(arrowItem.points()[2] + arrowItem.x());
      anchor2.y(arrowItem.points()[3] + arrowItem.y());
      layer.batchDraw();
    },
    onLineDrag(index) {
      const layer = this.$refs.layer.getNode();
      const lineItem = this.$refs.items[index].getNode();
      const points = chunk(lineItem.points(), 2);
      for (const [index, a] of this.lineAnchors.entries()) {
        const [pointX, pointY] = points[index];
        a.x(pointX + lineItem.x());
        a.y(pointY + lineItem.y());
      }
      layer.batchDraw();
    },
    onPolygonDrag(index) {
      const layer = this.$refs.layer.getNode();
      const polygonItem = this.$refs.items[index].getNode();
      const points = chunk(polygonItem.points(), 2);
      for (const [index, a] of this.polygonAnchors.entries()) {
        const [pointX, pointY] = points[index];
        a.x(pointX + polygonItem.x());
        a.y(pointY + polygonItem.y());
      }
      layer.batchDraw();
    },
    async onDragEnd(index, $event) {
      await this.$nextTick();
      const x = $event.target.x();
      const y = $event.target.y();
      if (!isNaN(x) && !isNaN(y)) {
        this.items[index].config.x = x;
        this.items[index].config.y = y;
      }
      this.changesMade = true;
    },
    addUndoItem(type, item) {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        if (!Array.isArray(this.undoItems[this.selectedPdfPage.file_id])) {
          this.undoItems[this.selectedPdfPage.file_id] = [];
        }
        this.undoItems[this.selectedPdfPage.file_id].push({
          type,
          item,
        });
      } else {
        if (!Array.isArray(this.undoItems[this.selectedFile.file_id])) {
          this.undoItems[this.selectedFile.file_id] = [];
        }
        this.undoItems[this.selectedFile.file_id].push({
          type,
          item,
        });
      }
    },
    async undo() {
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.arrowAnchors = [];
      this.lineAnchors = [];
      this.polygonAnchors = [];
      const transformerNode = this.$refs.transformer.getNode();

      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        if (!Array.isArray(this.undoItems[this.selectedPdfPage.file_id])) {
          return;
        }
        const undoEntry = this.undoItems[this.selectedPdfPage.file_id].pop();
        if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_ADDED) {
          if (this.items.length < 2) {
            return;
          }

          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!this.redoItems[this.selectedPdfPage.file_id]) {
            this.redoItems[this.selectedPdfPage.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_ADDED,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedPdfPage.file_id].push(redoEntry);
          this.items.splice(itemIndex, 1);
        }
        if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_REMOVED) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!this.redoItems[this.selectedPdfPage.file_id]) {
            this.redoItems[this.selectedPdfPage.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_REMOVED,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedPdfPage.file_id].push(redoEntry);
          this.items.push(undoEntry.item);
        } else if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_CHANGE) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!this.redoItems[this.selectedPdfPage.file_id]) {
            this.redoItems[this.selectedPdfPage.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_CHANGE,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedPdfPage.file_id].push(redoEntry);
          this.items[itemIndex] = {
            ...undoEntry.item,
          };
          const node = this.$refs.items[itemIndex]?.getNode();
          node.setAttrs(undoEntry.item.config);
        } else if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_CROPPED) {
          if (!this.redoItems[this.selectedPdfPage.file_id]) {
            this.redoItems[this.selectedPdfPage.file_id] = [];
          }

          this.redoItems[this.selectedPdfPage.file_id].push(redoEntry);
          const { index } = this.findMarkupImageItemAndNodeIndexes();
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_CROPPED,
            item: { ...this.items[index].layerConfig },
          };
          this.items[index] = {
            ...this.items[index],
            ...undoEntry.item,
          };
          const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
          const imageNode = this.$refs.items[nodeIndex].getNode();
          const layer = this.$refs.layer.getNode();
          layer.clipX(imageNode.x());
          layer.clipY(imageNode.y());
          layer.clipWidth(imageNode.width());
          layer.clipHeight(imageNode.height());
        }
      } else {
        if (!Array.isArray(this.undoItems[this.selectedFile.file_id])) {
          return;
        }
        const undoEntry = this.undoItems[this.selectedFile.file_id].pop();
        if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_ADDED) {
          if (this.items.length < 2) {
            return;
          }
          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!Array.isArray(this.redoItems[this.selectedFile.file_id])) {
            this.redoItems[this.selectedFile.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_ADDED,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedFile.file_id].push(redoEntry);
          this.items.splice(itemIndex, 1);
        } else if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_REMOVED) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!Array.isArray(this.redoItems[this.selectedFile.file_id])) {
            this.redoItems[this.selectedFile.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_REMOVED,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedFile.file_id].push(redoEntry);
          this.items.push(undoEntry.item);
        } else if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_CHANGE) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === undoEntry.item.id
          );
          if (!Array.isArray(this.redoItems[this.selectedFile.file_id])) {
            this.redoItems[this.selectedFile.file_id] = [];
          }
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_CHANGE,
            item: this.items[itemIndex],
          };
          this.redoItems[this.selectedFile.file_id].push(redoEntry);
          this.items[itemIndex] = {
            ...undoEntry.item,
          };
          const node = this.$refs.items[itemIndex]?.getNode();
          node.setAttrs(undoEntry.item.config);
        } else if (undoEntry?.type === UNDO_ITEM_TYPES.ITEM_CROPPED) {
          if (!Array.isArray(this.redoItems[this.selectedFile.file_id])) {
            this.redoItems[this.selectedFile.file_id] = [];
          }
          const { index, nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
          const redoEntry = {
            type: UNDO_ITEM_TYPES.ITEM_CROPPED,
            item: { ...this.items[index].layerConfig },
          };
          this.redoItems[this.selectedFile.file_id].push(redoEntry);
          this.items[index] = {
            ...this.items[index],
            ...undoEntry.item,
          };
          const imageNode = this.$refs.items[nodeIndex].getNode();
          const layer = this.$refs.layer.getNode();
          layer.clipX(imageNode.x());
          layer.clipY(imageNode.y());
          layer.clipWidth(imageNode.width());
          layer.clipHeight(imageNode.height());
        }
      }
      transformerNode.nodes([]);
      this.changesMade = true;
    },
    async redo() {
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.arrowAnchors = [];
      this.lineAnchors = [];
      this.polygonAnchors = [];
      const transformerNode = this.$refs.transformer.getNode();

      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        if (!Array.isArray(this.redoItems[this.selectedPdfPage.file_id])) {
          return;
        }
        const redoEntry = this.redoItems[this.selectedPdfPage.file_id].pop();
        if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_ADDED) {
          this.items.push(redoEntry.item);
        }
        if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_REMOVED) {
          if (this.items.length < 2) {
            return;
          }
          const itemIndex = this.items.findIndex(
            (it) => it.id === redoEntry.item.id
          );
          this.items.splice(itemIndex, 1);
        } else if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_CHANGE) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === redoEntry.item.id
          );
          this.items[itemIndex] = { ...redoEntry.item };
          const node = this.$refs.items[itemIndex]?.getNode();
          node.setAttrs(redoEntry.item.config);
        } else if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_CROPPED) {
          const { index } = this.findMarkupImageItemAndNodeIndexes();
          const { x, y, width, height } = redoEntry.item.clip;
          this.$set(this.items[index], "layerConfig", {
            clip: { x, y, width, height },
            draggable: false,
          });
          const layer = this.$refs.layer.getNode();
          layer.clipX(x);
          layer.clipY(y);
          layer.clipWidth(width);
          layer.clipHeight(height);
        }
      } else {
        if (!Array.isArray(this.redoItems[this.selectedFile.file_id])) {
          return;
        }
        const redoEntry = this.redoItems[this.selectedFile.file_id].pop();
        if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_ADDED) {
          this.items.push(redoEntry.item);
        }
        if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_REMOVED) {
          if (this.items.length < 2) {
            return;
          }
          const itemIndex = this.items.findIndex(
            (it) => it.id === redoEntry.item.id
          );
          this.items.splice(itemIndex, 1);
        } else if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_CHANGE) {
          const itemIndex = this.items.findIndex(
            (it) => it.id === redoEntry.item.id
          );
          this.items[itemIndex] = { ...redoEntry.item };
          const node = this.$refs.items[itemIndex]?.getNode();
          node.setAttrs(redoEntry.item.config);
        } else if (redoEntry?.type === UNDO_ITEM_TYPES.ITEM_CROPPED) {
          const { index } = this.findMarkupImageItemAndNodeIndexes();
          const { x, y, width, height } = redoEntry.item.clip;
          this.$set(this.items[index], "layerConfig", {
            clip: { x, y, width, height },
            draggable: false,
          });
          const layer = this.$refs.layer.getNode();
          layer.clipX(x);
          layer.clipY(y);
          layer.clipWidth(width);
          layer.clipHeight(height);
        }
      }
      transformerNode.nodes([]);
      this.changesMade = true;
    },
    clearSelection() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.nodes([]);
      this.selectedShapeName = "";
    },
    async deleteItem() {
      const transformerNode = this.$refs.transformer.getNode();
      const selectedNodeIndex = this.items.findIndex(
        (item) => item.id === this.selectedNode?.attrs?.name
      );
      const { index } = this.findMarkupImageItemAndNodeIndexes();
      if (selectedNodeIndex < 0 || selectedNodeIndex === index) {
        return;
      }
      this.addUndoItem(
        UNDO_ITEM_TYPES.ITEM_REMOVED,
        this.items[selectedNodeIndex]
      );
      this.items.splice(selectedNodeIndex, 1);
      transformerNode.nodes([]);
      this.selectedShapeName = "";
      this.$set(this.configKonva, "draggable", true);
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.arrowAnchors = [];
      this.lineAnchors = [];
      this.polygonAnchors = [];
      this.showGraphicDetailsPanel = false;
      this.changesMade = true;
      this.displayToolbar = false;
      this.displayEditItemToolbar = false;
    },
    addExternalImage(itemOptions = {}, label, markupSymbolId) {
      const { url, size, imageDataUrl, x, y } = itemOptions;
      const img = new window.Image();
      const id = uuidv4();
      img.src = url;
      img.crossOrigin = "Anonymous";
      img.width = size;
      img.height = size;
      img.onload = () => {
        const image = img;
        const imageItem = {
          id,
          src: url,
          imageDataUrl,
          width: size,
          height: size,
          label,
          markupSymbolId,
          config: {
            image,
            name: id,
            draggable: true,
            width: size,
            height: size,
            x,
            y,
          },
          type: ITEM_TYPES.IMAGE,
        };
        this.items.push(imageItem);
      };
    },
    addImage(itemOptions = {}, details = {}, label) {
      const {
        shapeUrl,
        shapeFill,
        shapeImageFillColor,
        shapeSymbol,
        shapeOutlineWidth,
      } = this.itemOptions;
      const img = new window.Image();
      const id = uuidv4();
      img.src = require(`@/assets${shapeUrl}`);
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const image = img;
        const imageItem = {
          id,
          src: require(`@/assets${shapeUrl}`),
          shapeFill,
          shapeImageFillColor,
          shapeSymbol,
          shapeOutlineWidth,
          label,
          config: {
            image,
            name: id,
            draggable: true,
            offsetX: image.width / 2,
            offsetY: image.height / 2,
            ...itemOptions,
          },
          type: ITEM_TYPES.SHAPE,
          shape: SHAPE_TYPES.MAP_SYMBOL,
          details,
        };
        this.items.push(imageItem);
      };
    },
    resetStageListeners() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      stage.off("mousedown touchstart");
      stage.off("mousemove touchmove");
      stage.off("mouseup touchend");
      stage.off("dblclick");
      stage.on("mousedown touchstart", this.handleStageMouseDown);
      stage.on("dblclick", this.handleStageDoubleClick);
      this.$set(this.configKonva, "draggable", true);
      this.addPinchZoomAndPan();
    },
    async addPolygon(itemOptions = {}, details = {}, label) {
      const { shapeFill, shapeOutline, shapeOutlineWidth } = this.itemOptions;
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const mode = "brush";
      const id = uuidv4();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const pos = transform.point(stage.getPointerPosition());

      const item = {
        type: ITEM_TYPES.SHAPE,
        shape: SHAPE_TYPES.POLYGON,
        shapeFill,
        shapeOutline,
        shapeOutlineWidth,
        config: {
          stroke: "#df4b26",
          strokeWidth: 5,
          globalCompositeOperation:
            mode === "brush" ? "source-over" : "destination-out",
          points: [pos.x, pos.y],
          name: id,
          closed: false,
          tension: 0,
          ...itemOptions,
        },
        id,
        details,
        label,
      };
      this.isPaintPolygon = true;
      this.items.push(item);
      const [undoItem] = this.items.slice(-1);
      this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
      await this.$nextTick();
      this.changesMade = true;

      stage.on("mousedown touchstart", () => {
        const item = this.$refs.items.find((it) => it.getNode().name() === id);
        const itemIndex = this.items.length - 1;
        const node = item?.getNode();
        if (!node) {
          return;
        }

        const transform = this.$refs.stage
          .getNode()
          .getAbsoluteTransform()
          .copy();
        transform.invert();
        const pos = transform.point(stage.getPointerPosition());
        if (typeof node?.points === "function") {
          const newPoints = [...new Set([...node.points(), pos.x, pos.y])];
          node?.points(newPoints);
          this.items[itemIndex].config.points = [
            ...new Set([...this.items[itemIndex].config.points, pos.x, pos.y]),
          ];
          const [firstX, firstY] = this.items[itemIndex].config.points;
          const [lastX, lastY] = this.items[itemIndex].config.points.slice(-2);
          if (Math.abs(firstX - lastX) < 20 && Math.abs(firstY - lastY) < 20) {
            const newPoints = this.items[itemIndex].config.points.slice(0, -2);
            this.items[itemIndex].config.points = newPoints;
            this.finishPolygon();
          } else {
            node?.closed(false);
          }
        }
      });

      stage.on("dblclick dbltap", () => {
        this.finishPolygon();
      });
    },
    addFreeDrawing(itemOptions = {}) {
      const { stroke, strokeWidth } = itemOptions;
      this.isPaint = false;
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const mode = "brush";
      this.$set(this.configKonva, "draggable", true);

      stage.on("mousedown touchstart", () => {
        if (!this.isPaint) {
          this.isPaint = true;
          this.displayToolbar = false;
        }
        const id = uuidv4();
        const transform = this.$refs.stage
          .getNode()
          .getAbsoluteTransform()
          .copy();
        transform.invert();
        const pos = transform.point(stage.getPointerPosition());
        const item = {
          type: ITEM_TYPES.SKETCH,
          sketchStroke: stroke,
          sketchStrokeWidth: strokeWidth,
          config: {
            stroke: "#df4b26",
            strokeWidth: 5,
            globalCompositeOperation:
              mode === "brush" ? "source-over" : "destination-out",
            points: [pos.x, pos.y],
            name: id,
            tension: 1,
            ...itemOptions,
          },
          id,
        };
        this.items.push(item);
      });

      stage.on("mouseup touchend", () => {
        this.isPaint = false;
        this.changesMade = true;
        transformerNode.nodes([]);
        this.resetStageListeners();
      });

      stage.on("mousemove touchmove", () => {
        if (!this.isPaint) {
          return;
        }
        const transform = this.$refs.stage
          .getNode()
          .getAbsoluteTransform()
          .copy();
        transform.invert();
        const pos = transform.point(stage.getPointerPosition());
        if (
          typeof this.$refs.items.slice(-1)[0]?.getNode()?.points === "function"
        ) {
          const newPoints = this.$refs.items
            .slice(-1)[0]
            ?.getNode()
            ?.points()
            ?.concat([pos.x, pos.y]);
          this.$refs.items.slice(-1)?.[0]?.getNode()?.points(newPoints);
          this.items[this.items.length - 1].config.points = [
            ...this.items[this.items.length - 1].config.points,
            pos.x,
            pos.y,
          ];
        }
      });
    },
    handleStageDoubleClick(e) {
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.showGraphicDetailsPanel = false;
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const item = this.items.find((item) => item.id === name);
      if (item) {
        if (this.selectedFile.s3_file_path_original_image?.endsWith("pdf")) {
          if (item.src === this.selectedPdfPage?.s3_file_path_original_image) {
            this.$set(this.configKonva, "draggable", true);
            return;
          }
        } else {
          if (item.src === this.selectedFile.s3_file_path_original_image) {
            this.$set(this.configKonva, "draggable", true);
            return;
          }
        }
        this.selectedShapeName = name;
        if (item.type !== ITEM_TYPES.TEXT) {
          this.showGraphicDetailsPanel = true;
        }
      } else {
        this.selectedShapeName = "";
        this.showGraphicDetailsPanel = false;
      }
    },
    handleStageMouseDown(e) {
      if (this.displayToolbar) {
        return;
      }

      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      const imageNode = this.$refs?.items?.[nodeIndex]?.getNode();
      if (e.target === e.target.getStage() || e.target === imageNode) {
        for (const a of this.arrowAnchors) {
          a?.destroy();
        }

        for (const a of this.lineAnchors) {
          a?.destroy();
        }

        for (const a of this.polygonAnchors) {
          a?.destroy();
        }
        this.selectedShapeName = "";
        this.$set(this.configKonva, "draggable", true);
        this.displayEditItemToolbar = false;
        this.updateTransformer();
        this.arrowAnchors = [];
        this.lineAnchors = [];
        this.polygonAnchors = [];
        this.selectedNode = undefined;
        this.showGraphicDetailsPanel = false;
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const item = this.items.find((item) => item.id === name);
      const itemIndex = this.items.findIndex((item) => item.id === name);
      if (item) {
        if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
          if (item.src === this.selectedPdfPage?.s3_file_path_original_image) {
            this.$set(this.configKonva, "draggable", true);
            return;
          }
        } else {
          if (item.src === this.selectedFile.s3_file_path_original_image) {
            this.$set(this.configKonva, "draggable", true);
            return;
          }
        }
        this.selectedShapeName = name;
        this.$set(this.configKonva, "draggable", false);
        this.items[itemIndex].config = {
          ...this.items[itemIndex].config,
          draggable: true,
        };
      } else {
        this.selectedShapeName = "";
        this.$set(this.configKonva, "draggable", true);
      }

      if (this.isCropping) {
        const cropperRect = this.$refs.cropperRect.getNode();
        if (e.target === cropperRect) {
          const transformerNode = this.$refs.cropperRectTransformer.getNode();
          transformerNode.nodes([cropperRect]);
          return;
        }
      }

      if (item?.type === ITEM_TYPES.ARROW) {
        this.addArrowAnchors();
      } else if (item?.type === ITEM_TYPES.LINE) {
        this.addLineAnchors();
      } else if (
        item?.type === ITEM_TYPES.SHAPE &&
        item?.shape === SHAPE_TYPES.POLYGON
      ) {
        this.addPolygonAnchors();
      } else {
        this.updateTransformer();
      }
    },
    addLineAnchors() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.nodes([]);
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.lineAnchors = [];
      const itemIndex = this.items.findIndex(
        (it) => it.id === this.selectedShapeName
      );
      const lineItem = this.$refs.items[itemIndex].getNode();
      this.selectedNode = lineItem;
      lineItem.draggable(true);
      this.items[itemIndex].config.draggable = true;
      const layer = this.$refs.layer.getNode();
      const points = lineItem.points();
      const chunkedPoints = chunk(points, 2);
      const { anchorScale } = this;
      this.lineAnchors = chunkedPoints.map(([x, y]) => {
        return new Konva.Rect({
          ...anchorOptions,
          x: x + lineItem.x(),
          y: y + lineItem.y(),
          offsetX: 5,
          offsetY: 5,
          scaleX: anchorScale,
          scaleY: anchorScale,
        });
      });
      for (const a of this.lineAnchors) {
        layer.add(a);
      }

      const updateLine = async () => {
        this.items[itemIndex].config.points = points;
        const lineItem = this.$refs.items[itemIndex].getNode();
        const points = this.lineAnchors
          .map((a) => {
            return [a.x() - lineItem.x(), a.y() - lineItem.y()];
          })
          .flat();
        lineItem.points(points);
        this.items[itemIndex].config.points = points;
        layer.batchDraw();
        this.changesMade = true;
      };

      for (const a of this.lineAnchors) {
        a.on("dragmove", updateLine);
      }
    },
    addPolygonAnchors() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.nodes([]);
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.polygonAnchors = [];
      const itemIndex = this.items.findIndex(
        (it) => it.id === this.selectedShapeName
      );
      const lineItem = this.$refs.items[itemIndex].getNode();
      this.selectedNode = lineItem;
      lineItem.draggable(true);
      this.items[itemIndex].config.draggable = true;
      const layer = this.$refs.layer.getNode();
      const points = lineItem.points();
      const chunkedPoints = chunk(points, 2);
      const { anchorScale } = this;
      this.polygonAnchors = chunkedPoints.map(([x, y]) => {
        return new Konva.Rect({
          ...anchorOptions,
          x: x + lineItem.x(),
          y: y + lineItem.y(),
          offsetX: 5,
          offsetY: 5,
          scaleX: anchorScale,
          scaleY: anchorScale,
        });
      });
      for (const a of this.polygonAnchors) {
        layer.add(a);
      }

      const updatePolygon = async () => {
        this.items[itemIndex].config.points = points;
        const lineItem = this.$refs.items[itemIndex].getNode();
        const points = this.polygonAnchors
          .map((a) => {
            return [a.x() - lineItem.x(), a.y() - lineItem.y()];
          })
          .flat();
        lineItem.points(points);
        this.items[itemIndex].config.points = points;
        layer.batchDraw();
      };

      for (const a of this.polygonAnchors) {
        a.on("dragmove", updatePolygon);
      }
    },
    addArrowAnchors() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.nodes([]);
      for (const a of this.arrowAnchors) {
        a?.destroy();
      }

      for (const a of this.lineAnchors) {
        a?.destroy();
      }

      for (const a of this.polygonAnchors) {
        a?.destroy();
      }
      this.arrowAnchors = [];
      const itemIndex = this.items.findIndex(
        (it) => it.id === this.selectedShapeName
      );
      const arrowItem = this.$refs.items[itemIndex].getNode();
      this.selectedNode = arrowItem;
      arrowItem.draggable(true);
      this.items[itemIndex].config.draggable = true;
      const layer = this.$refs.layer.getNode();
      const { anchorScale } = this;
      const anchor1 = new Konva.Rect({
        ...anchorOptions,
        x: arrowItem.points()[0] + arrowItem.x(),
        y: arrowItem.points()[1] + arrowItem.y(),
        offsetX: 5,
        offsetY: 5,
        scaleX: anchorScale,
        scaleY: anchorScale,
      });
      layer.add(anchor1);

      const anchor2 = new Konva.Rect({
        ...anchorOptions,
        x: arrowItem.points()[2] + arrowItem.x(),
        y: arrowItem.points()[3] + arrowItem.y(),
        offsetX: 5,
        offsetY: 5,
        scaleX: anchorScale,
        scaleY: anchorScale,
      });
      this.arrowAnchors = [anchor1, anchor2];
      layer.add(anchor2);

      const updateLine = async () => {
        this.items[itemIndex].config.points = points;
        const arrowItem = this.$refs.items[itemIndex].getNode();
        const points = [
          anchor1.x() - arrowItem.x(),
          anchor1.y() - arrowItem.y(),
          anchor2.x() - arrowItem.x(),
          anchor2.y() - arrowItem.y(),
        ];
        arrowItem.points(points);
        this.items[itemIndex].config.points = points;
        layer.batchDraw();
        this.changesMade = true;
      };

      anchor1.on("dragmove", updateLine);
      anchor2.on("dragmove", updateLine);
    },
    updateTransformer() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;
      const selectedNode = stage?.findOne("." + selectedShapeName);
      this.selectedNode = selectedNode;
      if (selectedNode === transformerNode.node()) {
        return;
      }

      const item = this.items.find((item) => item.id === selectedShapeName);
      if (selectedNode && !item?.label) {
        for (const a of this.arrowAnchors) {
          a?.destroy();
        }

        for (const a of this.lineAnchors) {
          a?.destroy();
        }

        for (const a of this.polygonAnchors) {
          a?.destroy();
        }
        transformerNode.nodes([selectedNode]);
      } else {
        transformerNode.nodes([]);
      }

      const itemIndex = this.items.findIndex(
        (item) => item.id === selectedShapeName
      );
      if (item?.type !== ITEM_TYPES.TEXT) {
        return;
      }

      const textNode = selectedNode;
      textNode.on("transform", () => {
        textNode.setAttrs({
          width: textNode.width(),
          height: textNode.height(),
          scaleX: textNode.scaleX(),
          scaleY: textNode.scaleY(),
        });
      });

      textNode.on("transformend", () => {
        this.addUndoItem(
          UNDO_ITEM_TYPES.ITEM_CHANGE,
          cloneDeep(this.items[itemIndex])
        );
        textNode.setAttrs({
          width: textNode.width(),
          height: textNode.height(),
          scaleX: textNode.scaleX(),
          scaleY: textNode.scaleY(),
        });
        this.items[itemIndex].config = {
          ...this.items[itemIndex].config,
          width: textNode.width(),
          height: textNode.height(),
          scaleX: textNode.scaleX(),
          scaleY: textNode.scaleY(),
        };
      });

      textNode.on("dblclick dbltap", () => {
        this.editingText = true;
      });
    },
    addPoint(itemOptions = {}, label, markupSymbolId) {
      const {
        pointFill: fill,
        pointOutlineColor,
        pointOutlineWidth,
        size,
        x = 10,
        y = 10,
      } = itemOptions;
      const id = uuidv4();
      const item = {
        id,
        type: ITEM_TYPES.POINT,
        pointOutlineColor,
        pointOutlineWidth,
        pointFill: fill,
        size,
        label,
        markupSymbolId,
        config: {
          x,
          y,
          draggable: true,
          name: id,
          stroke: pointOutlineColor,
          strokeWidth: pointOutlineWidth,
          fill,
          width: size,
          height: size,
          strokeScaleEnabled: false,
        },
      };
      this.items.push(item);
    },
    addCustomShape(itemOptions = {}, label, markupSymbolId) {
      const id = uuidv4();
      const transformerNode = this.$refs.transformer.getNode();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const { x, y } = transform.point(stage.getPointerPosition());
      const {
        shape,
        shapeFill: fill,
        shapeOutlineColor: stroke,
        size,
      } = itemOptions;
      if (shape === SHAPE_TYPES.RECTANGLE || shape === SHAPE_TYPES.ELLIPSE) {
        const item = {
          id,
          type: ITEM_TYPES.SHAPE,
          shape,
          shapeFill: fill,
          shapeOutlineColor: stroke,
          size,
          label,
          markupSymbolId,
          config: {
            x,
            y,
            draggable: true,
            name: id,
            fill,
            stroke,
            width: size,
            height: size,
          },
        };
        this.items.push(item);
        this.itemType = "";
      } else if (shape === SHAPE_TYPES.MAP_SYMBOL) {
        const img = new window.Image();
        const { shapeUrl, size } = itemOptions;
        img.src = require(`@/assets${shapeUrl}`);
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const image = img;
          const imageItem = {
            id,
            label,
            markupSymbolId,
            src: require(`@/assets${shapeUrl}`),
            config: {
              image,
              name: id,
              x,
              y,
              offsetX: size / 2,
              offsetY: size / 2,
              width: size,
              height: size,
            },
            type: ITEM_TYPES.SHAPE,
            shape: SHAPE_TYPES.MAP_SYMBOL,
          };
          this.items.push(imageItem);
          this.itemType = "";
        };
      }
    },
    async addShape(itemOptions = {}, details = {}) {
      const id = uuidv4();
      const transformerNode = this.$refs.transformer.getNode();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const { x, y } = transform.point(stage.getPointerPosition());
      const {
        shape,
        shapeFill: fill,
        shapeOutline: stroke,
        shapeOutlineWidth: strokeWidth,
      } = itemOptions;
      if (
        shape === SHAPE_TYPES.RECTANGLE ||
        shape === SHAPE_TYPES.POLYGON ||
        shape === SHAPE_TYPES.ELLIPSE
      ) {
        const item = {
          id,
          type: ITEM_TYPES.SHAPE,
          shape,
          shapeFill: fill,
          shapeOutline: stroke,
          shapeOutlineWidth: strokeWidth,
          config: {
            x,
            y,
            draggable: true,
            name: id,
            fill,
            stroke,
            strokeWidth,
            width: 50,
            height: 50,
            strokeScaleEnabled: false,
          },
          details,
        };
        this.items.push(item);
        const [undoItem] = this.items.slice(-1);
        this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
        this.changesMade = true;

        this.itemType = "";
        await this.$nextTick();
        const [shapeItem] = this.items.slice(-1);
        const selectedNode = stage.findOne("." + shapeItem.id);
        transformerNode.nodes([selectedNode]);
        this.resetStageListeners();
      } else if (shape === SHAPE_TYPES.MAP_SYMBOL) {
        const img = new window.Image();
        const {
          shapeUrl,
          shapeFill: fill,
          shapeOutline: stroke,
          shapeOutlineWidth: strokeWidth,
        } = itemOptions;
        img.src = require(`@/assets${shapeUrl}`);
        img.crossOrigin = "Anonymous";
        img.onload = async () => {
          const image = img;
          const imageItem = {
            id,
            src: require(`@/assets${shapeUrl}`),
            config: {
              image,
              name: id,
              width: image.width / 2,
              height: image.height / 2,
              ...itemOptions,
            },
            type: ITEM_TYPES.SHAPE,
            shape: SHAPE_TYPES.MAP_SYMBOL,
            shapeFill: fill,
            shapeOutline: stroke,
            shapeOutlineWidth: strokeWidth,
            details,
            strokeScaleEnabled: false,
          };
          this.items.push(imageItem);
          const [mapSymbolItem] = this.items.slice(-1);
          this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, mapSymbolItem);
          this.itemType = "";
          await this.$nextTick();
          const selectedNode = stage.findOne("." + mapSymbolItem.id);
          transformerNode.nodes([selectedNode]);
          this.resetStageListeners();
          this.changesMade = true;
        };
      }
    },
    async addText(itemOptions = {}, details = {}) {
      const { fill, fontSize } = itemOptions;
      this.isPaint = false;
      const id = uuidv4();
      const transformerNode = this.$refs.transformer.getNode();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const { x, y } = transform.point(stage.getPointerPosition());
      const item = {
        id,
        type: ITEM_TYPES.TEXT,
        fill,
        size: fontSize,
        config: {
          x,
          y,
          fontFamily: "Roboto",
          fontSize: 24,
          text: "new text",
          fill: "black",
          draggable: true,
          name: id,
          ...itemOptions,
        },
        details,
      };
      this.items.push(item);
      const [undoItem] = this.items.slice(-1);
      this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
      this.changesMade = true;
      this.itemType = "";
    },
    async addArrow(itemOptions = {}, details = {}) {
      const { stroke, strokeWidth, pointerLength } = itemOptions;
      const transformerNode = this.$refs.transformer.getNode();
      const id = uuidv4();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const pos = transform.point(stage.getPointerPosition());
      const item = {
        id,
        type: ITEM_TYPES.ARROW,
        arrowStroke: stroke,
        arrowStrokeWidth: strokeWidth,
        arrowPointerSize: pointerLength,
        config: {
          tension: 0,
          stroke: "black",
          strokeWidth,
          points: [pos.x, pos.y],
          name: id,
          draggable: true,
          ...itemOptions,
        },
        details,
      };

      stage.on("mousedown touchstart", async () => {
        this.items.push(item);
        await this.$nextTick();
        const [arrowItem] = this.$refs.items.slice(-1);
        const node = arrowItem?.getNode();
        const transform = this.$refs.stage
          .getNode()
          .getAbsoluteTransform()
          .copy();
        transform.invert();
        const pos = transform.point(stage.getPointerPosition());
        if (typeof node?.points === "function") {
          const newPoints = node?.points()?.concat([pos.x, pos.y]);
          node?.points(newPoints);
          this.items[this.items.length - 1].config.points = [
            ...this.items[this.items.length - 1].config.points,
            pos.x,
            pos.y,
          ];
        }
        const [undoItem] = this.items.slice(-1);
        this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
        this.itemType = "";
        this.changesMade = true;
        this.$set(this.configKonva, "draggable", true);
        this.resetStageListeners();
      });
    },
    async addLine(itemOptions = {}, details = {}, label, markupSymbolId) {
      this.drawingLine = true;
      const { stroke, strokeWidth } = itemOptions;
      const transformerNode = this.$refs.transformer.getNode();
      const id = uuidv4();
      const transform = this.$refs.stage
        .getNode()
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const stage = transformerNode.getStage();
      const pos = transform.point(stage.getPointerPosition());
      const item = {
        id,
        type: ITEM_TYPES.LINE,
        lineStroke: stroke,
        lineStrokeWidth: strokeWidth,
        label,
        markupSymbolId,
        config: {
          tension: 0,
          stroke: "black",
          strokeWidth,
          points: [pos.x, pos.y],
          name: id,
          ...itemOptions,
        },
        details,
      };
      this.items.push(item);
      const [undoItem] = this.items.slice(-1);
      this.addUndoItem(UNDO_ITEM_TYPES.ITEM_ADDED, undoItem);
      this.itemType = "";
      this.$set(this.configKonva, "draggable", false);
      await this.$nextTick();
      stage.off("dblclick dbltap");

      stage.on("mousedown touchstart", () => {
        const [item] = this.$refs.items.slice(-1);
        const node = item?.getNode();
        const transform = this.$refs.stage
          .getNode()
          .getAbsoluteTransform()
          .copy();
        transform.invert();
        const pos = transform.point(stage.getPointerPosition());
        if (typeof node?.points === "function") {
          const newPoints = node?.points()?.concat([pos.x, pos.y]);
          node?.points(newPoints);
          this.items[this.items.length - 1].config.points = [
            ...this.items[this.items.length - 1].config.points,
            pos.x,
            pos.y,
          ];
          this.changesMade = true;
        }
      });

      stage.on("dblclick dbltap", () => {
        this.finishLine();
      });

      this.changesMade = true;
    },
    addPinchZoomAndPan() {
      Konva.hitOnDragEnabled = true;
      const stage = this.$refs.stage.getNode();
      let lastCenter = null;
      let lastDist = 0;
      stage.on("touchmove", (e) => {
        e.evt.preventDefault();
        const [touch1, touch2] = e.evt.touches;
        if (touch1 && touch2) {
          if (stage.isDragging()) {
            stage.stopDrag();
          }

          const p1 = {
            x: touch1.clientX,
            y: touch1.clientY,
          };
          const p2 = {
            x: touch2.clientX,
            y: touch2.clientY,
          };

          if (!lastCenter) {
            lastCenter = getCenter(p1, p2);
            return;
          }
          const newCenter = getCenter(p1, p2);
          const dist = getDistance(p1, p2);

          if (!lastDist) {
            lastDist = dist;
          }
          const pointTo = {
            x: (newCenter.x - stage.x()) / stage.scaleX(),
            y: (newCenter.y - stage.y()) / stage.scaleX(),
          };

          const scale = stage.scaleX() * (dist / lastDist);

          stage.scaleX(scale);
          stage.scaleY(scale);
          const dx = newCenter.x - lastCenter.x;
          const dy = newCenter.y - lastCenter.y;
          const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy,
          };
          stage.position(newPos);

          lastDist = dist;
          lastCenter = newCenter;
        }
      });

      stage.on("touchend", () => {
        lastDist = 0;
        lastCenter = null;
      });
    },
    addZoom() {
      const scaleBy = 1.05;
      const stage = this.$refs.stage.getNode();
      const layer = this.$refs.layer.getNode();
      stage.on("wheel", (e) => {
        e.evt.preventDefault();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();
        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };
        const newScale =
          e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        stage.scale({ x: newScale, y: newScale });
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
        this.anchorScale = 1 / newScale;
        const { anchorScale } = this;
        for (const a of this.polygonAnchors) {
          a.scale({ x: anchorScale, y: anchorScale });
        }
        for (const a of this.arrowAnchors) {
          a.scale({ x: anchorScale, y: anchorScale });
        }
        for (const a of this.lineAnchors) {
          a.scale({ x: anchorScale, y: anchorScale });
        }
        layer.batchDraw();
      });
    },
    onResize() {
      const stage = this.$refs.stage?.getNode();
      if (
        !stage ||
        !this.$refs.markupToolbar ||
        !this.$refs.markupItemToolbar ||
        !this.$refs.card
      ) {
        return;
      }
      stage.scale({ x: 1, y: 1 });
      stage.width(this.$refs.card.$el.offsetWidth);
      stage.height(
        this.$refs.card.$el.offsetHeight -
          this.$refs.markupToolbar.$el.offsetHeight -
          this.$refs.markupItemToolbar.$el.offsetHeight
      );
      this.fitToScreen();
    },
    watchResize() {
      window.addEventListener("resize", this.onResize);
    },
    async getPdfPageImages() {
      if (!this.selectedFile) {
        return;
      }
      const { file_id: fileId } = this.selectedFile;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/files/${fileId}/images`);
      this.pdfPageImages = results.sort(
        (a, b) => a.pdf_page_number - b.pdf_page_number
      );
    },
    async loadPdfPage() {
      return new Promise((resolve, reject) => {
        const stage = this.$refs.stage?.getNode();
        if (!stage) {
          resolve();
        }
        stage.scale({ x: 1, y: 1 });
        const { selectedPdfPage } = this;
        const img = new window.Image();
        img.src = selectedPdfPage?.s3_file_path_original_image;
        img.crossOrigin = "Anonymous";
        img.onload = async () => {
          const image = img;
          const id = uuidv4();
          this.items = [
            {
              id,
              src: selectedPdfPage?.s3_file_path_original_image,
              config: {
                name: id,
                image,
                shadowColor: "black",
                shadowBlur: 0,
                shadowOffset: { x: 3, y: 3 },
                shadowOpacity: 0.5,
                x: 0,
                y: 0,
              },
              layerConfig: {
                clip: {},
                draggable: false,
              },
              type: ITEM_TYPES.IMAGE,
            },
          ];
          this.firstLoad = false;
          resolve(image);
        };
        img.onerror = () => {
          reject();
        };
      });
    },
    zoomToFit(imageObj) {
      if (!this.$refs.card?.$el || !this.$refs.markupItemToolbar?.$el) {
        return;
      }
      const containerWidth = this.$refs.card?.$el?.offsetWidth;
      const containerHeight =
        this.$refs.card?.$el.offsetHeight -
        (this.$refs.markupToolbar?.$el?.offsetHeight ??
          this.$refs.cropToolbar?.$el?.offsetHeight) -
        this.$refs.markupItemToolbar.$el.offsetHeight;
      const layer = this.$refs.layer.getNode();
      const stage = this.$refs.stage.getNode();
      if (stage.rotation() % 360 !== 0) {
        stage.scale({ x: 1, y: 1 });
        stage.position({ x: 0, y: 0 });
        stage.rotation(0);
        const { index } = this.findMarkupImageItemAndNodeIndexes();
        if (this.items[index]) {
          this.items[index].rotateAngleDegrees = 0;
        }
      }
      if (!isNaN(layer.clipWidth()) && !isNaN(layer.clipHeight())) {
        const widthScale = containerWidth / layer.clipWidth();
        const heightScale = containerHeight / layer.clipHeight();

        let scaleToFit =
          widthScale > heightScale ? heightScale * 0.95 : widthScale * 0.95;
        if (scaleToFit > 1) {
          scaleToFit = 1;
        }

        const zoomedImageHeight = layer.clipHeight() * scaleToFit;
        const zoomedImageWidth = layer.clipWidth() * scaleToFit;
        stage.position({
          x: (containerWidth - zoomedImageWidth) / 2 - layer.clipX(),
          y: (containerHeight - zoomedImageHeight) / 2 - layer.clipY(),
        });

        stage.scale({
          x: scaleToFit,
          y: scaleToFit,
        });
      } else {
        if (!imageObj) {
          return;
        }
        const widthScale = containerWidth / imageObj.width;
        const heightScale = containerHeight / imageObj.height;

        let scaleToFit =
          widthScale > heightScale ? heightScale * 0.95 : widthScale * 0.95;
        if (scaleToFit > 1) {
          scaleToFit = 1;
        }

        const zoomedImageHeight = imageObj.height * scaleToFit;
        const zoomedImageWidth = imageObj.width * scaleToFit;
        stage.position({
          x: (containerWidth - zoomedImageWidth) / 2,
          y: (containerHeight - zoomedImageHeight) / 2,
        });

        stage.scale({
          x: scaleToFit,
          y: scaleToFit,
        });
      }
    },
    loadImage() {
      const { selectedFile } = this;
      const img = new window.Image();
      img.src = selectedFile.s3_file_path_original_image;
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const image = img;
        const id = uuidv4();
        this.items = [
          {
            id,
            src: selectedFile.s3_file_path_original_image,
            config: {
              name: id,
              image,
              shadowColor: "black",
              shadowBlur: 0,
              shadowOffset: { x: 3, y: 3 },
              shadowOpacity: 0.5,
            },
            layerConfig: {
              clip: {},
              draggable: false,
            },
            type: ITEM_TYPES.IMAGE,
            rotateAngleDegrees: 0,
          },
        ];
        this.zoomToFit(image);
        this.firstLoad = false;
      };
    },
    finishLine() {
      this.displayToolbar = false;
      this.$set(this.configKonva, "draggable", true);
      this.drawingLine = false;
      this.resetStageListeners();
      this.changesMade = true;
    },
    finishPolygon() {
      const itemIndex = this.items.length - 1;
      const id = this.items[itemIndex].id;
      const item = this.$refs.items.find((it) => it.getNode().name() === id);
      const node = item?.getNode();
      if (!node) {
        return;
      }

      node?.closed(true);
      this.isPaintPolygon = false;
      this.items.slice(-1)[0].config.closed = true;
      this.resetStageListeners();
      this.displayToolbar = false;
    },
  },
  watch: {
    addedCustomSymbols: {
      deep: true,
      handler() {
        this.updateLegend();
      },
    },
    "itemOptions.legendFontSize"() {
      this.updateLegend();
    },
    selectedShapeName(val) {
      this.displayEditItemToolbar = Boolean(val);
    },
    selectedPdfPage: {
      deep: true,
      async handler() {
        await this.getFileMarkupRevisions();
        await this.loadPdfPageOrMarkup();
      },
    },
    isPaint(val) {
      if (val) {
        this.configKonva = {
          ...this.configKonva,
          draggable: false,
        };
        for (const item of this.items) {
          item.draggable = false;
        }
      } else {
        this.configKonva = {
          ...this.configKonva,
          draggable: true,
        };
        for (const item of this.items) {
          item.draggable = true;
        }
      }
    },
    itemOptions: {
      deep: true,
      handler(val) {
        if (this.itemType === ITEM_TYPES.SKETCH) {
          const { sketchStroke: stroke, sketchStrokeWidth: strokeWidth } = val;
          const itemOptions = { stroke, strokeWidth };
          this.addFreeDrawing(itemOptions);
        }
      },
    },
    async showPdfPagesPanel(val) {
      const stage = this.$refs.stage.getNode();
      if (val) {
        await this.$nextTick();
        stage.width(
          this.$refs.card.$el.offsetWidth - this.$refs.pdfList.offsetWidth
        );
      } else {
        stage.width(this.$refs.card.$el.offsetWidth);
      }
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}

.markup-item-button {
  flex-basis: 45%;
  cursor: pointer;
}

.stage {
  background-color: #6f6f6f;
}

td {
  border-bottom: none !important;
}

.bottom-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.bottom-right-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
