<template>
  <svg viewBox="0 0 20 20" width="20" height="20">
    <polygon
      points="10 0, 20 8, 15 20, 5 20, 0 8"
      :stroke="stroke"
      :fill="fill"
      :stroke-width="strokeWidth"
    />
  </svg>
</template>

<script>
export default {
  name: "Pentagon",
  props: {
    stroke: {
      type: String,
      default: "black",
    },
    fill: {
      type: String,
      default: "black",
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
  },
};
</script>
