var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.symbol && _vm.symbol.symbol
    ? _c(
        "div",
        {
          staticClass: "pr-3 d-flex justify-center align-center",
          staticStyle: { width: "40px" }
        },
        [
          _vm.symbol.symbol.symbolType === _vm.SYMBOL_TYPES.SHAPE
            ? [
                _vm.symbol.symbol.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
                  ? _c("img", {
                      style: {
                        width: _vm.symbol.symbol.size + "px",
                        height: _vm.symbol.symbol.size + "px"
                      },
                      attrs: {
                        src: require("@/assets" + _vm.symbol.symbol.shapeUrl)
                      }
                    })
                  : _vm.symbol.symbol.shape === _vm.SHAPE_TYPES.ELLIPSE
                  ? _c("div", [
                      _c("div", {
                        style: {
                          width: "30px",
                          height: "25px",
                          "border-radius": "50%",
                          "background-color": _vm.symbol.symbol.shapeFill,
                          border:
                            "1px solid " + _vm.symbol.symbol.shapeOutlineColor
                        }
                      })
                    ])
                  : _vm.symbol.symbol.shape === _vm.SHAPE_TYPES.RECTANGLE
                  ? _c("div", [
                      _c("div", {
                        style: {
                          width: "30px",
                          height: "25px",
                          "background-color": _vm.symbol.symbol.shapeFill,
                          border:
                            "1px solid " + _vm.symbol.symbol.shapeOutlineColor
                        }
                      })
                    ])
                  : _vm.symbol.symbol.shape === _vm.SHAPE_TYPES.POLYGON
                  ? _c(
                      "div",
                      [
                        _c("v-icon", [
                          _vm._v(" " + _vm._s(_vm.mdiVectorPolygon) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm.symbol.symbol.symbolType === _vm.SYMBOL_TYPES.IMAGE
            ? _c("img", {
                style: {
                  width: _vm.symbol.symbol.size + "px",
                  height: _vm.symbol.symbol.size + "px"
                },
                attrs: { src: _vm.symbol.symbol.imageUrl }
              })
            : _vm.symbol.symbol.symbolType === _vm.SYMBOL_TYPES.LINE
            ? _c("div", {
                style: {
                  "background-color": _vm.symbol.symbol.lineStroke,
                  height: _vm.symbol.symbol.lineStrokeWidth + "px",
                  width: "25px"
                }
              })
            : _vm.symbol.symbol.symbolType === _vm.SYMBOL_TYPES.POINT
            ? _c("div", {
                style: {
                  "background-color": _vm.symbol.symbol.pointFill,
                  width: _vm.symbol.symbol.size + "px",
                  height: _vm.symbol.symbol.size + "px",
                  "border-radius": "50%",
                  border: "1px solid " + _vm.symbol.symbol.pointOutlineColor
                }
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }