var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pl-3" }, [
    Array.isArray(_vm.items)
      ? _c(
          "div",
          { staticClass: "mt-n3" },
          _vm._l(_vm.items.slice().reverse(), function(item, index) {
            return _c(
              "div",
              {
                key: _vm.level + "-" + index + "-" + item.id,
                staticClass: "pa-0 mt-n6"
              },
              [
                _c(
                  "div",
                  { staticClass: "py-0 mt-n3 pl-3" },
                  [
                    item.utiliSyncLayerType === "S"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n              d-flex\n              flex-sm-nowrap flex-wrap\n              justify-space-between\n              align-center\n              py-0\n              mt-n3\n            "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  Array.isArray(_vm.getSublayers(item.layer)) &&
                                  _vm.getSublayers(item.layer).length > 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-n3 mt-n3",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleShowSublayer(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  !_vm.showSublayers[index]
                                                    ? _vm.mdiChevronDown
                                                    : _vm.mdiChevronUp
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "truncate mt-n1 item-title"
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-sm-nowrap flex-wrap align-center"
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mr-1",
                                    model: {
                                      value: item.visible,
                                      callback: function($$v) {
                                        _vm.$set(item, "visible", $$v)
                                      },
                                      expression: "item.visible"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      : item.utiliSyncLayerType === "F"
                      ? [
                          item.renderer &&
                          ["unique-value", "class-breaks"].includes(
                            item.renderer.type
                          )
                            ? [
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    d-flex\n                    flex-sm-nowrap flex-wrap\n                    justify-space-between\n                    align-center\n                    py-0\n                    mt-n3\n                  "
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            Array.isArray(
                                              _vm.getSublayers(item.layer)
                                            ) &&
                                            _vm.getSublayers(item.layer)
                                              .length > 0
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-n3 mt-n3",
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleShowSublayer(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            !_vm.showSublayers[
                                                              index
                                                            ]
                                                              ? _vm.mdiChevronDown
                                                              : _vm.mdiChevronUp
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "truncate mt-n1 item-title"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-sm-nowrap flex-wrap align-center"
                                          },
                                          [
                                            _c("v-switch", {
                                              staticClass: "mr-1",
                                              model: {
                                                value: item.visible,
                                                callback: function($$v) {
                                                  _vm.$set(item, "visible", $$v)
                                                },
                                                expression: "item.visible"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]),
                                  item.renderer
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.viewSymbols,
                                              expression: "viewSymbols"
                                            }
                                          ],
                                          staticClass: "mt-n5"
                                        },
                                        [
                                          item.renderer.type === "unique-value"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        item.renderer
                                                          .uniqueValueInfos
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : item.renderer.type ===
                                              "class-breaks"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        item.renderer
                                                          .classBreakInfos
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            : item.renderer &&
                              ["simple"].includes(item.renderer.type)
                            ? [
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                  d-flex\n                  flex-sm-nowrap flex-wrap\n                  justify-space-between\n                  align-center\n                  py-0\n                  mt-n3\n                "
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-sm-nowrap flex-wrap"
                                        },
                                        [
                                          Array.isArray(
                                            _vm.getSublayerItems(item)
                                          ) &&
                                          _vm.getSublayerItems(item).length > 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-n3 mt-n3",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleShowSublayer(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.showSublayers[
                                                            index
                                                          ]
                                                            ? _vm.mdiChevronDown
                                                            : _vm.mdiChevronUp
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.renderer
                                            ? _c("SimpleRendererItem", {
                                                attrs: {
                                                  itemInfo: _vm.layer,
                                                  viewSymbols: _vm.viewSymbols,
                                                  level: _vm.level
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-sm-nowrap flex-wrap align-center"
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mr-1",
                                            model: {
                                              value: item.visible,
                                              callback: function($$v) {
                                                _vm.$set(item, "visible", $$v)
                                              },
                                              expression: "item.visible"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ]
                            : _vm._e()
                        ]
                      : ["L", "U"].includes(item)
                      ? [
                          item.utilisyncRenderer.renderer_symbols &&
                          item.utilisyncRenderer.renderer_symbols.length > 1
                            ? [
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  item.utilisyncRenderer
                                    ? _c("div", { staticClass: "mt-n5" }, [
                                        item.utilisyncRenderer.renderer_type ===
                                        "unique_value"
                                          ? _c(
                                              "div",
                                              [
                                                _c("RendererItemList", {
                                                  attrs: {
                                                    infos:
                                                      item.utilisyncRenderer
                                                        .renderer_symbols,
                                                    viewSymbols:
                                                      _vm.viewSymbols,
                                                    level: _vm.level
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : item.utilisyncRenderer
                                              .renderer_type === "class_break"
                                          ? _c(
                                              "div",
                                              [
                                                _c("RendererItemList", {
                                                  attrs: {
                                                    infos:
                                                      item.utilisyncRenderer
                                                        .renderer_symbols,
                                                    viewSymbols:
                                                      _vm.viewSymbols,
                                                    level: _vm.level
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            : item.utilisyncRenderer.renderer_symbols &&
                              item.utilisyncRenderer.renderer_symbols.length ===
                                1
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                align-center\n                py-0\n                mt-n3\n              ",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap"
                                      },
                                      [
                                        Array.isArray(
                                          _vm.getSublayerItems(item)
                                        ) &&
                                        _vm.getSublayerItems(item).length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-n3 mt-n3",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleShowSublayer(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.showSublayers
                                                          ? _vm.mdiChevronDown
                                                          : _vm.mdiChevronUp
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("UtilisyncRendererItem", {
                                          attrs: {
                                            itemInfo:
                                              item.utilisyncRenderer
                                                .renderer_symbols[0],
                                            viewSymbols: _vm.viewSymbols,
                                            level: _vm.level
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("v-switch", {
                                          staticClass: "mt-4 mr-1",
                                          model: {
                                            value: item.visible,
                                            callback: function($$v) {
                                              _vm.$set(item, "visible", $$v)
                                            },
                                            expression: "item.visible"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ]
                      : [
                          _c("div", { staticClass: "pa-0 ma-0" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                ma-0\n                pa-0\n                align-center\n                py-0\n                mt-n6\n              "
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                  d-flex\n                  flex-sm-nowrap flex-wrap\n                  align-center\n                  gap\n                  py-0\n                  my-0\n                "
                                  },
                                  [
                                    Array.isArray(_vm.getSublayerItems(item)) &&
                                    _vm.getSublayerItems(item).length > 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-n3 mt-n3",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleShowSublayer(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.showSublayers[index]
                                                      ? _vm.mdiChevronDown
                                                      : _vm.mdiChevronUp
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.getImages(item).length === 1
                                      ? _c("img", {
                                          style: {
                                            width:
                                              _vm.getImages(item)[0].width +
                                              "px",
                                            height:
                                              _vm.getImages(item)[0].height +
                                              "px"
                                          },
                                          attrs: {
                                            src:
                                              "data:" +
                                              _vm.getImages(item)[0]
                                                .contentType +
                                              ";base64," +
                                              _vm.getImages(item)[0].imageData
                                          }
                                        })
                                      : _vm._e(),
                                    _c("RendererItem", {
                                      attrs: {
                                        itemInfo: item.renderer,
                                        level: _vm.level
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "truncate mt-n1 item-title"
                                      },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-sm-nowrap flex-wrap align-center"
                                  },
                                  [
                                    _c("v-switch", {
                                      staticClass: "mt-4 mr-1",
                                      model: {
                                        value: item.visible,
                                        callback: function($$v) {
                                          _vm.$set(item, "visible", $$v)
                                        },
                                        expression: "item.visible"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]),
                          _vm.getImages(item).length > 1
                            ? _c(
                                "section",
                                { staticClass: "pl-2 mt-n4 pb-4" },
                                _vm._l(_vm.getImages(item), function(image) {
                                  return _c(
                                    "div",
                                    {
                                      key: image.layerId,
                                      staticClass:
                                        "\n                d-flex\n                align-center\n                gap\n                flex-wrap flex-sm-nowrap\n                py-2\n                my-0\n              "
                                    },
                                    [
                                      image.imageData
                                        ? _c("img", {
                                            style: {
                                              width: image.width + "px",
                                              height: image.height + "px"
                                            },
                                            attrs: {
                                              src:
                                                "data:" +
                                                image.contentType +
                                                ";base64," +
                                                image.imageData
                                            }
                                          })
                                        : _vm._e(),
                                      _c("div", [
                                        _vm._v(" " + _vm._s(image.label) + " ")
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                ),
                Array.isArray(_vm.getSublayerItems(item)) &&
                _vm.getSublayerItems(item).length > 0 &&
                _vm.showSublayers[index]
                  ? _c(
                      "div",
                      [
                        _c("ChildVisibility", {
                          attrs: {
                            items: _vm.getSublayerItems(item),
                            level: _vm.level + 1,
                            childMultiLayerImages: _vm.childMultiLayerImages,
                            layer: _vm.layer
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }