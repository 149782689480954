<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
        ref="field"
      >
        <v-menu
          max-width="100%"
          v-model="showTimePicker"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="time-field"
              v-model="timeValue"
              @input="onInput"
              v-on="on"
              :label="item.question.label"
              color="#3F51B5"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
              readonly
              clearable
              hide-details
            ></v-text-field>
          </template>
          <v-time-picker v-model="timeValue" @change="onInput"></v-time-picker>
        </v-menu>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "GroupTimeInput",
  props: {
    item: Object,
    readOnly: Boolean,
    value: String,
  },
  computed: {
    isRequired() {
      return this.item.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      showTimePicker: false,
      canCloseOnClick: false,
      timeValue: undefined,
    };
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const { valid } = await this.$refs.field.validateSilent();
      const { item } = this;
      const { id, question } = item;
      this.$emit("validated", {
        valid,
        label: question.label,
        id,
      });
    },
    async onInput() {
      await this.validateField();
      this.$emit("input", this.timeValue);
      await this.$nextTick();
      this.showTimePicker = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.timeValue = val;
      },
    },
    isRequired() {
      this.validateField();
    },
  },
  beforeMount() {
    this.timeValue = cloneDeep(this.value);
  },
};
</script>
