var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showMarkupViewerDialog,
        fullscreen: "",
        "retain-focus": false,
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        {
          ref: "card",
          staticClass: "d-flex",
          staticStyle: { overflow: "hidden" },
          attrs: { height: "calc(100vh - (100vh - 100%) - 56px)" }
        },
        [
          _c("v-slide-x-reverse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPdfPagesPanel,
                    expression: "showPdfPagesPanel"
                  }
                ],
                ref: "pdfList",
                style: { width: "200px" }
              },
              [
                _c("MarkupPdfList", {
                  attrs: { selectedFile: _vm.selectedFile },
                  on: {
                    "markup-pdf-list-close": function($event) {
                      _vm.showPdfPagesPanel = false
                    },
                    "page-selected": function($event) {
                      _vm.selectedPdfPage = $event
                      _vm.loadPdfPageOrMarkup()
                    },
                    "revision-selected": _vm.onRevisionSelected
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "flex-grow-1 d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "flex-grow-1 stage" },
              [
                _c(
                  "v-toolbar",
                  { attrs: { dark: "", width: "100%", color: "#3F51B5" } },
                  [
                    _vm.selectedFile.s3_file_path &&
                    _vm.selectedFile.s3_file_path.endsWith("pdf")
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ml-n3",
                            attrs: { text: "", color: "white" },
                            on: {
                              click: function($event) {
                                _vm.showPdfPagesPanel = !_vm.showPdfPagesPanel
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mr-3 d-flex align-center gap",
                        staticStyle: { "max-width": "50%" }
                      },
                      [
                        _vm.$vuetify.breakpoint.smAndUp
                          ? _c("div", { staticClass: "text-truncate" }, [
                              _vm._v(" " + _vm._s(_vm.selectedFile.name) + " ")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c("p", { staticClass: "caption py-0 my-0" }, [
                              _vm._v("Version")
                            ]),
                            _c("RevisionDropdown", {
                              attrs: {
                                selectedPdfPage: _vm.selectedPdfPage,
                                selectedFile: _vm.selectedFile,
                                fileMarkupRevisions: _vm.fileMarkupRevisions,
                                pdfPageFileMarkupCounts:
                                  _vm.pdfPageFileMarkupCounts,
                                imageFileMarkupCount: _vm.imageFileMarkupCount
                              },
                              on: {
                                "revision-selected": _vm.onRevisionSelected,
                                "show-more": function($event) {
                                  return _vm.getFileMarkupRevisions(
                                    ++_vm.markupRevisionPage
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("markup-viewer-dialog-close")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex-grow-1 stage" },
                  [
                    _c(
                      "v-stage",
                      {
                        ref: "stage",
                        staticClass: "d-flex justify-center align-center",
                        attrs: { config: _vm.configKonva },
                        on: {
                          mousedown: _vm.handleStageMouseDown,
                          touchstart: _vm.handleStageMouseDown
                        }
                      },
                      [
                        _c(
                          "v-layer",
                          { ref: "layer" },
                          [
                            _vm._l(_vm.items, function(item) {
                              return [
                                item.type === _vm.ITEM_TYPES.IMAGE ||
                                item.type === _vm.ITEM_TYPES.LEGEND
                                  ? _c("v-image", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.TEXT
                                  ? _c("v-text", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.LINE
                                  ? _c("v-line", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.SHAPE &&
                                    item.shape === _vm.SHAPE_TYPES.POLYGON
                                  ? _c("v-line", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.SKETCH
                                  ? _c("v-line", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.SHAPE &&
                                    item.shape === _vm.SHAPE_TYPES.RECTANGLE
                                  ? _c("v-rect", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.SHAPE &&
                                    item.shape === _vm.SHAPE_TYPES.ELLIPSE
                                  ? _c("v-ellipse", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.SHAPE &&
                                    item.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
                                  ? _c("v-image", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.POINT
                                  ? _c("v-circle", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : item.type === _vm.ITEM_TYPES.ARROW
                                  ? _c("v-arrow", {
                                      key: item.id,
                                      ref: "items",
                                      refInFor: true,
                                      attrs: { config: item.config }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-navigation-drawer",
            {
              staticClass: "elevation-1",
              staticStyle: { "z-index": "100" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showGraphicDetailsPanel,
                "hide-overlay": "",
                width: "300px",
                stateless: ""
              },
              model: {
                value: _vm.showGraphicDetailsPanel,
                callback: function($$v) {
                  _vm.showGraphicDetailsPanel = $$v
                },
                expression: "showGraphicDetailsPanel"
              }
            },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "elevation-0",
                  attrs: { dark: "", width: "100%", color: "#3F51B5" }
                },
                [
                  _c("div", [_vm._v("Details")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showGraphicDetailsPanel = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                    1
                  )
                ],
                1
              ),
              _vm.selectedItem.details
                ? _c("div", { staticClass: "pa-4" }, [
                    _c("div", [
                      _c("p", { staticClass: "caption py-0 my-0" }, [
                        _vm._v("Label")
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.selectedItem.details.label))])
                    ]),
                    _c("div", [
                      _c("p", { staticClass: "caption py-0 my-0" }, [
                        _vm._v("Description")
                      ]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.selectedItem.details.description))
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }