<template>
  <div
    style="
      position: absolute;
      top: 0px;
      left: 30000px;
      border-radius: 10px;
      border: black;
      padding: 15px;
      background-color: lightgray;
      font-size: 30px;
    "
  >
    <b>Legend</b>
    <table :style="{ 'table-layout': 'fixed' }">
      <tbody>
        <tr v-for="s of addedCustomSymbols" :key="s.markupSymbolId">
          <td class="d-flex flex-column justify-center">
            <img
              :src="s.symbol.imageDataURL"
              v-if="s.symbol.symbolType === SYMBOL_TYPES.IMAGE"
              style="max-width: 30px; max-height: 30px"
            />
            <SymbolIconDisplay :symbol="s" v-else />
          </td>
          <td>{{ s.label }} ({{ s.count }})</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SymbolIconDisplay from "@/components/mapView/docs-tab/markup-image-dialog/markup-items-toolbar/SymbolIconDisplay";
import SYMBOL_TYPES from "@/constants/symbolTypes";

export default {
  name: "Legend",
  props: {
    addedCustomSymbols: Array,
  },
  components: {
    SymbolIconDisplay,
  },
  data() {
    return {
      SYMBOL_TYPES,
    };
  },
};
</script>

<style scoped>
td {
  white-space: nowrap;
}
</style>
