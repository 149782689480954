<template>
  <div
    class="d-flex justify-space-between px-3 py-2 rounded-0"
    style="background-color: #38383d; color: white"
    v-if="selectedItem"
  >
    <div
      class="d-flex gap justify-space-between flex-wrap flex-grow-1"
      v-if="!selectedItem.label"
    >
      <div class="d-flex gap flex-wrap">
        <div
          v-if="selectedItem.type === ITEM_TYPES.TEXT"
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.fill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.fill"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.TEXT"
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption px-2">Size</p>
          <v-slider
            color="white"
            :max="64"
            :min="0"
            hide-details
            v-model="options.size"
            @change="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.size"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div
          v-if="
            selectedItem.type === ITEM_TYPES.SHAPE &&
            selectedItem.shape === SHAPE_TYPES.MAP_SYMBOL
          "
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Shape</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div>
                  <img
                    v-if="options.shapeSymbol"
                    :src="
                      require(`@/assets/img/mapSymbols/${getShapeFillColor(
                        options.shapeFill
                      )}${options.shapeSymbol}.png`)
                    "
                    style="width: 25px; height: 25px"
                  />
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="s of symbols"
                :key="s"
                @click="
                  options.shape = SHAPE_TYPES.MAP_SYMBOL;
                  options.shapeSymbol = s;
                  options.shapeUrl = `/img/mapSymbols/${getShapeFillColor(
                    options.shapeFill
                  )}${options.shapeSymbol}.png`;
                  $emit('options-changed', selectedItem.id, options);
                "
              >
                <v-list-item-title class="d-flex align-center">
                  <img
                    :src="
                      require(`@/assets/img/mapSymbols/${getShapeFillColor(
                        options.shapeFill
                      )}${s}.png`)
                    "
                    style="width: 25px; height: 25px"
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.SHAPE"
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Symbol Color</p>

          <v-menu offset-y v-if="selectedItem.shape === SHAPE_TYPES.MAP_SYMBOL">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeFill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="choice of colorChoices"
                :key="choice.value"
                @click="
                  options.shapeFill = choice.value;
                  options.shapeImageFillColor = getShapeFillColor(choice.value);
                  options.shapeUrl = `/img/mapSymbols/${getShapeFillColor(
                    choice.value
                  )}${options.shapeSymbol}.png`;
                  $emit('options-changed', selectedItem.id, options);
                "
              >
                <v-list-item-title class="d-flex align-center">
                  <div
                    :style="{
                      'background-color': choice.value,
                      width: '25px',
                      height: '25px',
                    }"
                    class="mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ choice.label }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeFill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.shapeFill"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="
            selectedItem.type === ITEM_TYPES.SHAPE &&
            selectedItem.shape !== SHAPE_TYPES.MAP_SYMBOL
          "
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Outline Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeOutline,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.shapeOutline"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="
            selectedItem.type === ITEM_TYPES.SHAPE &&
            selectedItem.shape !== SHAPE_TYPES.MAP_SYMBOL
          "
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption">Outline Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="0"
            hide-details
            v-model="options.shapeOutlineWidth"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.shapeOutlineWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="selectedItem.type === ITEM_TYPES.POINT">
          <p class="py-0 my-0 caption">Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.pointFill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.pointFill"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.POINT"
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Outline Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.pointOutlineColor,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.pointOutlineColor"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.POINT"
          :class="{
            mobile: $vuetify.breakpoint.xsOnly,
            'number-input-slider': $vuetify.breakpoint.smAndup,
          }"
        >
          <p class="py-0 my-0 caption">Outline Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="0"
            hide-details
            v-model="options.pointOutlineWidth"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.pointOutlineWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.POINT"
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption">Symbol Size</p>
          <v-slider
            color="white"
            :max="16"
            :min="0"
            hide-details
            v-model="options.size"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.size"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.LINE"
          :class="{ mobile: $vuetify.breakpoint.xsOnly }"
        >
          <p class="py-0 my-0 caption">Stroke</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.lineStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.lineStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.LINE"
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption px-2">Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.lineStrokeWidth"
            @change="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.lineStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="selectedItem.type === ITEM_TYPES.SKETCH">
          <p class="py-0 my-0 caption">Stroke</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.sketchStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.sketchStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.SKETCH"
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption px-2">Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.sketchStrokeWidth"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.sketchStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="selectedItem.type === ITEM_TYPES.ARROW">
          <p class="py-0 my-0 caption">Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.arrowStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.arrowStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', selectedItem.id, options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="selectedItem.type === ITEM_TYPES.ARROW"
          class="number-input-slider"
        >
          <p class="py-0 my-0 caption px-2">Line Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.arrowStrokeWidth"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.arrowStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="selectedItem.type === ITEM_TYPES.ARROW">
          <p class="py-0 my-0 caption px-2">Pointer Size</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.arrowPointerSize"
            @input="$emit('options-changed', selectedItem.id, options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.arrowPointerSize"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>
      </div>

      <div class="d-flex align-center gap">
        <v-btn
          dark
          text
          class="d-flex px-0 mx-0"
          @click="$emit('delete-item')"
          :x-small="$vuetify.breakpoint.xsOnly"
        >
          <v-icon color="white">
            {{ mdiDelete }}
          </v-icon>
          <div v-if="$vuetify.breakpoint.smAndUp">Delete</div>
        </v-btn>

        <v-btn
          dark
          text
          @click="showGraphicDetailsPanel = true"
          class="d-flex px-0 mx-0"
          :x-small="$vuetify.breakpoint.xsOnly"
        >
          <img
            :src="require('@/assets/show_panel.svg')"
            style="width: 20px; height: 20px"
            class="mr-2"
          />
          <div v-if="$vuetify.breakpoint.smAndUp">Details</div>
        </v-btn>
      </div>
    </div>
    <div class="flex-grow-1 d-flex justify-end align-center" v-else>
      <v-btn
        dark
        text
        class="d-flex px-0 mx-0"
        @click="$emit('delete-item')"
        :x-small="$vuetify.breakpoint.xsOnly"
      >
        <v-icon color="white">
          {{ mdiDelete }}
        </v-icon>
        <div v-if="$vuetify.breakpoint.smAndUp">Delete</div>
      </v-btn>
    </div>

    <div class="d-flex flex-no-wrap align-center gap px-2">
      <div v-if="selectedItem.label">
        {{ selectedItem.label }}
      </div>
      <div>
        <v-btn icon @click="$emit('toolbar-close')">
          <v-icon color="white">{{ mdiClose }}</v-icon>
        </v-btn>
      </div>
    </div>

    <v-navigation-drawer
      app
      v-model="showGraphicDetailsPanel"
      right
      :permanent="showGraphicDetailsPanel"
      style="z-index: 100"
      hide-overlay
      width="300px"
      stateless
      class="elevation-1"
    >
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Details</div>
        <v-spacer />
        <v-btn icon @click="showGraphicDetailsPanel = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <EditGraphicDetailsForm
        :selectedItem="selectedItem"
        @graphic-details-changed="onGraphicDetailsChanged"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
  mdiDelete,
} from "@mdi/js";
import ITEM_TYPES from "@/constants/markupItemTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ColorPicker from "@/components/shared/ColorPicker";
import EditGraphicDetailsForm from "@/components/mapView/docs-tab/markup-image-dialog/edit-item-bottom-toolbar/EditGraphicDetailsForm";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "EditItemBottomToolbar",
  components: {
    ColorPicker,
    EditGraphicDetailsForm,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      mdiDelete,
      colorChoices,
      options: {
        fill: "yellow",
        size: 15,
        shape: "rectangle",
        shapeFill: "yellow",
        shapeImageFillColor: "Yellow",
        shapeOutline: "yellow",
        shapeSymbol: "CircleLargeB",
        shapeUrl: "",
        shapeOutlineWidth: 0,
        lineStroke: "yellow",
        lineStrokeWidth: 5,
        sketchStroke: "yellow",
        sketchStrokeWidth: 5,
        pointFill: "#2196F3",
        pointOutlineColor: "#2196F3",
        arrowStroke: "yellow",
        arrowStrokeWidth: 5,
        arrowPointerSize: 10,
      },
      ITEM_TYPES,
      SHAPE_TYPES,
      shapeChoices: [],
      symbols,
      showFillDialog: false,
      showGraphicDetailsPanel: false,
    };
  },
  props: {
    selectedItem: Object,
  },
  beforeMount() {
    this.loadShapeOptions();
  },
  watch: {
    "options.shape"(val) {
      if (
        val === SHAPE_TYPES.RECTANGLE ||
        val === SHAPE_TYPES.POLYGON ||
        val === SHAPE_TYPES.ELLIPSE
      ) {
        this.options.shapeFill = "#2196F3";
      }

      if (val === SHAPE_TYPES.MAP_SYMBOL) {
        this.options.shapeOutline = "black";
      }
    },
    selectedItem: {
      deep: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.loadShapeOptions();
      },
    },
  },
  methods: {
    onGraphicDetailsChanged(selectedItemId, selectedItemDetails) {
      this.$emit(
        "graphic-details-changed",
        selectedItemId,
        selectedItemDetails
      );
    },
    getShapeFillColor(shapeFill) {
      return (
        this.colorChoices.find((c) => c.value === shapeFill)?.fileNameColor ??
        "Blue"
      );
    },
    loadShapeOptions() {
      if (this.selectedItem.type === ITEM_TYPES.SHAPE) {
        const {
          shapeFill,
          shapeImageFillColor,
          shapeSymbol,
          shapeOutline,
          shapeOutlineWidth,
        } = this.selectedItem;
        this.options = {
          ...this.options,
          shapeFill,
          shapeImageFillColor,
          shapeSymbol,
          shapeOutline,
          shapeOutlineWidth,
        };
      } else if (this.selectedItem.type === ITEM_TYPES.POINT) {
        const {
          pointOutlineColor,
          pointOutlineWidth,
          pointFill: fill,
          size,
        } = this.selectedItem;
        this.options = {
          ...this.options,
          pointOutlineColor,
          pointOutlineWidth,
          pointFill: fill,
          size,
        };
      } else if (this.selectedItem.type === ITEM_TYPES.SKETCH) {
        const { sketchStroke, sketchStrokeWidth } = this.selectedItem;
        this.options = {
          ...this.options,
          sketchStroke,
          sketchStrokeWidth,
        };
      } else if (this.selectedItem.type === ITEM_TYPES.LINE) {
        const { lineStroke, lineStrokeWidth } = this.selectedItem;
        this.options = {
          ...this.options,
          lineStroke,
          lineStrokeWidth,
        };
      } else if (this.selectedItem.type === ITEM_TYPES.TEXT) {
        const { fill, size } = this.selectedItem;
        this.options = {
          ...this.options,
          fill,
          size,
        };
      } else if (this.selectedItem.type === ITEM_TYPES.ARROW) {
        const { arrowStroke, arrowStrokeWidth, arrowPointerSize } =
          this.selectedItem;
        this.options = {
          ...this.options,
          arrowStroke,
          arrowStrokeWidth,
          arrowPointerSize,
        };
      }
    },
  },
};
</script>

<style scoped>
.mobile {
  width: 45px;
  max-height: 65px;
}

.number-input {
  width: 20px;
}

.number-input-slider {
  width: 80px;
}

.number-input >>> .v-text-field__slot input {
  color: white;
}

.v-text-field >>> .v-input__slot::before {
  border-color: white !important;
}
</style>
