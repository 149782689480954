var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-between px-3 py-2",
      staticStyle: { "background-color": "#38383d", color: "white" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex gap justify-end flex-wrap align-center flex-grow-1"
        },
        [
          _c("div", { staticClass: "d-flex gap flex-wrap" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-no-wrap align-center gap px-2" },
              [
                _c("div", [
                  _vm._v(" Tap to add a "),
                  _c("b", [_vm._v(_vm._s(_vm.customSymbolName))])
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("toolbar-close")
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { color: "white" } }, [
                          _vm._v(_vm._s(_vm.mdiClose))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }