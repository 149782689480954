<template>
  <v-dialog :value="showEditSymbolDialog" width="600px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Edit Symbol</div>
        <v-spacer />
        <v-btn icon @click="$emit('edit-symbol-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="d-flex justify-center px-0 mx-0">
        <div>
          <v-alert
            color="#EF6C00"
            prominent
            class="ma-5"
            :icon="false"
            type="warning"
          >
            <template #prepend>
              <div class="pr-3">
                <v-icon>
                  {{ mdiAlertCircleOutline }}
                </v-icon>
              </div>
            </template>
            <div class="d-flex align-center">
              <div>
                Symbol are created at the organization level. Updating it will
                change how it appears on all markups in the organization.
              </div>
            </div>
          </v-alert>

          <validation-observer ref="form" slim>
            <div class="d-flex pa-0 ma-0 justify-center settings">
              <form
                @submit.prevent="saveSymbol"
                class="d-flex pa-0 ma-0 justify-center settings-row"
              >
                <div class="settings">
                  <div class="ma-0 pa-0 d-flex justify-space-between gap">
                    <div class="mt-2">
                      <p class="pb-1 my-0 caption">Symbol</p>
                      <template v-if="symbolType === SYMBOL_TYPES.SHAPE">
                        <img
                          v-if="shapeOptions.shape === SHAPE_TYPES.MAP_SYMBOL"
                          :src="
                            require(`@/assets/img/mapSymbols/${shapeOptions.shapeImageFillColor}${shapeOptions.shapeSymbol}.png`)
                          "
                          :style="{
                            width: `${shapeOptions.size}px`,
                            height: `${shapeOptions.size}px`,
                          }"
                        />
                        <div
                          v-else-if="shapeOptions.shape === SHAPE_TYPES.ELLIPSE"
                        >
                          <div
                            :style="{
                              width: `${shapeOptions.size}px`,
                              height: `${shapeOptions.size}px`,
                              'border-radius': '50%',
                              'background-color': shapeOptions.shapeFill,
                              border: `1px solid ${shapeOptions.shapeOutlineColor}`,
                            }"
                          ></div>
                        </div>
                        <div
                          v-else-if="
                            shapeOptions.shape === SHAPE_TYPES.RECTANGLE
                          "
                        >
                          <div
                            :style="{
                              width: '30px',
                              height: '25px',
                              'background-color': shapeOptions.shapeFill,
                              border: `1px solid ${shapeOptions.shapeOutlineColor}`,
                            }"
                          ></div>
                        </div>
                        <div
                          v-else-if="shapeOptions.shape === SHAPE_TYPES.POLYGON"
                        >
                          <v-icon>
                            {{ mdiVectorPolygon }}
                          </v-icon>
                        </div>
                      </template>
                      <div
                        class="d-flex align-center justify-center"
                        v-else-if="symbolType === SYMBOL_TYPES.LINE"
                        style="height: 30px"
                      >
                        <div
                          :style="{
                            height: `${lineOptions.lineStrokeWidth}px`,
                            width: '100%',
                            'background-color': lineOptions.lineStroke,
                          }"
                        ></div>
                      </div>
                      <div
                        class="d-flex align-center justify-center"
                        v-else-if="symbolType === SYMBOL_TYPES.IMAGE"
                      >
                        <img
                          :src="imageOptions.imageUrl"
                          :style="{ width: `${imageOptions.size}px` }"
                        />
                      </div>
                      <div
                        class="d-flex align-center justify-center"
                        v-else-if="symbolType === SYMBOL_TYPES.POINT"
                      >
                        <div
                          :style="{
                            width: `${pointOptions.size}px`,
                            height: `${pointOptions.size}px`,
                            'border-radius': '50%',
                            'background-color': pointOptions.pointFill,
                            border: `1px solid ${pointOptions.pointOutlineColor}`,
                          }"
                        ></div>
                      </div>
                    </div>

                    <div class="mt-2 flex-grow-1">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Label"
                        rules="required"
                      >
                        <v-text-field
                          v-model="label"
                          :error-messages="errors"
                          :success="valid"
                          label="Label"
                          color="#3F51B5"
                        >
                        </v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="d-flex justify-space-between gap">
                    <div style="width: 120px" class="mt-2">
                      <v-select
                        :items="symbolTypeChoices"
                        v-model="symbolType"
                        item-text="label"
                        item-value="value"
                        label="Symbol Type"
                      >
                      </v-select>
                    </div>

                    <ShapeForm
                      v-if="symbolType === SYMBOL_TYPES.SHAPE"
                      :shapeOptionsObj="shapeOptions"
                      @shape-options-changed="
                        shapeOptions = { ...shapeOptions, ...$event }
                      "
                    />

                    <LineForm
                      v-if="symbolType === SYMBOL_TYPES.LINE"
                      :lineOptionsObj="lineOptions"
                      @line-options-changed="
                        lineOptions = { ...lineOptions, ...$event }
                      "
                    />

                    <ImageForm
                      v-if="symbolType === SYMBOL_TYPES.IMAGE"
                      :imageOptionsObj="imageOptions"
                      @image-options-changed="
                        imageOptions = { ...imageOptions, ...$event }
                      "
                    />

                    <PointForm
                      v-if="symbolType === SYMBOL_TYPES.POINT"
                      :pointOptionsObj="pointOptions"
                      @point-options-changed="
                        pointOptions = { ...pointOptions, ...$event }
                      "
                    />
                  </div>

                  <div class="d-flex justify-end gap">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                          <v-icon>{{ mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="px-0 mx-0">
                        <v-list-item
                          class="px-5 mx-0 d-flex align-center cursor-pointer"
                          style="gap: 10px"
                          @click="onMakeSymbolActivateToggleClick"
                        >
                          <v-icon>
                            {{ !deactivated ? mdiEyeOff : mdiEye }}
                          </v-icon>
                          Make Symbol {{ !deactivated ? "Inactive" : "Active" }}
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn color="#3F51B5" type="submit" dark>
                      Update Symbol
                    </v-btn>
                  </div>
                </div>
              </form>
            </div>
          </validation-observer>

          <MakeSymbolInactiveDialog
            :showMakeSymbolInactiveDialog="showMakeSymbolInactiveDialog"
            @cancel-symbol-inactive-dialog="
              showMakeSymbolInactiveDialog = false
            "
            @confirm-symbol-inactive-dialog="toggleActivate()"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
  mdiAlertCircleOutline,
  mdiEyeOff,
  mdiEye,
  mdiDotsVertical,
} from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import SYMBOL_TYPES from "@/constants/symbolTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ShapeForm from "@/components/mapView/docs-tab/markup-image-dialog/add-symbol-dialog/ShapeForm.vue";
import LineForm from "@/components/mapView/docs-tab/markup-image-dialog/add-symbol-dialog/LineForm.vue";
import ImageForm from "@/components/mapView/docs-tab/markup-image-dialog/add-symbol-dialog/ImageForm.vue";
import PointForm from "@/components/mapView/docs-tab/markup-image-dialog/add-symbol-dialog/PointForm.vue";
import MakeSymbolInactiveDialog from "@/components/mapView/docs-tab/markup-image-dialog/edit-symbol-dialog/MakeSymbolInactiveDialog.vue";
const APIURL = process.env.VUE_APP_API_URL;

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

const symbolTypeChoices = [
  { label: "Shape", value: "shape" },
  { label: "Point", value: "point" },
  { label: "Image", value: "image" },
  { label: "Line", value: "line" },
];

export default {
  name: "AddSymbolDialog",
  components: {
    ShapeForm,
    LineForm,
    ImageForm,
    PointForm,
    MakeSymbolInactiveDialog,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      mdiAlertCircleOutline,
      mdiEyeOff,
      mdiEye,
      mdiDotsVertical,
      colorChoices,
      symbols,
      symbolTypeChoices,
      shapeOptions: {
        shape: SHAPE_TYPES.RECTANGLE,
        shapeFill: "#2196F3",
        shapeOutlineColor: "#2196F3",
        shapeImageFillColor: "Blue",
        shapeSymbol: "CircleLargeB",
        shapeUrl: "/img/mapSymbols/BlueCircleLargeB.png",
        size: 15,
      },
      lineOptions: {
        lineStroke: "#2196F3",
        lineStrokeWidth: 5,
      },
      imageOptions: {
        imageUrl: "",
        size: 30,
      },
      pointOptions: {
        pointFill: "#2196F3",
        pointOutlineColor: "#2196F3",
        size: 30,
      },
      symbolType: SYMBOL_TYPES.SHAPE,
      SYMBOL_TYPES,
      SHAPE_TYPES,
      label: "",
      deactivated: false,
      showMakeSymbolInactiveDialog: false,
    };
  },
  props: {
    showEditSymbolDialog: Boolean,
    selectedSymbol: Object,
  },
  methods: {
    onMakeSymbolActivateToggleClick() {
      if (!this.deactivated) {
        this.showMakeSymbolInactiveDialog = true;
      } else {
        this.toggleActivate();
      }
    },
    async toggleActivate() {
      this.deactivated = !this.deactivated;
      await this.saveSymbol();
    },
    async saveSymbol() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      let payload = {};
      const { symbolType, label, deactivated } = this;
      if (symbolType === SYMBOL_TYPES.SHAPE) {
        payload = {
          label,
          deactivated,
          symbol: { ...this.shapeOptions, symbolType, label },
        };
      } else if (symbolType === SYMBOL_TYPES.IMAGE) {
        payload = {
          label,
          deactivated,
          symbol: { ...this.imageOptions, symbolType, label },
        };
      } else if (symbolType === SYMBOL_TYPES.LINE) {
        payload = {
          label,
          deactivated,
          symbol: { ...this.lineOptions, symbolType, label },
        };
      } else if (symbolType === SYMBOL_TYPES.POINT) {
        payload = {
          label,
          deactivated,
          symbol: { ...this.pointOptions, symbolType, label },
        };
      }
      await axiosWithRegularAuth.put(
        `${APIURL}/markup_symbols/${this.selectedSymbol.markup_symbol_id}`,
        payload
      );
      this.$emit("custom-symbol-edited");
    },
    loadSymbol() {
      const { symbol, deactivated } = this.selectedSymbol;
      const { label, symbolType } = symbol;
      this.label = label;
      this.symbolType = symbolType;
      this.deactivated = Boolean(deactivated);
      if (symbolType === SYMBOL_TYPES.SHAPE) {
        this.shapeOptions = { ...symbol };
      } else if (symbolType === SYMBOL_TYPES.IMAGE) {
        this.imageOptions = { ...symbol };
      } else if (symbolType === SYMBOL_TYPES.LINE) {
        this.lineOptions = { ...symbol };
      } else if (symbolType === SYMBOL_TYPES.POINT) {
        this.pointOptions = { ...symbol };
      }
    },
  },
  beforeMount() {
    this.loadSymbol();
  },
  watch: {
    selectedSymbol: {
      deep: true,
      handler() {
        this.loadSymbol();
      },
    },
  },
};
</script>

<style scoped>
.number-input {
  width: 20px;
}

.settings {
  width: 100%;
}

.settings-row {
  width: 90%;
}

.gap {
  gap: 20px;
}

.settings-heading {
  margin-left: -1px;
}
</style>
