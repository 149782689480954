export default {
  data() {
    return {
      contentHeight: 0,
    };
  },
  methods: {
    async onResize() {
      await this.$nextTick();
      this.contentHeight =
        window.innerHeight -
        (this.$refs.toolbar?.$el?.clientHeight ?? 0) -
        (this.$refs.cardAction?.$el?.clientHeight ?? 0) -
        (this.$vuetify.breakpoint.xsOnly ? 60 : 100);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
