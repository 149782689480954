var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddImageDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column",
          staticStyle: { "z-index": "9999" },
          attrs: { "max-height": "80vh" }
        },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0 flex-grow-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("Add Image")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-photo-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3 flex-grow-1 overflow-y-auto" },
            [
              _c("div", [_vm._v("Add image by selecting a source")]),
              _c("input", {
                ref: "cameraInput",
                staticClass: "d-none",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  capture: "user",
                  name: "cameraFileInput"
                },
                on: { change: _vm.onFileInputChange }
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center my-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "#3F51B5",
                        disabled: _vm.readOnly
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.cameraInput.click()
                        }
                      }
                    },
                    [_vm._v(" Camera ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "#3F51B5",
                        disabled: _vm.readOnly
                      },
                      on: {
                        click: function($event) {
                          _vm.showSelectDocDialog = true
                        }
                      }
                    },
                    [_vm._v(" Docs ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: { click: _vm.onMapClick }
                    },
                    [_vm._v(" Map ")]
                  )
                ],
                1
              ),
              _c("file-pond", {
                ref: "files",
                attrs: {
                  name: "files",
                  "label-idle":
                    "Drag & Drop or <span class='filepond--label-action'> Browse </span>",
                  "allow-multiple": _vm.allowMultiple,
                  "accepted-file-types": "image/jpeg, image/png",
                  files: _vm.uploadFiles,
                  allowFileSizeValidation: "",
                  maxFileSize: "2MB",
                  disabled: _vm.readOnly
                },
                on: {
                  addfile: _vm.onChange,
                  click: function($event) {
                    _vm.uploadingImages = true
                  }
                }
              }),
              _vm.showSelectDocDialog
                ? _c("SelectDocDialog", {
                    attrs: {
                      showSelectDocDialog: _vm.showSelectDocDialog,
                      formDefinition: _vm.formDefinition,
                      maxWidthHeight: +_vm.maxWidthHeight,
                      formResultId: _vm.formResultId,
                      globalId: _vm.globalId,
                      objectId: _vm.objectId
                    },
                    on: {
                      "select-doc-dialog-close": function($event) {
                        _vm.showSelectDocDialog = false
                      },
                      "image-file-selected": _vm.onImageFileSelected
                    }
                  })
                : _vm._e(),
              _vm.showSelectMapScreenshotDialog
                ? _c("SelectMapScreenshotDialog", {
                    attrs: {
                      showSelectMapScreenshotDialog:
                        _vm.showSelectMapScreenshotDialog
                    },
                    on: {
                      "select-map-screenshot-dialog-close": function($event) {
                        _vm.showSelectMapScreenshotDialog = false
                      },
                      "screenshot-taken": _vm.onScreenShotTaken
                    }
                  })
                : _vm._e(),
              _vm.showOfflineDialog
                ? _c("OfflineDialog", {
                    attrs: { showOfflineDialog: _vm.showOfflineDialog },
                    on: {
                      "offline-dialog-close": function($event) {
                        _vm.showOfflineDialog = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: {
                    color: "#3F51B5",
                    disabled:
                      !(
                        _vm.uploadingImages ||
                        _vm.uploadingCameraImages ||
                        _vm.docsSelected
                      ) || _vm.resizingAndUploadingImages,
                    dark:
                      (_vm.uploadingImages ||
                        _vm.uploadingCameraImages ||
                        _vm.docsSelected) &&
                      !_vm.resizingAndUploadingImages
                  },
                  on: { click: _vm.resizeAndUploadImages }
                },
                [
                  _vm.resizingAndUploadingImages
                    ? _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: 15 }
                      })
                    : _vm._e(),
                  _vm._v(" Resize and Upload Images ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }