<template>
  <v-dialog :value="showAddPageToFormDialog" fullscreen persistent>
    <v-card color="#4F4F4F">
      <v-toolbar dark color="black" class="elevation-0">
        <v-toolbar-title>Select Page</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-page-to-form-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-3"
        style="height: calc(100vh - 150px); overflow-y: scroll"
      >
        <p class="white--text">Add this page to the form?</p>

        <img :src="selectedPage.s3_file_path" style="width: 100%" />
      </v-card-text>

      <v-card-actions>
        <div class="pa-3 d-flex justify-end align-center flex-grow-1">
          <v-btn
            color="#3F51B5"
            text
            @click="$emit('add-page-to-form-dialog-close')"
          >
            Cancel
          </v-btn>
          <v-btn color="#3F51B5" dark @click="confirmAddPage"> Add Page </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { axiosWithRegularAuth } from "@/plugins/axios";

const Compress = require("compress.js");
const compress = new Compress();
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddPageToFormDialog",
  props: {
    showAddPageToFormDialog: Boolean,
    selectedPage: Object,
    maxWidthHeight: Number,
    formResultId: String,
  },
  methods: {
    async confirmAddPage() {
      const { s3_file_path: url, original_file_name: fileName } =
        this.selectedPage;
      const { data: fileData } = await axiosWithRegularAuth.get(url, {
        responseType: "blob",
      });
      const { type: fileType } = fileData;
      const result = await compress.compress([fileData], {
        maxWidth: this.maxWidthHeight,
        maxHeight: this.maxWidthHeight,
        resize: true,
      });
      const [{ data, prefix }] = result;
      const dataUrl = `${prefix}${data}`;
      const res = await fetch(dataUrl);
      const buf = await res.arrayBuffer();
      const resizedImageFile = new File([buf], fileName, {
        type: fileType,
      });
      const formData = new FormData();
      formData.append("file", resizedImageFile);
      formData.append("file_name", fileName);
      formData.append("is_image", true);
      if (this.formResultId) {
        const {
          data: { file },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/form_results/${this.formResultId}/files`,
          formData
        );
        const image = {
          dataUrl,
          description: "",
          fileName,
          fileType,
          ...file,
          uploaded: true,
          isImage: true,
        };
        this.$emit("confirm-add-page", image);
      }
    },
  },
};
</script>
