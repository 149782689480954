var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.details
    ? _c(
        "div",
        { staticClass: "mx-4 my-2" },
        [
          _c("validation-observer", { ref: "graphicDetailsForm" }, [
            _c(
              "form",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Label" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  var valid = ref.valid
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        success: valid,
                                        label: "Label",
                                        color: "#3F51B5"
                                      },
                                      on: { change: _vm.onChange },
                                      model: {
                                        value: _vm.item.details.label,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.details,
                                            "label",
                                            $$v
                                          )
                                        },
                                        expression: "item.details.label"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4169716387
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Description" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  var valid = ref.valid
                                  return [
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        "error-messages": errors,
                                        success: valid,
                                        label: "Description",
                                        color: "#3F51B5"
                                      },
                                      on: { change: _vm.onChange },
                                      model: {
                                        value: _vm.item.details.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.details,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "item.details.description"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3970588297
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }