var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 20 20", width: "20", height: "20" } },
    [
      _c("polygon", {
        attrs: {
          points: "10 0, 20 8, 15 20, 5 20, 0 8",
          stroke: _vm.stroke,
          fill: _vm.fill,
          "stroke-width": _vm.strokeWidth
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }