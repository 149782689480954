<template>
  <v-dialog :value="showAddImageDialog" max-width="500px" persistent>
    <v-card class="d-flex flex-column" max-height="80vh" style="z-index: 9999">
      <v-toolbar dark color="#3F51B5" class="elevation-0 flex-grow-0">
        <v-toolbar-title>Add Image</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-photo-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3 flex-grow-1 overflow-y-auto">
        <div>Add image by selecting a source</div>

        <input
          type="file"
          accept="image/*"
          capture="user"
          class="d-none"
          ref="cameraInput"
          @change="onFileInputChange"
          name="cameraFileInput"
        />

        <div class="d-flex justify-center my-3">
          <v-btn
            text
            color="#3F51B5"
            @click="$refs.cameraInput.click()"
            :disabled="readOnly"
          >
            Camera
          </v-btn>
          <v-btn
            text
            color="#3F51B5"
            @click="showSelectDocDialog = true"
            :disabled="readOnly"
          >
            Docs
          </v-btn>
          <v-btn text color="#3F51B5" @click="onMapClick"> Map </v-btn>
        </div>

        <file-pond
          name="files"
          ref="files"
          label-idle="Drag & Drop or <span class='filepond--label-action'> Browse </span>"
          :allow-multiple="allowMultiple"
          accepted-file-types="image/jpeg, image/png"
          :files="uploadFiles"
          @addfile="onChange"
          allowFileSizeValidation
          maxFileSize="2MB"
          :disabled="readOnly"
          @click="uploadingImages = true"
        />

        <SelectDocDialog
          v-if="showSelectDocDialog"
          :showSelectDocDialog="showSelectDocDialog"
          :formDefinition="formDefinition"
          :maxWidthHeight="+maxWidthHeight"
          :formResultId="formResultId"
          :globalId="globalId"
          :objectId="objectId"
          @select-doc-dialog-close="showSelectDocDialog = false"
          @image-file-selected="onImageFileSelected"
        />

        <SelectMapScreenshotDialog
          v-if="showSelectMapScreenshotDialog"
          :showSelectMapScreenshotDialog="showSelectMapScreenshotDialog"
          @select-map-screenshot-dialog-close="
            showSelectMapScreenshotDialog = false
          "
          @screenshot-taken="onScreenShotTaken"
        />

        <OfflineDialog
          v-if="showOfflineDialog"
          :showOfflineDialog="showOfflineDialog"
          @offline-dialog-close="showOfflineDialog = false"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          color="#3F51B5"
          class="font-weight-regular"
          @click="resizeAndUploadImages"
          :disabled="
            !(uploadingImages || uploadingCameraImages || docsSelected) ||
            resizingAndUploadingImages
          "
          :dark="
            (uploadingImages || uploadingCameraImages || docsSelected) &&
            !resizingAndUploadingImages
          "
        >
          <v-progress-circular
            v-if="resizingAndUploadingImages"
            indeterminate
            :size="15"
          >
          </v-progress-circular>
          Resize and Upload Images
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { mdiDotsVertical, mdiDelete, mdiPencil, mdiClose } from "@mdi/js";
import SelectMapScreenshotDialog from "@/components/tickets/shared/photo-input/add-image-dialog/SelectMapScreenshotDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import OfflineDialog from "@/components/tickets/shared/photo-input/OfflineDialog";
import SelectDocDialog from "@/components/tickets/shared/photo-input/add-image-dialog/SelectDocDialog";
import { v4 as uuidv4 } from "uuid";
import heic2any from "heic2any";
import loadImage from "blueimp-load-image";
import sleep from "@/mixins/sleep";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond();
const APIURL = process.env.VUE_APP_API_URL;

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "AddImageDialog",
  props: {
    showAddImageDialog: Boolean,
    maxWidthHeight: Number,
    readOnly: Boolean,
    allowMultiple: Boolean,
    value: Array,
    formResultId: String,
    formDefinition: Object,
    selectedMapServiceId: String,
    item: Object,
    objectId: Number,
    globalId: String,
  },
  components: {
    FilePond,
    SelectMapScreenshotDialog,
    OfflineDialog,
    SelectDocDialog,
  },
  data() {
    return {
      mdiClose,
      uploadFiles: undefined,
      base64Images: [],
      showPhotoEditor: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedFile: {},
      showSelectMapScreenshotDialog: false,
      showMarkupImageDialog: {},
      uploadingImages: false,
      uploadingCameraImages: false,
      showOfflineDialog: true,
      showSelectDocDialog: false,
      resizingAndUploadingImages: false,
      docsSelected: false,
    };
  },
  methods: {
    onScreenShotTaken({ dataUrl }) {
      this.$refs.files.addFile(dataUrl);
      this.showSelectMapScreenshotDialog = false;
    },
    async resizeAndUploadImages() {
      this.resizingAndUploadingImages = true;
      if (this.uploadingImages) {
        const files = this.$refs.files.getFiles();
        for (const f of files) {
          this.$emit("start-photo-upload");
          const base64Image = await this.processImage(f.file);
          this.$emit("end-photo-upload");
          if (base64Image) {
            this.base64Images = [...this.base64Images, base64Image];
          }
          this.$refs.files.removeFile(f);
        }
        this.$emit("input", this.base64Images);
      }
      this.$emit("add-photo-dialog-close");
      this.resizingAndUploadingImages = false;
    },
    onMapClick() {
      this.showSelectMapScreenshotDialog = true;
    },
    onImageFileSelected(imageFile) {
      this.$refs.files.addFile(imageFile.dataUrl);
      this.showSelectDocDialog = false;
    },
    async createPayload(resultCanvas, fileName, fileType) {
      const dataUrl = resultCanvas.toDataURL("image/png");
      const blob = await new Promise((resolve) => {
        resultCanvas.toBlob(resolve, "image/png");
      });
      const resizedImageFile = new File([blob], fileName, {
        type: fileType,
      });
      const formData = new FormData();
      formData.append("file", resizedImageFile);
      formData.append("file_name", fileName);
      formData.append("is_image", true);
      return { formData, dataUrl };
    },
    async processImage(file) {
      try {
        const { name: fileName, type: fileType } = file;
        let result;

        await sleep(500);

        if (fileName.endsWith("heif") || fileName.endsWith("heic")) {
          const blobURL = URL.createObjectURL(file);
          const blobRes = await fetch(blobURL);
          const blob = await blobRes.blob();
          const conversionResult = await heic2any({
            blob,
          });
          const options = {
            orientation: true,
            maxWidth: this.maxWidthHeight,
            maxHeight: this.maxWidthHeight,
            canvas: true,
          };
          const { image } = await loadImage(conversionResult, options);
          result = image;
        } else {
          const options = {
            orientation: true,
            maxWidth: this.maxWidthHeight,
            maxHeight: this.maxWidthHeight,
            canvas: true,
          };
          try {
            const { image } = await loadImage(file, options);
            result = image;
          } catch (error) {
            console.log(error);
            return undefined;
          }
        }
        await sleep(500);
        const { formData, dataUrl } = await this.createPayload(
          result,
          fileName,
          fileType
        );
        if (result) {
          result.width = 1;
          result.height = 1;
          const ctx = result.getContext("2d");
          ctx?.clearRect?.(0, 0, 1, 1);
        }

        if (this.formResultId) {
          if (navigator.onLine) {
            const {
              data: { file },
            } = await axiosWithRegularAuth.post(
              `${APIURL}/form_results/${this.formResultId}/files`,
              formData
            );
            return {
              dataUrl,
              description: "",
              fileName,
              fileType,
              ...file,
              uploaded: true,
              isImage: true,
            };
          } else {
            return {
              dataUrl,
              description: "",
              fileName,
              fileType,
              id: uuidv4(),
              name: fileName,
              url: dataUrl,
              uploaded: false,
              isImage: true,
            };
          }
        } else {
          return {
            dataUrl,
            description: "",
            fileName,
            fileType,
            id: uuidv4(),
            name: fileName,
            url: dataUrl,
            uploaded: false,
            isImage: true,
          };
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    async onChange() {
      this.uploadingImages = true;
    },
    async removePhoto(index, imageId) {
      this.base64Images.splice(index, 1);
      if (navigator.onLine) {
        await axiosWithRegularAuth.delete(`${APIURL}/files/${imageId}`);
      }
      this.$emit("image-deleted", imageId);
    },
    async onFileInputChange(e) {
      this.$refs.files.addFile(...e.target.files);
    },
  },
};
</script>
