var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showEditSymbolDialog, width: "600px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Edit Symbol")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit-symbol-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "d-flex justify-center px-0 mx-0" },
            [
              _c(
                "div",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "ma-5",
                      attrs: {
                        color: "#EF6C00",
                        prominent: "",
                        icon: false,
                        type: "warning"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "pr-3" },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.mdiAlertCircleOutline) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c("div", [
                          _vm._v(
                            " Symbol are created at the organization level. Updating it will change how it appears on all markups in the organization. "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "validation-observer",
                    { ref: "form", attrs: { slim: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex pa-0 ma-0 justify-center settings"
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass:
                                "d-flex pa-0 ma-0 justify-center settings-row",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.saveSymbol.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "settings" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ma-0 pa-0 d-flex justify-space-between gap"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "pb-1 my-0 caption" },
                                          [_vm._v("Symbol")]
                                        ),
                                        _vm.symbolType ===
                                        _vm.SYMBOL_TYPES.SHAPE
                                          ? [
                                              _vm.shapeOptions.shape ===
                                              _vm.SHAPE_TYPES.MAP_SYMBOL
                                                ? _c("img", {
                                                    style: {
                                                      width:
                                                        _vm.shapeOptions.size +
                                                        "px",
                                                      height:
                                                        _vm.shapeOptions.size +
                                                        "px"
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/img/mapSymbols/" +
                                                        _vm.shapeOptions
                                                          .shapeImageFillColor +
                                                        _vm.shapeOptions
                                                          .shapeSymbol +
                                                        ".png")
                                                    }
                                                  })
                                                : _vm.shapeOptions.shape ===
                                                  _vm.SHAPE_TYPES.ELLIPSE
                                                ? _c("div", [
                                                    _c("div", {
                                                      style: {
                                                        width:
                                                          _vm.shapeOptions
                                                            .size + "px",
                                                        height:
                                                          _vm.shapeOptions
                                                            .size + "px",
                                                        "border-radius": "50%",
                                                        "background-color":
                                                          _vm.shapeOptions
                                                            .shapeFill,
                                                        border:
                                                          "1px solid " +
                                                          _vm.shapeOptions
                                                            .shapeOutlineColor
                                                      }
                                                    })
                                                  ])
                                                : _vm.shapeOptions.shape ===
                                                  _vm.SHAPE_TYPES.RECTANGLE
                                                ? _c("div", [
                                                    _c("div", {
                                                      style: {
                                                        width: "30px",
                                                        height: "25px",
                                                        "background-color":
                                                          _vm.shapeOptions
                                                            .shapeFill,
                                                        border:
                                                          "1px solid " +
                                                          _vm.shapeOptions
                                                            .shapeOutlineColor
                                                      }
                                                    })
                                                  ])
                                                : _vm.shapeOptions.shape ===
                                                  _vm.SHAPE_TYPES.POLYGON
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiVectorPolygon
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm.symbolType ===
                                            _vm.SYMBOL_TYPES.LINE
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-center",
                                                staticStyle: { height: "30px" }
                                              },
                                              [
                                                _c("div", {
                                                  style: {
                                                    height:
                                                      _vm.lineOptions
                                                        .lineStrokeWidth + "px",
                                                    width: "100%",
                                                    "background-color":
                                                      _vm.lineOptions.lineStroke
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm.symbolType ===
                                            _vm.SYMBOL_TYPES.IMAGE
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-center"
                                              },
                                              [
                                                _c("img", {
                                                  style: {
                                                    width:
                                                      _vm.imageOptions.size +
                                                      "px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.imageOptions.imageUrl
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm.symbolType ===
                                            _vm.SYMBOL_TYPES.POINT
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-center"
                                              },
                                              [
                                                _c("div", {
                                                  style: {
                                                    width:
                                                      _vm.pointOptions.size +
                                                      "px",
                                                    height:
                                                      _vm.pointOptions.size +
                                                      "px",
                                                    "border-radius": "50%",
                                                    "background-color":
                                                      _vm.pointOptions
                                                        .pointFill,
                                                    border:
                                                      "1px solid " +
                                                      _vm.pointOptions
                                                        .pointOutlineColor
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 flex-grow-1" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Label",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label: "Label",
                                                      color: "#3F51B5"
                                                    },
                                                    model: {
                                                      value: _vm.label,
                                                      callback: function($$v) {
                                                        _vm.label = $$v
                                                      },
                                                      expression: "label"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between gap"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mt-2",
                                        staticStyle: { width: "120px" }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.symbolTypeChoices,
                                            "item-text": "label",
                                            "item-value": "value",
                                            label: "Symbol Type"
                                          },
                                          model: {
                                            value: _vm.symbolType,
                                            callback: function($$v) {
                                              _vm.symbolType = $$v
                                            },
                                            expression: "symbolType"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.symbolType === _vm.SYMBOL_TYPES.SHAPE
                                      ? _c("ShapeForm", {
                                          attrs: {
                                            shapeOptionsObj: _vm.shapeOptions
                                          },
                                          on: {
                                            "shape-options-changed": function(
                                              $event
                                            ) {
                                              _vm.shapeOptions = Object.assign(
                                                {},
                                                _vm.shapeOptions,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.symbolType === _vm.SYMBOL_TYPES.LINE
                                      ? _c("LineForm", {
                                          attrs: {
                                            lineOptionsObj: _vm.lineOptions
                                          },
                                          on: {
                                            "line-options-changed": function(
                                              $event
                                            ) {
                                              _vm.lineOptions = Object.assign(
                                                {},
                                                _vm.lineOptions,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.symbolType === _vm.SYMBOL_TYPES.IMAGE
                                      ? _c("ImageForm", {
                                          attrs: {
                                            imageOptionsObj: _vm.imageOptions
                                          },
                                          on: {
                                            "image-options-changed": function(
                                              $event
                                            ) {
                                              _vm.imageOptions = Object.assign(
                                                {},
                                                _vm.imageOptions,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.symbolType === _vm.SYMBOL_TYPES.POINT
                                      ? _c("PointForm", {
                                          attrs: {
                                            pointOptionsObj: _vm.pointOptions
                                          },
                                          on: {
                                            "point-options-changed": function(
                                              $event
                                            ) {
                                              _vm.pointOptions = Object.assign(
                                                {},
                                                _vm.pointOptions,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end gap" },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { text: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mdiDotsVertical
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { staticClass: "px-0 mx-0" },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass:
                                                  "px-5 mx-0 d-flex align-center cursor-pointer",
                                                staticStyle: { gap: "10px" },
                                                on: {
                                                  click:
                                                    _vm.onMakeSymbolActivateToggleClick
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.deactivated
                                                          ? _vm.mdiEyeOff
                                                          : _vm.mdiEye
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(
                                                  " Make Symbol " +
                                                    _vm._s(
                                                      !_vm.deactivated
                                                        ? "Inactive"
                                                        : "Active"
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "#3F51B5",
                                          type: "submit",
                                          dark: ""
                                        }
                                      },
                                      [_vm._v(" Update Symbol ")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c("MakeSymbolInactiveDialog", {
                    attrs: {
                      showMakeSymbolInactiveDialog:
                        _vm.showMakeSymbolInactiveDialog
                    },
                    on: {
                      "cancel-symbol-inactive-dialog": function($event) {
                        _vm.showMakeSymbolInactiveDialog = false
                      },
                      "confirm-symbol-inactive-dialog": function($event) {
                        return _vm.toggleActivate()
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }