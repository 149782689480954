<template>
  <div
    v-if="itemInfo"
    @click="$emit('add-symbol', itemInfo)"
    class="px-4 py-2"
    style="width: 100%; box-sizing: border-box"
  >
    <div v-if="['image'].includes(itemInfo.symbol_type)">
      <div class="d-flex align-center">
        <div class="icon d-flex justify-center" v-if="viewSymbols">
          <div class="d-flex align-center">
            <img
              class="mr-2 mt-0"
              :style="{
                width: `${itemInfo.picture_size}px`,
                height: `${itemInfo.picture_size}px`,
              }"
              :src="
                itemInfoUrlStartsWithImg(itemInfo)
                  ? require(`@/assets${itemInfo.url}`)
                  : itemInfo.url
              "
            />
          </div>
        </div>
        <div>
          <div
            class="d-flex align-center text-truncate"
            v-if="itemInfo.label"
            :class="{ 'item-title': level > 0 }"
          >
            {{ itemInfo.label }}
          </div>
          <div
            class="d-flex align-center text-truncate"
            v-else-if="itemInfo.field_option_label"
            :class="{ 'item-title': level > 0 }"
          >
            {{ itemInfo.field_option_label }}
          </div>
          <div
            class="d-flex align-center text-truncate"
            v-else-if="itemInfo.field_option_value"
            :class="{ 'item-title': level > 0 }"
          >
            {{ itemInfo.field_option_value }}
          </div>
          <div
            class="d-flex align-center text-truncate"
            v-else-if="layer.utiliSyncLayerName"
            :class="{ 'item-title': level > 0 }"
          >
            {{ layer.utiliSyncLayerName }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="['basic'].includes(itemInfo.symbol_type)">
      <div class="d-flex align-center">
        <div
          class="mr-2"
          :style="{
            width: `${itemInfo.picture_size}px`,
            height: `${itemInfo.picture_size}px`,
            'background-color': `${itemInfo.fill_color}`,
            'border-radius': '50%',
          }"
        ></div>
        <div
          class="d-flex align-center text-truncate"
          v-if="itemInfo.label"
          :class="{ 'item-title': level > 0 }"
        >
          {{ itemInfo.label }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="icon" v-show="viewSymbols">X</div>
        <p v-if="itemInfo.label">{{ itemInfo.label }}</p>
        <p v-else-if="itemInfo.field_option_label">
          {{ itemInfo.field_option_label }}
        </p>
        <p v-else-if="itemInfo.field_option_value">
          {{ itemInfo.field_option_value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilisyncRendererItem",
  props: {
    itemInfo: Object,
    viewSymbols: Boolean,
    level: Number,
    layer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    itemInfoUrlStartsWithImg(itemInfo) {
      return itemInfo?.url?.startsWith?.("/img/");
    },
  },
};
</script>

<style scoped>
.icon {
  width: 40px;
}
</style>
