<template>
  <div>
    <validation-provider
      v-slot="{ errors, valid, validated }"
      :name="item.question.label"
      :rules="{ required: isRequired }"
      ref="field"
    >
      <v-card class="elevation-0" width="100%">
        <v-card-title
          class="ma-0 pa-0 d-flex flex-row justify-space-between elevation-0"
        >
          <p class="caption">{{ item.question.label }}</p>
          <v-btn
            text
            @click="clearSignature"
            class="mt-n3"
            :disabled="readOnly"
            color="#3F51B5"
          >
            Clear
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 mb-2">
          <VueSignaturePad
            width="100%"
            height="200px"
            ref="signaturePad"
            :options="{ onBegin, onEnd }"
            :customStyle="{
              border: `3px solid ${validated && !valid ? '#ff5252' : 'black'}`,
            }"
          />
          <p
            class="caption"
            v-if="!valid"
            :style="{ color: validated && !valid ? '#ff5252' : undefined }"
          >
            {{ errors[0] }}
          </p>
        </v-card-text>
      </v-card>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "GroupSignaturePad",
  props: {
    value: String,
    item: Object,
    readOnly: Boolean,
  },
  computed: {
    isRequired() {
      return this.item.question.required.condition === "ALWAYS";
    },
  },
  methods: {
    async validateField(data) {
      await this.$nextTick();
      const { valid } = await this.$refs.field.validate(data);
      const { item } = this;
      const { id, number, question } = item;
      this.$emit("validated", {
        valid,
        label: `${number} ${question.label}`,
        id,
      });
    },
    async onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    async onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.$emit("input", data);
      }
      await this.validateField(data);
    },
    async clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.$emit("input", "");
      await this.validateField("");
    },
  },
  watch: {
    isRequired() {
      this.validateField();
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      this.$refs.signaturePad?.resizeCanvas();
      this.$refs.signaturePad?.fromDataURL(this.value);
      if (this.readOnly) {
        this.$refs.signaturePad?.lockSignaturePad();
      }
    });
    resizeObserver.observe(document.body);
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff8e1;
}

#read-only-box {
  width: 100%;
  border: 3px black solid;
  height: 200px;
}
</style>
