var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemType === "HEADER"
        ? _c("h2", [_vm._v(_vm._s(_vm.item.information.label))])
        : _vm.itemType === "URL"
        ? _c(
            "a",
            { attrs: { href: _vm.item.information.url, target: "_blank" } },
            [
              _vm.item.information.label
                ? _c("div", [
                    _vm._v(" " + _vm._s(_vm.item.information.label) + " ")
                  ])
                : _c("div", [_vm._v(_vm._s(_vm.item.information.url))])
            ]
          )
        : _vm.itemType === "TEXT"
        ? _c("div", {
            staticClass: "ql-editor",
            domProps: { innerHTML: _vm._s(_vm.item.information.label) }
          })
        : _vm.itemType === "IMAGE"
        ? _c(
            "v-card",
            {
              staticClass: "pa-2",
              attrs: { "max-height": "fit-content", "max-width": "fit-content" }
            },
            [
              _c("v-card-text", [
                _c("p", [_vm._v(_vm._s(_vm.item.information.label))]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.item.information.openUrl,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      style: {
                        "max-height": "500px",
                        "max-width": "500px"
                      },
                      attrs: { src: _vm.item.information.url }
                    })
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }