<template>
  <v-dialog :value="showDeleteFormDialog" max-width="320px">
    <v-card>
      <v-card-text class="pa-5">
        <p>Delete form?</p>
        <p>This is permanent and cannot be undone.</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="$emit('cancel-delete-form')">
          <span class="font-weight-regular">Cancel</span>
        </v-btn>
        <v-btn
          text
          color="#3F51B5"
          class="font-weight-regular"
          @click="$emit('delete-form')"
        >
          <span class="font-weight-regular">Delete Form</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteFormDialog",
  props: {
    showDeleteFormDialog: Boolean,
  },
};
</script>
