var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showMeasurementWidget,
          expression: "showMeasurementWidget"
        },
        { name: "resize", rawName: "v-resize" }
      ],
      attrs: { width: "300px" }
    },
    [
      _c("v-card-text", [
        _c("div", { staticClass: "d-flex justify-space-between" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  class: { whiteText: _vm.selectedMeasurement === "distance" },
                  attrs: {
                    depressed: "",
                    text: _vm.selectedMeasurement !== "distance",
                    color:
                      _vm.selectedMeasurement === "distance"
                        ? "#3F51B5"
                        : undefined
                  },
                  on: { click: _vm.distanceMeasurement }
                },
                [_vm._v("Line")]
              ),
              _c(
                "v-btn",
                {
                  class: { whiteText: _vm.selectedMeasurement === "area" },
                  attrs: {
                    depressed: "",
                    text: _vm.selectedMeasurement !== "area",
                    color:
                      _vm.selectedMeasurement === "area" ? "#3F51B5" : undefined
                  },
                  on: { click: _vm.areaMeasurement }
                },
                [_vm._v("Area")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.measurement.clear()
                      _vm.showMeasurementWidget = false
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            ref: "measurementContainer",
            attrs: { id: "measurement-container" }
          },
          [
            _vm.loading
              ? _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c("v-card-text", [
                      _c("div", { staticClass: "d-flex justify-center" }, [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _vm._v("Loading...")
                        ])
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }