<template>
  <v-card flat id="layers-card" v-resize height="350px" class="px-3">
    <v-list>
      <v-list-item class="ma-0 pa-0">
        <v-list-item-content class="pa-0 ma-0">
          <div class="d-flex justify-space-between">
            <div class="mt-2">Layers</div>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="viewSymbols = !viewSymbols">
                  <v-list-item-title>
                    {{ viewSymbols ? "Hide" : "Show" }} Symbols
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <Visibility
      :layers="layers"
      :viewSymbols="viewSymbols"
      :level="0"
      :allLayers="allLayers"
      :multiLayerImages="multiLayerImages"
    />
  </v-card>
</template>

<script>
import Visibility from "@/components/mapView/layers/Visibility";
import { mdiDotsVertical } from "@mdi/js";
import { axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";

export default {
  name: "Layers",
  components: {
    Visibility,
  },
  props: {
    map: Object,
    mapId: String,
    allLayers: Array,
  },
  data() {
    return {
      layers: [],
      viewSymbols: true,
      mdiDotsVertical,
      multiLayerImages: [],
    };
  },
  async mounted() {
    if (this.map) {
      this.layers = [...this.map.layers._items].reverse();
      await this.getAllIcons();
    }
  },
  methods: {
    async getAllIcons() {
      const mapServices = await db.mapServices.toCollection().toArray();
      this.multiLayerImages = await Promise.all(
        this.layers
          .filter((layer) => layer.url)
          .map(async (layer) => {
            try {
              const { url, mapServiceId } = layer;
              const mapService = mapServices.find(
                (ms) => ms.map_service_id === mapServiceId
              );
              const securedService = mapService?.token_type !== "NONE";
              const { data } = await axiosWithNoAuth.get(`${url}/legend`, {
                params: {
                  token: securedService
                    ? localStorage.getItem("esri_token")
                    : undefined,
                  f: "pjson",
                },
                transformRequest: (data, headers) => {
                  delete headers["X-AUTH"];
                  return data;
                },
              });
              return { layer, ...data };
            } catch (error) {
              return { layer };
            }
          })
      );
    },
  },
  directives: {
    resize: {
      inserted(el) {
        const mutationObserver = new MutationObserver(() => {
          const viewDiv = document.querySelector("#viewDiv");
          if (!viewDiv) {
            return;
          }
          const resizeObserver = new ResizeObserver(() => {
            setTimeout(() => {
              el.style.height = `${Math.min(
                el.scrollHeight,
                viewDiv.clientHeight - 25
              )}px`;
            });
          });
          resizeObserver.observe(viewDiv);
        });
        mutationObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      },
    },
  },
};
</script>

<style scoped>
#layers-card {
  overflow-y: auto;
}
</style>
