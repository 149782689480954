var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "esri-widget--button esri-interactive esri-icon-measure-line",
    on: { click: _vm.openMeasurementWidget }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }