import { cloneDeep } from "lodash";
import moment from "moment";

export const convertEmailsIntoString = (item) => {
  if (Array.isArray(item.children)) {
    return [item.value, ...item.children.map((c) => c.value)].join(",");
  } else {
    return item.value;
  }
};

export const convertDateToUnixFormat = (humanReadableDate) => {
  return moment(humanReadableDate).unix() * 1000;
};

export const getGisRelatedTableFieldsToUpdate = (formResult) => {
  const items = formResult.form_values.sections.map((s) => s.items).flat();
  return (
    formResult?.form?.relatedTable?.fields
      ?.filter((field) => {
        return (
          field?.selectedQuestionId &&
          field?.selectedQuestionId !== null &&
          field?.name
        );
      })
      ?.map((field) => {
        let value;
        const { selectedQuestionId, type } = field;
        const item = items.find((item) => +item.id === +selectedQuestionId);
        const oldValue = item?.value;

        if (type === "esriFieldTypeDate") {
          value = convertEmailsIntoString(item);
        }
        if (typeof value === "string") {
          value = oldValue.replace(/(<([^>]+)>)/gi, "");
        } else {
          value = oldValue;
        }
        return [field?.name, value];
      }) ?? []
  );
};

export const getUpdateGisMainTable = (formResult, formResultId, feature) => {
  const formResultTaskMessage = [];
  const formResultTaskMessageErrors = [];
  try {
    const updatedFeature = cloneDeep(feature);
    const formResultClone = cloneDeep(formResult);
    const formValues = formResultClone.form_values.sections
      .map((s) => s.items)
      .flat();
    for (const section of formResultClone.form.sections) {
      for (const item of section.items) {
        if (item.type === "QUESTION") {
          item.value = formValues.find((f) => +f.id === +item.id)?.value;
        }
      }
    }

    for (const section of formResultClone.form.sections) {
      for (const item of section.items) {
        if (item.type === "QUESTION" && item.question?.updateEsriObject) {
          const { value: oldValue, question } = item;
          if (oldValue) {
            if (question.updateEsriObject in updatedFeature?.attributes ?? {}) {
              let updateToVal = oldValue;
              if (question.type === "DATE") {
                updateToVal = convertDateToUnixFormat(oldValue);
              } else if (question.type === "EMAIL") {
                updateToVal = convertEmailsIntoString(item);
              }

              if (typeof value === "string") {
                updatedFeature.attributes[question.updateEsriObject] =
                  updateToVal.replace(/(<([^>]+)>)/gi, "");
              } else {
                updatedFeature.attributes[question.updateEsriObject] =
                  updateToVal;
              }

              if (question.type === "DATE") {
                updateToVal = moment(oldValue).format("MM/DD/YY");
              } else {
                updateToVal =
                  updatedFeature.attributes[question.updateEsriObject];
              }
              formResultTaskMessage.push(
                `"${item.question.updateEsriObject}" Updated To "${updateToVal}".`
              );
            } else {
              formResultTaskMessageErrors.push(
                `Could not update Field: "${item.question.updateEsriObject}", it does not exist.`
              );
            }
          }
        }
      }
    }
    return {
      updatedFeature,
      formResultTaskMessage,
      formResultTaskMessageErrors,
    };
  } catch (err) {
    console.log(err);
    const errMessage = err.message ?? "Unspecified error";
    formResultTaskMessageErrors.push(
      "Error when trying to configure updateGisMainTable: " + errMessage
    );
    return { formResultTaskMessageErrors, formResultTaskMessage };
  }
};
