var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showPdfDialog, fullscreen: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column",
          staticStyle: { "overflow-x": "hidden" }
        },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0 flex-grow-0",
              attrs: {
                dark: "",
                width: "100%",
                color: "#3F51B5",
                height: "56px"
              }
            },
            [
              _c("div", [_vm._v("PDF Viewer")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("pdf-viewer-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0 ma-0 d-flex flex-column flex-grow-1" },
            [
              _vm.showPdfDialog
                ? _c("PdfViewer", {
                    staticClass: "flex-grow-1",
                    staticStyle: {
                      width: "100%",
                      height: "calc(100vh - 130px)"
                    },
                    attrs: { src: _vm.fileUrl }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end flex-grow-0" },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.downloadFile(_vm.selectedPdfFileUrl)
                    }
                  }
                },
                [_vm._v(" Download ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }