<template>
  <div>
    <h2 v-if="itemType === 'HEADER'">{{ item.information.label }}</h2>
    <a
      v-else-if="itemType === 'URL'"
      :href="item.information.url"
      target="_blank"
    >
      <div v-if="item.information.label">
        {{ item.information.label }}
      </div>
      <div v-else>{{ item.information.url }}</div>
    </a>
    <div
      v-else-if="itemType === 'TEXT'"
      class="ql-editor"
      v-html="item.information.label"
    ></div>
    <v-card
      v-else-if="itemType === 'IMAGE'"
      max-height="fit-content"
      max-width="fit-content"
      class="pa-2"
    >
      <v-card-text>
        <p>{{ item.information.label }}</p>
        <a :href="item.information.openUrl" target="_blank">
          <img
            :src="item.information.url"
            :style="{
              'max-height': '500px',
              'max-width': '500px',
            }"
          />
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "InformationItem",
  props: {
    item: Object,
  },
  computed: {
    itemType() {
      return this.item?.information?.type;
    },
  },
};
</script>

<style scoped>
.ql-editor {
  margin: 0px;
  padding: 0px;
}
</style>
