<template>
  <div :id="viewDivId" v-basemap-resize class="flex-grow-1"></div>
</template>

<script>
import { loadModules } from "esri-loader";
import mapLayerMixin from "@/mixins/mapLayerMixin";
import Layers from "@/components/mapView/Layers.vue";
import MeasureButton from "@/components/mapView/MeasureButton.vue";
import SelectButton from "@/components/mapView/esri-map-view/SelectButton.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import { axiosWithRegularAuth } from "@/plugins/axios";

const LayersClass = Vue.extend(Layers);
const MeasureButtonClass = Vue.extend(MeasureButton);
const SelectButtonClass = Vue.extend(SelectButton);
const vuetify = new Vuetify();
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "MapScreenshotDialogEsriMapView",
  data() {
    return {
      view: undefined,
      measurementWidgetComponent: undefined,
      map: undefined,
      selectBtn: undefined,
      layerListExpand: undefined,
      bgExpand: undefined,
      measureButton: undefined,
      magnifyBtn: undefined,
    };
  },
  props: {
    selectOnMap: Boolean,
    viewDivId: {
      type: String,
      default: "selectorMapDiv",
    },
    mapIdSelected: String,
  },

  mixins: [mapLayerMixin],
  beforeDestroy() {
    if (this.view) {
      this.view.destroy();
    }
  },
  methods: {
    moveMagnifyingGlass() {
      const [width, height] = this.view.size;
      const x = width / 2;
      const y = height / 2;
      this.view.magnifier.position = { x, y };
    },
    moveSelectButton() {
      const [width, height] = this.view.size;
      this.selectBtn.style.left = `${width / 2 - 45}px`;
      this.selectBtn.style.top = `${height / 2 + 60}px`;
    },
    minimizeTicketList() {
      this.$emit("minimize-ticket-list");
    },
    async getLayersInMap(mapId) {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const params = {
        map_id: mapId,
        user_group_id: userGroupId,
      };

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services/inmap`, {
        params,
      });
      return results;
    },
    async renderMap() {
      if (this.view?.ui) {
        this.view.ui.empty("top-left");
        this.view.ui.empty("top-right");
        this.view.ui.empty("bottom-left");
        this.view.ui.empty("bottom-right");
      }
      const [
        Map,
        MapView,
        reactiveUtils,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        Track,
        BasemapGallery,
        Expand,
        Extent,
        esriConfig,
        Search,
      ] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/core/reactiveUtils",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/widgets/Track",
        "esri/widgets/BasemapGallery",
        "esri/widgets/Expand",
        "esri/geometry/Extent",
        "esri/config",
        "esri/widgets/Search",
      ]);
      let defaultCenter = {};
      try {
        const centerString = localStorage.getItem("default-center");
        if (centerString) {
          defaultCenter = JSON.parse(centerString);
        } else {
          defaultCenter = {};
        }
      } catch (error) {
        defaultCenter = {};
      }
      const {
        data: { results: msResults },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services`);
      const mapServices = msResults;
      if (!this.mapIdSelected) {
        return;
      }
      const {
        data: { results: mapResult },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/maps/${this.mapIdSelected}`
      );
      const {
        map_id: mapId,
        center_x_coord: x,
        center_y_coord: y,
        wkid,
        zoom_level: zoomLevel = 20,
      } = mapResult;
      const basemapPortaId = localStorage.getItem("basemap-portal-id");
      const map = new Map({
        basemap: basemapPortaId
          ? {
              portalItem: {
                id: basemapPortaId,
              },
            }
          : "streets-vector",
      });
      this.map = map;
      const { latitude = 0, longitude = 0 } = defaultCenter;
      this.view = new MapView({
        container: this.viewDivId,
        map,
        center: [longitude, latitude],
        zoom: zoomLevel,
        popup: {
          autoOpenEnabled: false,
        },
      });
      this.view.ui.remove("zoom");
      this.view.center = new Point({
        x,
        y,
        spatialReference: {
          wkid,
        },
      });
      this.view.zoom = zoomLevel;
      let mapExtent;
      try {
        const extent = JSON.parse(localStorage.getItem("view-extent"));
        mapExtent = new Extent(extent);
      } catch (error) {
        mapExtent = new Extent({
          xmin: x,
          ymin: y,
          xmax: x,
          ymax: y,
          spatialReference: {
            wkid,
          },
        });
      }
      this.view.extent = mapExtent;
      const layersArr = await this.getLayersInMap(mapId);
      const layersVisibility = layersArr.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });

      const { map_services: layers = [] } = mapResult;
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        esriConfig,
        config: {
          mapConfig: {
            map_services: mapServices,
          },
          loadedLayers: [],
          layers,
        },
        layersVisibility,
      });
      map.watch("basemap", (evt) => {
        localStorage.setItem("basemap-portal-id", evt.portalItem.id);
      });

      reactiveUtils.watch(
        () => this.view?.stationary,
        () => {
          if (this.view.extent) {
            localStorage.setItem(
              "view-extent",
              JSON.stringify(this.view.extent)
            );
          }
        }
      );
      const { view, minimizeTicketList } = this;
      const trackWidget = new Track({
        view,
        id: "track",
      });
      const basemapGallery = new BasemapGallery({
        view,
      });
      const bgExpand = new Expand({
        view,
        content: basemapGallery,
      });
      const homeBtn = document.createElement("div");
      homeBtn.className =
        "esri-icon-home esri-widget--button esri-widget esri-interactive";
      homeBtn.addEventListener("click", async () => {
        const {
          data: { results: newMapData },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/maps/${this.mapIdSelected}`
        );
        const {
          center_x_coord: centerXCoord,
          center_y_coord: centerYCoord,
          wkid,
          zoom_level: zoomLevel,
        } = newMapData;
        const finalWkid = typeof wkid === "number" ? wkid : 102100;
        const mapCenter = new Point(
          centerXCoord,
          centerYCoord,
          new SpatialReference({ wkid: finalWkid })
        );
        this.view.center = mapCenter;
        this.view.zoom = zoomLevel;
      });
      const searchBox = new Search({
        view,
      });
      const searchWidget = new Expand({
        expandIconClass: "esri-icon-search",
        view,
        content: searchBox,
        mode: "floating",
        id: "searchWidget",
      });

      this.selectBtn = new SelectButtonClass({ vuetify }).$mount().$el;
      this.selectBtn.id = "selectBtn";
      this.selectBtn.addEventListener("click", async () => {
        const [width, height] = this.view.size;
        const x = width / 2;
        const y = height / 2;
        const resp = await view.popup.fetchFeatures({ x, y });
        const results = await resp.allGraphicsPromise;
        if (Array.isArray(results)) {
          if (results.length === 0) {
            this.$emit("pointer-clicked-outside");
            return;
          }
          const popupResults = results.filter((r) => {
            const { layer, geometry } = r;
            return (
              geometry &&
              (layer?.fields ||
                layer?.mapServiceId ||
                layer?.utiliSyncLayerType === "U")
            );
          });
          this.$emit("pointer-down", popupResults);
        }
      });
      this.moveSelectButton();
      this.view.magnifier.visible = false;
      const magnifyBtn = document.createElement("div");
      magnifyBtn.className =
        "esri-icon-zoom-in-magnifying-glass esri-widget--button esri-widget esri-interactive";
      magnifyBtn.addEventListener("click", () => {
        this.view.magnifier.visible = !this.view.magnifier.visible;
        if (this.view.magnifier.visible) {
          this.view.ui.add(this.selectBtn);
        } else {
          this.view.ui.remove(this.selectBtn);
        }

        this.view.on("resize", () => {
          this.moveMagnifyingGlass();
          this.moveSelectButton();
        });
        this.moveMagnifyingGlass();
        this.moveSelectButton();
      });

      const measureButtonComponent = new MeasureButtonClass({
        propsData: { view, minimizeTicketList },
        vuetify,
      });
      measureButtonComponent.$mount();
      await Promise.all(
        map.layers.items.map((lv) => {
          return Promise.race([
            reactiveUtils.whenOnce(() => lv?.loadStatus === "loaded"),
            reactiveUtils.whenOnce(() => lv?.loadStatus === "failed"),
          ]);
        })
      );
      if (!this.view.ui?.find("search")) {
        this.view.ui?.add(searchWidget, "top-left");
      }
      if (!this.view.ui?.find("zoom")) {
        this.view.ui?.add("zoom", "top-left");
      }
      if (!this.view.ui?.find("home-btn")) {
        this.view.ui?.add(homeBtn, "top-left");
      }
      if (!this.view.ui?.find("track")) {
        this.view.ui.add(trackWidget, "top-left");
      }
      const allLayers = await this.getLayersInMap(mapId);

      const layersComponent = new LayersClass({
        propsData: { map, mapId, allLayers },
        vuetify,
      });
      layersComponent.$mount();
      const layerListExpand = new Expand({
        expandIconClass: "esri-icon-layers",
        view,
        content: layersComponent.$el,
      });
      if (this.view.ui?.find("layers-expand")) {
        this.view.ui?.remove("layers-expand");
      }
      this.view.ui?.add(layerListExpand, "top-right");
      if (this.view.ui?.find("bg-expand")) {
        this.view.ui?.remove("bg-expand");
      }
      this.view.ui?.add(bgExpand, "top-right");
      if (this.view.ui?.find("measure-button")) {
        this.view.ui?.remove("measure-button");
      }
      this.view.ui?.add(measureButtonComponent.$el, "top-right");

      if (this.view.ui?.find("magnify-button")) {
        this.view.ui?.remove("magnify-button");
      }
      this.view.ui?.add(magnifyBtn, "top-right");

      this.view.on("click", async (event) => {
        // Use popup to select features on the map. Only features with popupTemplate enabled will be selected
        const resp = await view.popup.fetchFeatures(event);
        const results = await resp.allGraphicsPromise;
        if (Array.isArray(results)) {
          if (results.length === 0) {
            this.$emit("pointer-clicked-outside");
            return;
          }
          const popupResults = results.filter((r) => {
            const { layer, attributes, geometry } = r;
            return (
              geometry &&
              (layer?.mapServiceId ||
                layer?.utiliSyncLayerType === "U" ||
                (typeof attributes === "object" && attributes !== null))
            );
          });
          this.$emit("pointer-down", popupResults);
        }
      });
      let extentGraphic = null;
      let origin = null;
      const fillSymbol = {
        type: "simple-fill",
        color: [227, 139, 79, 0.8],
        outline: {
          color: [255, 255, 255],
          width: 1,
        },
      };

      this.view.on("drag", (e) => {
        if (!this.selectOnMap) {
          return;
        }
        e.stopPropagation();
        if (e.action === "start") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          origin = view.toMap(e);
        } else if (e.action === "update") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          const p = view.toMap(e);
          const coords = {
            xmin: Math.min(p.x, origin.x),
            xmax: Math.max(p.x, origin.x),
            ymin: Math.min(p.y, origin.y),
            ymax: Math.max(p.y, origin.y),
          };
          extentGraphic = new Graphic({
            geometry: new Extent({
              ...coords,
              spatialReference: { wkid: 102100 },
            }),
            symbol: fillSymbol,
          });
          const { xmin, xmax, ymin, ymax } = coords;
          const [lonMin, latMin] = webMercatorUtils.xyToLngLat(xmin, ymin);
          const [lonMax, latMax] = webMercatorUtils.xyToLngLat(xmax, ymax);
          view.graphics.add(extentGraphic);
          this.$router.push({
            path: "/map",
            query: {
              ...this.$route.query,
              lonMin,
              latMin,
              lonMax,
              latMax,
              timestamp: +new Date(),
            },
          });
        } else if (e.action === "end") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          const p = view.toMap(e);
          const coords = {
            xmin: Math.min(p.x, origin.x),
            xmax: Math.max(p.x, origin.x),
            ymin: Math.min(p.y, origin.y),
            ymax: Math.max(p.y, origin.y),
          };
          extentGraphic = new Graphic({
            geometry: new Extent({
              ...coords,
              spatialReference: { wkid: 102100 },
            }),
            symbol: fillSymbol,
          });
          const { xmin, xmax, ymin, ymax } = coords;
          const [lonMin, latMin] = webMercatorUtils.xyToLngLat(xmin, ymin);
          const [lonMax, latMax] = webMercatorUtils.xyToLngLat(xmax, ymax);
          view.graphics.add(extentGraphic);
          this.$router.push({
            path: "/map",
            query: {
              ...this.$route.query,
              lonMin,
              latMin,
              lonMax,
              latMax,
              timestamp: +new Date(),
            },
          });
          this.$emit("dragged-extent", { coords, extentGraphic });
        }
      });
      this.$emit("map-created", { map, view });
    },
    async reloadMap(mapId) {
      if (!mapId) {
        return;
      }

      if (!this.map) {
        this.renderMap();
        return;
      }
      const [
        reactiveUtils,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        esriConfig,
      ] = await loadModules([
        "esri/core/reactiveUtils",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/config",
      ]);
      const {
        data: { results: msResults },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services`);
      const mapServices = msResults;
      if (!mapId) {
        return;
      }
      const {
        data: { results: mapResult },
      } = await axiosWithRegularAuth.get(`${APIURL}/maps/${mapId}`);
      const { map_services: layers = [] } = mapResult;
      const { map } = this;
      const layersArr = await this.getLayersInMap(mapId);
      const layersVisibility = layersArr.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });
      map.removeAll();
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        reactiveUtils,
        PopupTemplate,
        esriConfig,
        config: {
          mapConfig: {
            map_services: mapServices,
          },
          loadedLayers: [],
          layers,
        },
        layersVisibility,
      });
    },
    async reloadWidgets() {
      const [Expand, BasemapGallery, reactiveUtils] = await loadModules([
        "esri/widgets/Expand",
        "esri/widgets/BasemapGallery",
        "esri/core/reactiveUtils",
      ]);
      if (this.view?.ui) {
        this.view.ui.empty("top-right");
      }
      const { map, view, mapIdSelected } = this;
      if (!map) {
        return;
      }
      const allLayers = await this.getLayersInMap(mapIdSelected);
      await Promise.all(
        map.layers.items.map((lv) => {
          return Promise.race([
            reactiveUtils.whenEqualOnce(lv, "loadStatus", "loaded"),
            reactiveUtils.whenEqualOnce(lv, "loadStatus", "failed"),
          ]);
        })
      );
      const layersComponent = new LayersClass({
        propsData: { map, mapId: mapIdSelected, allLayers },
        vuetify,
      });
      layersComponent.$mount();
      const basemapGallery = new BasemapGallery({
        view,
      });
      const layerListExpand = new Expand({
        expandIconClass: "esri-icon-layers",
        view,
        content: layersComponent.$el,
        id: "bg-expand",
      });
      const bgExpand = new Expand({
        view,
        content: basemapGallery,
        id: "layers-expand",
      });
      const { minimizeTicketList } = this;
      const measureButtonComponent = new MeasureButtonClass({
        propsData: { view, minimizeTicketList },
        vuetify,
        id: "measure-button",
      });
      const magnifyBtn = document.createElement("div");
      magnifyBtn.id = "magnify-button";
      magnifyBtn.className =
        "esri-icon-zoom-in-magnifying-glass esri-widget--button esri-widget esri-interactive";
      magnifyBtn.addEventListener("click", () => {
        this.view.magnifier.visible = !this.view.magnifier.visible;
        if (this.view.magnifier.visible) {
          this.view.ui.add(this.selectBtn);
        } else {
          this.view.ui.remove(this.selectBtn);
        }

        this.view.on("resize", () => {
          this.moveMagnifyingGlass();
          this.moveSelectButton();
        });
        this.moveMagnifyingGlass();
        this.moveSelectButton();
      });
      measureButtonComponent.$mount();
      if (this.view.ui?.find("layers-expand")) {
        this.view.ui?.remove("layers-expand");
      }
      this.view.ui?.add(layerListExpand, "top-right");
      if (this.view.ui?.find("bg-expand")) {
        this.view.ui?.remove("bg-expand");
      }
      this.view.ui?.add(bgExpand, "top-right");
      if (this.view.ui?.find("measure-button")) {
        this.view.ui?.remove("measure-button");
      }
      this.view.ui?.add(measureButtonComponent.$el, "top-right");

      if (this.view.ui?.find("magnify-button")) {
        this.view.ui?.remove("magnify-button");
      }
      this.view.ui?.add(magnifyBtn, "top-right");

      if (this.view.ui?.find("feature-button")) {
        this.view.ui?.remove("feature-button");
      }
      this.view.ui?.add(this.featureBtn, "top-right");
    },
  },
  directives: {
    basemapResize: {
      inserted(el) {
        const mutationObserver = new MutationObserver(() => {
          const resizeObserver = new ResizeObserver(() => {
            const basemapGallery = document.querySelector(
              ".esri-basemap-gallery"
            );
            if (!basemapGallery) {
              return;
            }
            setTimeout(() => {
              basemapGallery.style.maxHeight = "unset";
              basemapGallery.style.height = `${Math.min(
                basemapGallery.scrollHeight,
                el.clientHeight - 60
              )}px`;
            });
          });
          resizeObserver.observe(el);
        });
        mutationObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      },
    },
  },
  watch: {
    async mapIdSelected(val) {
      await this.reloadMap(val);
      await this.reloadWidgets();
    },
  },
  async mounted(val) {
    await this.reloadMap(val);
    await this.reloadWidgets();
  },
};
</script>

<style scoped>
#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>
