import { render, staticRenderFns } from "./InformationItem.vue?vue&type=template&id=7b65384d&scoped=true&"
import script from "./InformationItem.vue?vue&type=script&lang=js&"
export * from "./InformationItem.vue?vue&type=script&lang=js&"
import style0 from "./InformationItem.vue?vue&type=style&index=0&id=7b65384d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b65384d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b65384d')) {
      api.createRecord('7b65384d', component.options)
    } else {
      api.reload('7b65384d', component.options)
    }
    module.hot.accept("./InformationItem.vue?vue&type=template&id=7b65384d&scoped=true&", function () {
      api.rerender('7b65384d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tickets/shared/InformationItem.vue"
export default component.exports