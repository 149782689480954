<template>
  <v-card class="elevation-0" width="100%">
    <v-card-text class="pa-0 ma-0 mb-2">
      <v-row>
        <template v-if="item.question.isImage">
          <v-col cols="12" class="my-0 py-2">
            <p class="caption mb-0">
              {{ `${item.number} ${item.question.label}` }}
            </p>
            <p class="caption mb-0">{{ item.question.placeholder }}</p>

            <validation-provider
              slim
              v-slot="{ errors, valid }"
              :name="item.question.label"
              ref="field"
            >
              <v-checkbox
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                v-model="selectedValues"
                @change="onInputChange"
                :error-messages="errors"
                :success="valid"
                :disabled="isDisabled"
              >
                <template v-slot:label>
                  <img
                    :src="option.value"
                    :style="{ width: 'auto', height: '64px' }"
                  />
                </template>
              </v-checkbox>
            </validation-provider>
          </v-col>
        </template>
        <template v-else>
          <template v-if="item.question.showAllOptions">
            <v-col cols="12" class="my-0 py-2">
              <p class="caption">
                {{ `${item.number} ${item.question.label}` }}
              </p>
              <p class="caption mb-0">{{ item.question.placeholder }}</p>

              <validation-provider
                slim
                v-slot="{ errors, valid }"
                :name="item.question.label"
                ref="field"
              >
                <v-checkbox
                  v-for="option in options"
                  :key="option.id"
                  :value="option.value"
                  :label="option.label"
                  v-model="selectedValues"
                  @change="onInputChange"
                  :error-messages="errors"
                  :success="valid"
                  :disabled="isDisabled"
                  hide-details
                >
                </v-checkbox>
              </validation-provider>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="my-0 py-0 select">
              <validation-provider
                slim
                v-slot="{ errors, valid }"
                :name="item.question.label"
                ref="field"
              >
                <v-select
                  :items="options"
                  item-text="label"
                  item-value="value"
                  :label="`${item.number} ${item.question.label}`"
                  v-model="selectedValues"
                  @change="onInputChange"
                  :error-messages="errors"
                  :success="valid"
                  multiple
                  :disabled="isDisabled"
                  :hint="item.question.placeholder"
                  persistent-hint
                >
                </v-select>
              </validation-provider>
            </v-col>
          </template>
        </template>

        <v-col cols="12" v-if="showTextInput">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="item.question.label"
            rules="required"
            ref="field"
          >
            <v-text-field
              v-model="otherValue"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="#3F51B5"
              :name="item.id"
              @input="onInputChange"
              :disabled="isDisabled"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";

export default {
  name: "MultiSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  mixins: [dependantValueMixin],
  computed: {
    options() {
      if (this.item.question.isImage) {
        return [...this.item.question.options];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...this.item.question.options,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...this.item.question.options];
        }
      }
    },
    showTextInput() {
      return (
        Array.isArray(this.selectedValues) &&
        this.selectedValues.includes("other")
      );
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValues: [],
    };
  },
  methods: {
    async onInputChange() {
      this.$emit(
        "input",
        this.selectedValues
          .filter((v) => Boolean(v))
          .map((v) => {
            if (v === "other") {
              return {
                key: this.otherValue ?? "",
                val: v,
              };
            }
            return {
              key: v,
              val: v,
            };
          })
      );
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          const otherValue = val.find(({ val }) => val === "other");
          this.selectedValues = val.map(({ val }) => val);
          if (otherValue?.key) {
            this.otherValue = otherValue.key;
          }
        } else {
          if (typeof val === "string") {
            this.selectedValues = [val];
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff8e1;
}

.select >>> .v-select {
  width: unset;
}

.v-input--selection-controls {
  margin-top: 4px !important;
}

.v-application p {
  margin-bottom: 4px !important;
}
</style>
