<template>
  <v-dialog
    :value="showMarkupViewerDialog"
    fullscreen
    :retain-focus="false"
    persistent
  >
    <v-card
      class="d-flex"
      style="overflow: hidden"
      ref="card"
      height="calc(100vh - (100vh - 100%) - 56px)"
    >
      <v-slide-x-reverse-transition>
        <div
          :style="{ width: '200px' }"
          v-show="showPdfPagesPanel"
          ref="pdfList"
        >
          <MarkupPdfList
            @markup-pdf-list-close="showPdfPagesPanel = false"
            @page-selected="
              selectedPdfPage = $event;
              loadPdfPageOrMarkup();
            "
            @revision-selected="onRevisionSelected"
            :selectedFile="selectedFile"
          />
        </div>
      </v-slide-x-reverse-transition>
      <div class="flex-grow-1 d-flex flex-column">
        <div class="flex-grow-1 stage">
          <v-toolbar dark width="100%" color="#3F51B5">
            <v-btn
              text
              color="white"
              class="ml-n3"
              @click="showPdfPagesPanel = !showPdfPagesPanel"
              v-if="
                selectedFile.s3_file_path &&
                selectedFile.s3_file_path.endsWith('pdf')
              "
            >
              <v-icon>{{ mdiMenu }}</v-icon>
            </v-btn>

            <div class="mr-3 d-flex align-center gap" style="max-width: 50%">
              <div class="text-truncate" v-if="$vuetify.breakpoint.smAndUp">
                {{ selectedFile.name }}
              </div>
              <div>
                <p class="caption py-0 my-0">Version</p>
                <RevisionDropdown
                  :selectedPdfPage="selectedPdfPage"
                  :selectedFile="selectedFile"
                  :fileMarkupRevisions="fileMarkupRevisions"
                  :pdfPageFileMarkupCounts="pdfPageFileMarkupCounts"
                  :imageFileMarkupCount="imageFileMarkupCount"
                  @revision-selected="onRevisionSelected"
                  @show-more="getFileMarkupRevisions(++markupRevisionPage)"
                />
              </div>
            </div>

            <v-spacer />

            <v-btn icon @click="$emit('markup-viewer-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="flex-grow-1 stage">
            <v-stage
              :config="configKonva"
              @mousedown="handleStageMouseDown"
              @touchstart="handleStageMouseDown"
              ref="stage"
              class="d-flex justify-center align-center"
            >
              <v-layer ref="layer">
                <template v-for="item of items">
                  <v-image
                    v-if="
                      item.type === ITEM_TYPES.IMAGE ||
                      item.type === ITEM_TYPES.LEGEND
                    "
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-text
                    v-else-if="item.type === ITEM_TYPES.TEXT"
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-line
                    v-else-if="item.type === ITEM_TYPES.LINE"
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-line
                    v-else-if="
                      item.type === ITEM_TYPES.SHAPE &&
                      item.shape === SHAPE_TYPES.POLYGON
                    "
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-line
                    v-else-if="item.type === ITEM_TYPES.SKETCH"
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-rect
                    v-else-if="
                      item.type === ITEM_TYPES.SHAPE &&
                      item.shape === SHAPE_TYPES.RECTANGLE
                    "
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-ellipse
                    v-else-if="
                      item.type === ITEM_TYPES.SHAPE &&
                      item.shape === SHAPE_TYPES.ELLIPSE
                    "
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-image
                    v-else-if="
                      item.type === ITEM_TYPES.SHAPE &&
                      item.shape === SHAPE_TYPES.MAP_SYMBOL
                    "
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-circle
                    v-else-if="item.type === ITEM_TYPES.POINT"
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                  <v-arrow
                    v-else-if="item.type === ITEM_TYPES.ARROW"
                    :config="item.config"
                    :key="item.id"
                    ref="items"
                  />
                </template>
              </v-layer>
            </v-stage>
          </div>
        </div>
      </div>

      <v-navigation-drawer
        app
        v-model="showGraphicDetailsPanel"
        right
        :permanent="showGraphicDetailsPanel"
        style="z-index: 100"
        hide-overlay
        width="300px"
        stateless
        class="elevation-1"
      >
        <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
          <div>Details</div>
          <v-spacer />
          <v-btn icon @click="showGraphicDetailsPanel = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-4" v-if="selectedItem.details">
          <div>
            <p class="caption py-0 my-0">Label</p>
            <p>{{ selectedItem.details.label }}</p>
          </div>

          <div>
            <p class="caption py-0 my-0">Description</p>
            <p>{{ selectedItem.details.description }}</p>
          </div>
        </div>
      </v-navigation-drawer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiMenu,
  mdiClose,
  mdiDotsVertical,
  mdiRotateLeft,
  mdiRotateRight,
  mdiFitToPageOutline,
  mdiDownload,
} from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import ITEM_TYPES from "@/constants/markupItemTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";
import MarkupPdfList from "@/components/mapView/docs-tab/markup-image-dialog/MarkupPdfList";
import RevisionDropdown from "@/components/mapView/docs-tab/markup-image-dialog/RevisionDropdown";
import { v4 as uuidv4 } from "uuid";
import Konva from "konva";

const APIURL = process.env.VUE_APP_API_URL;

const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

export default {
  name: "MarkupViewerDialog",
  components: {
    MarkupPdfList,
    RevisionDropdown,
  },
  data() {
    return {
      mdiMenu,
      mdiClose,
      mdiDotsVertical,
      mdiRotateLeft,
      mdiRotateRight,
      mdiFitToPageOutline,
      mdiDownload,
      showPdfPagesPanel: false,
      isLoadingMarkup: false,
      pdfPageItems: [],
      items: [],
      configKonva: {
        width: window.innerWidth - 200,
        height: window.innerHeight - 65,
        draggable: true,
      },
      pdfPageImages: [],
      selectedPdfPage: {},
      ITEM_TYPES,
      SHAPE_TYPES,
      showGraphicDetailsPanel: false,
      selectedItem: { details: {} },
      fileMarkupRevisions: [],
      pdfPageFileMarkupCounts: [],
      imageFileMarkupCount: {},
      downloading: false,
      markupRevisionPage: 1,
    };
  },
  props: {
    showMarkupViewerDialog: Boolean,
    selectedFile: Object,
  },
  computed: {
    layerConfig() {
      if (!Array.isArray(this.items)) {
        return {
          clip: {},
          draggable: false,
        };
      }
      let index = -1;
      if (this.selectedFile.s3_file_path.endsWith("pdf")) {
        index = this.items.findIndex(
          (it) => it.src === this.selectedPdfPage?.s3_file_path
        );
      } else {
        index = this.items.findIndex(
          (it) => it.src === this.selectedFile?.s3_file_path
        );
      }
      return (
        this.items[index]?.layerConfig ?? {
          clip: {},
          draggable: false,
        }
      );
    },
  },
  async beforeMount() {
    this.onResize();
    this.watchResize();
  },
  async mounted() {
    this.addZoom();
    this.addPinchZoomAndPan();
    this.isLoadingMarkup = true;

    if (this.selectedFile?.s3_file_path?.endsWith("pdf")) {
      await this.countPdfPageMarkup();
      await this.getPdfPageImages();
      this.selectedPdfPage = this.pdfPageImages[0];
      await this.getFileMarkupRevisions();
      await this.loadPdfPageOrMarkup();
    } else {
      await this.countImagePageMarkup();
      const { count } = this.imageFileMarkupCount;
      if (count === 0) {
        await this.loadImage();
      } else {
        await this.getFileMarkupRevisions();
        await this.getMarkup();
        this.fitToScreen();
      }

      const { index } = this.findMarkupImageItemAndNodeIndexes();
      const { rotateAngleDegrees } = this.items[index] ?? {};
      if (typeof rotateAngleDegrees === "number") {
        this.rotate(rotateAngleDegrees);
      }
    }

    const stage = this.$refs.stage?.getNode();
    if (!stage || !this.$refs.card.$el) {
      return;
    }
    stage.width(this.$refs.card.$el.offsetWidth);
    stage.height(this.$refs.card.$el.offsetHeight);
    this.configKonva = {
      ...this.configKonva,
      width: this.$refs.card.$el.offsetWidth,
      height: this.$refs.card.$el.offsetHeight,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    loadImage() {
      const { selectedFile } = this;
      const img = new window.Image();
      img.src = selectedFile.s3_file_path_original_image;
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const image = img;
        const id = uuidv4();
        this.items = [
          {
            id,
            src: selectedFile.s3_file_path_original_image,
            config: {
              name: id,
              image,
              shadowColor: "black",
              shadowBlur: 0,
              shadowOffset: { x: 3, y: 3 },
              shadowOpacity: 0.5,
            },
            layerConfig: {
              clip: {},
              draggable: false,
            },
            type: ITEM_TYPES.IMAGE,
            rotateAngleDegrees: 0,
          },
        ];
        this.zoomToFit(image);
        this.firstLoad = false;
      };
    },
    addPinchZoomAndPan() {
      Konva.hitOnDragEnabled = true;
      const stage = this.$refs.stage.getNode();
      let lastCenter = null;
      let lastDist = 0;
      stage.on("touchmove", (e) => {
        e.evt.preventDefault();
        const [touch1, touch2] = e.evt.touches;
        if (touch1 && touch2) {
          if (stage.isDragging()) {
            stage.stopDrag();
          }

          const p1 = {
            x: touch1.clientX,
            y: touch1.clientY,
          };
          const p2 = {
            x: touch2.clientX,
            y: touch2.clientY,
          };

          if (!lastCenter) {
            lastCenter = getCenter(p1, p2);
            return;
          }
          const newCenter = getCenter(p1, p2);
          const dist = getDistance(p1, p2);

          if (!lastDist) {
            lastDist = dist;
          }
          const pointTo = {
            x: (newCenter.x - stage.x()) / stage.scaleX(),
            y: (newCenter.y - stage.y()) / stage.scaleX(),
          };

          const scale = stage.scaleX() * (dist / lastDist);

          stage.scaleX(scale);
          stage.scaleY(scale);
          const dx = newCenter.x - lastCenter.x;
          const dy = newCenter.y - lastCenter.y;
          const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy,
          };
          stage.position(newPos);

          lastDist = dist;
          lastCenter = newCenter;
        }
      });

      stage.on("touchend", () => {
        lastDist = 0;
        lastCenter = null;
      });
    },
    addZoom() {
      const scaleBy = 1.05;
      const stage = this.$refs.stage.getNode();
      const layer = this.$refs.layer.getNode();
      stage.on("wheel", (e) => {
        e.evt.preventDefault();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();
        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };
        const newScale =
          e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        stage.scale({ x: newScale, y: newScale });
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
        layer.batchDraw();
      });
    },
    watchResize() {
      window.addEventListener("resize", this.onResize);
    },
    fitToScreen() {
      if (!Array.isArray(this.items) || !Array.isArray(this.$refs.items)) {
        return;
      }
      const { nodeIndex } = this.findMarkupImageItemAndNodeIndexes();
      const image = this.$refs.items[nodeIndex].getNode().image();
      this.zoomToFit(image);
    },
    onResize() {
      const stage = this.$refs.stage?.getNode();
      if (!stage || !this.$refs.card) {
        return;
      }
      stage.scale({ x: 1, y: 1 });
      stage.width(this.$refs.card.$el.offsetWidth);
      stage.height(this.$refs.card.$el.offsetHeight);
      this.configKonva = {
        ...this.configKonva,
        width: this.$refs.card.$el.offsetWidth,
        height: this.$refs.card.$el.offsetHeight,
      };
      this.fitToScreen();
    },
    async getFileMarkupRevisions(page = 1) {
      if (this.selectedFile.s3_file_path?.endsWith(".pdf")) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedPdfPage.file_id}`,
          { params: { page } }
        );
        if (page === 1) {
          this.fileMarkupRevisions = results;
        } else {
          this.fileMarkupRevisions = [...this.fileMarkupRevisions, ...results];
        }
      } else {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedFile.file_id}`,
          { params: { page } }
        );
        if (page === 1) {
          this.fileMarkupRevisions = results;
        } else {
          this.fileMarkupRevisions = [...this.fileMarkupRevisions, ...results];
        }
      }
    },
    handleStageDoubleClick(e) {
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.selectedItem = { details: {} };
        this.showGraphicDetailsPanel = false;
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const item = this.items.find((item) => item.id === name);
      if (item) {
        if (this.selectedFile.s3_file_path.endsWith("pdf")) {
          if (item.src === this.selectedPdfPage?.s3_file_path) {
            return;
          }
        } else {
          if (item.src === this.selectedFile.s3_file_path) {
            return;
          }
        }
        this.selectedShapeName = name;
        this.selectedItem = item;
        if (item.type !== ITEM_TYPES.TEXT) {
          this.showGraphicDetailsPanel = true;
        }
      } else {
        this.selectedShapeName = "";
        this.selectedItem = { details: {} };
        this.showGraphicDetailsPanel = false;
      }
    },
    handleStageMouseDown(e) {
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.selectedItem = { details: {} };
        this.showGraphicDetailsPanel = false;
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const item = this.items.find((item) => item.id === name);
      if (item) {
        if (this.selectedFile.s3_file_path.endsWith("pdf")) {
          if (item.src === this.selectedPdfPage?.s3_file_path_original_image) {
            return;
          }
        } else {
          if (item.src === this.selectedFile.s3_file_path_original_image) {
            return;
          }
        }
        this.selectedShapeName = name;
        this.selectedItem = item;
        this.showGraphicDetailsPanel = true;
      } else {
        this.selectedShapeName = "";
        this.selectedItem = { details: {} };
        this.showGraphicDetailsPanel = false;
      }
    },
    findMarkupImageItemAndNodeIndexes() {
      let index = -1;
      if (
        this.selectedFile?.s3_file_path_original_image?.endsWith("pdf") ||
        this.selectedFile?.s3_file_path?.endsWith("pdf")
      ) {
        index = this.items?.findIndex(
          (it) => it.src === this.selectedPdfPage?.s3_file_path_original_image
        );
      } else {
        index = this.items?.findIndex(
          (it) => it.src === this.selectedFile?.s3_file_path_original_image
        );
      }
      const nodeIndex = this.$refs.items?.findIndex(
        (it) => it.getNode().name() === this.items[index]?.id
      );
      return { index, nodeIndex };
    },
    rotate(angleDegrees) {
      const stage = this.$refs.stage.getNode();
      const point = { x: stage.getWidth() / 2, y: stage.getHeight() / 2 };
      const angleRadians = (angleDegrees * Math.PI) / 180;
      const x =
        point.x +
        (stage.x() - point.x) * Math.cos(angleRadians) -
        (stage.y() - point.y) * Math.sin(angleRadians);
      const y =
        point.y +
        (stage.x() - point.x) * Math.sin(angleRadians) +
        (stage.y() - point.y) * Math.cos(angleRadians);
      stage.position({ x, y });
      stage.rotation(0);
      stage.rotation(stage.rotation() + angleDegrees);
    },
    async download() {
      this.downloading = true;
      const transformerNode = this.$refs.transformer.getNode();
      const oldNodes = transformerNode.nodes();
      transformerNode.nodes([]);
      const layer = this.$refs.layer.getNode();
      for (const a of this.arrowAnchors) {
        a?.remove();
      }

      for (const a of this.lineAnchors) {
        a?.remove();
      }

      for (const a of this.polygonAnchors) {
        a?.remove();
      }
      layer.batchDraw();
      await this.$nextTick();
      const dataURL = layer.toDataURL({ pixelRatio: 3 });
      const link = document.createElement("a");
      link.download = `markup.png`;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      transformerNode.nodes(oldNodes);
      for (const a of this.arrowAnchors) {
        layer.add(a);
      }

      for (const a of this.lineAnchors) {
        layer.add(a);
      }

      for (const a of this.polygonAnchors) {
        layer.add(a);
      }
      this.downloading = false;
    },
    zoomToFit(imageObj) {
      if (!this.$refs.card?.$el) {
        return;
      }
      const containerWidth = this.$refs.card?.$el?.offsetWidth;
      const containerHeight = this.$refs.card?.$el.offsetHeight;
      const layer = this.$refs.layer.getNode();
      const stage = this.$refs.stage.getNode();
      if (!isNaN(layer.clipWidth()) && !isNaN(layer.clipHeight())) {
        const widthScale = containerWidth / layer.clipWidth();
        const heightScale = containerHeight / layer.clipHeight();

        let scaleToFit =
          widthScale > heightScale ? heightScale * 0.95 : widthScale * 0.95;
        if (scaleToFit > 1) {
          scaleToFit = 1;
        }

        const zoomedImageHeight = layer.clipHeight() * scaleToFit;
        const zoomedImageWidth = layer.clipWidth() * scaleToFit;
        stage.position({
          x: (containerWidth - zoomedImageWidth) / 2 - layer.clipX(),
          y: (containerHeight - zoomedImageHeight) / 2 - layer.clipY(),
        });

        stage.scale({
          x: scaleToFit,
          y: scaleToFit,
        });
      } else {
        const widthScale = containerWidth / imageObj.width;
        const heightScale = containerHeight / imageObj.height;

        let scaleToFit =
          widthScale > heightScale ? heightScale * 0.95 : widthScale * 0.95;
        if (scaleToFit > 1) {
          scaleToFit = 1;
        }

        const zoomedImageHeight = imageObj.height * scaleToFit;
        const zoomedImageWidth = imageObj.width * scaleToFit;
        stage.position({
          x: (containerWidth - zoomedImageWidth) / 2,
          y: (containerHeight - zoomedImageHeight) / 2,
        });

        stage.scale({
          x: scaleToFit,
          y: scaleToFit,
        });
      }
    },
    async loadPdfPageOrMarkup() {
      const savedMarkup =
        this.pdfPageFileMarkupCounts.find(
          (p) => p.fileId === this.selectedPdfPage.file_id
        )?.count > 0;
      if (savedMarkup) {
        this.items = await this.loadPdfPageMarkup();
        await this.$nextTick();
        const layer = this.$refs.layer?.getNode();
        if (!layer) {
          return;
        }
        const { nodeIndex, index } = this.findMarkupImageItemAndNodeIndexes();
        const imageNode = this.$refs.items?.[nodeIndex]?.getNode();
        if (!imageNode) {
          return;
        }
        const { x, y, width, height } =
          this.items[index]?.layerConfig?.clip ?? {};
        if (!isNaN(width) && !isNaN(height) && !isNaN(x) && !isNaN(y)) {
          layer.clipX(x);
          layer.clipY(y);
          layer.clipWidth(width);
          layer.clipHeight(height);
        } else {
          layer.clipX(imageNode.x());
          layer.clipY(imageNode.y());
          layer.clipWidth(imageNode.width());
          layer.clipHeight(imageNode.height());
        }
        const { rotateAngleDegrees } = this.items[index] ?? {};
        this.rotate(0);
        this.fitToScreen();
        if (typeof rotateAngleDegrees === "number") {
          this.rotate(rotateAngleDegrees);
        }
      } else {
        this.firstLoad = true;
        const image = await this.loadPdfPage();
        this.zoomToFit(image);
      }
    },
    async getMarkup() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/file_markup/${this.selectedFile.file_id}`
      );
      const [latestMarkup = {}] = results;
      if (
        Array.isArray(latestMarkup.markup) &&
        latestMarkup.markup.length > 0
      ) {
        await this.loadItemsFromMarkup(latestMarkup.markup);
      }
    },
    async countPdfPageMarkup() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/pdf_file_markup_counts/${this.selectedFile.file_id}`
      );
      this.pdfPageFileMarkupCounts = results.map((r) => {
        const { count, file_id: fileId } = r;
        return { fileId, count };
      });
    },
    async countImagePageMarkup() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/image_file_markup_counts/${this.selectedFile.file_id}`
      );
      this.imageFileMarkupCount = results;
    },
    async onRevisionSelected(selectedVersionDateTime) {
      if (this.selectedFile.s3_file_path.endsWith(".pdf")) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedPdfPage.file_id}`
        );
        const markupObj = results.find(
          (r) => r.saved === selectedVersionDateTime
        );
        await this.loadItemsFromMarkup(markupObj.markup);
      } else {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/file_markup/${this.selectedFile.file_id}`
        );
        const markupObj = results.find(
          (r) => r.saved === selectedVersionDateTime
        );
        await this.loadItemsFromMarkup(markupObj.markup);
      }
    },
    async loadPdfPageMarkup() {
      const [latestMarkup = {}] = this.fileMarkupRevisions;
      if (
        Array.isArray(latestMarkup.markup) &&
        latestMarkup.markup.length > 0
      ) {
        const items = await this.returnItemsFromMarkup(latestMarkup.markup);
        return items;
      }
      return [];
    },
    async returnItemsFromMarkup(markup) {
      const items = [];
      try {
        for (const m of markup) {
          if (m.type === ITEM_TYPES.IMAGE) {
            const item = await this.loadImageFromMarkup(m, false);
            items.push(item);
          } else if (
            m.type === ITEM_TYPES.LINE ||
            m.type === ITEM_TYPES.SKETCH ||
            m.type === ITEM_TYPES.TEXT ||
            m.type === ITEM_TYPES.POINT ||
            m.type === ITEM_TYPES.ARROW
          ) {
            items.push(m);
          } else if (m.type === ITEM_TYPES.SHAPE) {
            const { shape } = m;
            if (shape === SHAPE_TYPES.MAP_SYMBOL) {
              const item = await this.loadImageFromMarkup(m, true);
              items.push(item);
            } else {
              items.push(m);
            }
          }
        }

        for (const [index] of items.entries()) {
          this.$set(items[index].config, "draggable", false);
        }
        return items;
      } catch (error) {
        console.log(error);
      }
    },
    loadImageFromMarkup(markupItem, isMapSymbol) {
      return new Promise((resolve, reject) => {
        const {
          src,
          id,
          config,
          shapeUrl,
          shapeFill,
          shapeImageFillColor,
          shapeSymbol,
          shapeOutlineWidth,
          rotateAngleDegrees = 0,
        } = markupItem;
        const img = new window.Image();
        img.src = src;
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const image = img;
          const item = {
            id,
            src,
            shapeUrl,
            shapeFill,
            shapeImageFillColor,
            shapeSymbol,
            shapeOutlineWidth,
            config: {
              ...config,
              image,
              draggable: false,
              name: id,
            },
            type: isMapSymbol ? ITEM_TYPES.SHAPE : ITEM_TYPES.IMAGE,
            shape: isMapSymbol ? SHAPE_TYPES.MAP_SYMBOL : undefined,
            rotateAngleDegrees,
          };
          resolve(item);
        };
        img.onerror = () => {
          reject();
        };
      });
    },
    async loadItemsFromMarkup(markup) {
      this.items = [];
      try {
        for (const m of markup) {
          if (m.type === ITEM_TYPES.IMAGE) {
            const item = await this.loadImageFromMarkup(m);
            this.items.push(item);
          } else if (
            m.type === ITEM_TYPES.LINE ||
            m.type === ITEM_TYPES.SKETCH ||
            m.type === ITEM_TYPES.TEXT ||
            m.type === ITEM_TYPES.POINT ||
            m.type === ITEM_TYPES.ARROW
          ) {
            m.config.draggable = false;
            this.items.push(m);
          } else if (m.type === ITEM_TYPES.SHAPE) {
            const { shape } = m;
            if (shape === SHAPE_TYPES.MAP_SYMBOL) {
              const item = await this.loadImageFromMarkup(m);
              this.items.push(item);
            } else {
              m.config.draggable = false;
              this.items.push(m);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPdfPageImages() {
      if (!this.selectedFile) {
        return;
      }
      const { file_id: fileId } = this.selectedFile;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/files/${fileId}/images`);
      this.pdfPageImages = results.sort(
        (a, b) => a.pdf_page_number - b.pdf_page_number
      );
      await this.loadPdfPageMarkup();
      this.selectedPdfPage = this.pdfPageImages[0];
    },
    async loadPdfPage() {
      return new Promise((resolve, reject) => {
        const stage = this.$refs.stage?.getNode();
        if (!stage) {
          resolve();
        }
        stage.scale({ x: 1, y: 1 });
        const { selectedPdfPage } = this;
        const img = new window.Image();
        img.src = selectedPdfPage?.s3_file_path;
        img.crossOrigin = "Anonymous";
        img.onload = async () => {
          const image = img;
          const id = uuidv4();
          this.items = [
            {
              id,
              src: selectedPdfPage?.s3_file_path,
              config: {
                name: id,
                image,
                shadowColor: "black",
                shadowBlur: 0,
                shadowOffset: { x: 3, y: 3 },
                shadowOpacity: 0.5,
                x: 0,
                y: 0,
              },
              layerConfig: {
                clip: {},
                draggable: false,
              },
              type: ITEM_TYPES.IMAGE,
            },
          ];
          this.firstLoad = false;
          resolve(image);
        };
        img.onerror = () => {
          reject();
        };
      });
    },
  },
  watch: {
    selectedPdfPage: {
      deep: true,
      async handler() {
        await this.getFileMarkupRevisions();
        await this.loadPdfPageOrMarkup();
      },
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}

.button {
  flex-basis: 45%;
  cursor: pointer;
}

.stage {
  background-color: #6f6f6f;
}

td {
  border-bottom: none !important;
}

.bottom-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
</style>
