import moment from "moment";
import { axiosWithRegularAuth } from "@/plugins/axios";
import DEFAULT_VALUES_TYPES from "@/constants/defaultValueTypes";

const Compress = require("compress.js");
const compress = new Compress();
const APIURL = process.env.VUE_APP_API_URL;

export const getSingleGisDataValue = (value, gisDataValues) => {
  const gisDataValue = gisDataValues?.find(
    (g) => g.gis_data_field_id === value
  )?.value;
  return gisDataValue;
};

export const getGisDataValue = (value, gisDataValues) => {
  const gisDataValue = gisDataValues?.find(
    (g) => g.gis_data_field_id === value
  )?.value;
  const values = gisDataValue?.split(",");
  if (Array.isArray(values) && values.length > 1) {
    return values;
  }
  return gisDataValue;
};

export const getOrgValue = () => {
  const {
    organization: { name },
  } = JSON.parse(localStorage.getItem("auth"));
  return name;
};
export const getUserValue = (value, userDataValues) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const { f_name: fName, l_name: lName, email, user_id: userId } = auth;
  if ("full_name" === value) {
    return `${fName} ${lName}`;
  } else if ("email" === value) {
    return email;
  } else if ("f_name" === value) {
    return fName;
  } else if ("l_name" === value) {
    return lName;
  } else {
    return userDataValues?.find(
      (v) => v.user_data_field_id === value && v.user_id === userId
    )?.value;
  }
};
export const getGisValue = (
  value,
  featureAttributes,
  allowMultiple,
  gisInfoQueryResult
) => {
  const field = gisInfoQueryResult?.fields?.find(
    (f) =>
      f?.name?.toLowerCase?.() === value?.toLowerCase?.() ||
      f?.alias?.toLowerCase?.() === value?.toLowerCase?.()
  );
  const gisValue =
    featureAttributes?.[value] ?? featureAttributes?.[field?.name];
  const values = gisValue?.toString()?.split(",");
  if (allowMultiple) {
    return values;
  }
  return gisValue;
};

export const getCurrentValue = (item, value) => {
  if (item?.question?.type === "DATE") {
    return moment()
      .add(+(value ?? 0), "minutes")
      .format("YYYY-MM-DD");
  } else if (item?.question?.type === "TIME") {
    return moment()
      .add(+(value ?? 0), "minutes")
      .format("hh:mm:ss");
  }
};
export const getUrlValue = async (value, formResultId, item) => {
  try {
    const { maxWidthHeight } = item.question;
    const imgRes = await fetch(value);
    const blob = await imgRes.blob();
    const { type } = blob;
    const [, extension] = type.split("/");
    const file = new File([blob], `pic.${extension}`, {
      type,
    });
    const { name: fileName, type: fileType } = file;
    const result = await compress.compress([file], {
      maxWidth: +maxWidthHeight,
      maxHeight: +maxWidthHeight,
      resize: true,
    });
    const [{ data, prefix }] = result;
    const dataUrl = `${prefix}${data}`;
    const res = await fetch(dataUrl);
    const buf = await res.arrayBuffer();
    const resizedImageFile = new File([buf], fileName, {
      type: fileType,
    });
    const formData = new FormData();
    formData.append("file", resizedImageFile);
    formData.append("file_name", fileName);
    formData.append("is_image", true);
    const {
      data: { file: uploadedFile },
    } = await axiosWithRegularAuth.post(
      `${APIURL}/form_results/${formResultId}/files`,
      formData
    );
    return [
      {
        ...uploadedFile,
      },
    ];
  } catch (error) {
    return [];
  }
};

export const getSharedSiteUrlValue = async (value, formResultId, item) => {
  try {
    const { maxWidthHeight } = item.question;
    const imgRes = await fetch(value);
    const blob = await imgRes.blob();
    const { type } = blob;
    const [, extension] = type.split("/");
    const file = new File([blob], `pic.${extension}`, {
      type,
    });
    const { name: fileName, type: fileType } = file;
    const result = await compress.compress([file], {
      maxWidth: +maxWidthHeight,
      maxHeight: +maxWidthHeight,
      resize: true,
    });
    const [{ data, prefix }] = result;
    const dataUrl = `${prefix}${data}`;
    const res = await fetch(dataUrl);
    const buf = await res.arrayBuffer();
    const resizedImageFile = new File([buf], fileName, {
      type: fileType,
    });
    const formData = new FormData();
    formData.append("file", resizedImageFile);
    formData.append("file_name", fileName);
    formData.append("is_image", true);
    const {
      data: { file: uploadedFile },
    } = await axiosWithRegularAuth.post(
      `${APIURL}/shared_site/form_results/${formResultId}/files`,
      formData
    );
    return [
      {
        ...uploadedFile,
      },
    ];
  } catch (error) {
    return [];
  }
};

export const getRepeatingGroupDefaultValues = async ({
  item,
  userDataValues,
  featureAttributes,
  gisDataValues,
  formResultId,
}) => {
  const itemsPromises =
    item?.question?.groupedItems?.map(async (it) => {
      const { type, value } = it?.question?.default ?? {};
      if (type === DEFAULT_VALUES_TYPES.ORGANIZATION) {
        return { ...it, value: getOrgValue() };
      } else if (type === DEFAULT_VALUES_TYPES.USER) {
        return { ...it, value: getUserValue(value, userDataValues) };
      } else if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
        return { ...it, value };
      } else if (type === DEFAULT_VALUES_TYPES.GIS) {
        return { ...it, value: getGisValue(value, featureAttributes) };
      } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
        return { ...it, value: getGisDataValue(value, gisDataValues) };
      } else if (type === DEFAULT_VALUES_TYPES.CURRENT) {
        return { ...it, value: getCurrentValue(it, value) };
      } else if (type === DEFAULT_VALUES_TYPES.URL) {
        return { ...it, value: await getUrlValue(value, formResultId, item) };
      }
      return { ...it };
    }) ?? [];
  const itemsWithValues = await Promise.all(itemsPromises);
  return [itemsWithValues] ?? [[]];
};

export const getSharedSiteRepeatingGroupDefaultValues = async ({
  item,
  userDataValues,
  featureAttributes,
  gisDataValues,
  formResultId,
}) => {
  const itemsPromises =
    item?.question?.groupedItems?.map(async (it) => {
      const { type, value } = it?.question?.default ?? {};
      if (type === DEFAULT_VALUES_TYPES.ORGANIZATION) {
        return { ...it, value: getOrgValue() };
      } else if (type === DEFAULT_VALUES_TYPES.USER) {
        return { ...it, value: getUserValue(value, userDataValues) };
      } else if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
        return { ...it, value };
      } else if (type === DEFAULT_VALUES_TYPES.GIS) {
        return { ...it, value: getGisValue(value, featureAttributes) };
      } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
        return { ...it, value: getGisDataValue(value, gisDataValues) };
      } else if (type === DEFAULT_VALUES_TYPES.CURRENT) {
        return { ...it, value: getCurrentValue(it, value) };
      } else if (type === DEFAULT_VALUES_TYPES.URL) {
        return {
          ...it,
          value: await getSharedSiteUrlValue(value, formResultId, item),
        };
      }
      return { ...it };
    }) ?? [];
  const itemsWithValues = await Promise.all(itemsPromises);
  return [itemsWithValues] ?? [[]];
};
