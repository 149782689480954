var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "px-0",
                          attrs: { text: "", "x-small": "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDotsVertical))])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      _vm.showTestArcGisLayerConnectionDialog = true
                    }
                  }
                },
                [_c("v-list-item-title", [_vm._v(" Test Connection ")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showTestArcGisLayerConnectionDialog
        ? _c("TestArcGisLayerConnectionDialog", {
            attrs: {
              showTestArcGisLayerConnectionDialog:
                _vm.showTestArcGisLayerConnectionDialog,
              allLayers: _vm.allLayers,
              mapServiceId: _vm.mapServiceId
            },
            on: {
              "test-connection-dialog-close": function($event) {
                _vm.showTestArcGisLayerConnectionDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }