var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.item.question.isImage
        ? [
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _c("p", { staticClass: "caption" }, [
                  _vm._v(" " + _vm._s(_vm.item.question.label) + " ")
                ]),
                _c(
                  "v-radio-group",
                  {
                    attrs: { disabled: _vm.readOnly },
                    model: {
                      value: _vm.selectedValue,
                      callback: function($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue"
                    }
                  },
                  _vm._l(_vm.options, function(option) {
                    return _c("v-radio", {
                      key: option.id,
                      attrs: { value: option.value, readonly: _vm.readOnly },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("img", { attrs: { src: option.value } })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]
        : [
            _vm.item.question.showAllOptions
              ? [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(" " + _vm._s(_vm.item.question.label) + " ")
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { disabled: _vm.readOnly },
                          on: {
                            input: _vm.onInputChange,
                            change: _vm.onInputChange
                          },
                          model: {
                            value: _vm.selectedValue,
                            callback: function($$v) {
                              _vm.selectedValue = $$v
                            },
                            expression: "selectedValue"
                          }
                        },
                        _vm._l(_vm.options, function(option) {
                          return _c("v-radio", {
                            key: option.id,
                            attrs: { value: option.value, label: option.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              : [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        ref: "field",
                        attrs: {
                          slim: "",
                          name: _vm.item.question.label,
                          rules: {
                            required: _vm.isRequired
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              var valid = ref.valid
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.options,
                                    "item-text": "label",
                                    "item-value": "value",
                                    label: _vm.item.question.label,
                                    "error-messages": errors,
                                    success: valid,
                                    disabled: _vm.readOnly,
                                    "hide-details": ""
                                  },
                                  on: {
                                    input: _vm.onInputChange,
                                    change: _vm.onInputChange
                                  },
                                  model: {
                                    value: _vm.selectedValue,
                                    callback: function($$v) {
                                      _vm.selectedValue = $$v
                                    },
                                    expression: "selectedValue"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
          ],
      _vm.showTextInput
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("validation-provider", {
                attrs: { name: _vm.item.question.label, rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        var valid = ref.valid
                        return [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              "error-messages": errors,
                              success: valid,
                              color: "#3F51B5",
                              name: _vm.item.id,
                              disabled: _vm.readOnly,
                              label: _vm.item.question.label
                            },
                            on: {
                              input: _vm.onInputChange,
                              change: _vm.onInputChange
                            },
                            model: {
                              value: _vm.otherValue,
                              callback: function($$v) {
                                _vm.otherValue = $$v
                              },
                              expression: "otherValue"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1902936587
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }