<template>
  <v-dialog
    v-model="showConfirmActionItemCancelDialog"
    max-width="600"
    close
    persistent
  >
    <v-card>
      <v-card-text class="py-5">
        Are you sure you want to proceed?
      </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end gap">
        <v-btn @click="$emit('cancel-confirm-cancel')"> Close </v-btn>
        <v-btn color="#3F51B5" dark @click="$emit('confirm-cancel')">
          Cancel Action Item
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "showConfirmActionItemCancelDialog",
  props: {
    showConfirmActionItemCancelDialog: Boolean,
  },
};
</script>
