<template>
  <svg :width="size" :height="size" xmlns="http://www.w3.org/2000/svg">
    <rect
      :width="size"
      :height="size"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Square",
  props: {
    itemInfo: Object,
  },
  computed: {
    fillColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    size() {
      return this.itemInfo?.symbol?.size;
    },
    strokeColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.outline?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    strokeWidth() {
      const { width } = this.itemInfo?.symbol?.outline ?? {};
      return width ?? 1;
    },
  },
};
</script>
