var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemInfo
    ? _c(
        "div",
        {
          staticClass: "px-4 py-2",
          staticStyle: { width: "100%", "box-sizing": "border-box" },
          on: {
            click: function($event) {
              return _vm.$emit("add-symbol", _vm.itemInfo)
            }
          }
        },
        [
          ["image"].includes(_vm.itemInfo.symbol_type)
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _vm.viewSymbols
                    ? _c("div", { staticClass: "icon d-flex justify-center" }, [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c("img", {
                            staticClass: "mr-2 mt-0",
                            style: {
                              width: _vm.itemInfo.picture_size + "px",
                              height: _vm.itemInfo.picture_size + "px"
                            },
                            attrs: {
                              src: _vm.itemInfoUrlStartsWithImg(_vm.itemInfo)
                                ? require("@/assets" + _vm.itemInfo.url)
                                : _vm.itemInfo.url
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm.itemInfo.label
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center text-truncate",
                            class: { "item-title": _vm.level > 0 }
                          },
                          [_vm._v(" " + _vm._s(_vm.itemInfo.label) + " ")]
                        )
                      : _vm.itemInfo.field_option_label
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center text-truncate",
                            class: { "item-title": _vm.level > 0 }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.itemInfo.field_option_label) +
                                " "
                            )
                          ]
                        )
                      : _vm.itemInfo.field_option_value
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center text-truncate",
                            class: { "item-title": _vm.level > 0 }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.itemInfo.field_option_value) +
                                " "
                            )
                          ]
                        )
                      : _vm.layer.utiliSyncLayerName
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center text-truncate",
                            class: { "item-title": _vm.level > 0 }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.layer.utiliSyncLayerName) + " "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            : ["basic"].includes(_vm.itemInfo.symbol_type)
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c("div", {
                    staticClass: "mr-2",
                    style: {
                      width: _vm.itemInfo.picture_size + "px",
                      height: _vm.itemInfo.picture_size + "px",
                      "background-color": "" + _vm.itemInfo.fill_color,
                      "border-radius": "50%"
                    }
                  }),
                  _vm.itemInfo.label
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex align-center text-truncate",
                          class: { "item-title": _vm.level > 0 }
                        },
                        [_vm._v(" " + _vm._s(_vm.itemInfo.label) + " ")]
                      )
                    : _vm._e()
                ])
              ])
            : _c("div", [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewSymbols,
                          expression: "viewSymbols"
                        }
                      ],
                      staticClass: "icon"
                    },
                    [_vm._v("X")]
                  ),
                  _vm.itemInfo.label
                    ? _c("p", [_vm._v(_vm._s(_vm.itemInfo.label))])
                    : _vm.itemInfo.field_option_label
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.itemInfo.field_option_label) + " "
                        )
                      ])
                    : _vm.itemInfo.field_option_value
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.itemInfo.field_option_value) + " "
                        )
                      ])
                    : _vm._e()
                ])
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }