<template>
  <v-dialog
    :value="showFeatureServiceUnreachableDialog"
    max-width="550px"
    persistent
  >
    <v-card>
      <v-card-text class="py-3">
        <section class="d-flex justify-end">
          <v-btn icon @click="$emit('cancel')">
            <v-icon color="#3F51B5">{{ mdiClose }}</v-icon>
          </v-btn>
        </section>

        <section>
          {{ errorMessage }}
        </section>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="copyToClipboard">
          <span class="font-weight-regular">Copy Message to Clipboard</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mdiClose } from "@mdi/js";

export default {
  name: "FeatureServiceUnreachableDialog",
  props: {
    showFeatureServiceUnreachableDialog: Boolean,
    selectedMapServiceId: String,
    fullRequestUrl: String,
  },
  data() {
    return {
      selectedMapService: {},
      esriToken: localStorage.getItem("esri-token"),
      mdiClose,
    };
  },
  computed: {
    errorMessage() {
      return `The feature service URL for this layer: ${this.fullRequestUrl} cannot be
          reached or is returning an invalid response. Please check with your
          GIS admin to see if there is a problem with that layer.`;
    },
  },
  methods: {
    async getMapService() {
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedMapServiceId)
        .toArray();
      this.selectedMapService = selectedMapService;
    },
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.errorMessage);
    },
  },
};
</script>
