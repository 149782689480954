<template>
  <v-dialog :value="showFormOfflineDialog" max-width="550px" persistent>
    <v-card>
      <v-card-text class="py-3">
        This device is currently offline and a new form submission cannot be
        created on this GIS layer while offline. Please connect to the internet
        and try again.
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="$emit('cancel')">
          <span class="font-weight-regular">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormOfflineDialog",
  props: {
    showFormOfflineDialog: Boolean,
  },
};
</script>
