<template>
  <v-toolbar dark width="100%" color="#3F51B5" ref="toolbar" height="56px">
    <v-btn
      text
      color="white"
      class="ml-n3"
      @click="$emit('show-pdf-pages-panel')"
      v-if="isPdf"
    >
      <v-icon>{{ mdiMenu }}</v-icon>
    </v-btn>

    <div class="mr-3 d-flex align-center gap" style="max-width: 50%">
      <div class="text-truncate" v-if="$vuetify.breakpoint.smAndUp">
        {{ selectedFile.name }}
      </div>
      <div>
        <p class="caption py-0 my-0">Version</p>
        <RevisionDropdown
          :selectedPdfPage="selectedPdfPage"
          :selectedFile="selectedFile"
          :fileMarkupRevisions="fileMarkupRevisions"
          :pdfPageFileMarkupCounts="pdfPageFileMarkupCounts"
          :imageFileMarkupCount="imageFileMarkupCount"
          @revision-selected="$emit('revision-selected', $event)"
          @show-more="$emit('show-more')"
          @revision-deleted="$emit('revision-deleted')"
        />
      </div>
    </div>

    <v-spacer />

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" x-small icon>
          <v-icon>{{ mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            @click="$emit('rotate', -90)"
          >
            <v-icon>
              {{ mdiRotateLeft }}
            </v-icon>
            Rotate Left
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            @click="$emit('rotate', 90)"
          >
            <v-icon>
              {{ mdiRotateRight }}
            </v-icon>
            Rotate Right
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            @click="$emit('fit-to-screen')"
            :style="{
              'pointer-events': disableFitToScreen ? 'none' : undefined,
              opacity: disableFitToScreen ? 0.5 : 1,
            }"
          >
            <v-icon>
              {{ mdiFitToPageOutline }}
            </v-icon>
            Fit to Screen
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            @click="$emit('crop')"
            :style="{
              'pointer-events': disableCrop ? 'none' : undefined,
              opacity: disableCrop ? 0.5 : 1,
            }"
          >
            <v-icon>
              {{ mdiCrop }}
            </v-icon>
            Crop
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            :class="{ 'text--disabled': hasLegend }"
            @click="onClickAddLegend"
          >
            <v-icon>
              {{ mdiFormatListBulleted }}
            </v-icon>
            Add Legend
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="d-flex align-center gap cursor-pointer"
            @click="$emit('download')"
          >
            <v-icon>
              {{ mdiDownload }}
            </v-icon>
            Download
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn icon @click="$emit('dialog-close-button-click')">
      <v-icon>{{ mdiClose }}</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import RevisionDropdown from "@/components/mapView/docs-tab/markup-image-dialog/RevisionDropdown";
import {
  mdiMenu,
  mdiDotsVertical,
  mdiRotateLeft,
  mdiRotateRight,
  mdiFitToPageOutline,
  mdiUndo,
  mdiRedo,
  mdiDownload,
  mdiCrop,
  mdiClose,
  mdiFormatListBulleted,
} from "@mdi/js";

export default {
  name: "MarkupToolbar",
  components: {
    RevisionDropdown,
  },
  props: {
    selectedFile: Object,
    selectedPdfPage: Object,
    hasLegend: Boolean,
    fileMarkupRevisions: Array,
    pdfPageFileMarkupCounts: Array,
    imageFileMarkupCount: Object,
    disableFitToScreen: Boolean,
    disableCrop: Boolean,
  },
  computed: {
    isPdf() {
      return this.selectedFile?.s3_file_path_original_image?.endsWith("pdf");
    },
  },
  data() {
    return {
      mdiMenu,
      mdiDotsVertical,
      mdiRotateLeft,
      mdiRotateRight,
      mdiFitToPageOutline,
      mdiUndo,
      mdiRedo,
      mdiDownload,
      mdiCrop,
      mdiClose,
      mdiFormatListBulleted,
    };
  },
  methods: {
    onClickAddLegend() {
      if (this.hasLegend) {
        return;
      }
      this.$emit("add-legend");
    },
  },
};
</script>
