var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "absolute",
        top: "0px",
        left: "30000px",
        "border-radius": "10px",
        border: "black",
        padding: "15px",
        "background-color": "lightgray",
        "font-size": "30px"
      }
    },
    [
      _c("b", [_vm._v("Legend")]),
      _c("table", { style: { "table-layout": "fixed" } }, [
        _c(
          "tbody",
          _vm._l(_vm.addedCustomSymbols, function(s) {
            return _c("tr", { key: s.markupSymbolId }, [
              _c(
                "td",
                { staticClass: "d-flex flex-column justify-center" },
                [
                  s.symbol.symbolType === _vm.SYMBOL_TYPES.IMAGE
                    ? _c("img", {
                        staticStyle: {
                          "max-width": "30px",
                          "max-height": "30px"
                        },
                        attrs: { src: s.symbol.imageDataURL }
                      })
                    : _c("SymbolIconDisplay", { attrs: { symbol: s } })
                ],
                1
              ),
              _c("td", [_vm._v(_vm._s(s.label) + " (" + _vm._s(s.count) + ")")])
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }