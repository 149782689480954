<template>
  <div>
    <p class="caption">{{ item.question.label }}</p>
    <file-pond
      v-if="!readOnly"
      name="files"
      ref="files"
      label-idle="Drop files here..."
      :allow-multiple="item.allowMultiple"
      :files="uploadFiles"
      @addfile="onChange"
      allowFileSizeValidation
      maxFileSize="2MB"
      :disabled="readOnly || (!item.allowMultiple && files.length === 1)"
    />
    <v-card v-for="(file, index) of files" :key="file.id">
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-card class="d-flex flex-row justify-start elevation-0">
              <v-card
                class="elevation-0 d-flex flex-column justify-center mr-2"
              >
                {{ file.fileName || file.description }}
              </v-card>
              <v-card class="elevation-0" width="100%" v-if="showDescription">
                <v-text-field
                  :key="file.id"
                  label="Description"
                  color="#3F51B5"
                  :name="file.id"
                  :disabled="readOnly"
                  v-model="file.description"
                  @change="updateFileDescription"
                >
                </v-text-field>
              </v-card>
            </v-card>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <v-menu v-if="!isDisabled">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="removeFile(index, file.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { v4 as uuidv4 } from "uuid";
import { mdiDotsVertical, mdiDelete } from "@mdi/js";
import { cloneDeep } from "lodash";

const FilePond = vueFilePond();

export default {
  name: "GroupFileInput",
  props: {
    item: Object,
    allowMultiple: Boolean,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    readOnly: Boolean,
    alreadySubmittedFinalOnline: Boolean,
    formResultId: String,
  },
  components: {
    FilePond,
  },
  computed: {
    showDescription() {
      return Boolean(this.item?.question?.showDescription);
    },
    isRequired() {
      return this.item.question?.required?.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  data() {
    return {
      uploadFiles: undefined,
      files: [],
      mdiDotsVertical,
      mdiDelete,
    };
  },
  methods: {
    updateFileDescription() {
      this.$emit("input", this.files);
    },
    async onChange() {
      const files = this.$refs.files.getFiles();
      const addedFiles = files.map((f) => {
        const { name: fileName, type: fileType } = f.file;
        return {
          description: "",
          fileName,
          fileType,
          id: uuidv4(),
          name: fileName,
          uploaded: false,
        };
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }

        this.files = [...this.files, ...addedFiles];
        this.$emit("input", this.files);
      }
    },
    async removeFile(index) {
      this.files.splice(index, 1);
      this.$emit("input", this.files);
    },
    async validateInput() {
      await this.$nextTick();
      const { label } = this.item.question;
      const { id, isRequired, files } = this;
      const valid = isRequired ? files.length > 0 : true;
      this.$emit("validated", {
        valid,
        label,
        id,
      });
    },
  },
  mounted() {
    this.validateInput();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.files = cloneDeep(val);
      },
    },
    isRequired() {
      this.validateInput();
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}
</style>
