var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0", attrs: { width: "100%" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 ma-0 mb-2" },
        [
          _c(
            "v-row",
            [
              _vm.item.question.isImage
                ? [
                    _c(
                      "v-col",
                      { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "p",
                          { staticClass: "caption mb-0" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.number +
                                    " " +
                                    _vm.item.question.label
                                ) +
                                " "
                            ),
                            !_vm.isDisabled
                              ? [
                                  _vm._v(" ( "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedValue = undefined
                                        }
                                      }
                                    },
                                    [_vm._v(" Clear ")]
                                  ),
                                  _vm._v(" ) ")
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("p", { staticClass: "caption mb-0" }, [
                          _vm._v(_vm._s(_vm.item.question.placeholder))
                        ]),
                        _c("validation-provider", {
                          ref: "field",
                          attrs: {
                            slim: "",
                            name: _vm.item.question.label,
                            rules: {
                              required: _vm.isRequired
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  var valid = ref.valid
                                  return [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          "error-messages": errors,
                                          success: valid,
                                          disabled: _vm.isDisabled
                                        },
                                        on: { change: _vm.onInputChange },
                                        model: {
                                          value: _vm.selectedValue,
                                          callback: function($$v) {
                                            _vm.selectedValue = $$v
                                          },
                                          expression: "selectedValue"
                                        }
                                      },
                                      _vm._l(_vm.options, function(option) {
                                        return _c("v-radio", {
                                          key: option.id,
                                          attrs: { value: option.value },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c("img", {
                                                      style: {
                                                        width: "auto",
                                                        height: "64px"
                                                      },
                                                      attrs: {
                                                        src: option.value
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1414047403
                          )
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _vm.item.question.showAllOptions
                      ? [
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.item.number +
                                          " " +
                                          _vm.item.question.label
                                      ) +
                                      " "
                                  ),
                                  !_vm.isDisabled
                                    ? [
                                        _vm._v(" ( "),
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                _vm.selectedValue = undefined
                                              }
                                            }
                                          },
                                          [_vm._v(" Clear ")]
                                        ),
                                        _vm._v(" ) ")
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(_vm._s(_vm.item.question.placeholder))
                              ]),
                              _c("validation-provider", {
                                ref: "field",
                                attrs: {
                                  slim: "",
                                  name: _vm.item.question.label,
                                  rules: {
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        var valid = ref.valid
                                        return [
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: {
                                                "error-messages": errors,
                                                success: valid,
                                                disabled: _vm.isDisabled
                                              },
                                              on: { change: _vm.onInputChange },
                                              model: {
                                                value: _vm.selectedValue,
                                                callback: function($$v) {
                                                  _vm.selectedValue = $$v
                                                },
                                                expression: "selectedValue"
                                              }
                                            },
                                            _vm._l(_vm.options, function(
                                              option
                                            ) {
                                              return _c("v-radio", {
                                                key: option.id,
                                                attrs: {
                                                  value: option.value,
                                                  label: option.label
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2173157897
                                )
                              })
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "v-col",
                            {
                              staticClass: "my-0 py-0 select",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("validation-provider", {
                                ref: "field",
                                attrs: {
                                  slim: "",
                                  name: _vm.item.question.label,
                                  rules: {
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.options,
                                            "item-text": "label",
                                            "item-value": "value",
                                            label:
                                              _vm.item.number +
                                              " " +
                                              _vm.item.question.label,
                                            "error-messages": errors,
                                            success: valid,
                                            clearable: "",
                                            disabled: _vm.isDisabled,
                                            "hide-details": "auto",
                                            hint: _vm.item.question.placeholder,
                                            "persistent-hint": ""
                                          },
                                          on: { change: _vm.onInputChange },
                                          model: {
                                            value: _vm.selectedValue,
                                            callback: function($$v) {
                                              _vm.selectedValue = $$v
                                            },
                                            expression: "selectedValue"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]
                  ],
              _vm.showTextInput
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        ref: "field",
                        attrs: {
                          name: _vm.item.question.label,
                          rules: "required"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var valid = ref.valid
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "#673AB7",
                                      name: _vm.item.id,
                                      label:
                                        _vm.item.number +
                                        " " +
                                        _vm.item.question.label
                                    },
                                    on: { input: _vm.onInputChange },
                                    model: {
                                      value: _vm.otherValue,
                                      callback: function($$v) {
                                        _vm.otherValue = $$v
                                      },
                                      expression: "otherValue"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1461517085
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }