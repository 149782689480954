<template>
  <v-card class="elevation-0" width="100%">
    <v-card-text class="pa-0 ma-0 mb-2">
      <v-row>
        <template v-if="item.question.isImage">
          <v-col cols="12" class="my-0 py-0">
            <p class="caption mb-0">
              {{ `${item.number} ${item.question.label}` }}
              <template v-if="!isDisabled">
                (
                <a href="#" @click="selectedValue = undefined"> Clear </a>
                )
              </template>
            </p>
            <p class="caption mb-0">{{ item.question.placeholder }}</p>

            <validation-provider
              slim
              v-slot="{ errors, valid }"
              :name="item.question.label"
              :rules="{
                required: isRequired,
              }"
              ref="field"
            >
              <v-radio-group
                v-model="selectedValue"
                @change="onInputChange"
                :error-messages="errors"
                :success="valid"
                :disabled="isDisabled"
              >
                <v-radio
                  v-for="option in options"
                  :key="option.id"
                  :value="option.value"
                >
                  <template v-slot:label>
                    <img
                      :src="option.value"
                      :style="{ width: 'auto', height: '64px' }"
                    />
                  </template>
                </v-radio>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </template>
        <template v-else>
          <template v-if="item.question.showAllOptions">
            <v-col cols="12" class="my-0 py-0">
              <p class="caption mb-0">
                {{ `${item.number} ${item.question.label}` }}
                <template v-if="!isDisabled">
                  (
                  <a href="#" @click="selectedValue = undefined"> Clear </a>
                  )
                </template>
              </p>
              <p class="caption mb-0">{{ item.question.placeholder }}</p>

              <validation-provider
                slim
                v-slot="{ errors, valid }"
                :name="item.question.label"
                :rules="{
                  required: isRequired,
                }"
                ref="field"
              >
                <v-radio-group
                  v-model="selectedValue"
                  @change="onInputChange"
                  :error-messages="errors"
                  :success="valid"
                  :disabled="isDisabled"
                >
                  <v-radio
                    v-for="option in options"
                    :key="option.id"
                    :value="option.value"
                    :label="option.label"
                  >
                  </v-radio>
                </v-radio-group>
              </validation-provider>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="my-0 py-0 select">
              <validation-provider
                slim
                v-slot="{ errors, valid }"
                :name="item.question.label"
                :rules="{
                  required: isRequired,
                }"
                ref="field"
              >
                <v-select
                  :items="options"
                  item-text="label"
                  item-value="value"
                  :label="`${item.number} ${item.question.label}`"
                  v-model="selectedValue"
                  @change="onInputChange"
                  :error-messages="errors"
                  :success="valid"
                  clearable
                  :disabled="isDisabled"
                  hide-details="auto"
                  :hint="item.question.placeholder"
                  persistent-hint
                >
                </v-select>
              </validation-provider>
            </v-col>
          </template>
        </template>

        <v-col cols="12" v-if="showTextInput">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="item.question.label"
            rules="required"
            ref="field"
          >
            <v-text-field
              v-model="otherValue"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="#673AB7"
              :name="item.id"
              @input="onInputChange"
              :label="`${item.number} ${item.question.label}`"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";

export default {
  name: "SingleSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  mixins: [dependantValueMixin],
  computed: {
    options() {
      const filteredOptions =
        this.item?.question?.options?.filter((o) => {
          if (typeof o?.visible !== "object") {
            return true;
          }
          const { sections } = { ...this.formDefinition.form };
          const items = sections.map((s) => s.items).flat();
          const value = items?.find(
            (it) => it.id === o?.visible?.dependantId
          )?.value;
          if (o?.visible?.condition === "EQUAL") {
            return value === o?.visible?.value;
          } else if (o?.visible?.condition === "NOT_EQUAL") {
            return value !== o?.visible?.value;
          } else if (o?.visible?.condition === "GREATER_THAN") {
            return +value > +o?.visible?.value;
          } else if (o?.visible?.condition === "GREATER_THAN_OR_EQUAL") {
            return +value >= +o?.visible?.value;
          } else if (o?.visible?.condition === "LESS_THAN") {
            return +value < +o?.visible?.value;
          } else if (o?.visible?.condition === "LESS_THAN_OR_EQUAL") {
            return +value <= +o?.visible?.value;
          }
        }) ?? [];
      if (this.item.question.isImage) {
        return [...filteredOptions];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...filteredOptions,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...filteredOptions];
        }
      }
    },
    showTextInput() {
      return this.selectedValue === "other";
    },
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValue: "",
    };
  },
  mounted() {
    this.validateField();
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const result = await this.$refs?.field?.validateSilent();
      const { valid = true } = result ?? {};
      const { item } = this;
      const { id, number, question } = item;
      this.$emit("validated", {
        valid,
        label: `${number} ${question.label}`,
        id,
      });
    },
    async onInputChange() {
      await this.validateField();
      if (this.selectedValue === "other") {
        this.$emit("input", this.otherValue);
      } else {
        this.$emit("input", this.selectedValue);
      }
    },
  },
  beforeMount() {
    const { value } = this;
    const values = this.options.map((o) => o.value);
    if (value && !values.includes(value)) {
      this.otherValue = value;
      this.selectedValue = "other";
    } else {
      this.selectedValue = value;
    }
  },
  watch: {
    isRequired() {
      this.validateField();
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #fff8e1;
}

.select >>> .v-select {
  width: unset;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

.v-application p {
  margin-bottom: 4px !important;
}
</style>
