var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showAddSymbolDialog, width: "600px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Add Symbol")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-symbol-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "d-flex justify-center px-0 mx-0" },
            [
              _c("validation-observer", { ref: "form", attrs: { slim: "" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex pa-0 ma-0 justify-center settings" },
                  [
                    _c(
                      "form",
                      {
                        staticClass:
                          "d-flex pa-0 ma-0 justify-center settings-row",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.saveSymbol.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "settings" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ma-0 pa-0 d-flex justify-space-between gap"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "pb-1 my-0 caption" },
                                    [_vm._v("Symbol")]
                                  ),
                                  _vm.symbolType === _vm.SYMBOL_TYPES.SHAPE
                                    ? [
                                        _vm.shapeOptions.shape ===
                                        _vm.SHAPE_TYPES.MAP_SYMBOL
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "30px",
                                                height: "30px"
                                              },
                                              attrs: {
                                                src: require("@/assets/img/mapSymbols/" +
                                                  _vm.shapeOptions
                                                    .shapeImageFillColor +
                                                  _vm.shapeOptions.shapeSymbol +
                                                  ".png")
                                              }
                                            })
                                          : _vm.shapeOptions.shape ===
                                            _vm.SHAPE_TYPES.ELLIPSE
                                          ? _c("div", [
                                              _c("div", {
                                                style: {
                                                  width:
                                                    _vm.shapeOptions.size +
                                                    "px",
                                                  height:
                                                    _vm.shapeOptions.size +
                                                    "px",
                                                  "border-radius": "50%",
                                                  "background-color":
                                                    _vm.shapeOptions.shapeFill,
                                                  border:
                                                    "1px solid " +
                                                    _vm.shapeOptions
                                                      .shapeOutlineColor
                                                }
                                              })
                                            ])
                                          : _vm.shapeOptions.shape ===
                                            _vm.SHAPE_TYPES.RECTANGLE
                                          ? _c("div", [
                                              _c("div", {
                                                style: {
                                                  width: "30px",
                                                  height: "25px",
                                                  "background-color":
                                                    _vm.shapeOptions.shapeFill,
                                                  border:
                                                    "1px solid " +
                                                    _vm.shapeOptions
                                                      .shapeOutlineColor
                                                }
                                              })
                                            ])
                                          : _vm.shapeOptions.shape ===
                                            _vm.SHAPE_TYPES.POLYGON
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiVectorPolygon
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm.symbolType === _vm.SYMBOL_TYPES.LINE
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          staticStyle: { height: "30px" }
                                        },
                                        [
                                          _c("div", {
                                            style: {
                                              height:
                                                _vm.lineOptions
                                                  .lineStrokeWidth + "px",
                                              width: "100%",
                                              "background-color":
                                                _vm.lineOptions.lineStroke
                                            }
                                          })
                                        ]
                                      )
                                    : _vm.symbolType === _vm.SYMBOL_TYPES.IMAGE
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center"
                                        },
                                        [
                                          _c("img", {
                                            style: {
                                              width:
                                                _vm.imageOptions.size + "px"
                                            },
                                            attrs: {
                                              src: _vm.imageOptions.imageUrl
                                            }
                                          })
                                        ]
                                      )
                                    : _vm.symbolType === _vm.SYMBOL_TYPES.POINT
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center"
                                        },
                                        [
                                          _c("div", {
                                            style: {
                                              width:
                                                _vm.pointOptions.size + "px",
                                              height:
                                                _vm.pointOptions.size + "px",
                                              "border-radius": "50%",
                                              "background-color":
                                                _vm.pointOptions.pointFill,
                                              border:
                                                "1px solid " +
                                                _vm.pointOptions
                                                  .pointOutlineColor
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-2 flex-grow-1" },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Label", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                "error-messages": errors,
                                                success: valid,
                                                label: "Label",
                                                color: "#3F51B5"
                                              },
                                              model: {
                                                value: _vm.label,
                                                callback: function($$v) {
                                                  _vm.label = $$v
                                                },
                                                expression: "label"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between gap" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { width: "120px" }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.symbolTypeChoices,
                                      "item-text": "label",
                                      "item-value": "value",
                                      label: "Symbol Type"
                                    },
                                    model: {
                                      value: _vm.symbolType,
                                      callback: function($$v) {
                                        _vm.symbolType = $$v
                                      },
                                      expression: "symbolType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.symbolType === _vm.SYMBOL_TYPES.SHAPE
                                ? _c("ShapeForm", {
                                    attrs: {
                                      shapeOptionsObj: _vm.shapeOptions
                                    },
                                    on: {
                                      "shape-options-changed": function(
                                        $event
                                      ) {
                                        _vm.shapeOptions = Object.assign(
                                          {},
                                          _vm.shapeOptions,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.symbolType === _vm.SYMBOL_TYPES.LINE
                                ? _c("LineForm", {
                                    attrs: { lineOptionsObj: _vm.lineOptions },
                                    on: {
                                      "line-options-changed": function($event) {
                                        _vm.lineOptions = Object.assign(
                                          {},
                                          _vm.lineOptions,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.symbolType === _vm.SYMBOL_TYPES.IMAGE
                                ? _c("ImageForm", {
                                    attrs: {
                                      imageOptionsObj: _vm.imageOptions
                                    },
                                    on: {
                                      "image-options-changed": function(
                                        $event
                                      ) {
                                        _vm.imageOptions = Object.assign(
                                          {},
                                          _vm.imageOptions,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.symbolType === _vm.SYMBOL_TYPES.POINT
                                ? _c("PointForm", {
                                    attrs: {
                                      pointOptionsObj: _vm.pointOptions
                                    },
                                    on: {
                                      "point-options-changed": function(
                                        $event
                                      ) {
                                        _vm.pointOptions = Object.assign(
                                          {},
                                          _vm.pointOptions,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "#3F51B5",
                                    type: "submit",
                                    dark: ""
                                  }
                                },
                                [_vm._v(" Create Symbol ")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }