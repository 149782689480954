var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showMakeSymbolInactiveDialog, width: "500px" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "font-weight-regular" }, [
            _vm._v(" Make Symbol Inactive ")
          ]),
          _c("v-card-text", [
            _vm._v(
              " If you make this symbol inactive, it will no longer show in the list of symbols, but it will still show in markups where it was already added. Do you want to make the symbol inactive? "
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel-symbol-inactive-dialog")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm-symbol-inactive-dialog")
                    }
                  }
                },
                [_vm._v(" Make Symbol Inactive ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }