var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between flex-grow-1 gap" },
    [
      _c(
        "div",
        { staticClass: "mt-2 flex-grow-1" },
        [
          _c("validation-provider", {
            attrs: { name: "Image URL" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  var valid = ref.valid
                  return [
                    _c("v-text-field", {
                      attrs: {
                        "error-messages": errors,
                        success: valid,
                        label: "Image URL",
                        color: "#3F51B5"
                      },
                      on: { change: _vm.onImageUrlChange },
                      model: {
                        value: _vm.imageOptions.imageUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.imageOptions, "imageUrl", $$v)
                        },
                        expression: "imageOptions.imageUrl"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("p", { staticClass: "pb-1 px-1 my-0 caption" }, [
            _vm._v("Symbol Size")
          ]),
          _c("v-slider", {
            attrs: { max: 40, min: 0, "hide-details": "" },
            on: {
              input: function($event) {
                return _vm.$emit("image-options-changed", _vm.imageOptions)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "number-input my-0 py-0 mt-n2",
                      attrs: { readonly: "", "hide-details": "" },
                      on: {
                        input: function($event) {
                          return _vm.$emit(
                            "image-options-changed",
                            _vm.imageOptions
                          )
                        }
                      },
                      model: {
                        value: _vm.imageOptions.size,
                        callback: function($$v) {
                          _vm.$set(_vm.imageOptions, "size", $$v)
                        },
                        expression: "imageOptions.size"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.imageOptions.size,
              callback: function($$v) {
                _vm.$set(_vm.imageOptions, "size", $$v)
              },
              expression: "imageOptions.size"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }