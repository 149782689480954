<template>
  <v-toolbar
    height="56px"
    class="elevation-0 flex-grow-0 py-0 my-0"
    dark
    color="#3F51B5"
  >
    <v-toolbar-items class="d-flex align-end">
      <v-select
        color="white"
        dark
        :items="mapChoices"
        v-model="selectedMapId"
        item-text="label"
        item-value="value"
        label="Map"
        :style="{
          'max-width': $vuetify.breakpoint.xsOnly ? '180px' : undefined,
        }"
        hide-details
        class="map-dropdown"
        @change="$emit('selected-map-id-changed', selectedMapId)"
      >
      </v-select>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn icon dark @click="$emit('select-map-screenshot-dialog-close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import {
  mdiMenu,
  mdiDotsVertical,
  mdiFormatListCheckbox,
  mdiLayers,
  mdiMap,
  mdiBell,
} from "@mdi/js";
import { db } from "@/mixins/utilisync-db";

export default {
  name: "MapScreenshotDialogToolbar",
  computed: {
    mapChoices() {
      return this.maps.map(({ map_id: mapId, name: label }) => ({
        value: mapId,
        label,
      }));
    },
  },
  props: {
    view: Object,
    mapIdSelected: String,
  },
  data() {
    return {
      mdiMenu,
      mdiDotsVertical,
      mdiFormatListCheckbox,
      mdiLayers,
      mdiMap,
      mdiBell,
      selectedMapId: undefined,
      maps: [],
    };
  },
  methods: {
    async getMaps() {
      this.maps = await db.maps.orderBy("name").toArray();
    },
  },
  async beforeMount() {
    await this.getMaps();
    this.selectedMapId = this.mapIdSelected;
  },
};
</script>

<style>
.map-dropdown.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.map-dropdown.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
