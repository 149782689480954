<template>
  <div class="pa-0 ma-0">
    <div
      class="
        d-flex
        flex-sm-nowrap flex-wrap
        justify-space-between
        align-center
        py-0
        mt-n3
      "
      style="height: 80px"
    >
      <div class="d-flex">
        <div>
          <div class="truncate mt-n1" :class="{ 'item-title': level > 0 }">
            {{ layer.utiliSyncLayerName }}
          </div>
          <div class="error--text">Failed to Load</div>
        </div>
      </div>
      <VisibilityMenu
        :mapServiceId="layer.mapServiceId"
        :allLayers="allLayers"
      />
    </div>
  </div>
</template>

<script>
import VisibilityMenu from "@/components/mapView/layers/visibility/VisibilityMenu";

export default {
  name: "FailedLayerDisplay",
  props: {
    layer: Object,
    allLayers: Array,
    level: Number,
  },
  components: {
    VisibilityMenu,
  },
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.item-title {
  font-size: 12px;
}
</style>
