<template>
  <v-row>
    <v-col
      cols="12"
      v-for="(v, index) in values"
      :key="index"
      class="mt-0 mb-0 pt-0 pb-0"
    >
      <template v-if="['TEXT', 'EMAIL'].includes(item.question.type)">
        <template v-if="item.question.isLong">
          <validation-provider
            slim
            v-slot="{ errors, valid, validated }"
            :name="item.question.label"
            :rules="{
              required: isRequired,
              email: item.question.type === 'EMAIL',
              max: Boolean(item.question.max_length)
                ? item.question.max_length
                : Infinity,
            }"
            ref="field"
          >
            <QuillEditor
              v-model="v.text"
              :label="`${item.number} ${item.question.label}`"
              :placeholder="item.question.placeholder"
              :validated="validated"
              :valid="valid"
              :errors="errors"
              :multipleItems="values.length > 1"
              :allowMultiple="item.allowMultiple"
              :disabled="isDisabled"
              :maxChars="+item.question.max_length"
              @remove-entry="removeEntry"
              @add-entry="addEntry"
              @input="onInput"
            />
          </validation-provider>
        </template>

        <template v-else>
          <template v-if="item.question.forInformationOnly">
            <label class="caption">
              {{ `${item.number} ${item.question.label}` }}
            </label>
            <p>{{ v.text }}</p>
          </template>
          <template v-else>
            <validation-provider
              slim
              v-slot="{ errors, valid }"
              :name="item.question.label"
              :rules="{
                required: isRequired,
                email: item.question.type === 'EMAIL',
                max: Boolean(item.question.max_length)
                  ? item.question.max_length
                  : Infinity,
              }"
              ref="field"
            >
              <v-text-field
                :key="index"
                v-model="v.text"
                :label="`${item.number} ${item.question.label}`"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="#3F51B5"
                :name="item.id"
                :hint="item.question.placeholder"
                persistent-hint
                @input="onInput"
                :disabled="isDisabled"
                :maxlength="item.question.max_length"
              >
                <v-icon
                  slot="append"
                  color="red"
                  @click="addEntry"
                  v-if="item.allowMultiple"
                >
                  {{ mdiPlus }}
                </v-icon>
                <v-icon
                  slot="append"
                  color="green"
                  @click="removeEntry(index)"
                  v-if="values.length > 1"
                >
                  {{ mdiMinus }}
                </v-icon>
              </v-text-field>
              <p
                class="caption"
                :style="{
                  color: getCharLimitTextColor(item.question, v.text),
                }"
              >
                {{ getCharLimitHint(item.question, v.text) }}
              </p>
            </validation-provider>
          </template>
        </template>
      </template>

      <template v-if="['NUMBER'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
            regex: /^\-?\d+(\.\d+)?$/,
          }"
          ref="field"
        >
          <v-text-field
            :key="index"
            v-model="v.text"
            :label="`${item.number} ${item.question.label}`"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="#3F51B5"
            :name="item.id"
            :hint="item.question.placeholder"
            persistent-hint
            @keyup="onNumberInput(errors)"
            @keydown="onNumberKeyDown"
            :disabled="isDisabled"
            :maxlength="item.question.max_length"
          >
            <v-icon
              slot="append"
              color="red"
              @click="addEntry"
              v-if="item.allowMultiple"
            >
              {{ mdiPlus }}
            </v-icon>
            <v-icon
              slot="append"
              color="green"
              @click="removeEntry(index)"
              v-if="values.length > 1"
            >
              {{ mdiMinus }}
            </v-icon>
          </v-text-field>
          <p
            class="caption"
            :style="{
              color: getCharLimitTextColor(item.question, v.text),
            }"
          >
            {{ getCharLimitHint(item.question, v.text) }}
          </p>
        </validation-provider>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";
import { mdiPlus, mdiMinus } from "@mdi/js";
import { cloneDeep } from "lodash";
import QuillEditor from "@/components/shared/QuillEditor";

export default {
  name: "TextInput",
  mixins: [dependantValueMixin],
  components: {
    QuillEditor,
  },
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String, Boolean, Number],
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  data() {
    return {
      mdiPlus,
      mdiMinus,
      values: [],
    };
  },
  methods: {
    getCharLimitTextColor(question, text) {
      if (question.max_length - (text ? text.toString().length : 0) === 0) {
        return "red";
      }
    },
    getCharLimitHint(question, text) {
      return question.max_length
        ? `${
            question.max_length - (text ? text.toString().length : 0)
          } characters left`
        : "";
    },
    addEntry() {
      this.values.push({ text: undefined });
      this.onInput();
    },
    removeEntry(index) {
      this.values.splice(index, 1);
      this.onInput();
      6;
    },
    async validateFields() {
      await this.$nextTick();
      const { item } = this;
      const { id, number, question } = item;
      if (Array.isArray(this.$refs.field)) {
        const validatePromises = this.$refs.field.map(async (f) => {
          const { valid } = await f.validateSilent();
          return valid;
        });
        const validationResults = await Promise.all(validatePromises);
        const allValid = validationResults.every((v) => Boolean(v));

        this.$emit("validated", {
          valid: allValid,
          label: `${number} ${question.label}`,
          id,
        });
      } else {
        const valid = await this.$refs.field?.validateSilent();
        this.$emit("validated", {
          valid,
          label: `${number} ${question.label}`,
          id,
        });
      }
    },
    async onInput() {
      if (this.values.length > 1) {
        const values = this.values.map((v) => v.text);
        this.$emit("input", values);
      } else {
        const [{ text }] = this.values;
        this.$emit("input", text);
      }
      await this.validateFields();
    },
    onNumberKeyDown(e) {
      if (["e", "+", "*", "/"].includes(e.key?.toLowerCase())) {
        e.preventDefault();
      }
    },
    async onNumberInput(errors) {
      if (errors.length > 0) {
        return;
      }

      if (this.values.length > 1) {
        const values = this.values.map((v) => +v.text);
        this.$emit("input", values);
      } else {
        const [{ text }] = this.values;
        this.$emit("input", +text);
      }
      await this.validateFields();
    },
  },
  computed: {
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  watch: {
    isRequired() {
      this.validateFields();
    },
  },
  mounted() {
    this.validateFields();
    if (Array.isArray(this.value)) {
      this.values = cloneDeep(this.value.map((v) => ({ text: v })));
      return;
    }
    this.values = [{ text: this.value }];
  },
};
</script>
