var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex px-4 pt-2 pb-0",
      on: {
        click: function($event) {
          return _vm.$emit("add-symbol", _vm.itemInfo.renderer)
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.viewSymbols,
              expression: "viewSymbols"
            }
          ],
          staticClass: "pa-0"
        },
        [
          _vm.symbolStyle === "circle"
            ? _c(
                "svg",
                { attrs: { overflow: "hidden", width: "15", height: "15" } },
                [
                  _c("circle", {
                    attrs: {
                      cx: "10",
                      cy: "10",
                      r: "5",
                      fill: _vm.fill,
                      "fill-opacity": _vm.itemInfo.opacity,
                      stroke: _vm.outlineStroke,
                      "stroke-opacity": _vm.itemInfo.opacity,
                      "stroke-width": _vm.strokeWidth,
                      "stroke-linecap": "butt",
                      "stroke-linejoin": "miter",
                      "stroke-miterlimit": _vm.strokeWidth
                    }
                  })
                ]
              )
            : ["solid", "dot"].includes(_vm.symbolStyle)
            ? _c(
                "svg",
                { attrs: { overflow: "hidden", width: "30", height: "30" } },
                [
                  _c("defs"),
                  _vm.symbolType === "simple-fill"
                    ? _c("path", {
                        attrs: {
                          fill: _vm.fill,
                          "fill-opacity": "0.5",
                          stroke: _vm.outlineStroke,
                          "stroke-opacity": "0.5",
                          "stroke-width": _vm.strokeWidth,
                          "stroke-linecap": "butt",
                          "stroke-linejoin": "miter",
                          "stroke-miterlimit": _vm.strokeWidth,
                          "stroke-dasharray": "16,12",
                          path: "M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z",
                          d: "M-10-10L 10 0L 10 10L-10 10L-10-10Z",
                          "fill-rule": "evenodd",
                          transform:
                            "matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                        }
                      })
                    : _vm.symbolType === "simple-line"
                    ? _c("path", {
                        attrs: {
                          fill: "none",
                          "fill-opacity": _vm.itemInfo.opacity,
                          stroke: _vm.simpleLineStroke,
                          "stroke-opacity": _vm.itemInfo.opacity,
                          "stroke-width": _vm.lineWidth,
                          "stroke-linecap": "butt",
                          "stroke-linejoin": "miter",
                          "stroke-miterlimit": _vm.lineWidth,
                          path: "M -15,0 L 15,0 E",
                          d: "M-15 0L 15 0",
                          "stroke-dasharray": "none",
                          transform:
                            "matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
                        }
                      })
                    : _vm._e()
                ]
              )
            : _c("img", {
                style: { width: _vm.imageWidth + "px" },
                attrs: { src: _vm.symbolUrl }
              })
        ]
      ),
      _c("div", { staticClass: "ml-3 mt-1" }, [_vm._v(_vm._s(_vm.itemInfo.id))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }