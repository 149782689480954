const DEFAULT_VALUES_TYPES = {
  ORGANIZATION: "ORGANIZATION",
  USER: "USER",
  CUSTOM: "CUSTOM",
  GIS_DATA: "GIS_DATA",
  GIS: "GIS",
  CURRENT: "CURRENT",
  LOCATE_DATA: "LOCATE_DATA",
  URL: "URL",
};

export default DEFAULT_VALUES_TYPES;
