<template>
  <v-dialog :value="showErrorConnectingToGisFeatureDialog" max-width="500px">
    <v-card>
      <v-card-text class="py-5">
        There was an error connecting to the GIS feature:
        {{ errorMsg }}. Verify you are signed in with an ArcGIS user and that
        you have access to the layer {{ layerName }}. Would you like to proceed
        with creating a new form? If you proceed, any form field that has a GIS
        field default will be blank.
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          @click="$emit('error-connecting-to-gis-feature-dialog-close')"
          color="#3F51B5"
        >
          Cancel
        </v-btn>
        <v-btn text @click="$emit('create-form-result')" color="#3F51B5">
          Create Form Result
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorConnectingToGisFeatureDialog",
  props: {
    showErrorConnectingToGisFeatureDialog: Boolean,
    gisInfoQueryResult: Object,
    selectedMapService: Object,
  },
  computed: {
    errorMsg() {
      return this.gisInfoQueryResult?.error?.message;
    },
    layerName() {
      return this.selectedMapService?.service_name;
    },
  },
};
</script>
