export const ASSIGN_TO = {
  SITE_CONTACT: "SITE_CONTACT",
  ORG_USER: "ORG_USER",
};

export const ASSIGN_TO_CHOICES = [
  { label: "Site Contact", value: ASSIGN_TO.SITE_CONTACT },
  { label: "Organization User", value: ASSIGN_TO.ORG_USER },
];

export const MANUALLY_ADD_EMAIL = "manually-add-email";
