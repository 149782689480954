<template>
  <div>
    <div v-if="Array.isArray(layers)" class="py-0 mt-n3">
      <div
        class="pa-0"
        v-for="(layer, index) of layers"
        :key="`${level}-${index}-${layer.mapServiceId}`"
      >
        <div class="py-0 mt-n3">
          <template v-if="layer.utiliSyncLayerType === 'S'">
            <div
              class="
                d-flex
                flex-sm-nowrap flex-wrap
                justify-space-between
                align-center
                py-0
                mt-n3
              "
              v-if="layer.loadStatus !== 'failed'"
            >
              <div class="d-flex">
                <v-btn
                  icon
                  @click="toggleShowSublayer(index)"
                  class="mt-n3"
                  v-if="
                    Array.isArray(getSublayerItems(layer)) &&
                    getSublayerItems(layer).length > 0
                  "
                >
                  <v-icon>
                    {{ !showSublayers[index] ? mdiChevronDown : mdiChevronUp }}
                  </v-icon>
                </v-btn>
                <div
                  class="truncate mt-n1"
                  :class="{ 'item-title': level > 0 }"
                >
                  {{ layer.id }}
                </div>
              </div>
              <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                <v-switch class="mr-1" v-model="layer.visible"> </v-switch>
                <VisibilityMenu
                  :mapServiceId="layer.mapServiceId"
                  :allLayers="allLayers"
                />
              </div>
            </div>
            <FailedLayerDisplay
              v-else
              :allLayers="allLayers"
              :layer="layer"
              :level="level"
            />
          </template>

          <template v-else-if="layer.utiliSyncLayerType === 'F'">
            <template
              v-if="
                layer.renderer &&
                ['unique-value', 'class-breaks'].includes(layer.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div>
                  <div
                    class="
                      d-flex
                      flex-sm-nowrap flex-wrap
                      justify-space-between
                      align-center
                      py-0
                      mt-n3
                    "
                  >
                    <div class="d-flex">
                      <v-btn
                        icon
                        @click="toggleShowSublayer(index)"
                        class="mt-n3"
                        v-if="
                          Array.isArray(getSublayerItems(layer)) &&
                          getSublayerItems(layer).length > 0
                        "
                      >
                        <v-icon>
                          {{
                            !showSublayers[index]
                              ? mdiChevronDown
                              : mdiChevronUp
                          }}
                        </v-icon>
                      </v-btn>
                      <div
                        class="truncate mt-n1"
                        :class="{ 'item-title': level > 0 }"
                      >
                        {{ layer.utiliSyncLayerName }}
                      </div>
                    </div>
                    <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                      <v-switch class="mr-1" v-model="layer.visible">
                      </v-switch>
                      <VisibilityMenu
                        :mapServiceId="layer.mapServiceId"
                        :allLayers="allLayers"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="layer.renderer" v-show="viewSymbols" class="mt-n5">
                  <div v-if="layer.renderer.type === 'unique-value'">
                    <RendererItemList
                      :infos="layer.renderer.uniqueValueInfos"
                      :viewSymbols="viewSymbols"
                    />
                  </div>

                  <div v-else-if="layer.renderer.type === 'class-breaks'">
                    <RendererItemList
                      :infos="layer.renderer.classBreakInfos"
                      :viewSymbols="viewSymbols"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                layer.renderer && ['simple'].includes(layer.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div
                  class="
                    d-flex
                    flex-sm-nowrap flex-wrap
                    justify-space-between
                    align-center
                    py-0
                    mt-n3
                  "
                >
                  <div class="d-flex flex-sm-nowrap flex-wrap">
                    <v-btn
                      icon
                      @click="toggleShowSublayer(index)"
                      class="mt-n3"
                      v-if="
                        Array.isArray(getSublayerItems(layer)) &&
                        getSublayerItems(layer).length > 0
                      "
                    >
                      <v-icon>
                        {{
                          !showSublayers[index] ? mdiChevronDown : mdiChevronUp
                        }}
                      </v-icon>
                    </v-btn>
                    <SimpleRendererItem
                      v-if="layer.renderer"
                      :itemInfo="layer"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                    <v-switch class="mr-1" v-model="layer.visible"> </v-switch>
                    <VisibilityMenu
                      :mapServiceId="layer.mapServiceId"
                      :allLayers="allLayers"
                    />
                  </div>
                </div>
              </div>
            </template>
            <FailedLayerDisplay
              v-else-if="layer.loadStatus === 'failed'"
              :allLayers="allLayers"
              :layer="layer"
              :level="level"
            />
          </template>

          <template v-else-if="['L', 'U'].includes(layer.utiliSyncLayerType)">
            <template
              v-if="
                layer.utilisyncRenderer.renderer_symbols &&
                layer.utilisyncRenderer.renderer_symbols.length > 1
              "
            >
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  align-center
                  py-0
                  mt-n3
                "
              >
                <div class="d-flex">
                  <div
                    class="truncate mt-n1"
                    :class="{ 'item-title': level > 0 }"
                  >
                    {{ layer.id }}
                  </div>
                </div>
                <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                  <v-switch class="mr-1" v-model="layer.visible"> </v-switch>
                </div>
              </div>

              <div class="pa-0 ma-0">
                <div v-if="layer.utilisyncRenderer" class="mt-n5">
                  <div
                    v-if="
                      layer.utilisyncRenderer.renderer_type === 'unique_value'
                    "
                  >
                    <RendererItemList
                      :infos="layer.utilisyncRenderer.renderer_symbols"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                  <div
                    v-else-if="
                      layer.utilisyncRenderer.renderer_type === 'class_break'
                    "
                  >
                    <RendererItemList
                      :infos="layer.utilisyncRenderer.renderer_symbols"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                layer.utilisyncRenderer.renderer_symbols &&
                layer.utilisyncRenderer.renderer_symbols.length <= 1
              "
            >
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  align-center
                  py-0
                  mt-n3
                "
                style="width: 100%"
              >
                <div class="d-flex flex-sm-nowrap flex-wrap">
                  <v-btn
                    icon
                    @click="toggleShowSublayer(index)"
                    class="mt-n3"
                    v-if="
                      Array.isArray(getSublayerItems(layer)) &&
                      getSublayerItems(layer).length > 0
                    "
                  >
                    <v-icon>
                      {{ !showSublayers ? mdiChevronDown : mdiChevronUp }}
                    </v-icon>
                  </v-btn>
                  <UtilisyncRendererItem
                    :itemInfo="layer.utilisyncRenderer.renderer_symbols[0]"
                    :viewSymbols="viewSymbols"
                    :layer="layer"
                  />
                </div>
                <div>
                  <v-switch class="mt-4 mr-1" v-model="layer.visible">
                  </v-switch>
                </div>
              </div>
            </template>
            <template v-else-if="!layer.utilisyncRenderer.renderer_symbols">
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  align-center
                  py-0
                  mt-n3
                "
                style="width: 100%"
              >
                <div class="d-flex flex-sm-nowrap flex-wrap">
                  <v-btn
                    icon
                    @click="toggleShowSublayer(index)"
                    class="mt-n3"
                    v-if="
                      Array.isArray(getSublayerItems(layer)) &&
                      getSublayerItems(layer).length > 0
                    "
                  >
                    <v-icon>
                      {{ !showSublayers ? mdiChevronDown : mdiChevronUp }}
                    </v-icon>
                  </v-btn>
                </div>
                <section class="px-2">
                  <div
                    style="
                      background-color: black;
                      border-radius: 50%;
                      height: 15px;
                      width: 15px;
                    "
                  ></div>
                </section>
                <div class="px-2">{{ layer.utiliSyncLayerName }}</div>
                <div>
                  <v-switch class="mt-4 mr-1" v-model="layer.visible">
                  </v-switch>
                </div>
              </div>
            </template>
            <FailedLayerDisplay
              v-else-if="layer.loadStatus === 'failed'"
              :allLayers="allLayers"
              :layer="layer"
              :level="level"
            />
          </template>

          <template v-else>
            <div class="pa-0 ma-0" v-if="layer.loadStatus !== 'failed'">
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  ma-0
                  pa-0
                  align-center
                  py-0
                  mt-n3
                "
              >
                <div class="d-flex flex-sm-nowrap flex-wrap">
                  <div v-show="viewSymbols">
                    <RendererItem :itemInfo="layer.renderer" />
                  </div>
                  <div class="truncate mt-n1">
                    {{ layer.utiliSyncLayerName }}
                  </div>
                </div>
                <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                  <v-switch class="mt-4 mr-1" v-model="layer.visible">
                  </v-switch>
                  <VisibilityMenu :index="index" :allLayers="allLayers" />
                </div>
              </div>
            </div>
            <FailedLayerDisplay
              v-else
              :allLayers="allLayers"
              :layer="layer"
              :level="level"
            />
          </template>
        </div>

        <div
          v-if="
            Array.isArray(getSublayerItems(layer)) &&
            getSublayerItems(layer).length > 0 &&
            showSublayers[index]
          "
        >
          <ChildVisibility
            :items="getSublayerItems(layer)"
            :level="level + 1"
            :childMultiLayerImages="getChildMultiLayerImages(layer)"
            :layer="layer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RendererItem from "@/components/shared/RendererItem";
import RendererItemList from "@/components/shared/RendererItemList";
import UtilisyncRendererItem from "@/components/shared/UtilisyncRendererItem";
import SimpleRendererItem from "@/components/shared/SimpleRendererItem";
import VisibilityMenu from "@/components/mapView/layers/visibility/VisibilityMenu";
import FailedLayerDisplay from "@/components/mapView/layers/visibility/FailedLayerDisplay";
import { mdiDotsVertical, mdiChevronDown, mdiChevronUp } from "@mdi/js";
import ChildVisibility from "@/components/mapView/layers/visibility/ChildVisibility";

export default {
  name: "Visibility",
  props: {
    layers: Array,
    viewSymbols: Boolean,
    level: Number,
    allLayers: Array,
    multiLayerImages: Array,
  },
  components: {
    RendererItem,
    RendererItemList,
    UtilisyncRendererItem,
    SimpleRendererItem,
    VisibilityMenu,
    ChildVisibility,
    FailedLayerDisplay,
  },
  methods: {
    getSublayerItems(layer) {
      return layer?.sublayers?.items;
    },
    toggleShowSublayer(index) {
      this.$set(this.showSublayers, index, !this.showSublayers[index]);
    },
    getChildMultiLayerImages(layer) {
      const { multiLayerImages } = this;
      return (
        multiLayerImages?.find(
          (l) => l?.layer?.mapServiceId === layer?.mapServiceId
        )?.layers ?? []
      );
    },
  },
  data() {
    return {
      mdiDotsVertical,
      showSublayers: [],
      mdiChevronDown,
      mdiChevronUp,
    };
  },
  watch: {
    layers: {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.showSublayers = val.map(() => false);
        }
      },
    },
  },
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.item-title {
  font-size: 12px;
}
</style>

<style>
.group-toggle {
  cursor: pointer;
}
</style>
