var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { "max-width": "500px", value: _vm.showFormInfoDialog } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h4", { staticClass: "font-weight-regular" }, [
              _vm._v("Form Details")
            ])
          ]),
          _c("v-card-text", [_vm._v(" ID: " + _vm._s(_vm.formResultId) + " ")]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("form-info-dialog-close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }