import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";

export default {
  methods: {
    getActionItemLabel(actionItem) {
      if (
        actionItem.status === ACTION_ITEM_STATUSES.READY_FOR_REVIEW ||
        actionItem.status === "ready_for_review"
      ) {
        return "Review";
      } else if (
        actionItem.status === ACTION_ITEM_STATUSES.CLOSED ||
        actionItem.status === ACTION_ITEM_STATUSES.CANCELED
      ) {
        return "View";
      }
      return "Complete";
    },
  },
};
