<template>
  <v-dialog :value="showSelectMapScreenshotDialog" fullscreen persistent>
    <v-card class="d-flex flex-column">
      <MapScreenshotDialogToolbar
        :mapIdSelected="mapIdSelected"
        @select-map-screenshot-dialog-close="
          $emit('select-map-screenshot-dialog-close')
        "
        @selected-map-id-changed="selectedMapId = $event"
        ref="mapViewTopBar"
      />

      <v-card-text class="flex-grow-1 d-flex flex-column pa-0 ma-0">
        <div id="maskDiv" :style="maskDivStyles" v-show="showMaskDiv"></div>

        <MapScreenshotDialogEsriMapView
          class="d-flex flex-column flex-grow-1"
          :mapIdSelected="selectedMapId"
          @map-created="onMapCreated"
        />

        <v-snackbar v-model="showMapScreenshotRToast" :timeout="-1">
          Drag to select area on map to screenshot
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="$emit('select-map-screenshot-dialog-close')"
            >
              Cancel
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MapScreenshotDialogEsriMapView from "@/components/tickets/shared/photo-input/add-image-dialog/select-map-screenshot-dialog/MapScreenshotDialogEsriMapView";
import MapScreenshotDialogToolbar from "@/components/tickets/shared/photo-input/add-image-dialog/select-map-screenshot-dialog/MapScreenshotDialogToolbar";

const clamp = (value, from, to) => {
  return value < from ? from : value > to ? to : value;
};

export default {
  name: "SelectMapScreenshotDialog",
  props: {
    showSelectMapScreenshotDialog: Boolean,
  },
  components: {
    MapScreenshotDialogEsriMapView,
    MapScreenshotDialogToolbar,
  },
  data() {
    return {
      map: undefined,
      view: undefined,
      maskDivStyles: {
        position: "absolute",
        background: "rgba(255, 51, 0, 0.1)",
        border: "2px dashed rgb(255, 51, 0)",
        "z-index": 500,
      },
      showMaskDiv: false,
      base64Images: [],
      selectedMapId: undefined,
      showMapScreenshotRToast: false,
    };
  },
  mounted() {
    this.selectedMapId = this.mapIdSelected;
    this.showMapScreenshotRToast = true;
  },
  computed: {
    ...mapGetters(["mapIdSelected"]),
  },
  methods: {
    setMaskPosition(area) {
      if (area) {
        this.showMaskDiv = true;
        this.maskDivStyles = {
          ...this.maskDivStyles,
          left: `${area.x}px`,
          top: `${area.y}px`,
          width: `${area.width}px`,
          height: `${area.height}px`,
        };
      } else {
        this.showMaskDiv = false;
      }
    },
    async onMapCreated({ map, view }) {
      await this.$nextTick();
      this.map = map;
      this.view = view;
      this.enableDragSelectionOnMap();
    },
    enableDragSelectionOnMap() {
      const dragHandler = this.view.on("drag", async (event) => {
        event.stopPropagation();

        if (event.action !== "end") {
          const xmin = clamp(
            Math.min(event.origin.x, event.x),
            0,
            this.view.width
          );
          const xmax = clamp(
            Math.max(event.origin.x, event.x),
            0,
            this.view.width
          );
          const ymin = clamp(
            Math.min(event.origin.y, event.y),
            0,
            this.view.height
          );
          const ymax = clamp(
            Math.max(event.origin.y, event.y),
            0,
            this.view.height
          );
          this.selectedMapArea = {
            x: xmin,
            y: ymin + this.$refs.mapViewTopBar.$el.clientHeight,
            width: xmax - xmin,
            height: ymax - ymin,
          };
          this.setMaskPosition(this.selectedMapArea);
        } else {
          dragHandler.remove();
          const { selectedMapArea } = this;
          const screenshot = await this.view.takeScreenshot({
            area: selectedMapArea,
            format: "png",
          });
          this.$emit("screenshot-taken", screenshot);
        }
      });
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
