var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showTestArcGisLayerConnectionDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Test ArcGIS Layer Connection")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("test-connection-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-5" }, [
            _c(
              "form",
              [
                _c(
                  "validation-observer",
                  { ref: "form" },
                  [
                    _c("validation-provider", {
                      attrs: { name: "arcGisServiceUrl", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            var valid = ref.valid
                            return [
                              _c(
                                "section",
                                { staticClass: "d-flex align-center gap" },
                                [
                                  _c("v-textarea", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "error-messages": errors,
                                      success: valid,
                                      label: "ArcGIS Service URL *",
                                      color: "#3F51B5",
                                      "hide-details": "",
                                      disabled: "",
                                      "auto-grow": "",
                                      rows: "1"
                                    },
                                    model: {
                                      value: _vm.arcGisServiceUrl,
                                      callback: function($$v) {
                                        _vm.arcGisServiceUrl = $$v
                                      },
                                      expression: "arcGisServiceUrl"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        dark: "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.openUrl }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.mdiOpenInNew))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.submitted
                                ? [
                                    _vm.error
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "caption my-0 py-0",
                                            staticStyle: { color: "red" }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.error) + " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticClass: "caption my-0 py-0",
                                            staticStyle: { color: "green" }
                                          },
                                          [
                                            _vm._v(
                                              " Success! Connected to ArcGIS Feature Service. "
                                            )
                                          ]
                                        )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c(
                      "validation-provider",
                      [
                        _c("v-switch", {
                          attrs: { label: "Secured Service", disabled: "" },
                          model: {
                            value: _vm.securedService,
                            callback: function($$v) {
                              _vm.securedService = $$v
                            },
                            expression: "securedService"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "#3F51B5" },
                  on: { click: _vm.testUrl }
                },
                [_vm._v(" Test Connection ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }