var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between flex-grow-1 gap" },
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("p", { staticClass: "pb-1 my-0 caption" }, [_vm._v("Shape")]),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "pa-0 d-flex",
                              attrs: { text: "", "x-small": "" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm.shapeOptions.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
                            ? _c("img", {
                                style: {
                                  width: _vm.shapeOptions.size + "px",
                                  height: _vm.shapeOptions.size + "px"
                                },
                                attrs: {
                                  src: require("@/assets/img/mapSymbols/" +
                                    _vm.shapeOptions.shapeImageFillColor +
                                    _vm.shapeOptions.shapeSymbol +
                                    ".png")
                                }
                              })
                            : _vm.shapeOptions.shape === _vm.SHAPE_TYPES.ELLIPSE
                            ? _c("div", [
                                _c("div", {
                                  style: {
                                    width: "30px",
                                    height: "25px",
                                    "border-radius": "50%",
                                    "background-color":
                                      _vm.shapeOptions.shapeFill,
                                    border:
                                      "1px solid " +
                                      _vm.shapeOptions.shapeOutlineColor
                                  }
                                })
                              ])
                            : _vm.shapeOptions.shape ===
                              _vm.SHAPE_TYPES.RECTANGLE
                            ? _c("div", [
                                _c("div", {
                                  style: {
                                    width: "30px",
                                    height: "25px",
                                    "background-color":
                                      _vm.shapeOptions.shapeFill,
                                    border:
                                      "1px solid " +
                                      _vm.shapeOptions.shapeOutlineColor
                                  }
                                })
                              ])
                            : _vm.shapeOptions.shape === _vm.SHAPE_TYPES.POLYGON
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color:
                                          _vm.shapeOptions.shapeOutlineColor
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiVectorPolygon) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-icon", { staticClass: "my-0 py-0" }, [
                            _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          _vm.shapeOptions.shape = _vm.SHAPE_TYPES.RECTANGLE
                          _vm.$emit("shape-options-changed", _vm.shapeOptions)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiVectorRectangle) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          _vm.shapeOptions.shape = _vm.SHAPE_TYPES.POLYGON
                          _vm.$emit("shape-options-changed", _vm.shapeOptions)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiVectorPolygon) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          _vm.shapeOptions.shape = _vm.SHAPE_TYPES.ELLIPSE
                          _vm.$emit("shape-options-changed", _vm.shapeOptions)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiEllipseOutline) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.symbols, function(s) {
                    return _c(
                      "v-list-item",
                      {
                        key: s,
                        on: {
                          click: function($event) {
                            _vm.shapeOptions.shape = _vm.SHAPE_TYPES.MAP_SYMBOL
                            _vm.shapeOptions.shapeSymbol = s
                            _vm.shapeOptions.shapeUrl =
                              "/img/mapSymbols/" +
                              _vm.shapeOptions.shapeImageFillColor +
                              _vm.shapeOptions.shapeSymbol +
                              ".png"
                            _vm.$emit("shape-options-changed", _vm.shapeOptions)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "d-flex justify-center align-center" },
                          [
                            _c("img", {
                              staticStyle: { width: "30px", height: "30px" },
                              attrs: {
                                src: require("@/assets/img/mapSymbols/" +
                                  _vm.shapeOptions.shapeImageFillColor +
                                  s +
                                  ".png")
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("p", { staticClass: "pb-1 my-0 caption settings-heading" }, [
            _vm._v("Color")
          ]),
          _vm.shapeOptions.shape === _vm.SHAPE_TYPES.MAP_SYMBOL
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pa-0 d-flex",
                                    attrs: { text: "", "x-small": "" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      "background-color":
                                        _vm.shapeOptions.shapeFill,
                                      width: "25px",
                                      height: "25px",
                                      border: "1px solid black"
                                    }
                                  },
                                  [_vm._v("   ")]
                                ),
                                _c("v-icon", { staticClass: "my-0 py-0" }, [
                                  _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3583292388
                  )
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.colorChoices, function(choice) {
                      return _c(
                        "v-list-item",
                        {
                          key: choice.value,
                          on: {
                            click: function($event) {
                              _vm.shapeOptions.shapeFill = choice.value
                              _vm.shapeOptions.shapeImageFillColor = _vm.getShapeFillColor(
                                choice.value
                              )
                              _vm.shapeOptions.shapeUrl =
                                "/img/mapSymbols/" +
                                _vm.shapeOptions.shapeImageFillColor +
                                _vm.shapeOptions.shapeSymbol +
                                ".png"
                              _vm.$emit(
                                "shape-options-changed",
                                _vm.shapeOptions
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mr-2",
                                  style: {
                                    "background-color": choice.value,
                                    width: "25px",
                                    height: "25px"
                                  }
                                },
                                [_vm._v("   ")]
                              ),
                              _c("div", [_vm._v(_vm._s(choice.label))])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pa-0 d-flex",
                                  attrs: { text: "", "x-small": "" }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "div",
                                {
                                  style: {
                                    "background-color":
                                      _vm.shapeOptions.shapeFill,
                                    width: "25px",
                                    height: "25px",
                                    border: "1px solid black"
                                  }
                                },
                                [_vm._v("   ")]
                              ),
                              _c("v-icon", { staticClass: "my-0 py-0" }, [
                                _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("ColorPicker", {
                            on: {
                              "color-picker-cancelled": function($event) {
                                _vm.showFillDialog = false
                              },
                              input: function($event) {
                                _vm.showFillDialog = false
                                _vm.$emit(
                                  "shape-options-changed",
                                  _vm.shapeOptions
                                )
                              }
                            },
                            model: {
                              value: _vm.shapeOptions.shapeFill,
                              callback: function($$v) {
                                _vm.$set(_vm.shapeOptions, "shapeFill", $$v)
                              },
                              expression: "shapeOptions.shapeFill"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.shapeOptions.shape !== _vm.SHAPE_TYPES.MAP_SYMBOL
        ? _c(
            "div",
            { staticClass: "mt-2", staticStyle: { width: "120px" } },
            [
              _c("p", { staticClass: "pb-1 my-0 caption settings-heading" }, [
                _vm._v("Outline Color")
              ]),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pa-0 d-flex",
                                    attrs: { text: "", "x-small": "" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      width: "25px",
                                      height: "25px",
                                      "background-color":
                                        _vm.shapeOptions.shapeOutlineColor,
                                      border: "1px solid black"
                                    }
                                  },
                                  [_vm._v("   ")]
                                ),
                                _c("v-icon", { staticClass: "my-0 py-0" }, [
                                  _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3372745878
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("ColorPicker", {
                            on: {
                              "color-picker-cancelled": function($event) {
                                _vm.showFillDialog = false
                              },
                              input: function($event) {
                                _vm.showFillDialog = false
                                _vm.$emit(
                                  "shape-options-changed",
                                  _vm.shapeOptions
                                )
                              }
                            },
                            model: {
                              value: _vm.shapeOptions.shapeOutlineColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.shapeOptions,
                                  "shapeOutlineColor",
                                  $$v
                                )
                              },
                              expression: "shapeOptions.shapeOutlineColor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-2", staticStyle: { width: "25%" } },
        [
          _c("p", { staticClass: "pb-1 my-0 caption" }, [
            _vm._v("Symbol Size")
          ]),
          _c("v-slider", {
            attrs: { max: 64, min: 0, "hide-details": "" },
            on: {
              input: function($event) {
                return _vm.$emit("shape-options-changed", _vm.shapeOptions)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "number-input my-0 py-0 mt-n2",
                      staticStyle: { width: "20px" },
                      attrs: { readonly: "", "hide-details": "" },
                      model: {
                        value: _vm.shapeOptions.size,
                        callback: function($$v) {
                          _vm.$set(_vm.shapeOptions, "size", $$v)
                        },
                        expression: "shapeOptions.size"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.shapeOptions.size,
              callback: function($$v) {
                _vm.$set(_vm.shapeOptions, "size", $$v)
              },
              expression: "shapeOptions.size"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }