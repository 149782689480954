<template>
  <v-dialog :value="showSelectDocDialog" max-width="400px" persistent>
    <v-card>
      <v-toolbar dark color="#3F51B5" class="elevation-0">
        <v-toolbar-title>Select Doc</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('select-doc-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <p class="caption py-0 my-0">Folder</p>

        <DocsBreadcrumb
          :folderPath="currentFolder"
          @navigate-folder="onNavigateToFolder"
          class="py-2"
        />

        <v-divider class="my-2"></v-divider>

        <div v-if="foldersAtLevel.length > 0 || filteredFiles.length > 0">
          <div
            class="d-flex gap align-center py-1 cursor-pointer"
            v-for="f of foldersAtLevel"
            :key="f.folder"
            @click="onFolderClick(f.folder)"
          >
            <div>
              <v-icon color="#3F51B5">
                {{ mdiFolder }}
              </v-icon>
            </div>
            <div :style="{ color: '#00A9F4' }">
              {{ f.folder.split("/").slice(-1)[0] }}
            </div>
          </div>

          <div
            class="d-flex gap align-center py-1 cursor-pointer"
            v-for="f of filteredFiles"
            :key="f.file_id"
            @click="onFileClick(f)"
          >
            <div>
              <v-icon color="#4F4F4F">
                {{ mdiFile }}
              </v-icon>
            </div>
            <div :style="{ color: '#00A9F4' }" class="truncate">
              {{ f.name }}
            </div>
          </div>
        </div>
        <div v-else>No images in this folder</div>

        <SelectPdfPageDialog
          v-if="showSelectPdfPageDialog"
          :showSelectPdfPageDialog="showSelectPdfPageDialog"
          :selectedFile="selectedFile"
          :maxWidthHeight="+maxWidthHeight"
          :formResultId="formResultId"
          @select-pdf-page-dialog-close="showSelectPdfPageDialog = false"
          @confirm-add-page="onConfirmAddPage"
        />

        <AddPageToFormDialog
          v-if="showAddPageToFormDialog"
          :showAddPageToFormDialog="showAddPageToFormDialog"
          :selectedPage="selectedPage"
          :maxWidthHeight="+maxWidthHeight"
          :formResultId="formResultId"
          @add-page-to-form-dialog-close="showAddPageToFormDialog = false"
          @confirm-add-page="onConfirmAddPage"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiHome, mdiFolder, mdiFile } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import DocsBreadcrumb from "@/components/mapView/docs-tab/DocsBreadcrumb.vue";
import SelectPdfPageDialog from "@/components/tickets/shared/photo-input/add-image-dialog/select-doc-dialog/SelectPdfPageDialog";
import AddPageToFormDialog from "@/components/tickets/shared/photo-input/add-image-dialog/select-doc-dialog/select-pdf-page-dialog/AddPageToFormDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SelectDocDialog",
  props: {
    showSelectDocDialog: Boolean,
    formDefinition: Object,
    maxWidthHeight: Number,
    formResultId: String,
    objectId: Number,
    globalId: String,
  },
  components: {
    DocsBreadcrumb,
    SelectPdfPageDialog,
    AddPageToFormDialog,
  },
  data() {
    return {
      mdiHome,
      mdiFolder,
      mdiFile,
      folders: [],
      files: [],
      openFolders: [],
      currentFolder: undefined,
      selectedFile: {},
      level: 1,
      showSelectPdfPageDialog: false,
      showAddPageToFormDialog: false,
      selectedPage: {},
    };
  },
  computed: {
    allFolders() {
      const folders = this.folders
        ?.map((f) => {
          const splitPath = f.folder.split("/");
          const subfolders = Array(splitPath.length)
            .fill()
            .map((_, i) => splitPath.slice(0, i + 1).join("/"));
          return [...subfolders];
        })
        .flat()
        .filter((f) => Boolean(f) && !f.includes("undefined"));
      const foldersWithoutDups = [...new Set(folders)];
      return foldersWithoutDups.map((folderName) => {
        const folder = this.folders?.find((fs) =>
          fs.folder.includes(folderName)
        );
        return {
          folder: folderName,
          cover_image_url: folder?.cover_image_url,
        };
      });
    },
    foldersAtLevel() {
      return this.allFolders?.filter(
        (f) => f?.folder?.split("/")?.length === this.level
      );
    },
    selectedMapServiceId() {
      return this.formDefinition?.map_service_id;
    },
    filteredFiles() {
      return this.files.filter((f) => {
        return (
          f.name.endsWith("png") ||
          f.name.endsWith("jpg") ||
          f.name.endsWith("jpeg") ||
          (f.name.endsWith("pdf") && f.pdf_page_image_file_count > 0)
        );
      });
    },
  },
  async beforeMount() {
    await this.getFolders();
    await this.getFiles();
  },
  methods: {
    onConfirmAddPage(file) {
      this.$emit("image-file-selected", file);
      this.showSelectPdfPageDialog = false;
      this.showAddPageToFormDialog = false;
    },
    async onFileClick(file) {
      if (
        file.name.endsWith("jpeg") ||
        file.name.endsWith("png") ||
        file.name.endsWith("jpg")
      ) {
        const { s3_file_path: dataUrl, file_id: id } = file;
        const image = {
          dataUrl,
          url: dataUrl,
          description: "",
          id,
          ...file,
          uploaded: true,
          isImage: true,
        };
        this.$emit("image-file-selected", image);
      } else if (file.name.endsWith("pdf")) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/files/${file.file_id}/images`
        );
        if (results.length > 1) {
          this.showSelectPdfPageDialog = true;
        } else {
          const {
            data: { results },
          } = await axiosWithRegularAuth.get(
            `${APIURL}/files/${file.file_id}/images`
          );
          const [page] = results;
          this.selectedPage = page;
          this.showAddPageToFormDialog = true;
        }
        this.selectedFile = file;
      }
    },
    async onNavigateToFolder(index) {
      if (typeof index === "undefined") {
        this.currentFolder = undefined;
        this.level = 1;
        await this.getFiles();
        return;
      }
      this.currentFolder = this.currentFolder
        ?.split("/")
        ?.slice(0, index + 1)
        ?.join("/");
      this.level = index + 2;
      await this.getFiles();
    },
    onFolderClick(folderName) {
      this.level++;
      this.currentFolder = folderName;
      this.getFiles();
    },
    async getFolders() {
      const { objectId, globalId, selectedMapServiceId } = this;
      if (!selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/map_services/${selectedMapServiceId}/features/${globalId}/folders`,
        {
          params: {
            object_id: objectId,
          },
        }
      );
      this.folders = results;
    },
    async getFiles() {
      const { currentFolder, objectId, globalId } = this;
      if (!this.selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/map_services/${this.selectedMapServiceId}/features/${globalId}/files`,
        { params: { folder: currentFolder, object_id: objectId } }
      );
      this.files = results.filter((r) => !r.created_from_pdf_file_id);
    },
  },
  watch: {
    async globalId() {
      await this.getFolders();
      await this.getFiles();
    },
    async featureId() {
      await this.getFolders();
      await this.getFiles();
    },
    async selectedMapServiceId() {
      await this.getFolders();
      await this.getFiles();
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
