<template>
  <validation-provider
    v-slot="{ errors, valid, validated }"
    :name="item.question.label"
    :rules="{
      required: isRequired,
    }"
    ref="field"
  >
    <v-card class="elevation-0" width="100%">
      <v-card-title
        class="ma-0 pa-0 d-flex flex-row justify-space-between elevation-0"
      >
        <p
          class="caption"
          :style="{ color: validated && !valid ? '#ff5252' : undefined }"
        >
          {{ `${item.number} ${item.question.label}` }}
        </p>
        <v-btn
          text
          @click="clearSignature"
          color="#3F51B5"
          class="mt-n3"
          :disabled="isDisabled"
        >
          Clear
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 mb-2">
        <VueSignaturePad
          width="100%"
          height="150px"
          ref="signaturePad"
          :options="{ onBegin, onEnd }"
          :customStyle="{
            border: `${$vuetify.breakpoint.xsOnly ? '3px' : '2px'} solid ${
              validated && !valid ? '#ff5252' : 'black'
            }`,
          }"
        />
        <p
          class="caption"
          v-if="!valid"
          :style="{ color: validated && !valid ? '#ff5252' : undefined }"
        >
          {{ errors[0] }}
        </p>
      </v-card-text>
    </v-card>
  </validation-provider>
</template>
<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";

export default {
  name: "SignaturePad",
  props: {
    value: String,
    item: Object,
    formDefinition: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  mixins: [dependantValueMixin],
  computed: {
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
  },
  methods: {
    async validateField() {
      await this.$nextTick();
      const { valid } = await this.$refs.field.validate(this.value);
      const { item } = this;
      const { id, number, question } = item;
      this.$emit("validated", {
        valid,
        label: `${number} ${question.label}`,
        id,
      });
    },
    async onBegin() {
      await this.validateField();
    },
    async onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.$emit("input", data);
      }
      await this.validateField();
    },
    async clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.$emit("input", "");
      await this.validateField();
    },
    onResize() {
      const canvas = this.$refs.signaturePad.$el.querySelector("canvas");
      if (this.$vuetify.breakpoint.xsOnly) {
        this.$refs.signaturePad.$el.style.width = `${window.innerWidth - 75}px`;
        this.$refs.signaturePad.$el.style.height = `${
          (window.innerWidth - 75) * (150 / 520)
        }px`;
        canvas.width = window.innerWidth - 79;
        canvas.height = (window.innerWidth - 79) * (146 / 516);
        canvas.style.width = `${window.innerWidth - 79}px`;
        canvas.style.height = `${(window.innerWidth - 79) * (146 / 516)}px`;
      } else {
        this.$refs.signaturePad.$el.style.width = "520px";
        this.$refs.signaturePad.$el.style.height = "150px";
        canvas.width = 516;
        canvas.height = 146;
        canvas.style.width = "516px";
        canvas.style.height = "146px";
      }
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      };
      img.src = this.value;
    },
  },
  watch: {
    isRequired() {
      this.validateField();
    },
    isDisabled() {
      if (!this.isDisabled) {
        this.$refs.signaturePad.openSignaturePad();
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    if (this.isDisabled) {
      this.$refs.signaturePad.lockSignaturePad();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
