<template>
  <svg :width="size" :height="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <line
        :stroke-width="strokeWidth"
        :fill="fillColor"
        :stroke="strokeColor"
        :x1="0"
        :y1="size * 0.5"
        :x2="size"
        :y2="size * 0.5"
        id="svg_2"
      />
      <line
        :stroke-width="strokeWidth"
        id="svg_1"
        :y2="size"
        :x2="size * 0.5"
        :y1="0"
        :x1="size * 0.5"
        :stroke="strokeColor"
        :fill="fillColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Cross",
  props: {
    itemInfo: Object,
  },
  computed: {
    fillColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    size() {
      return this.itemInfo?.symbol?.size;
    },
    strokeColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.outline?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    strokeWidth() {
      const { width } = this.itemInfo?.symbol?.outline ?? {};
      return width ?? 1;
    },
  },
};
</script>
