var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      ["URL"].includes(_vm.item.information.type)
        ? _c("v-card", { staticClass: "elevation-0" }, [
            _c(
              "a",
              { attrs: { href: _vm.item.information.url, target: "_blank" } },
              [_vm._v(" " + _vm._s(_vm.item.information.label) + " ")]
            )
          ])
        : _vm._e(),
      ["HEADER"].includes(_vm.item.information.type)
        ? _c("v-card", { staticClass: "elevation-0" }, [
            _c("h2", [_vm._v(" " + _vm._s(_vm.item.information.label) + " ")])
          ])
        : _vm._e(),
      ["TEXT"].includes(_vm.item.information.type)
        ? _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("v-card-text", { staticClass: "pa-0 ma-0" }, [
                _c("div", {
                  staticClass: "raw-html",
                  domProps: { innerHTML: _vm._s(_vm.item.information.label) }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      ["IMAGE"].includes(_vm.item.information.type)
        ? _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.information.url,
                  expression: "item.information.url"
                }
              ],
              staticClass: "pa-2 my-6",
              attrs: { "max-height": "fit-content", "max-width": "fit-content" }
            },
            [
              _c("v-card-text", [
                _c("p", [_vm._v(_vm._s(_vm.item.information.label))]),
                _c(
                  "a",
                  {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      href: _vm.item.information.openUrl,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "pa-0 ma-0",
                      style: {
                        "max-height": "500px",
                        "max-width": "500px"
                      },
                      attrs: { src: _vm.item.information.url }
                    })
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }