<template>
  <v-dialog max-width="500px" :value="showInvalidFieldsDialog">
    <v-toolbar dark color="#3F51B5" max-width="500px">
      <v-toolbar-title>
        <div>Invalid Fields</div>
      </v-toolbar-title>
      <v-spacer />
      <v-btn text color="white" @click="$emit('invalid-fields-dialog-close')">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="rounded-0">
      <v-card-text class="overflow-y-auto" style="height: 60vh">
        <div class="text-center pt-3">
          The following form fields contain invalid data or are required to be
          completed before the form can be submitted as final.
        </div>
        <v-list>
          <v-list-item v-for="(section, i) of invalidFieldSections" :key="i">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <b>{{ section.name }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(item, j) of section.items" :key="j">
                <v-list-item-content>
                  {{ item.number }} {{ item.question.label }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          color="#3F51B5"
          @click="$emit('invalid-fields-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "InvalidFieldsDialog",
  props: {
    showInvalidFieldsDialog: Boolean,
    invalidFieldSections: Array,
  },
  data() {
    return { mdiClose };
  },
};
</script>
