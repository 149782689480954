<template>
  <v-card class="m-2" flat>
    <v-card-text>
      <v-row justify="space-around">
        <v-color-picker
          :value="color"
          hide-canvas
          hide-inputs
          show-swatches
          mode.sync="rgba"
          @update:color="onUpdate"
        />
      </v-row>
      <v-row>
        <v-col class="text-right" cols="12">
          <v-btn
            elevation="2"
            type="button"
            color="#3F51B5"
            @click="$emit('color-picker-cancelled')"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const parse = require("parse-color");

export default {
  name: "ColorPicker",
  props: {
    value: String,
  },
  data() {
    return {
      color: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        const { rgba } = parse(val);
        const [r, g, b, a] = rgba;
        this.color = { r, g, b, a };
      },
      immediate: true,
    },
  },
  methods: {
    onUpdate({ rgba }) {
      const { r, g, b, a } = rgba;
      const val = `rgba(${r},${g},${b},${a})`;
      this.$emit("input", val);
    },
  },
};
</script>
