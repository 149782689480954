<template>
  <div
    class="d-flex justify-space-between px-3 py-2 rounded-0"
    style="background-color: #38383d; color: white"
  >
    <div class="d-flex gap justify-space-between flex-wrap flex-grow-1">
      <div class="d-flex gap flex-wrap">
        <div
          v-if="itemType === ITEM_TYPES.TEXT"
          style="height: 45px; width: 45px"
          class="d-flex align-content-space-between flex-wrap"
        >
          <p class="py-0 my-0 caption">Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.fill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.fill"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-if="itemType === ITEM_TYPES.TEXT" class="number-input-slider">
          <p class="py-0 my-0 caption px-2">Size</p>
          <v-slider
            color="white"
            :max="64"
            :min="0"
            hide-details
            v-model="options.size"
            @change="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.size"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="itemType === ITEM_TYPES.SHAPE">
          <p class="py-0 my-0 caption">Shape</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div>
                  <div
                    :style="{
                      width: '20px',
                      height: '15px',
                      'background-color': options.shapeFill,
                      border: `${options.shapeOutlineWidth}px solid ${options.shapeOutline}`,
                    }"
                    v-if="options.shape === SHAPE_TYPES.RECTANGLE"
                  ></div>
                  <Pentagon
                    v-else-if="options.shape === SHAPE_TYPES.POLYGON"
                    :stroke="options.shapeOutline"
                    :fill="options.shapeFill"
                    :strokeWidth="Math.min(options.shapeOutlineWidth, 2)"
                  />
                  <div
                    :style="{
                      width: `${options.size}px`,
                      height: `${options.size}px`,
                      border: `${Math.min(
                        options.shapeOutlineWidth,
                        2
                      )}px solid ${options.shapeOutline}`,
                      'border-radius': '50%',
                      'background-color': options.shapeFill,
                    }"
                    v-else-if="options.shape === SHAPE_TYPES.ELLIPSE"
                  ></div>
                  <img
                    v-else-if="options.shape === SHAPE_TYPES.MAP_SYMBOL"
                    :src="
                      require(`@/assets/img/mapSymbols/${getShapeFillColor(
                        options.shapeFill
                      )}${options.shapeSymbol}.png`)
                    "
                    style="width: 25px; height: 25px"
                  />
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  options.shape = SHAPE_TYPES.RECTANGLE;
                  $emit('options-changed', options);
                "
              >
                <v-list-item-title class="d-flex justify-center align-center">
                  <div
                    :style="{
                      width: '25px',
                      height: '20px',
                      'background-color': options.shapeFill,
                      border: `${Math.min(
                        options.shapeOutlineWidth,
                        2
                      )}px solid ${options.shapeOutline}`,
                    }"
                  ></div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  options.shape = SHAPE_TYPES.POLYGON;
                  $emit('options-changed', options);
                "
              >
                <v-list-item-title class="d-flex justify-center align-center">
                  <Pentagon
                    :stroke="options.shapeOutline"
                    :fill="options.shapeFill"
                    :strokeWidth="Math.min(options.shapeOutlineWidth, 2)"
                  />
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  options.shape = SHAPE_TYPES.ELLIPSE;
                  $emit('options-changed', options);
                "
              >
                <v-list-item-title class="d-flex justify-center align-center">
                  <div
                    :style="{
                      width: `20px`,
                      height: `20px`,
                      'border-radius': '50%',
                      'background-color': options.shapeFill,
                      border: `${Math.min(
                        options.shapeOutlineWidth,
                        2
                      )}px solid ${options.shapeOutline}`,
                    }"
                  ></div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-for="s of symbols"
                :key="s"
                @click="
                  options.shape = SHAPE_TYPES.MAP_SYMBOL;
                  options.shapeSymbol = s;
                  options.shapeUrl = `/img/mapSymbols/${getShapeFillColor(
                    options.shapeFill
                  )}${options.shapeSymbol}.png`;
                  $emit('options-changed', options);
                "
              >
                <v-list-item-title class="d-flex justify-center align-center">
                  <img
                    :src="
                      require(`@/assets/img/mapSymbols/${getShapeFillColor(
                        options.shapeFill
                      )}${s}.png`)
                    "
                    style="width: 25px; height: 25px"
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-if="itemType === ITEM_TYPES.SHAPE">
          <p class="py-0 my-0 caption">
            {{
              options.shape === SHAPE_TYPES.MAP_SYMBOL ? "Symbol Color" : "Fill"
            }}
          </p>

          <v-menu offset-y v-if="options.shape === SHAPE_TYPES.MAP_SYMBOL">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeFill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="choice of colorChoices"
                :key="choice.value"
                @click="
                  options.shapeFill = choice.value;
                  options.shapeImageFillColor = getShapeFillColor(choice.value);
                  options.shapeUrl = `/img/mapSymbols/${getShapeFillColor(
                    choice.value
                  )}${options.shapeSymbol}.png`;
                  $emit('options-changed', options);
                "
              >
                <v-list-item-title class="d-flex align-center">
                  <div
                    :style="{
                      'background-color': choice.value,
                      width: '25px',
                      height: '25px',
                    }"
                    class="mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ choice.label }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeFill,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.shapeFill"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="
            itemType === ITEM_TYPES.SHAPE &&
            options.shape !== SHAPE_TYPES.MAP_SYMBOL
          "
        >
          <p class="py-0 my-0 caption">Outline</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.shapeOutline,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.shapeOutline"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          v-if="
            itemType === ITEM_TYPES.SHAPE &&
            options.shape !== SHAPE_TYPES.MAP_SYMBOL
          "
        >
          <p class="py-0 my-0 caption">Outline Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="0"
            hide-details
            v-model="options.shapeOutlineWidth"
            @input="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.shapeOutlineWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="itemType === ITEM_TYPES.LINE">
          <p class="py-0 my-0 caption">Stroke</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.lineStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.lineStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-if="itemType === ITEM_TYPES.LINE" class="number-input-slider">
          <p class="py-0 my-0 caption px-2">Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.lineStrokeWidth"
            @change="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.lineStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div
          v-if="itemType === ITEM_TYPES.SKETCH"
          style="height: 45px; width: 45px"
          class="d-flex align-content-space-between flex-wrap"
        >
          <p class="py-0 my-0 caption">Stroke</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.sketchStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.sketchStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-if="itemType === ITEM_TYPES.SKETCH" class="number-input-slider">
          <p class="py-0 my-0 caption px-2">Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.sketchStrokeWidth"
            @input="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.sketchStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="itemType === ITEM_TYPES.ARROW">
          <p class="py-0 my-0 caption">Color</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
                <div
                  :style="{
                    'background-color': options.arrowStroke,
                    width: '25px',
                    height: '25px',
                    border: '1px solid black',
                  }"
                >
                  &nbsp;
                </div>

                <v-icon class="my-0 py-0" color="white">
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <ColorPicker
                  v-model="options.arrowStroke"
                  @color-picker-cancelled="showFillDialog = false"
                  @input="
                    showFillDialog = false;
                    $emit('options-changed', options);
                  "
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-if="itemType === ITEM_TYPES.ARROW">
          <p class="py-0 my-0 caption px-2">Line Width</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.arrowStrokeWidth"
            @input="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.arrowStrokeWidth"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>

        <div v-if="itemType === ITEM_TYPES.ARROW">
          <p class="py-0 my-0 caption px-2">Pointer Size</p>
          <v-slider
            color="white"
            :max="16"
            :min="1"
            hide-details
            v-model="options.arrowPointerSize"
            @input="$emit('options-changed', options)"
          >
            <template v-slot:append>
              <v-text-field
                v-model="options.arrowPointerSize"
                readonly
                class="number-input my-0 py-0 mt-n2"
                hide-details
              />
            </template>
          </v-slider>
        </div>
      </div>
    </div>

    <div class="d-flex flex-no-wrap align-center gap px-2">
      <div v-if="$vuetify.breakpoint.smAndUp">
        <template v-if="drawingLine"> Tap to add line </template>
        <template v-else>
          <template v-if="itemType === ITEM_TYPES.LINE">
            Tap to add line
          </template>
          <template v-else>
            Tap on the image to add
            <span v-html="$options.filters.itemTypeFilter(itemType)"></span>
          </template>
        </template>
      </div>
      <div>
        <v-btn
          v-if="drawingLine"
          text
          color="white"
          @click="$emit('finish-line')"
        >
          Finish Line
        </v-btn>
        <v-btn
          v-else-if="isPaintPolygon"
          text
          color="white"
          @click="$emit('finish-polygon')"
        >
          Finish Polygon
        </v-btn>
      </div>
      <div>
        <v-btn icon @click="$emit('toolbar-close')">
          <v-icon color="white">{{ mdiClose }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiEllipseOutline,
} from "@mdi/js";
import ITEM_TYPES from "@/constants/markupItemTypes";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ColorPicker from "@/components/shared/ColorPicker";
import Pentagon from "@/components/mapView/docs-tab/markup-image-dialog/shared/Pentagon";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "BottomToolbar",
  components: {
    ColorPicker,
    Pentagon,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiEllipseOutline,
      colorChoices,
      options: {
        fill: "yellow",
        size: 15,
        shape: "rectangle",
        shapeFill: "yellow",
        shapeImageFillColor: "Yellow",
        shapeOutline: "yellow",
        shapeSymbol: "CircleLargeB",
        shapeUrl: "",
        shapeOutlineWidth: 0,
        lineStroke: "yellow",
        lineStrokeWidth: 5,
        sketchStroke: "yellow",
        sketchStrokeWidth: 5,
        arrowStroke: "yellow",
        arrowStrokeWidth: 5,
        arrowPointerSize: 10,
      },
      ITEM_TYPES,
      SHAPE_TYPES,
      shapeChoices: [],
      symbols,
      showFillDialog: false,
      showGraphicDetailsPanel: false,
    };
  },
  props: {
    itemType: String,
    drawingLine: Boolean,
    isPaintPolygon: Boolean,
    selectedFile: Object,
  },
  beforeMount() {
    const settingsObj = localStorage.getItem("markup-item-settings");
    this.setDefaultColors();
    let isValidJson;
    try {
      JSON.parse(settingsObj);
      isValidJson = true;
    } catch (error) {
      isValidJson = false;
    }

    if (
      isValidJson &&
      typeof JSON.parse(settingsObj) === "object" &&
      JSON.parse(settingsObj) !== null
    ) {
      this.options = JSON.parse(settingsObj);
    } else {
      localStorage.setItem(
        "markup-item-settings",
        JSON.stringify(this.options)
      );
    }

    this.$emit("options-changed", this.options);
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        localStorage.setItem("markup-item-settings", JSON.stringify(val));
      },
    },
    "options.shape"(val) {
      if (
        val === SHAPE_TYPES.RECTANGLE ||
        val === SHAPE_TYPES.POLYGON ||
        val === SHAPE_TYPES.ELLIPSE
      ) {
        const shapeFillInChoice = colorChoices.find(
          (c) => c.value === this.options.shapeFill
        );
        const shapeImageFillColorInChoice = colorChoices.find(
          (c) => c.label === this.options.shapeFill
        );

        this.options.shapeFill = shapeFillInChoice
          ? this.options.shapeFill
          : "#2196F3";
        this.options.shapeImageFillColor = shapeImageFillColorInChoice
          ? this.options.shapeImageFillColor
          : "Blue";
      }

      if (val === SHAPE_TYPES.MAP_SYMBOL) {
        const shapeFillInChoice = colorChoices.find(
          (c) => c.value === this.options.shapeFill
        );
        const shapeImageFillColorInChoice = colorChoices.find(
          (c) => c.label === this.options.shapeFill
        );

        this.options.shapeFill = shapeFillInChoice
          ? this.options.shapeFill
          : "#2196F3";
        this.options.shapeOutline = shapeImageFillColorInChoice
          ? this.options.shapeOutline
          : "black";
      }
    },
  },
  methods: {
    setDefaultColors() {
      if (this.selectedFile?.s3_file_path_original_image?.endsWith("pdf")) {
        this.options = {
          ...this.options,
          fill: "red",
          shapeFill: "red",
          shapeImageFillColor: "Red",
          shapeOutline: "red",
          lineStroke: "red",
          sketchStroke: "red",
          arrowStroke: "red",
        };
      } else {
        this.options = {
          ...this.options,
          fill: "yellow",
          shapeFill: "yellow",
          shapeImageFillColor: "Yellow",
          shapeOutline: "yellow",
          lineStroke: "yellow",
          sketchStroke: "yellow",
          arrowStroke: "yellow",
        };
      }
    },
    onGraphicDetailsChanged(itemOptions) {
      this.options = { ...this.options, ...itemOptions };
      this.$emit("options-changed", this.options);
    },
    getShapeFillColor(shapeFill) {
      return (
        this.colorChoices.find((c) => c.value === shapeFill)?.fileNameColor ??
        "Blue"
      );
    },
  },
};
</script>

<style scoped>
.number-input {
  width: 20px;
}

.number-input-slider {
  width: 75px;
}

.number-input >>> .v-text-field__slot input {
  color: white;
}

.v-text-field >>> .v-input__slot::before {
  border-color: white !important;
}
</style>
