var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "#3F51B5" } },
        [
          _c("v-toolbar-title", [_vm._v("Calculation")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("calculation-list-close")
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0 mb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-0 my-0" },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "heading" },
                            [_c("b", [_vm._v("Formula")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { staticClass: "py-0 my-0" },
                        [
                          _c("v-list-item-content", [
                            _c(
                              "section",
                              {
                                staticClass: "d-flex flex-wrap align-center gap"
                              },
                              [
                                _vm._l(_vm.splitExpression, function(s, index) {
                                  return [
                                    /^{(\d+?)}$/.test(s)
                                      ? _c(
                                          "v-chip",
                                          { key: index, attrs: { small: "" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getQuestionLabelById(s)
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("div", { key: index }, [
                                          _vm._v(" " + _vm._s(s) + " ")
                                        ])
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-0 mx-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    { staticClass: "px-0 mx-0" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "py-0 my-0" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0 my-0" },
                            [
                              _c("v-simple-table", [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "px-3" }, [
                                      _c("b", [_vm._v("Question")])
                                    ]),
                                    _c("th", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-end" },
                                        [_c("b", [_vm._v("Value")])]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.variables, function(v) {
                                    return _c("tr", { key: v.id }, [
                                      _c(
                                        "td",
                                        { staticClass: "px-3 caption" },
                                        [_vm._v(_vm._s(v.label))]
                                      ),
                                      _c("td", { staticClass: "caption" }, [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex justify-end" },
                                          [_vm._v(_vm._s(v.value))]
                                        )
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 mt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    { staticClass: "pt-0 mt-0" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "pt-0 mt-0" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "heading" },
                            [_c("b", [_vm._v("Calculation")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", [
                            _vm._v(
                              " " + _vm._s(_vm.calculationExpression) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }