<template>
  <div
    class="d-flex px-4 pt-2 pb-0"
    @click="$emit('add-symbol', itemInfo.renderer)"
  >
    <div v-show="viewSymbols" class="pa-0">
      <svg
        overflow="hidden"
        width="15"
        height="15"
        v-if="symbolStyle === 'circle'"
      >
        <circle
          cx="10"
          cy="10"
          r="5"
          :fill="fill"
          :fill-opacity="itemInfo.opacity"
          :stroke="outlineStroke"
          :stroke-opacity="itemInfo.opacity"
          :stroke-width="strokeWidth"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          :stroke-miterlimit="strokeWidth"
        ></circle>
      </svg>
      <svg
        overflow="hidden"
        width="30"
        height="30"
        v-else-if="['solid', 'dot'].includes(symbolStyle)"
      >
        <defs></defs>
        <path
          v-if="symbolType === 'simple-fill'"
          :fill="fill"
          fill-opacity="0.5"
          :stroke="outlineStroke"
          stroke-opacity="0.5"
          :stroke-width="strokeWidth"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          :stroke-miterlimit="strokeWidth"
          stroke-dasharray="16,12"
          path="M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z"
          d="M-10-10L 10 0L 10 10L-10 10L-10-10Z"
          fill-rule="evenodd"
          transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
        ></path>
        <path
          v-else-if="symbolType === 'simple-line'"
          fill="none"
          :fill-opacity="itemInfo.opacity"
          :stroke="simpleLineStroke"
          :stroke-opacity="itemInfo.opacity"
          :stroke-width="lineWidth"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          :stroke-miterlimit="lineWidth"
          path="M -15,0 L 15,0 E"
          d="M-15 0L 15 0"
          stroke-dasharray="none"
          transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
        ></path>
      </svg>
      <img :src="symbolUrl" v-else :style="{ width: `${imageWidth}px` }" />
    </div>
    <div class="ml-3 mt-1">{{ itemInfo.id }}</div>
  </div>
</template>

<script>
export default {
  name: "SimpleRendererItem",
  props: {
    itemInfo: Object,
    viewSymbols: Boolean,
  },
  computed: {
    fill() {
      const { itemInfo } = this;
      const color = itemInfo?.renderer?.symbol?.color;
      if (color) {
        return `rgb(${color.r},${color.g},${color.b})`;
      }
      return undefined;
    },
    simpleLineStroke() {
      const { itemInfo } = this;
      const color = itemInfo?.renderer?.symbol?.color;
      if (color) {
        return `rgb(${color.r},${color.g},${color.b})`;
      }
      return undefined;
    },
    outlineStroke() {
      const { itemInfo } = this;
      const color = itemInfo?.renderer?.symbol?.outline?.color;
      if (color) {
        return `rgb(${color.r},${color.g},${color.b})`;
      }
      return undefined;
    },
    strokeWidth() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.outline?.width ?? 1;
    },
    lineWidth() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.width ?? 1;
    },
    symbolUrl() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.url;
    },
    symbolType() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.type;
    },
    symbolStyle() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.style;
    },
    imageWidth() {
      const { itemInfo } = this;
      return itemInfo?.renderer?.symbol?.width;
    },
  },
};
</script>
