var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0", attrs: { width: "100%" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 ma-0 mb-2" },
        [
          _c(
            "v-row",
            [
              _vm.item.question.isImage
                ? [
                    _c(
                      "v-col",
                      { staticClass: "my-0 py-2", attrs: { cols: "12" } },
                      [
                        _c("p", { staticClass: "caption mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.item.number + " " + _vm.item.question.label
                              ) +
                              " "
                          )
                        ]),
                        _c("p", { staticClass: "caption mb-0" }, [
                          _vm._v(_vm._s(_vm.item.question.placeholder))
                        ]),
                        _c("validation-provider", {
                          ref: "field",
                          attrs: { slim: "", name: _vm.item.question.label },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  var valid = ref.valid
                                  return _vm._l(_vm.options, function(option) {
                                    return _c("v-checkbox", {
                                      key: option.id,
                                      attrs: {
                                        value: option.value,
                                        "error-messages": errors,
                                        success: valid,
                                        disabled: _vm.isDisabled
                                      },
                                      on: { change: _vm.onInputChange },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("img", {
                                                  style: {
                                                    width: "auto",
                                                    height: "64px"
                                                  },
                                                  attrs: { src: option.value }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.selectedValues,
                                        callback: function($$v) {
                                          _vm.selectedValues = $$v
                                        },
                                        expression: "selectedValues"
                                      }
                                    })
                                  })
                                }
                              }
                            ],
                            null,
                            false,
                            3373741586
                          )
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _vm.item.question.showAllOptions
                      ? [
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-2", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.item.number +
                                        " " +
                                        _vm.item.question.label
                                    ) +
                                    " "
                                )
                              ]),
                              _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(_vm._s(_vm.item.question.placeholder))
                              ]),
                              _c("validation-provider", {
                                ref: "field",
                                attrs: {
                                  slim: "",
                                  name: _vm.item.question.label
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        var valid = ref.valid
                                        return _vm._l(_vm.options, function(
                                          option
                                        ) {
                                          return _c("v-checkbox", {
                                            key: option.id,
                                            attrs: {
                                              value: option.value,
                                              label: option.label,
                                              "error-messages": errors,
                                              success: valid,
                                              disabled: _vm.isDisabled,
                                              "hide-details": ""
                                            },
                                            on: { change: _vm.onInputChange },
                                            model: {
                                              value: _vm.selectedValues,
                                              callback: function($$v) {
                                                _vm.selectedValues = $$v
                                              },
                                              expression: "selectedValues"
                                            }
                                          })
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3892621737
                                )
                              })
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "v-col",
                            {
                              staticClass: "my-0 py-0 select",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("validation-provider", {
                                ref: "field",
                                attrs: {
                                  slim: "",
                                  name: _vm.item.question.label
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.options,
                                            "item-text": "label",
                                            "item-value": "value",
                                            label:
                                              _vm.item.number +
                                              " " +
                                              _vm.item.question.label,
                                            "error-messages": errors,
                                            success: valid,
                                            multiple: "",
                                            disabled: _vm.isDisabled,
                                            hint: _vm.item.question.placeholder,
                                            "persistent-hint": ""
                                          },
                                          on: { change: _vm.onInputChange },
                                          model: {
                                            value: _vm.selectedValues,
                                            callback: function($$v) {
                                              _vm.selectedValues = $$v
                                            },
                                            expression: "selectedValues"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]
                  ],
              _vm.showTextInput
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        ref: "field",
                        attrs: {
                          name: _vm.item.question.label,
                          rules: "required"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var valid = ref.valid
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "#3F51B5",
                                      name: _vm.item.id,
                                      disabled: _vm.isDisabled
                                    },
                                    on: { input: _vm.onInputChange },
                                    model: {
                                      value: _vm.otherValue,
                                      callback: function($$v) {
                                        _vm.otherValue = $$v
                                      },
                                      expression: "otherValue"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          182005028
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }