<template>
  <v-dialog
    :value="showOfflineDialog"
    max-width="400px"
    @click="$emit('offline-dialog-close')"
  >
    <v-card>
      <v-card-text class="pa-5">
        <div>
          The markup tool requires an internet connection and this device is
          currently offline. Try again when the device is back online.
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-3 py-2">
        <v-btn text color="#3F51B5" @click="$emit('offline-dialog-close')">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OfflineDialog",
  props: {
    showOfflineDialog: Boolean,
  },
};
</script>
