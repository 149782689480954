<template>
  <svg overflow="hidden" width="30" height="30">
    <defs></defs>
    <path
      fill="none"
      fill-opacity="0"
      :stroke="strokeColor"
      stroke-opacity="1"
      :stroke-width="strokeWidth"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      :stroke-miterlimit="strokeWidth"
      path="M -15,0 L 15,0 E"
      d="M-15 0L 15 0"
      :stroke-dasharray="strokeDashArray"
      transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)"
    ></path>
  </svg>
</template>

<script>
const LINE_STYLES = {
  SOLID: "solid",
  DOT: "dot",
  DASH: "dash",
  DASH_DOT: "dash-dot",
  LONG_DASH_DOT_DOT: "long-dash-dot-dot",
  LONG_DASH: "long-dash",
  LONG_DASH_DOT: "long-dash-dot",
  SHORT_DASH: "short-dash",
  SHORT_DOT: "short-dot",
  SHORT_DASH_DOT: "short-dash-dot",
  SHORT_DASH_DOT_DOT: "short-dash-dot-dot",
};

export default {
  name: "RendererLine",
  props: {
    itemInfo: Object,
  },
  computed: {
    fillColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    size() {
      return this.itemInfo?.symbol?.size;
    },
    strokeColor() {
      const { r, g, b, a } = this.itemInfo?.symbol?.color ?? {};
      return `rgba(${r},${g},${b},${a})`;
    },
    strokeDashArray() {
      switch (this.itemInfo?.symbol?.style) {
        case LINE_STYLES.SOLID: {
          return "none";
        }
        case LINE_STYLES.DOT: {
          return "1";
        }
        case LINE_STYLES.DASH: {
          return "3";
        }
        case LINE_STYLES.DASH_DOT: {
          return "3 1";
        }
        case LINE_STYLES.LONG_DASH_DOT_DOT: {
          return "5 1 1";
        }
        case LINE_STYLES.LONG_DASH: {
          return "5";
        }
        case LINE_STYLES.LONG_DASH_DOT: {
          return "5 1";
        }
        case LINE_STYLES.SHORT_DASH: {
          return "3";
        }
        case LINE_STYLES.SHORT_DOT: {
          return "1";
        }
        case LINE_STYLES.SHORT_DASH_DOT: {
          return "3 1";
        }
        case LINE_STYLES.SHORT_DASH_DOT_DOT: {
          return "3 1 1";
        }
        default: {
          return "none";
        }
      }
    },
    strokeWidth() {
      return this.itemInfo?.symbol?.width ?? 1;
    },
  },
};
</script>
