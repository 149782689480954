export default {
  methods: {
    checkDependantValue(
      dependantItemValue,
      type = "question",
      field = "required"
    ) {
      if (this.item[type][field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +this.item[type][field].value &&
          dependantItemValue !== this.item[type][field].value
        );
      } else if (this.item[type][field].condition === "EQUAL") {
        return (
          +dependantItemValue === +this.item[type][field].value ||
          dependantItemValue === this.item[type][field].value
        );
      } else if (this.item[type][field].condition === "GREATER_THAN") {
        return +dependantItemValue > +this.item[type][field].value;
      } else if (this.item[type][field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +this.item[type][field].value;
      } else if (this.item[type][field].condition === "LESS_THAN") {
        return +dependantItemValue < +this.item[type][field].value;
      } else if (this.item[type][field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +this.item[type][field].value;
      }
    },
    checkDependantValueWithItem(
      item,
      dependantItemValue,
      type = "question",
      field = "required"
    ) {
      if (item[type][field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +item[type][field].value &&
          dependantItemValue !== item[type][field].value
        );
      } else if (item[type][field].condition === "EQUAL") {
        return (
          +dependantItemValue === +item[type][field].value ||
          dependantItemValue === item[type][field].value
        );
      } else if (item[type][field].condition === "GREATER_THAN") {
        return +dependantItemValue > +item[type][field].value;
      } else if (item[type][field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN") {
        return +dependantItemValue < +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +item[type][field].value;
      }
    },
    checkSectionDependantValue(dependantItemValue, section, field = "visible") {
      if (section[field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +section[field].value &&
          dependantItemValue !== section[field].value
        );
      } else if (section[field].condition === "EQUAL") {
        return (
          +dependantItemValue === +section[field].value ||
          dependantItemValue === section[field].value
        );
      } else if (section[field].condition === "GREATER_THAN") {
        return +dependantItemValue > +section[field].value;
      } else if (section[field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +section[field].value;
      } else if (section[field].condition === "LESS_THAN") {
        return +dependantItemValue < +section[field].value;
      } else if (section[field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +section[field].value;
      }
    },
  },
};
