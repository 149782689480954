import moment from "moment";
import { axiosWithRegularAuth } from "@/plugins/axios";
const APIURL = process.env.VUE_APP_API_URL;

export const setGraphicSymbol = ({ graphicValue, utilisyncRenderer }) => {
  switch (utilisyncRenderer.renderer_type) {
    case "simple": {
      let symbol = setRendererSymbol({
        symbol: utilisyncRenderer.renderer_symbols[0],
        utilisyncRenderer,
      });
      if (!symbol) {
        symbol = setDefaultSymbol({ utilisyncRenderer });
      }
      return symbol;
    }
    case "unique_value": {
      const renderSymbol = utilisyncRenderer.renderer_symbols.find((obj) => {
        return obj.field_option_value === graphicValue;
      });
      if (renderSymbol) {
        let symbol = setRendererSymbol({
          symbol: renderSymbol,
          utilisyncRenderer,
        });
        if (!symbol) {
          symbol = setDefaultSymbol({ utilisyncRenderer });
        }
        return symbol;
      } else {
        return setDefaultSymbol({ utilisyncRenderer });
      }
    }
    case "class_break": {
      let symbol = {};
      for (const renderSymbol of utilisyncRenderer.renderer_symbols) {
        if (
          graphicValue > renderSymbol.min_value &&
          graphicValue <= renderSymbol.max_value
        ) {
          symbol = setRendererSymbol({
            symbol: renderSymbol,
            utilisyncRenderer,
          });
        }
      }
      if (!symbol || Object.keys(symbol).length === 0) {
        symbol = setDefaultSymbol({ utilisyncRenderer });
      }
      return symbol;
    }
    default: {
      return setDefaultSymbol({ utilisyncRenderer });
    }
  }
};

const fetchUtiliyncLayerPoints = async ({
  utilisyncGraphicLayer,
  mapServiceId,
  Point,
  SpatialReference,
  Graphic,
  webMercatorUtils,
  utilisyncRenderer,
}) => {
  if (!navigator.onLine) {
    return;
  }
  const utiliSyncLayerPointsUrl = `${APIURL}/gis_data_points/map_service/${mapServiceId}`;
  const {
    data: { results: utilisyncPoints },
  } = await axiosWithRegularAuth.get(utiliSyncLayerPointsUrl);
  for (const utilisyncPoint of utilisyncPoints) {
    const latlng = new Point(
      utilisyncPoint.geo_json.coordinates[0],
      utilisyncPoint.geo_json.coordinates[1],
      new SpatialReference({ wkid: 4326 })
    );
    const webMercator = webMercatorUtils.geographicToWebMercator(latlng);
    const pnt = new Graphic(webMercator);
    const attr = {
      OBJECTID: utilisyncPoint.object_id,
      gisDataPointId: utilisyncPoint.gis_data_point_id,
    };
    pnt.symbol = setGraphicSymbol({
      graphicValue: utilisyncPoint.reference_field_value,
      utilisyncRenderer,
    });
    pnt.attributes = attr;
    pnt.popupTemplate = {}; // This is needed so view.popup.fetchFeatures can find it
    utilisyncGraphicLayer.add(pnt);
  }
};

export const setRendererSymbol = ({ symbol, utilisyncRenderer }) => {
  switch (utilisyncRenderer.type) {
    case "Point: Picture": {
      switch (symbol?.symbol_type) {
        case "basic": {
          return {
            type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
            style: "circle",
            color: symbol.fill_color,
            size: symbol.picture_size, // pixels
            outline: {
              // autocasts as new SimpleLineSymbol()
              color: symbol.outline_color,
              width: symbol.width, // points
            },
          };
        }
        case "image": {
          return {
            type: "picture-marker",
            url: symbol.url.startsWith("/img/")
              ? require(`@/assets${symbol.url}`)
              : symbol.url,
            width: symbol.picture_size,
            height: symbol.picture_size,
          };
        }
      }
      break;
    }
    case "Line: Simple": {
      return {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: symbol.color,
        width: symbol.width,
        style: "solid",
      };
    }
  }
};

const setDefaultSymbol = ({ utilisyncRenderer }) => {
  if (utilisyncRenderer && utilisyncRenderer.type) {
    switch (utilisyncRenderer.type) {
      case "Point: Picture": {
        return { type: "simple-marker", color: [0, 0, 0] };
      }
      case "Line: Simple": {
        return { type: "simple-line" };
      }
      default: {
        console.log("Error: Unknown utilisyncRenderer.type");
        return {};
      }
    }
  } else {
    return {};
  }
};

const setRenderer = ({ utilisyncRenderer }) => {
  // This function overwrites the default renderer for a featureService
  let renderer = {};
  const defaultSymbol = setDefaultSymbol({ utilisyncRenderer });
  if (utilisyncRenderer.renderer_symbols.length > 0) {
    switch (utilisyncRenderer.renderer_type) {
      case "simple": {
        const symbolJson = setRendererSymbol({
          symbol: utilisyncRenderer.renderer_symbols[0],
          utilisyncRenderer,
        });
        renderer = {
          type: "simple", // autocasts as new SimpleRenderer()
          symbol: symbolJson,
        };
        break;
      }
      case "unique_value": {
        const uniqueValueInfos = [];
        for (const renderSymbol of utilisyncRenderer.renderer_symbols) {
          const symbolJson = setRendererSymbol({
            symbol: renderSymbol,
            utilisyncRenderer,
          });
          uniqueValueInfos.push({
            value: renderSymbol.field_option_value,
            label: renderSymbol.field_option_label
              ? renderSymbol.field_option_label
              : renderSymbol.field_option_value,
            symbol: symbolJson,
          });
        }
        renderer = {
          type: "unique-value", // autocasts as new UniqueValueRenderer()
          field: utilisyncRenderer.reference_field,
          defaultSymbol,
          uniqueValueInfos,
        };
        break;
      }
      case "class_break": {
        const classBreakInfos = [];
        for (const renderSymbol of utilisyncRenderer.renderer_symbols) {
          const minValue = utilisyncRenderer.convert_date_to_days
            ? moment().add(renderSymbol.min_value, "days").format("x")
            : renderSymbol.min_value;
          const maxValue = utilisyncRenderer.convert_date_to_days
            ? moment().add(renderSymbol.max_value, "days").format("x")
            : renderSymbol.max_value;
          classBreakInfos.push({
            minValue,
            maxValue,
            label: renderSymbol.label,
            symbol: setRendererSymbol({
              symbol: renderSymbol,
              utilisyncRenderer,
            }),
          });
        }
        renderer = {
          type: "class-breaks",
          field: utilisyncRenderer.reference_field,
          defaultSymbol: defaultSymbol,
          defaultLabel: "Unknown",
          classBreakInfos,
        };
        break;
      }
      default: {
        return null;
      }
    }
  } else {
    renderer = {
      type: "simple", // autocasts as new SimpleRenderer()
      symbol: defaultSymbol,
    };
  }
  return renderer;
};

const getMapServiceUrl = (layer) => {
  const { esri_token: esriToken, esri_user_token: esriUserToken } = JSON.parse(
    localStorage.getItem("auth")
  );
  const { token_type: tokenType, service_url: serviceUrl } = layer;
  if (tokenType === "AGOL") {
    return `${serviceUrl}?token=${esriToken}`;
  } else if (tokenType === "USER") {
    return `${serviceUrl}?token=${esriUserToken}`;
  } else {
    return serviceUrl;
  }
};

const addInterceptorToSecureFeatureService = ({ layer, esriConfig }) => {
  const { token_type: tokenType, service_url: serviceUrl } = layer;
  if (tokenType === "AGOL") {
    // Set interceptors for this layer so the token is always applied to the request
    // Esri strips off any query parameters that are manually added to the url
    const esriToken = localStorage.getItem("esri_token");
    if (esriToken) {
      esriConfig.request.interceptors.push({
        urls: serviceUrl,
        // use the BeforeInterceptorCallback to add token to query
        before(params) {
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token = esriToken;
        },
        after: (response) => {
          response.data.supportedQueryFormats = "JSON";
        },
      });
    } else {
      //Add this interceptor to public services so that the response is returned in JSON format, not PBF
      esriConfig.request.interceptors.push({
        urls: serviceUrl,
        after: (response) => {
          response.data.supportedQueryFormats = "JSON";
        },
      });
    }
  }
};

const addLayer = ({
  map,
  layer,
  esriConfig,
  GraphicsLayer,
  FeatureLayer,
  MapImageLayer,
  ImageParameters,
  Point,
  SpatialReference,
  Graphic,
  webMercatorUtils,
  defaultLayersVisibility,
  layersVisibilitySettings = [],
  mapIdSelected,
}) => {
  const {
    service_name: layerName,
    service_type: layerType,
    map_service_id: mapServiceId,
    renderer: utilisyncRenderer,
  } = layer;
  let layerToAdd;
  const lyr = defaultLayersVisibility?.find(
    (l) => l.mapServiceId === mapServiceId
  );
  const layersVisibilitySetting = layersVisibilitySettings?.find(
    (l) => l.mapId === mapIdSelected
  );
  const layerVisibilitySetting =
    layersVisibilitySetting?.layerVisibilitySettings?.[mapServiceId];
  const visible = layerVisibilitySetting ?? lyr.isVisible;

  if (layerType !== "T") {
    switch (layerType) {
      case "L": {
        // Locate Request Layer
        layerToAdd = new GraphicsLayer({
          id: layerName,
          visible,
        });
        layerToAdd.utilisyncRenderer = utilisyncRenderer;
        layerToAdd.utiliSyncLayerType = layerType;

        // createHistoricTicketLayer({
        //   GraphicsLayer,
        //   map
        // })
        break;
      }
      case "U": {
        // UtiliSync Layer
        layerToAdd = new GraphicsLayer({
          id: layerName,
          visible,
        });
        layerToAdd.utilisyncRenderer = utilisyncRenderer;
        layerToAdd.utiliSyncLayerType = layerType;

        fetchUtiliyncLayerPoints({
          utilisyncGraphicLayer: layerToAdd,
          mapServiceId,
          Point,
          SpatialReference,
          Graphic,
          webMercatorUtils,
          utilisyncRenderer,
        });
        break;
      }
      case "F": {
        // ArcGIS Feature Service
        addInterceptorToSecureFeatureService({ layer, esriConfig });
        layerToAdd = new FeatureLayer(layer.service_url, {
          outFields: ["*"],
          id: layerName,
          popupTemplate: {}, // required to trigger info panel
          visible,
        });
        break;
      }
      case "S": {
        // ArcGIS Map Service
        const imageParameters = new ImageParameters();
        imageParameters.format = "jpeg";
        const url = getMapServiceUrl(layer);
        layerToAdd = new MapImageLayer(url, {
          id: layerName,
          visible,
          imageParameters,
        });

        layerToAdd.when(() => {
          layerToAdd.allSublayers.forEach((sublayer) => {
            sublayer.popupEnabled = true; // required to trigger info panel
            sublayer.popupTemplate = {
              // required to trigger info panel
              outFields: ["*"], // required to view all attributes in popup
            };
          });
        });
        break;
      }
      default: {
        break;
      }
    }

    if (
      layerType === "F" &&
      utilisyncRenderer.apply_renderer_to_feature_service &&
      utilisyncRenderer &&
      utilisyncRenderer.renderer_type
    ) {
      const renderer = setRenderer({
        utilisyncRenderer,
      });
      if (renderer) {
        layerToAdd.renderer = renderer;
      }
    }

    if (layerToAdd) {
      layerToAdd.utiliSyncLayerType = layerType;
      layerToAdd.utiliSyncLayerName = layerName;
      layerToAdd.showInLayerPanel = true;
      layerToAdd.mapServiceId = mapServiceId;
      if (layer?.ref_field) {
        layerToAdd.utilisyncRefField = layer.ref_field;
      }
      map.add(layerToAdd);
    }
  }
};

export default {
  methods: {
    addLayers({
      map,
      layers,
      config = { layers: [] },
      PopupTemplate,
      esriConfig,
      GraphicsLayer,
      FeatureLayer,
      MapImageLayer,
      ImageParameters,
      Point,
      SpatialReference,
      Graphic,
      webMercatorUtils,
      defaultLayersVisibility,
      layersVisibilitySettings = [],
      mapIdSelected,
    }) {
      for (const layer of layers) {
        try {
          addLayer({
            map,
            layer,
            config,
            PopupTemplate,
            esriConfig,
            GraphicsLayer,
            FeatureLayer,
            MapImageLayer,
            ImageParameters,
            Point,
            SpatialReference,
            Graphic,
            webMercatorUtils,
            defaultLayersVisibility,
            layersVisibilitySettings,
            mapIdSelected,
          });
        } catch (e) {
          console.log(e);
          let errorMsg = `Error loading layer: ${layer.service_name} `;
          if (
            e.error &&
            e.error.code &&
            e.error.message &&
            e.error.details[0]
          ) {
            errorMsg += `.Error  ${e.error.message}.${e.error.code} ${e.error.details[0]} `;
          } else if (e.error && e.error.message) {
            errorMsg += `.Error message: ${e.error.message} `;
          }
          console.log(errorMsg);
        }
      }
    },
    drawLocateRequests({
      locateRequests,
      locateGraphicLayer,
      Point,
      Graphic,
      map,
      webMercatorUtils,
    }) {
      if (!locateGraphicLayer) {
        return;
      }
      locateGraphicLayer.removeAll();
      for (const locateRequest of locateRequests) {
        const long = locateRequest.geo_point.coordinates[0];
        const lat = locateRequest.geo_point.coordinates[1];
        const latlng = new Point(long, lat, map.spatialReference);
        const webMercator = webMercatorUtils.geographicToWebMercator(latlng);
        const ticket = new Graphic(webMercator);
        let symVal = locateRequest.legal_date_unix_ts;
        if (locateRequest.ticket_type?.toLowerCase() === "cancel") {
          symVal = moment().add(120, "days").valueOf();
        } else if (locateRequest.ticket_type?.toLowerCase() === "2nd notice") {
          symVal = moment().add(100, "days").valueOf();
        } else if (locateRequest.ticket_type?.toLowerCase() === "exrn") {
          symVal = moment().add(100, "days").valueOf();
        } else if (locateRequest.ticket_type?.toLowerCase() === "retransmit") {
          symVal = moment().add(130, "days").valueOf();
        } else if (locateRequest.ticket_type?.toLowerCase() === "remark") {
          symVal = moment().add(110, "days").valueOf();
        } else if (locateRequest.is_emergency) {
          symVal = moment().add(90, "days").valueOf();
        } else if (locateRequest.is_rush) {
          symVal = moment().add(80, "days").valueOf();
        } else if (locateRequest.meet) {
          symVal = moment().add(70, "days").valueOf();
        }
        ticket.attributes = {
          OBJECTID: locateRequest.feature_id,
          locate_request_id: locateRequest.locate_request_id,
          legal_date: locateRequest.legal_date_unix_ts,
          locate_request_number: locateRequest.locate_request_number,
          street_address: locateRequest.street_address,
          is_emergency: locateRequest.is_emergency,
          is_rush: locateRequest.is_rush,
          place: locateRequest.place,
          ticket_type: locateRequest.ticket_type,
          work_type: locateRequest.work_type,
          symVal: symVal,
          account: locateRequest.provider_account_name,
        };
        if (locateGraphicLayer.utilisyncRenderer.convert_date_to_days) {
          const legalDate = moment(symVal);
          const now = moment();
          symVal = legalDate.diff(now, "days", true);
        }
        ticket.symbol = setGraphicSymbol({
          graphicValue: symVal,
          utilisyncRenderer: locateGraphicLayer.utilisyncRenderer,
        });
        ticket.popupTemplate = {}; // This is needed so view.popup.fetchFeatures can find it
        locateGraphicLayer.add(ticket);
      }
    },
  },
};
