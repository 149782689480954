var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-0",
      attrs: {
        value: _vm.showManageSymbolsDialog,
        "retain-focus": false,
        persistent: "",
        width: "400px"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-0" },
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              attrs: {
                dark: "",
                width: "100%",
                color: "#3F51B5",
                height: "56px"
              }
            },
            [
              _c("div", { staticClass: "mr-3" }, [
                _c("div", [_vm._v("Manage Symbols")])
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-button-click")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end gap" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      _vm.showAddSymbolDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]),
                  _vm._v(" Add Symbol ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "px-4" },
            [
              _c(
                "v-chip-group",
                [
                  _c(
                    "v-chip",
                    {
                      on: {
                        click: function($event) {
                          _vm.symbolInactiveStatus = !_vm.symbolInactiveStatus
                          _vm.$emit(
                            "get-custom-symbols",
                            _vm.symbolInactiveStatus
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.symbolInactiveStatus ? "Inactive" : "Active"
                          ) +
                          " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { size: 18 }
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiSyncCircle) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("section", { staticClass: "px-4" }, [
            _c("div", { staticClass: "d-flex align-center gap" }, [
              _c(
                "form",
                { staticClass: "flex-grow-1" },
                [
                  _c("validation-provider", {
                    attrs: { name: "Symbol" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              attrs: {
                                "error-messages": errors,
                                success: valid,
                                label: "Symbol",
                                color: "#3F51B5"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c("v-icon", [
                                          _vm._v(" " + _vm._s(_vm.mdiMagnify))
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.symbolKeyword,
                                callback: function($$v) {
                                  _vm.symbolKeyword = $$v
                                },
                                expression: "symbolKeyword"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _c(
              "table",
              {
                staticClass: "flex-grow-1 px-0 mx-0 py-3",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "tbody",
                  _vm._l(_vm.filteredCustomSymbols, function(s) {
                    return _c(
                      "tr",
                      {
                        key: s.markup_symbol_id,
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            _vm.selectedCustomSymbol = s
                            _vm.displayCustomSymbolToolbar = true
                            _vm.showSymbolPanel = false
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "d-flex align-center py-2 gap" },
                          [
                            _c("SymbolIconDisplay", { attrs: { symbol: s } }),
                            _vm._v(" " + _vm._s(s.label) + " ")
                          ],
                          1
                        ),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-end align-center py-2"
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pa-0 d-flex",
                                                    attrs: {
                                                      text: "",
                                                      "x-small": "",
                                                      icon: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-btn",
                                                  { attrs: { icon: "" } },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mdiDotsVertical
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "d-flex align-center cursor-pointer gap",
                                              on: {
                                                click: function($event) {
                                                  _vm.showEditSymbolDialog = true
                                                  _vm.selectedSymbol = s
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiPencil) +
                                                    " "
                                                )
                                              ]),
                                              _vm._v(" Edit Symbol ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ]),
          _vm.showAddSymbolDialog
            ? _c("AddSymbolDialog", {
                attrs: { showAddSymbolDialog: _vm.showAddSymbolDialog },
                on: {
                  "add-symbol-dialog-close": function($event) {
                    _vm.showAddSymbolDialog = false
                  },
                  "custom-symbol-added": function($event) {
                    _vm.showAddSymbolDialog = false
                    _vm.$emit("custom-symbol-added")
                  }
                }
              })
            : _vm._e(),
          _vm.showEditSymbolDialog
            ? _c("EditSymbolDialog", {
                attrs: {
                  showEditSymbolDialog: _vm.showEditSymbolDialog,
                  selectedSymbol: _vm.selectedSymbol
                },
                on: {
                  "edit-symbol-dialog-close": function($event) {
                    _vm.showEditSymbolDialog = false
                  },
                  "custom-symbol-edited": function($event) {
                    _vm.showEditSymbolDialog = false
                    _vm.$emit("custom-symbol-edited")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }