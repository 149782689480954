var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    Array.isArray(_vm.layers)
      ? _c(
          "div",
          { staticClass: "py-0 mt-n3" },
          _vm._l(_vm.layers, function(layer, index) {
            return _c(
              "div",
              {
                key: _vm.level + "-" + index + "-" + layer.mapServiceId,
                staticClass: "pa-0"
              },
              [
                _c(
                  "div",
                  { staticClass: "py-0 mt-n3" },
                  [
                    layer.utiliSyncLayerType === "S"
                      ? [
                          layer.loadStatus !== "failed"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "\n              d-flex\n              flex-sm-nowrap flex-wrap\n              justify-space-between\n              align-center\n              py-0\n              mt-n3\n            "
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      Array.isArray(
                                        _vm.getSublayerItems(layer)
                                      ) &&
                                      _vm.getSublayerItems(layer).length > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-n3",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleShowSublayer(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      !_vm.showSublayers[index]
                                                        ? _vm.mdiChevronDown
                                                        : _vm.mdiChevronUp
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "truncate mt-n1",
                                          class: { "item-title": _vm.level > 0 }
                                        },
                                        [_vm._v(" " + _vm._s(layer.id) + " ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-sm-nowrap flex-wrap align-center"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass: "mr-1",
                                        model: {
                                          value: layer.visible,
                                          callback: function($$v) {
                                            _vm.$set(layer, "visible", $$v)
                                          },
                                          expression: "layer.visible"
                                        }
                                      }),
                                      _c("VisibilityMenu", {
                                        attrs: {
                                          mapServiceId: layer.mapServiceId,
                                          allLayers: _vm.allLayers
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _c("FailedLayerDisplay", {
                                attrs: {
                                  allLayers: _vm.allLayers,
                                  layer: layer,
                                  level: _vm.level
                                }
                              })
                        ]
                      : layer.utiliSyncLayerType === "F"
                      ? [
                          layer.renderer &&
                          ["unique-value", "class-breaks"].includes(
                            layer.renderer.type
                          )
                            ? [
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    d-flex\n                    flex-sm-nowrap flex-wrap\n                    justify-space-between\n                    align-center\n                    py-0\n                    mt-n3\n                  "
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            Array.isArray(
                                              _vm.getSublayerItems(layer)
                                            ) &&
                                            _vm.getSublayerItems(layer).length >
                                              0
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mt-n3",
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleShowSublayer(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            !_vm.showSublayers[
                                                              index
                                                            ]
                                                              ? _vm.mdiChevronDown
                                                              : _vm.mdiChevronUp
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "truncate mt-n1",
                                                class: {
                                                  "item-title": _vm.level > 0
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      layer.utiliSyncLayerName
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-sm-nowrap flex-wrap align-center"
                                          },
                                          [
                                            _c("v-switch", {
                                              staticClass: "mr-1",
                                              model: {
                                                value: layer.visible,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    layer,
                                                    "visible",
                                                    $$v
                                                  )
                                                },
                                                expression: "layer.visible"
                                              }
                                            }),
                                            _c("VisibilityMenu", {
                                              attrs: {
                                                mapServiceId:
                                                  layer.mapServiceId,
                                                allLayers: _vm.allLayers
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]),
                                  layer.renderer
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.viewSymbols,
                                              expression: "viewSymbols"
                                            }
                                          ],
                                          staticClass: "mt-n5"
                                        },
                                        [
                                          layer.renderer.type === "unique-value"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        layer.renderer
                                                          .uniqueValueInfos,
                                                      viewSymbols:
                                                        _vm.viewSymbols
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : layer.renderer.type ===
                                              "class-breaks"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        layer.renderer
                                                          .classBreakInfos,
                                                      viewSymbols:
                                                        _vm.viewSymbols
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            : layer.renderer &&
                              ["simple"].includes(layer.renderer.type)
                            ? [
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                  d-flex\n                  flex-sm-nowrap flex-wrap\n                  justify-space-between\n                  align-center\n                  py-0\n                  mt-n3\n                "
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-sm-nowrap flex-wrap"
                                        },
                                        [
                                          Array.isArray(
                                            _vm.getSublayerItems(layer)
                                          ) &&
                                          _vm.getSublayerItems(layer).length > 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-n3",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleShowSublayer(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.showSublayers[
                                                            index
                                                          ]
                                                            ? _vm.mdiChevronDown
                                                            : _vm.mdiChevronUp
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          layer.renderer
                                            ? _c("SimpleRendererItem", {
                                                attrs: {
                                                  itemInfo: layer,
                                                  viewSymbols: _vm.viewSymbols,
                                                  level: _vm.level
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-sm-nowrap flex-wrap align-center"
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mr-1",
                                            model: {
                                              value: layer.visible,
                                              callback: function($$v) {
                                                _vm.$set(layer, "visible", $$v)
                                              },
                                              expression: "layer.visible"
                                            }
                                          }),
                                          _c("VisibilityMenu", {
                                            attrs: {
                                              mapServiceId: layer.mapServiceId,
                                              allLayers: _vm.allLayers
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ]
                            : layer.loadStatus === "failed"
                            ? _c("FailedLayerDisplay", {
                                attrs: {
                                  allLayers: _vm.allLayers,
                                  layer: layer,
                                  level: _vm.level
                                }
                              })
                            : _vm._e()
                        ]
                      : ["L", "U"].includes(layer.utiliSyncLayerType)
                      ? [
                          layer.utilisyncRenderer.renderer_symbols &&
                          layer.utilisyncRenderer.renderer_symbols.length > 1
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                align-center\n                py-0\n                mt-n3\n              "
                                  },
                                  [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "truncate mt-n1",
                                          class: { "item-title": _vm.level > 0 }
                                        },
                                        [_vm._v(" " + _vm._s(layer.id) + " ")]
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap align-center"
                                      },
                                      [
                                        _c("v-switch", {
                                          staticClass: "mr-1",
                                          model: {
                                            value: layer.visible,
                                            callback: function($$v) {
                                              _vm.$set(layer, "visible", $$v)
                                            },
                                            expression: "layer.visible"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "pa-0 ma-0" }, [
                                  layer.utilisyncRenderer
                                    ? _c("div", { staticClass: "mt-n5" }, [
                                        layer.utilisyncRenderer
                                          .renderer_type === "unique_value"
                                          ? _c(
                                              "div",
                                              [
                                                _c("RendererItemList", {
                                                  attrs: {
                                                    infos:
                                                      layer.utilisyncRenderer
                                                        .renderer_symbols,
                                                    viewSymbols:
                                                      _vm.viewSymbols,
                                                    level: _vm.level
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : layer.utilisyncRenderer
                                              .renderer_type === "class_break"
                                          ? _c(
                                              "div",
                                              [
                                                _c("RendererItemList", {
                                                  attrs: {
                                                    infos:
                                                      layer.utilisyncRenderer
                                                        .renderer_symbols,
                                                    viewSymbols:
                                                      _vm.viewSymbols,
                                                    level: _vm.level
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            : layer.utilisyncRenderer.renderer_symbols &&
                              layer.utilisyncRenderer.renderer_symbols.length <=
                                1
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                align-center\n                py-0\n                mt-n3\n              ",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap"
                                      },
                                      [
                                        Array.isArray(
                                          _vm.getSublayerItems(layer)
                                        ) &&
                                        _vm.getSublayerItems(layer).length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-n3",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleShowSublayer(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.showSublayers
                                                          ? _vm.mdiChevronDown
                                                          : _vm.mdiChevronUp
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("UtilisyncRendererItem", {
                                          attrs: {
                                            itemInfo:
                                              layer.utilisyncRenderer
                                                .renderer_symbols[0],
                                            viewSymbols: _vm.viewSymbols,
                                            layer: layer
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("v-switch", {
                                          staticClass: "mt-4 mr-1",
                                          model: {
                                            value: layer.visible,
                                            callback: function($$v) {
                                              _vm.$set(layer, "visible", $$v)
                                            },
                                            expression: "layer.visible"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            : !layer.utilisyncRenderer.renderer_symbols
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                align-center\n                py-0\n                mt-n3\n              ",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap"
                                      },
                                      [
                                        Array.isArray(
                                          _vm.getSublayerItems(layer)
                                        ) &&
                                        _vm.getSublayerItems(layer).length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-n3",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleShowSublayer(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        !_vm.showSublayers
                                                          ? _vm.mdiChevronDown
                                                          : _vm.mdiChevronUp
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._m(0, true),
                                    _c("div", { staticClass: "px-2" }, [
                                      _vm._v(_vm._s(layer.utiliSyncLayerName))
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-switch", {
                                          staticClass: "mt-4 mr-1",
                                          model: {
                                            value: layer.visible,
                                            callback: function($$v) {
                                              _vm.$set(layer, "visible", $$v)
                                            },
                                            expression: "layer.visible"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            : layer.loadStatus === "failed"
                            ? _c("FailedLayerDisplay", {
                                attrs: {
                                  allLayers: _vm.allLayers,
                                  layer: layer,
                                  level: _vm.level
                                }
                              })
                            : _vm._e()
                        ]
                      : [
                          layer.loadStatus !== "failed"
                            ? _c("div", { staticClass: "pa-0 ma-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                ma-0\n                pa-0\n                align-center\n                py-0\n                mt-n3\n              "
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.viewSymbols,
                                                expression: "viewSymbols"
                                              }
                                            ]
                                          },
                                          [
                                            _c("RendererItem", {
                                              attrs: {
                                                itemInfo: layer.renderer
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "truncate mt-n1" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  layer.utiliSyncLayerName
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-sm-nowrap flex-wrap align-center"
                                      },
                                      [
                                        _c("v-switch", {
                                          staticClass: "mt-4 mr-1",
                                          model: {
                                            value: layer.visible,
                                            callback: function($$v) {
                                              _vm.$set(layer, "visible", $$v)
                                            },
                                            expression: "layer.visible"
                                          }
                                        }),
                                        _c("VisibilityMenu", {
                                          attrs: {
                                            index: index,
                                            allLayers: _vm.allLayers
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            : _c("FailedLayerDisplay", {
                                attrs: {
                                  allLayers: _vm.allLayers,
                                  layer: layer,
                                  level: _vm.level
                                }
                              })
                        ]
                  ],
                  2
                ),
                Array.isArray(_vm.getSublayerItems(layer)) &&
                _vm.getSublayerItems(layer).length > 0 &&
                _vm.showSublayers[index]
                  ? _c(
                      "div",
                      [
                        _c("ChildVisibility", {
                          attrs: {
                            items: _vm.getSublayerItems(layer),
                            level: _vm.level + 1,
                            childMultiLayerImages: _vm.getChildMultiLayerImages(
                              layer
                            ),
                            layer: layer
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "px-2" }, [
      _c("div", {
        staticStyle: {
          "background-color": "black",
          "border-radius": "50%",
          height: "15px",
          width: "15px"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }