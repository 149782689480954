var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "elevation-0 flex-grow-0 py-0 my-0",
      attrs: { height: "56px", dark: "", color: "#3F51B5" }
    },
    [
      _c(
        "v-toolbar-items",
        { staticClass: "d-flex align-end" },
        [
          _c("v-select", {
            staticClass: "map-dropdown",
            style: {
              "max-width": _vm.$vuetify.breakpoint.xsOnly ? "180px" : undefined
            },
            attrs: {
              color: "white",
              dark: "",
              items: _vm.mapChoices,
              "item-text": "label",
              "item-value": "value",
              label: "Map",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.$emit("selected-map-id-changed", _vm.selectedMapId)
              }
            },
            model: {
              value: _vm.selectedMapId,
              callback: function($$v) {
                _vm.selectedMapId = $$v
              },
              expression: "selectedMapId"
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("select-map-screenshot-dialog-close")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }