<template>
  <div class="pl-3">
    <div v-if="Array.isArray(items)" class="mt-n3">
      <div
        class="pa-0 mt-n6"
        v-for="(item, index) of items.slice().reverse()"
        :key="`${level}-${index}-${item.id}`"
      >
        <div class="py-0 mt-n3 pl-3">
          <template v-if="item.utiliSyncLayerType === 'S'">
            <div
              class="
                d-flex
                flex-sm-nowrap flex-wrap
                justify-space-between
                align-center
                py-0
                mt-n3
              "
            >
              <div class="d-flex">
                <v-btn
                  icon
                  @click="toggleShowSublayer(index)"
                  class="ml-n3 mt-n3"
                  v-if="
                    Array.isArray(getSublayers(item.layer)) &&
                    getSublayers(item.layer).length > 0
                  "
                >
                  <v-icon>
                    {{ !showSublayers[index] ? mdiChevronDown : mdiChevronUp }}
                  </v-icon>
                </v-btn>
                <div class="truncate mt-n1 item-title">
                  {{ item.title }}
                </div>
              </div>
              <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                <v-switch class="mr-1" v-model="item.visible"> </v-switch>
              </div>
            </div>
          </template>

          <template v-else-if="item.utiliSyncLayerType === 'F'">
            <template
              v-if="
                item.renderer &&
                ['unique-value', 'class-breaks'].includes(item.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div>
                  <div
                    class="
                      d-flex
                      flex-sm-nowrap flex-wrap
                      justify-space-between
                      align-center
                      py-0
                      mt-n3
                    "
                  >
                    <div class="d-flex">
                      <v-btn
                        icon
                        @click="toggleShowSublayer(index)"
                        class="ml-n3 mt-n3"
                        v-if="
                          Array.isArray(getSublayers(item.layer)) &&
                          getSublayers(item.layer).length > 0
                        "
                      >
                        <v-icon>
                          {{
                            !showSublayers[index]
                              ? mdiChevronDown
                              : mdiChevronUp
                          }}
                        </v-icon>
                      </v-btn>
                      <div class="truncate mt-n1 item-title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                      <v-switch class="mr-1" v-model="item.visible"> </v-switch>
                    </div>
                  </div>
                </div>

                <div v-if="item.renderer" v-show="viewSymbols" class="mt-n5">
                  <div v-if="item.renderer.type === 'unique-value'">
                    <RendererItemList :infos="item.renderer.uniqueValueInfos" />
                  </div>

                  <div v-else-if="item.renderer.type === 'class-breaks'">
                    <RendererItemList :infos="item.renderer.classBreakInfos" />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                item.renderer && ['simple'].includes(item.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div
                  class="
                    d-flex
                    flex-sm-nowrap flex-wrap
                    justify-space-between
                    align-center
                    py-0
                    mt-n3
                  "
                >
                  <div class="d-flex flex-sm-nowrap flex-wrap">
                    <v-btn
                      icon
                      @click="toggleShowSublayer(index)"
                      class="ml-n3 mt-n3"
                      v-if="
                        Array.isArray(getSublayerItems(item)) &&
                        getSublayerItems(item).length > 0
                      "
                    >
                      <v-icon>
                        {{
                          !showSublayers[index] ? mdiChevronDown : mdiChevronUp
                        }}
                      </v-icon>
                    </v-btn>
                    <SimpleRendererItem
                      v-if="item.renderer"
                      :itemInfo="layer"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                    <v-switch class="mr-1" v-model="item.visible"> </v-switch>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else-if="['L', 'U'].includes(item)">
            <template
              v-if="
                item.utilisyncRenderer.renderer_symbols &&
                item.utilisyncRenderer.renderer_symbols.length > 1
              "
            >
              <div class="pa-0 ma-0">
                <div v-if="item.utilisyncRenderer" class="mt-n5">
                  <div
                    v-if="
                      item.utilisyncRenderer.renderer_type === 'unique_value'
                    "
                  >
                    <RendererItemList
                      :infos="item.utilisyncRenderer.renderer_symbols"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                  <div
                    v-else-if="
                      item.utilisyncRenderer.renderer_type === 'class_break'
                    "
                  >
                    <RendererItemList
                      :infos="item.utilisyncRenderer.renderer_symbols"
                      :viewSymbols="viewSymbols"
                      :level="level"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                item.utilisyncRenderer.renderer_symbols &&
                item.utilisyncRenderer.renderer_symbols.length === 1
              "
            >
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  align-center
                  py-0
                  mt-n3
                "
                style="width: 100%"
              >
                <div class="d-flex flex-sm-nowrap flex-wrap">
                  <v-btn
                    icon
                    @click="toggleShowSublayer(index)"
                    class="ml-n3 mt-n3"
                    v-if="
                      Array.isArray(getSublayerItems(item)) &&
                      getSublayerItems(item).length > 0
                    "
                  >
                    <v-icon>
                      {{ !showSublayers ? mdiChevronDown : mdiChevronUp }}
                    </v-icon>
                  </v-btn>
                  <UtilisyncRendererItem
                    :itemInfo="item.utilisyncRenderer.renderer_symbols[0]"
                    :viewSymbols="viewSymbols"
                    :level="level"
                  />
                </div>
                <div>
                  <v-switch class="mt-4 mr-1" v-model="item.visible">
                  </v-switch>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="pa-0 ma-0">
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  ma-0
                  pa-0
                  align-center
                  py-0
                  mt-n6
                "
              >
                <div
                  class="
                    d-flex
                    flex-sm-nowrap flex-wrap
                    align-center
                    gap
                    py-0
                    my-0
                  "
                >
                  <v-btn
                    icon
                    @click="toggleShowSublayer(index)"
                    class="ml-n3 mt-n3"
                    v-if="
                      Array.isArray(getSublayerItems(item)) &&
                      getSublayerItems(item).length > 0
                    "
                  >
                    <v-icon>
                      {{
                        !showSublayers[index] ? mdiChevronDown : mdiChevronUp
                      }}
                    </v-icon>
                  </v-btn>

                  <img
                    v-if="getImages(item).length === 1"
                    :src="`data:${getImages(item)[0].contentType};base64,${
                      getImages(item)[0].imageData
                    }`"
                    :style="{
                      width: `${getImages(item)[0].width}px`,
                      height: `${getImages(item)[0].height}px`,
                    }"
                  />
                  <RendererItem :itemInfo="item.renderer" :level="level" />

                  <div class="truncate mt-n1 item-title">
                    {{ item.title }}
                  </div>
                </div>
                <div class="d-flex flex-sm-nowrap flex-wrap align-center">
                  <v-switch class="mt-4 mr-1" v-model="item.visible">
                  </v-switch>
                </div>
              </div>
            </div>

            <section class="pl-2 mt-n4 pb-4" v-if="getImages(item).length > 1">
              <div
                v-for="image of getImages(item)"
                :key="image.layerId"
                class="
                  d-flex
                  align-center
                  gap
                  flex-wrap flex-sm-nowrap
                  py-2
                  my-0
                "
              >
                <img
                  v-if="image.imageData"
                  :src="`data:${image.contentType};base64,${image.imageData}`"
                  :style="{
                    width: `${image.width}px`,
                    height: `${image.height}px`,
                  }"
                />

                <div>
                  {{ image.label }}
                </div>
              </div>
            </section>
          </template>
        </div>

        <div
          v-if="
            Array.isArray(getSublayerItems(item)) &&
            getSublayerItems(item).length > 0 &&
            showSublayers[index]
          "
        >
          <ChildVisibility
            :items="getSublayerItems(item)"
            :level="level + 1"
            :childMultiLayerImages="childMultiLayerImages"
            :layer="layer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RendererItem from "@/components/shared/RendererItem";
import RendererItemList from "@/components/shared/RendererItemList";
import UtilisyncRendererItem from "@/components/shared/UtilisyncRendererItem";
import SimpleRendererItem from "@/components/shared/SimpleRendererItem";
import { mdiDotsVertical, mdiChevronDown, mdiChevronUp } from "@mdi/js";

export default {
  name: "ChildVisibility",
  props: {
    items: Array,
    viewSymbols: Boolean,
    level: Number,
    childMultiLayerImages: Array,
    layer: Object,
  },
  components: {
    RendererItem,
    RendererItemList,
    UtilisyncRendererItem,
    SimpleRendererItem,
  },
  methods: {
    getSublayerItems(item) {
      return item?.sublayers?.items;
    },
    toggleShowSublayer(index) {
      this.$set(this.showSublayers, index, !this.showSublayers[index]);
    },
    getImages(item) {
      const { childMultiLayerImages } = this;
      const icon = childMultiLayerImages.find(
        (l) => l?.layerName === item?.title
      );
      return icon?.legend ?? [];
    },
  },
  data() {
    return {
      mdiDotsVertical,
      showSublayers: [],
      mdiChevronDown,
      mdiChevronUp,
    };
  },
  watch: {
    layers: {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.showSublayers = val.map(() => false);
        }
      },
    },
  },
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.item-title {
  font-size: 12px;
}
</style>

<style>
.group-toggle {
  cursor: pointer;
}
</style>
