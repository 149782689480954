<template>
  <button
    class="esri-widget--button esri-interactive esri-icon-measure-line"
    @click="openMeasurementWidget"
  ></button>
</template>

<script>
import Vue from "vue";
import Vuetify from "vuetify";
import MeasurementWidget from "@/components/mapView/measurement/MeasurementWidget.vue";
const vuetify = new Vuetify();
const MeasurementWidgetClass = Vue.extend(MeasurementWidget);

export default {
  name: "MeasureButton",
  props: {
    view: Object,
    measurement: Object,
    minimizeTicketList: Function,
  },
  data() {
    return {
      measurementWidgetComponent: undefined,
      oldMeasurementWidgetComponent: undefined,
    };
  },
  methods: {
    openMeasurementWidget() {
      const { view, measurement } = this;
      if (this.measurementWidgetComponent) {
        this.oldMeasurementWidgetComponent = this.measurementWidgetComponent;
      }
      this.measurementWidgetComponent = new MeasurementWidgetClass({
        propsData: {
          view,
          measurement,
        },
        vuetify,
      });
      this.measurementWidgetComponent.$mount();
      if (this.oldMeasurementWidgetComponent) {
        this.view.ui.remove(this.oldMeasurementWidgetComponent.$el);
      }
      this.view.ui.add(this.measurementWidgetComponent.$el, "bottom-right");
      this.minimizeTicketList();
    },
  },
};
</script>
