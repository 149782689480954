<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text x-small v-bind="attrs" v-on="on" class="px-0">
          <v-icon>{{ mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="showTestArcGisLayerConnectionDialog = true">
          <v-list-item-title> Test Connection </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <TestArcGisLayerConnectionDialog
      @test-connection-dialog-close="
        showTestArcGisLayerConnectionDialog = false
      "
      :showTestArcGisLayerConnectionDialog="showTestArcGisLayerConnectionDialog"
      :allLayers="allLayers"
      :mapServiceId="mapServiceId"
      v-if="showTestArcGisLayerConnectionDialog"
    />
  </div>
</template>

<script>
import { mdiDotsVertical } from "@mdi/js";
import TestArcGisLayerConnectionDialog from "@/components/mapView/layers/visibility/visibility-menu/TestArcGisLayerConnectionDialog";

export default {
  name: "VisibilityMenu",
  data() {
    return {
      mdiDotsVertical,
      showTestArcGisLayerConnectionDialog: false,
    };
  },
  props: {
    index: Number,
    allLayers: Array,
    mapServiceId: String,
  },
  components: {
    TestArcGisLayerConnectionDialog,
  },
};
</script>
