var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between flex-grow-1 gap" },
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("p", { staticClass: "pb-1 my-0 caption settings-heading" }, [
            _vm._v("Color")
          ]),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "pa-0 d-flex",
                              attrs: { text: "", "x-small": "" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "div",
                            {
                              style: {
                                "background-color": _vm.pointOptions.pointFill,
                                width: "25px",
                                height: "25px"
                              }
                            },
                            [_vm._v("   ")]
                          ),
                          _c("v-icon", { staticClass: "my-0 py-0" }, [
                            _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c("ColorPicker", {
                        on: {
                          "color-picker-cancelled": function($event) {
                            _vm.showFillDialog = false
                          },
                          input: function($event) {
                            _vm.showFillDialog = false
                            _vm.$emit("point-options-changed", _vm.pointOptions)
                          }
                        },
                        model: {
                          value: _vm.pointOptions.pointFill,
                          callback: function($$v) {
                            _vm.$set(_vm.pointOptions, "pointFill", $$v)
                          },
                          expression: "pointOptions.pointFill"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2", staticStyle: { width: "120px" } },
        [
          _c("p", { staticClass: "pb-1 my-0 caption settings-heading" }, [
            _vm._v("Outline Color")
          ]),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "pa-0 d-flex",
                              attrs: { text: "", "x-small": "" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "div",
                            {
                              style: {
                                width: "25px",
                                height: "25px",
                                "background-color":
                                  _vm.pointOptions.pointOutlineColor
                              }
                            },
                            [_vm._v("   ")]
                          ),
                          _c("v-icon", { staticClass: "my-0 py-0" }, [
                            _vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c("ColorPicker", {
                        on: {
                          "color-picker-cancelled": function($event) {
                            _vm.showFillDialog = false
                          },
                          input: function($event) {
                            _vm.showFillDialog = false
                            _vm.$emit("point-options-changed", _vm.pointOptions)
                          }
                        },
                        model: {
                          value: _vm.pointOptions.pointOutlineColor,
                          callback: function($$v) {
                            _vm.$set(_vm.pointOptions, "pointOutlineColor", $$v)
                          },
                          expression: "pointOptions.pointOutlineColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("p", { staticClass: "pb-1 my-0 caption settings-heading" }, [
            _vm._v("Outline Width")
          ]),
          _c("v-slider", {
            attrs: { max: 16, min: 0, "hide-details": "" },
            on: {
              input: function($event) {
                return _vm.$emit("point-options-changed", _vm.pointOptions)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "number-input my-0 py-0 mt-n2",
                      staticStyle: { width: "20px" },
                      attrs: { readonly: "", "hide-details": "" },
                      model: {
                        value: _vm.pointOptions.pointOutlineWidth,
                        callback: function($$v) {
                          _vm.$set(_vm.pointOptions, "pointOutlineWidth", $$v)
                        },
                        expression: "pointOptions.pointOutlineWidth"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.pointOptions.pointOutlineWidth,
              callback: function($$v) {
                _vm.$set(_vm.pointOptions, "pointOutlineWidth", $$v)
              },
              expression: "pointOptions.pointOutlineWidth"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 flex-grow-1" },
        [
          _c("p", { staticClass: "pb-1 px-1 my-0 caption" }, [
            _vm._v("Symbol Size")
          ]),
          _c("v-slider", {
            attrs: { max: 40, min: 0, "hide-details": "" },
            on: {
              change: function($event) {
                return _vm.$emit("point-options-changed", _vm.pointOptions)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "number-input my-0 py-0 mt-n2",
                      staticStyle: { width: "20px" },
                      attrs: { readonly: "", "hide-details": "" },
                      model: {
                        value: _vm.pointOptions.size,
                        callback: function($$v) {
                          _vm.$set(_vm.pointOptions, "size", $$v)
                        },
                        expression: "pointOptions.size"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.pointOptions.size,
              callback: function($$v) {
                _vm.$set(_vm.pointOptions, "size", $$v)
              },
              expression: "pointOptions.size"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }