var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("validation-provider", {
            ref: "field",
            attrs: {
              slim: "",
              name: _vm.item.question.label,
              rules: {
                required: _vm.isRequired
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  var valid = ref.valid
                  return [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "max-width": "100%",
                          "close-on-content-click": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        staticClass: "time-field",
                                        attrs: {
                                          label: _vm.item.question.label,
                                          color: "#3F51B5",
                                          "error-messages": errors,
                                          success: valid,
                                          disabled: _vm.readOnly,
                                          readonly: "",
                                          clearable: "",
                                          "hide-details": ""
                                        },
                                        on: { input: _vm.onInput },
                                        model: {
                                          value: _vm.timeValue,
                                          callback: function($$v) {
                                            _vm.timeValue = $$v
                                          },
                                          expression: "timeValue"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.showTimePicker,
                          callback: function($$v) {
                            _vm.showTimePicker = $$v
                          },
                          expression: "showTimePicker"
                        }
                      },
                      [
                        _c("v-time-picker", {
                          on: { change: _vm.onInput },
                          model: {
                            value: _vm.timeValue,
                            callback: function($$v) {
                              _vm.timeValue = $$v
                            },
                            expression: "timeValue"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }