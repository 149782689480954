<template>
  <v-card v-show="showMeasurementWidget" width="300px" v-resize>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <v-btn
            depressed
            :text="selectedMeasurement !== 'distance'"
            @click="distanceMeasurement"
            :color="selectedMeasurement === 'distance' ? '#3F51B5' : undefined"
            :class="{ whiteText: selectedMeasurement === 'distance' }"
            >Line</v-btn
          >
          <v-btn
            depressed
            :text="selectedMeasurement !== 'area'"
            @click="areaMeasurement"
            :color="selectedMeasurement === 'area' ? '#3F51B5' : undefined"
            :class="{ whiteText: selectedMeasurement === 'area' }"
            >Area</v-btn
          >
        </div>
        <div>
          <v-btn
            text
            icon
            @click="
              measurement.clear();
              showMeasurementWidget = false;
            "
          >
            <v-icon color="#3F51B5">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div ref="measurementContainer" id="measurement-container">
        <v-card flat v-if="loading">
          <v-card-text>
            <div class="d-flex justify-center">
              <div class="d-flex align-center">Loading...</div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { loadModules } from "esri-loader";

export default {
  name: "MeasurementWidget",
  props: {
    view: Object,
  },
  data() {
    return {
      showMeasurementWidget: true,
      mdiClose,
      measurement: undefined,
      selectedMeasurement: "distance",
      loading: false,
    };
  },
  directives: {
    resize: {
      inserted(el) {
        const measurementContainer = document.querySelector(
          "#measurement-container"
        );
        if (!measurementContainer) {
          return;
        }
        const mutationObserver = new MutationObserver(() => {
          const resizeObserver = new ResizeObserver(() => {
            setTimeout(() => {
              el.style.height = `${measurementContainer.clientHeight + 100}px`;
            });
          });

          resizeObserver.observe(measurementContainer);
        });
        mutationObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      },
    },
  },
  methods: {
    distanceMeasurement() {
      this.selectedMeasurement = "distance";
      this.measurement.clear();
      const { type } = this.view;
      this.measurement.activeTool =
        type.toUpperCase() === "2D" ? "distance" : "direct-line";
    },
    areaMeasurement() {
      this.selectedMeasurement = "area";
      this.measurement.clear();
      this.measurement.activeTool = "area";
    },
    async addMeasurementWidget() {
      const [Measurement] = await loadModules(["esri/widgets/Measurement"]);
      const { view } = this;
      this.measurement = new Measurement({
        view,
        container: this.$refs.measurementContainer,
        areaUnit: "imperial",
        linearUnit: "imperial",
      });
    },
  },
  async mounted() {
    this.loading = true;
    await this.addMeasurementWidget();
    this.distanceMeasurement();
    this.loading = false;
  },
};
</script>

<style scoped>
.whiteText {
  color: white;
}
</style>
