var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    ref: "field",
    attrs: {
      name: _vm.item.question.label,
      rules: {
        required: _vm.isRequired
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var valid = ref.valid
          var validated = ref.validated
          return [
            _c(
              "v-card",
              { staticClass: "elevation-0", attrs: { width: "100%" } },
              [
                _c(
                  "v-card-title",
                  {
                    staticClass:
                      "ma-0 pa-0 d-flex flex-row justify-space-between elevation-0"
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "caption",
                        style: {
                          color: validated && !valid ? "#ff5252" : undefined
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.item.number + " " + _vm.item.question.label
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-n3",
                        attrs: {
                          text: "",
                          color: "#3F51B5",
                          disabled: _vm.isDisabled
                        },
                        on: { click: _vm.clearSignature }
                      },
                      [_vm._v(" Clear ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  { staticClass: "pa-0 mb-2" },
                  [
                    _c("VueSignaturePad", {
                      ref: "signaturePad",
                      attrs: {
                        width: "100%",
                        height: "150px",
                        options: { onBegin: _vm.onBegin, onEnd: _vm.onEnd },
                        customStyle: {
                          border:
                            (_vm.$vuetify.breakpoint.xsOnly ? "3px" : "2px") +
                            " solid " +
                            (validated && !valid ? "#ff5252" : "black")
                        }
                      }
                    }),
                    !valid
                      ? _c(
                          "p",
                          {
                            staticClass: "caption",
                            style: {
                              color: validated && !valid ? "#ff5252" : undefined
                            }
                          },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }