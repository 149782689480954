<template>
  <v-dialog
    :value="showFormIdDialog"
    max-width="400px"
    @click:outside="$emit('form-id-dialog-close')"
  >
    <v-card>
      <v-card-text class="pa-5">
        <div class="caption">Form Submission ID</div>
        <div class="d-flex justify-space-between">
          <div>{{ formResultId }}</div>
          <v-btn icon class="mt-n2" @click="copyToClipboard">
            <v-icon color="#3F51B5"> {{ mdiContentCopy }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentCopy } from "@mdi/js";

export default {
  name: "FormIdDialog",
  props: {
    showFormIdDialog: Boolean,
    formResultId: String,
  },
  data() {
    return {
      mdiContentCopy,
    };
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.formResultId);
    },
  },
};
</script>
