<template>
  <v-dialog :value="showPdfDialog" fullscreen>
    <v-card style="overflow-x: hidden" class="d-flex flex-column">
      <v-toolbar
        dark
        class="elevation-0 flex-grow-0"
        width="100%"
        color="#3F51B5"
        height="56px"
      >
        <div>PDF Viewer</div>
        <v-spacer />
        <v-btn icon @click="$emit('pdf-viewer-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-0 ma-0 d-flex flex-column flex-grow-1">
        <PdfViewer
          v-if="showPdfDialog"
          :src="fileUrl"
          style="width: 100%; height: calc(100vh - 130px)"
          class="flex-grow-1"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end flex-grow-0">
        <v-btn dark color="#3F51B5" @click="downloadFile(selectedPdfFileUrl)">
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  name: "PdfViewerDialog",
  props: {
    showPdfDialog: Boolean,
    selectedPdfFileUrl: String,
  },
  components: { PdfViewer },
  data() {
    return {
      mdiClose,
      fileUrl: "",
    };
  },
  methods: {
    downloadFile(selectedPdfFileUrl) {
      const link = document.createElement("a");
      link.download = "Image";
      link.href = selectedPdfFileUrl;
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 500);
    },
    async getFileUrl() {
      const { data } = await axiosWithRegularAuth.get(this.selectedPdfFileUrl, {
        responseType: "blob",
      });
      const fileUrl = URL.createObjectURL(data);
      this.fileUrl = fileUrl;
    },
  },
  beforeMount() {
    this.getFileUrl();
  },
};
</script>
